import React,{useState,useEffect}  from "react";
import { RootState } from "Store/mainStore";
import { useSelector } from "react-redux";
import TextInput, {TextInputLabelContent} from "@nokia-csf-uxr/ccfk/TextInput";


interface DataList {
    data: Array<any>,
    active_step: string,
    updateDispatchStore?: Function
    isDisabled:boolean
    errorMessageText?: Function,
    stepKey?: string,
    compositeField? : string

}

const TextField = (props:DataList) => {
    const dynamicSelector = useSelector((state: RootState) => state.dynamic);
    const label = props.data[1].required ?  props.data[1].label + ' *' : props.data[1].label
    const [errorDisabled, setErrorDisabled] = useState(props.data[1].required);



    useEffect(() => {
        if(props.data[1].required){
            if(props.compositeField && props.stepKey){
                if(dynamicSelector.data.element[props.active_step][props.compositeField][props.stepKey]){
                    if(dynamicSelector.data.element[props.active_step][props.compositeField][props.stepKey][props.data[0]] !== ""){
                        setErrorDisabled(false)
                    }
                    else{
                        setErrorDisabled(true)
                    }
                }
            }
            else{
                if(dynamicSelector.data.element[props.active_step]){
                    if(dynamicSelector.data.element[props.active_step][props.data[0]] !== ""){
                        setErrorDisabled(false)
                    }
                    else{
                        setErrorDisabled(true)
                    }
                }
            }
        }
    },[dynamicSelector.data.element[props.active_step]])

    const updateDispatchStoreData = (value) => {
        if(props.compositeField && props.stepKey){
            props.updateDispatchStore(props.compositeField,props.stepKey,props.data[0],value)
        }
        else{
            props.updateDispatchStore(props.data[0],value)
        }
    }

    const getTextFieldValue = () => {
        let textFieldValue = ""
        if(props.compositeField && props.stepKey){
            textFieldValue =  dynamicSelector.data.element[props.active_step]?.[props.compositeField] ? dynamicSelector.data.element[props.active_step][props.compositeField][props.stepKey][props.data[0]] : ""
        }
        else{
            textFieldValue = dynamicSelector.data.element[props.active_step] ? dynamicSelector.data.element[props.active_step][props.data[0]] : ""
        }
        return textFieldValue
    }

    const formatSentence = (text) => {
        let data = "Enter " + text
        return data.substring(0,1).toUpperCase()+data.substring(1).toLowerCase();  
    };

    return(
        <>
        <div style={{marginTop:'10px'}}>
         <TextInputLabelContent>
                                {label}
            </TextInputLabelContent>
            <TextInput

                            id={props.data[1].id}
                            style={{ width: "100%" }}
                            value={getTextFieldValue()}
                            disabled={props.isDisabled}
                            placeholder={formatSentence(label)}
                            error={errorDisabled}
                            errorMessage={errorDisabled ? props.errorMessageText(props.data[1].label) : ""}
                            onChange={(e) => {
                                updateDispatchStoreData(e.target.value)

                            }
                            }
                            />
            </div>
        </>
    )
}

export default TextField;