import React, { useState } from "react";
import Dialog, {
    DialogContent,
    DialogFooter,
    DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";
import CloseCloseIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';
import userStyles from "../Style/UserManagement.module.css";
import { Label } from "@nokia-csf-uxr/ccfk";
import { Button, TextInput } from "@nokia-csf-uxr/ccfk";
import MultiSelect from "Pages/marketplace/common/MultiSelect";
import { RootState } from "Store/mainStore";
import { useSelector } from "react-redux";
import { modifyData } from "../user-management/utils/constants";
import OOT from "Constants/services/oot";
import useFetchRoles from "../hooks/useFetchRoles";

type IDAddRoleModelProp = {
    onClose: () => void;
    showSnackbar: (message: string) => void;

};

const DIALOG_HEIGHT = "50%";
const DIALOG_WIDTH = "20%";

const CONFIRM_ALERT_STYLE = {
    top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
    height: DIALOG_HEIGHT,
    minHeight: DIALOG_HEIGHT,
    left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    width: DIALOG_WIDTH,
};


const AddRoleDialog = ({ onClose, showSnackbar }: IDAddRoleModelProp) => {

    const [roleName, setRoleName] = useState('');
    const [roleId, setRoleId] = useState('');
    const [organizationType, setOrganizationType] = useState([]);
    const personaList = useSelector((state: RootState) => state.userManagement.metaData.persona);

    const modifiedPersonaData = modifyData(personaList, 'id', 'name');

    const { fetchUserRoles } = useFetchRoles();


    const handleRoleName = (name) => {
        setRoleName(name);
        if (name) {
            let formattedName = name.toLowerCase()
            const roleId = formattedName.includes(' ') ? formattedName.replace(/\s+/g, '_') : formattedName;
            setRoleId(roleId);
        }else{
            setRoleId('');
        }

    }

    const handleOrganizationTypeSelection = (selectedItems) => {
        setOrganizationType(selectedItems);
    }



    const handleSubmit = async () => {
        try {
            let data = {
                "id": roleId,
                "name": roleName,
                "data_objects": {
                    "allowed_org_type": organizationType.map((org) => org.id)
                }
            }
            const response = await OOT.saveUserRoles(data, 'add');
            if (response.status) {
                fetchUserRoles(""); // Ensure roles are fetched after successful save
                showSnackbar(response.msg); // Show success message
                setTimeout(() => {
                    onClose(); // Close dialog after a slight delay
                }, 100);

            }
        } catch (error) {
            showSnackbar("Error while saving roles!");
        }
    }

    const disableCreate = roleName.trim() === '' || organizationType.length === 0;


    const handleOnClose = () => {
        onClose();
    }
    return (
        <Dialog
            isOpen={true}
            ariaHideApp={false}
            style={{ content: CONFIRM_ALERT_STYLE }}
        >
            <DialogTitle
                title={"Add role"}
                buttonContent={<CloseCloseIcon />}
                buttonProps={{
                    onClick: handleOnClose,
                }}
            />
            <DialogContent isTopDividerVisible={false} isBottomDividerVisible={false}>
                <div className={userStyles.marginBottom}>
                    <Label className={userStyles.userLabel}>Role name <span style={{ color: '#C91514' }}>*</span></Label>

                    <TextInput
                        id="role-name"
                        // disabled={prop.mode === "edit" && prop.selectedData.name.trim() !== ""}
                        value={roleName}
                        variant={"outlined"}
                        placeholder="Enter role name"
                        onChange={(e) => handleRoleName(e.target.value)}
                    />

                </div>
                <div className={userStyles.marginBottom}>
                    <Label className={userStyles.userLabel}>Organisation <span style={{ color: '#C91514' }}>*</span></Label>
                    <MultiSelect
                        placeholder={"Select organisation"}
                        label={""}
                        data={modifiedPersonaData}
                        selectedItems={organizationType}
                        onSelected={(selectedItems) => handleOrganizationTypeSelection(selectedItems)}
                    //onSelected={handleOrganizationTypeSelection}
                    />

                </div>

            </DialogContent>
            <DialogFooter>
                
                    <Button onClick={handleOnClose}>Cancel</Button>
                    <Button variant="call-to-action" onClick={handleSubmit} disabled={disableCreate}>
                        Create
                    </Button>
                
            </DialogFooter>
        </Dialog >
    )
}

export default AddRoleDialog;