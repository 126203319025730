import React, { useEffect, useState } from "react";
import { Card } from "@nokia-csf-uxr/ccfk";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import Button from '@nokia-csf-uxr/ccfk/Button';
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import userStyles from './Style/RadioNetworkCiq.module.css'
import HorizontalDivider from '@nokia-csf-uxr/ccfk/HorizontalDivider';
import DownloadIcon from '../../Assets/Icons/download.svg'
import ExpansionPanelRadioNetworkPlan from "./ExpansionPanelRadioNetworkPlan";
import CaptureRadioNetworkPlanInvoice from "./CaptureRadioNetworkPlanInvoice";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { formatDate } from '../marketplace/Utils/commons';
import CaptureRadioNetworkCIQDialog from "./CaptureRadioNetworkCIQDialog";
import { setProp } from "Store/Actions";
import { useNavigate } from 'react-router-dom';



const SelectedRadioNetworkPlanPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const radioNetworkCIQData = useSelector((stete: RootState) => stete.radioNetworkCIQ)
  const configstepperSelectorData = useSelector((state: RootState) => state.config_stepper)
  const showCaptureDialog = useSelector((state: RootState) => state.radioNetworkCIQ.captureNetworkPlanModal);
  const isEmpty = Object.keys(radioNetworkCIQData.selectedNetworkPlan).length === 0;
  if(isEmpty){
    navigate('/radio_network_ciq');
  }
  return (
    <>
      {
        showCaptureDialog && <CaptureRadioNetworkCIQDialog onClose={() => dispatch(setProp({ slice: "radioNetworkCIQ", key: "captureNetworkPlanModal", value: false }))} />
      }
      {!isEmpty && <Card style={{ padding: '1rem', height: 'auto' }}>
        <div className={userStyles.captureView}>
          <div>
            Captured data
          </div>
          <div>
            <Button variant="secondary-small">
              <ButtonIcon><img src={DownloadIcon} alt="order-icon" /></ButtonIcon>
              <PDFDownloadLink document={<CaptureRadioNetworkPlanInvoice radioNetworkCIQ={radioNetworkCIQData.selectedNetworkPlan} configstepperSelector={configstepperSelectorData} formDate={formatDate(new Date(), 'yyyy')} />} fileName={'CaptureRadioNetworkPlan.pdf'} style={{ textDecoration: 'none', color: '#FFFFFF !important' }}>
                <ButtonText style={{ color: '#FFFFFF !important' }}>Download</ButtonText>
              </PDFDownloadLink>
            </Button>
          </div>
        </div>
        <div style={{ marginTop: '10px' }}>
          <HorizontalDivider />
        </div>
        <div>
          <ExpansionPanelRadioNetworkPlan />
        </div>
      </Card>}
    </>
  )
}

export default SelectedRadioNetworkPlanPage;