import React, { useState, useEffect, useCallback, useRef } from 'react';
import MultiSelectSmartList, { MultiSelectSmartListHeader, MultiSelectSmartCheckboxList, MultiSelectSmartListCheckbox, MultiSelectSmartListDivider } from '@nokia-csf-uxr/ccfk/MultiSelectSmartList';
import _cloneDeep from 'lodash/cloneDeep';
import OOT from 'Constants/services/oot';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'Store/mainStore';
import Label from '@nokia-csf-uxr/ccfk/Label';
import userStyles from '../../Style/UserManagement.module.css'
import { setUserInputFields } from 'Store/contentState/userManagementSlice';
import { getRequestedInfo } from '../utils/constants';
import FilterField, { FilterFieldIcon } from "@nokia-csf-uxr/ccfk/FilterField";
import { setProp } from 'Store/Actions';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';

const ENTER_KEY = 'Enter';
const SPACE_KEY = ' ';



const isSelectionKeyPressed = key => key && (key === ENTER_KEY || key === SPACE_KEY);


const AccountListSmartSelect = (prop) => {
  const dispatch = useDispatch();
  const filter = useSelector((state: RootState) => state.userManagement.hierachyFilterQuery);
  const selectedOrgType = useSelector((state: RootState) => state.userManagement.userData.orgType);
  const selectedAccountNumbers = useSelector((state: RootState) => state.userManagement.userData.oneStarNumber);

  const markets = useSelector((state: RootState) => state.userManagement.userData.markets)

  const [sortedData, setSortedData] = useState([]);
  const [accountSearchText, setAccountSearchText] = useState('');
  const [selectAllSelected, setSelectAllSelected] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [offset, setOffset] = useState(0);
  // const [accountsIdsToSearch, setAccountsIdsToSearch] = useState(accountOneStarNumbers);
  const limit = 10;

  const listRef = useRef(null);
  const inputRef = useRef(null);
  const selectAllSelectedRef = useRef(selectAllSelected);



  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      let updatedFilter = filter;

      // Append only if `accountSearchText` is not empty
      if (accountSearchText.trim()) {
        updatedFilter += ` AND (name like '%${accountSearchText}%')`;
      }

      dispatch(setProp({ slice: 'userManagement', key: 'hierachyFilterQuery', value: updatedFilter }));
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setAccountSearchText(value);
    // Update filter only if `value` is not empty
    if (value === "") {
      const nameLikeRegex = /AND \(name like '%.*?%'\)/;
      let updatedFilter = filter.replace(nameLikeRegex, '').trim();
      dispatch(setProp({ slice: 'userManagement', key: 'hierachyFilterQuery', value: updatedFilter }));
    }
  };

  const handleClear = () => {
    setAccountSearchText(''); // Clear the search text
    const nameLikeRegex = /AND \(name like '%.*?%'\)/;
    let updatedFilter = filter.replace(nameLikeRegex, '').trim();
    dispatch(setProp({ slice: 'userManagement', key: 'hierachyFilterQuery', value: updatedFilter }));
    resetScroll()
  };



  const isFilterEmpty = (filter) => !filter || Object.keys(filter).length === 0;

  // const sortDataBySelection = (data) => {
  //   const selectedData = [];
  //   const unSelectedData = [];
  //   data.forEach((item) => (item.selected ? selectedData.push(item) : unSelectedData.push(item)));
  //   return selectedData.concat(unSelectedData);
  // };

  const updateSelectedAccountDetails = (data, selectAllSelected = false) => {

    if (selectAllSelected) {
      // If selectAllSelected is true, clear the fields
      dispatch(setUserInputFields({ field: "threeStarNumber", value: [] }));
      dispatch(setUserInputFields({ field: "business_name", value: [] }));
      dispatch(setUserInputFields({ field: "oneStarNumber", value: [] }));
    } else {
      // Otherwise, process the selected accounts
      const selectedAccounts = data.filter((item) => item.selected);


      const accountThreeStarNumbers = selectedAccounts.map((item) => item.parentAccountNumber);

      const accountBusinessNames = selectedAccounts.map((item) => item.businessName);
      const accountOneStarNumbers = selectedAccounts.map((item) => item.id);

      dispatch(setUserInputFields({ field: "threeStarNumber", value: accountThreeStarNumbers }));
      dispatch(setUserInputFields({ field: "business_name", value: accountBusinessNames }));
      dispatch(setUserInputFields({ field: "oneStarNumber", value: accountOneStarNumbers }));
    }
  };

  const fetchAccountList = async (currentOffset = offset) => {
    if (isFetching) return;
    setIsFetching(true);
    try {
      const requested_info = getRequestedInfo(selectedOrgType[0]);
      const response = await OOT.getAccountsForInternalExternal(filter, limit, currentOffset, requested_info, []);
      if (response.status) {
        const data = response.data;
        if (data && data.length > 0) {
          const newAccountList = data.map((item) => ({
            id: item.customer_account_number,
            label: item.name,
            businessName: item.business_name,
            parentAccountNumber: item.parent_account_number,
            selected: selectAllSelectedRef.current
              ? true
              : prop.mode === 'edit'
                ? selectedAccountNumbers.includes(item.customer_account_number)
                : false,
            disabled: false,
          }));
          setSortedData((prevData) => [...prevData, ...newAccountList]);
          //setSelectAllSelected(true); // Set "Select All" to true if all items are selected by default
          // console.log(currentOffset)
          setOffset((prevOffset) => prevOffset + limit);

          if (newAccountList.length < limit) setHasMoreData(false); // No more data
        } else {
          setHasMoreData(false); // Stop fetching if no data returned
        }
      }
    } catch (error) {
      console.error("Error fetching account list:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleScroll = () => {
    if (!listRef.current || isFetching || !hasMoreData) return;

    const { scrollTop, clientHeight, scrollHeight, clientWidth } = listRef.current;

    console.log(scrollTop, clientHeight, scrollHeight, clientWidth);
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      fetchAccountList(); // Fetch more records when close to bottom
    }
  };

  const handleOnChange = (id) => {
    const updatedData = sortedData.map((item) =>
      item.id === id ? { ...item, selected: !item.selected } : item
    );
    setSortedData(updatedData);
    setSelectAllSelected(updatedData.every((item) => item.selected));
    updateSelectedAccountDetails(updatedData);
  };


  const resetScroll = () => {
    // Reset pagination or offset to the initial state
    setOffset(0);
    // Optionally reset the 'hasMoreData' flag if necessary
    setHasMoreData(true);
    // Optionally scroll the list back to the top
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
  };

  const handleSelectAllOnChange = (e) => {
    const updatedData = sortedData.map((item) => ({
      ...item,
      selected: e.target.checked,
    }));

    setSortedData(updatedData);
    setSelectAllSelected(e.target.checked);
    updateSelectedAccountDetails(updatedData, e.target.checked);
  };

  useEffect(() => {
    selectAllSelectedRef.current = selectAllSelected;
  }, [selectAllSelected]);

  useEffect(() => {
    if (!isFilterEmpty(filter)) {
      // Reset pagination and data state
      setOffset(0); // Ensure this updates first
      // setHasMoreData(true);
      setSortedData([]);
      // Trigger API call after resetting state
      fetchAccountList(0);
    } else if (sortedData.length > 0 || offset > 0) {
      setSortedData([]);
      setOffset(0);
      dispatch(setUserInputFields({ field: "threeStarNumber", value: [] }));
      dispatch(setUserInputFields({ field: "business_name", value: [] }));
      dispatch(setUserInputFields({ field: "oneStarNumber", value: [] }));
    }
  }, [filter, markets]);

  useEffect(() => {
    const currentRef = listRef.current;
    if (currentRef) currentRef.addEventListener("scroll", handleScroll);
    return () => currentRef?.removeEventListener("scroll", handleScroll);
  }, [listRef, isFetching, hasMoreData]);

  return (
    <>
      {sortedData.length > 0 && (
        <div>
          <Label className={userStyles.userLabel}>
            Account
            {/* <span style={{ color: "#C91514" }}>*</span> */}
          </Label>
          <FilterField
            value={accountSearchText}
            onChange={handleChange}
            variant="outlined"
            textInputProps={{
              placeholder: "Search",
              showClearButton: true,
              clearButtonProps: {
                onClick: handleClear,
              },
              inputProps: {
                'aria-label': 'Search',
                onKeyDown: handleSearch,
                ref: inputRef, // Ref applied directly to the input element

              },
            }}
            renderIcon={<FilterFieldIcon title="click enter to search" />}

          />
          <MultiSelectSmartList ref={listRef} style={{ height: "400px", overflowY: "auto", position: 'relative' }}>
            <MultiSelectSmartListHeader
              title="Select all"
              selectAllCheckbox={{
                isEnabled: true,
                checked: selectAllSelected,
                indeterminate: sortedData.some((item) => item.selected),
                onChange: (e) => handleSelectAllOnChange(e),
              }}
            />
            {/* <MultiSelectSmartCheckboxList>
              {sortedData.map((data, index) => (
                <React.Fragment key={`listItem-${index}`}>
                  <MultiSelectSmartListCheckbox
                    label={`${data.label} | ${data.id} | ${data.parentAccountNumber}`}
                    selected={data.selected}
                    disabled={data.disabled}
                    onChange={() => handleOnChange(data.id)}
                  />
                  {index + 1 < sortedData.length &&
                    !sortedData[index + 1].selected &&
                    data.selected && <MultiSelectSmartListDivider />}
                </React.Fragment>
              ))}
            </MultiSelectSmartCheckboxList> */}

            <MultiSelectSmartCheckboxList>
              {sortedData.map((data, index) => {
                const fullLabel = `${data.label} | ${data.id} | ${data.parentAccountNumber}`;
                //console.log(fullLabel.length)
                const maxLength = 50; // Set max length for truncation
                //const truncatedLabel = fullLabel.length > maxLength ? `${fullLabel.slice(0, maxLength)}...` : fullLabel;
                //console.log(fullLabel.length > maxLength)
                const truncatedLabel = `${fullLabel.slice(0, maxLength)}...`
                return (
                  <React.Fragment key={`listItem-${index}`}>
                    <div>
                      <MultiSelectSmartListCheckbox
                        label={fullLabel.length > maxLength ? <Tooltip
                          tooltip={fullLabel}
                          placement="bottom"
                          trigger="hover"
                          containerProps={{
                            boundary: listRef?.current || "scrollParent", // Constrains tooltip within scrollable area
                            rootBoundary: "viewport", // Prevents escaping the viewport
                            preventOverflow: true,
                          }}
                        >
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "inline-block",
                              maxWidth: "200px",
                            }}
                          >
                            {truncatedLabel}
                          </span>
                        </Tooltip> : fullLabel}
                        selected={data.selected}
                        disabled={data.disabled}
                        onChange={() => handleOnChange(data.id)}
                      />
                    </div>
                    {index + 1 < sortedData.length &&
                      !sortedData[index + 1].selected &&
                      data.selected && <MultiSelectSmartListDivider />}
                  </React.Fragment>
                );
              })}
            </MultiSelectSmartCheckboxList>
          </MultiSelectSmartList>
        </div>
      )}
    </>
  );
};



export default AccountListSmartSelect;
