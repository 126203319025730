import React, { useState } from "react";
import { IPV4REGEX, IPV6REGEX, machineIPTypeOptions } from "../utils/constants";
import userStyles from "../../Style/UserManagement.module.css"
import RadioButton from '@nokia-csf-uxr/ccfk/RadioButton';
import { Button, TextInput } from "@nokia-csf-uxr/ccfk";
import Chip, {
    ChipAvatar,
    ChipLabel,
    ChipIconButton,
} from '@nokia-csf-uxr/ccfk/Chip';
import Label from '@nokia-csf-uxr/ccfk/Label';
import CloseIconLatest from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";


import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import CommonFormFields from "./CommonFormFields";
import { setProp } from "Store/Actions";
import { setWhiteListedIP } from "Store/contentState/userManagementSlice";
import CommonNameEmailField from "./CommonEmailField";


const MachineFormDetails = (prop) => {
    const dispatch = useDispatch();
    const machineUserWhiteListedIP = useSelector((state: RootState) => state.userManagement.userData.whitelisted_ip);
    // State for tracking the type of IP input (IP Address or IP Range)
    const [selectedIPType, setSelectedIPType] = useState('ipAddress');

    // Store IP addresses and IP ranges
    const [ipList, setIpList] = useState(machineUserWhiteListedIP.ip_list || []);
    const [ipRanges, setIpRanges] = useState(machineUserWhiteListedIP.ip_ranges || []);

    // Separate states for current input values (not part of the main lists)
    const [currentIP, setCurrentIP] = useState(''); // Current IP address being entered
    const [currentRangeStart, setCurrentRangeStart] = useState(''); // Current IP range start
    const [currentRangeEnd, setCurrentRangeEnd] = useState(''); // Current IP range end

    const [isIpAdded, setIsIpAdded] = useState(false); // Flag to indicate IP was added
    const [selectedChip, setSelectedChip] = useState(null); // Tracks which chip is selected

    const [warningMessage, setWarningMessage] = useState('');

    // Handle IP type selection change
    const handleMachineIPTypeChange = (value) => {
        setSelectedIPType(value);
        setCurrentIP(''); // Reset single IP address input
        setCurrentRangeStart(''); // Reset IP range start
        setCurrentRangeEnd(''); // Reset IP range end
        setIsIpAdded(false);
        setWarningMessage('');
    };

    // Handle changes to the input fields
    const handleIPAddressChange = (value, type) => {
        if (selectedIPType === 'ipAddress' && type === 'ipAddress') {
            setCurrentIP(value); // Store current IP address
        } else if (selectedIPType === 'ipRange') {
            if (type === 'ipRangeStart') {
                setCurrentRangeStart(value); // Store current range start
            } else if (type === 'ipRangeEnd') {
                setCurrentRangeEnd(value); // Store current range end
            }
        }
    };

    // Handle the addition of the current IP or IP range to the main state
    const handleAddIp = () => {
        // Reset any existing warning
        setWarningMessage('');
        setIsIpAdded(true); // Indicate IP has been added

        // Add the current IP address or IP range to the main state list
        if (selectedIPType === 'ipAddress' && currentIP) {
            // Check for duplicate IP
            if (ipList.includes(currentIP)) {
                setWarningMessage('Duplicate IP address. Please enter a unique IP.');
                return;
            }
            // Check for valid IP
            if (!isValidIPAddress(currentIP)) {
                setWarningMessage('Invalid IP address format. Please enter a valid IP.');
                return;
            }
            setIpList([...ipList, currentIP]); // Add to the list of single IPs
            dispatch(setWhiteListedIP({ type: 'ip_list', value: [...ipList, currentIP] }));
            setCurrentIP(''); // Reset the IP input field
        } else if (selectedIPType === 'ipRange' && currentRangeStart && currentRangeEnd) {
            // Check if range already exists
            const isDuplicateRange = ipRanges.some(
                (range) => range.start === currentRangeStart && range.end === currentRangeEnd
            );
            if (isDuplicateRange) {
                setWarningMessage('Duplicate IP range. Please enter a unique IP range.');
                return;
            }

            // Check if both IPs in the range are valid
            if (!isValidIPAddress(currentRangeStart) || !isValidIPAddress(currentRangeEnd)) {
                setWarningMessage('Invalid IP range. Please enter valid start and end IP addresses.');
                return;
            }
            setIpRanges([...ipRanges, { start: currentRangeStart, end: currentRangeEnd }]);
            dispatch(setWhiteListedIP({ type: 'ip_ranges', value: [...ipRanges, { start: currentRangeStart, end: currentRangeEnd }] }));
            // Add to IP ranges list
            setCurrentRangeStart(''); // Reset the range start field
            setCurrentRangeEnd(''); // Reset the range end field
        }
    };

    // Handle chip selection
    const handleChipSelect = (key) => {
        setSelectedChip(key);
    };

    // Remove specific IP from the list
    const handleRemoveSingleIP = (ip) => {
        setIpList(ipList.filter((item) => item !== ip));
    };

    // Remove specific IP range from the list
    const handleRemoveIPRange = (range) => {
        setIpRanges(ipRanges.filter((r) => r.start !== range.start || r.end !== range.end));
    };

    const isValidIPAddress = (ip) => {
        return IPV4REGEX.test(ip.trim()) || IPV6REGEX.test(ip.trim());
    };


    const isAddButtonDisabled = () => {
        if (selectedIPType === 'ipAddress') {
            return !currentIP || !isValidIPAddress(currentIP);
        } else if (selectedIPType === 'ipRange') {
            return !currentRangeStart.trim() || !currentRangeEnd.trim() || !isValidIPAddress(currentRangeStart) || !isValidIPAddress(currentRangeEnd);
        }
        return true;
    };
    return (
        <>
            <div className={userStyles.gridContainerNew}>
                <CommonNameEmailField mode={prop.mode} />
                {/* <CommonOrganizationType /> */}
                <CommonFormFields mode={prop.mode} />
                {/* <CommonFromOotApprover /> */}
            </div>
            {/* <div className={userStyles.borderBottomLine}></div> */}



            <div style={{ display: 'flex' }}>
                <div className={userStyles.marginBottom}>
                    <Label className={userStyles.userLabel}>IP type <span style={{ color: '#C91514' }}>*</span></Label>
                    <div className={userStyles.dFlex}>
                        {machineIPTypeOptions.map((option) => (
                            <div key={option.value} className={userStyles.dFlex5}>
                                <RadioButton
                                    checked={selectedIPType === option.value}
                                    onChange={(e) => handleMachineIPTypeChange(e.target.value)}
                                    inputProps={{
                                        'aria-label': option.label,
                                        value: option.value,
                                        name: 'addUserRadio'
                                    }}
                                />
                                <Label>{option.label}</Label>
                            </div>
                        ))}

                    </div>
                </div>
                <div style={{ paddingLeft: '30px' }}>
                    {(selectedIPType === 'ipAddress') &&

                        <div className={userStyles.marginBottom}>
                            <Label className={userStyles.userLabel}>IP address <span style={{ color: '#C91514' }}>*</span></Label>
                            <div className={userStyles.dFlex}>
                                <TextInput
                                    id="ip-address"
                                    // disabled={mode === "edit"}
                                    value={currentIP}
                                    variant={"outlined"}
                                    placeholder="e.g. 64.22.44.22"
                                    onChange={(e) => handleIPAddressChange(e.target.value, 'ipAddress')}
                                />
                            </div>
                        </div>}


                    {(selectedIPType === 'ipRange') &&
                        <div style={{ display: 'flex' }}>

                            <div className={userStyles.marginBottom}>
                                <Label className={userStyles.userLabel}>IP start address <span style={{ color: '#C91514' }}>*</span></Label>
                                <div className={userStyles.dFlex}>
                                    <TextInput
                                        id="ip-address"
                                        // disabled={mode === "edit"}
                                        value={currentRangeStart}
                                        variant={"outlined"}
                                        placeholder="e.g. 64.22.44.22"
                                        onChange={(e) => handleIPAddressChange(e.target.value, 'ipRangeStart')}
                                    />
                                </div>
                            </div>
                            <div className={userStyles.marginBottom}>
                                <Label className={userStyles.userLabel}>IP end address <span style={{ color: '#C91514' }}>*</span></Label>
                                <div className={userStyles.dFlex}>
                                    <TextInput
                                        id="ip-address"
                                        // disabled={mode === "edit"}
                                        value={currentRangeEnd}
                                        variant={"outlined"}
                                        placeholder="e.g. 64.22.44.22"
                                        onChange={(e) => handleIPAddressChange(e.target.value, 'ipRangeEnd')}
                                    />
                                </div>
                            </div>

                        </div>
                    }
                </div>
                <div className={userStyles.gridItem}>
                    <div className={`${userStyles.marginBottom}`} style={{ marginTop: '25px' }}>
                        <Button
                            variant="secondary"
                            disabled={isAddButtonDisabled()} onClick={handleAddIp}
                        >
                            <ButtonText>Add</ButtonText>
                        </Button>
                    </div>
                </div>
            </div>



            <div className={userStyles.borderBottomLine}></div>
            <div className={userStyles.gridItem}>
                {(ipList && selectedIPType === 'ipAddress') && <div className={userStyles.dFlex}>
                    {ipList.map((ip, index) => (
                        <Chip
                            key={`ip-${index}`}
                            size="medium"
                            // selected={selectedChip === `ip-${index}`}
                            error={false}
                            onClick={() => handleChipSelect(`ip-${index}`)}
                        >

                            <ChipLabel label={ip} />
                            <ChipIconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemoveSingleIP(ip);
                                }}
                            >
                                <CloseIconLatest />
                            </ChipIconButton>
                        </Chip>
                    ))}


                </div>}
                {
                    (ipRanges && selectedIPType === 'ipRange') && <div className={userStyles.dFlex}>
                        {ipRanges.map((range, index) => (
                            <Chip
                                key={`range-${index}`}
                                size="medium"
                                selected={selectedChip === `range-${index}`}
                                error={false}
                                onClick={() => handleChipSelect(`range-${index}`)}
                            >
                                <ChipLabel label={`${range.start} - ${range.end}`} />
                                <ChipIconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveIPRange(range);
                                    }}
                                >
                                    <CloseIconLatest />
                                </ChipIconButton>
                            </Chip>
                        ))}
                    </div>
                }
            </div>



        </>
    )
}

export default MachineFormDetails;