import React, { useState, useRef, useEffect } from "react";
import styles from "./Style/Search.module.css"
import { Button, FilterField, List, Tooltip } from "@nokia-csf-uxr/ccfk";
import { FilterFieldIcon } from "@nokia-csf-uxr/ccfk/FilterField";
import { ButtonText } from "@nokia-csf-uxr/ccfk/Button";
import noSerachList from "../../Assets/Icons/NoData.svg"
import { ListItemBasic, ListItemText } from "@nokia-csf-uxr/ccfk/List";
import PropTypes from "prop-types";
import Loader from "Components/Elements/Loader";
import { useSelector } from "react-redux";
import { RootState, store } from "Store/mainStore";

const SelectSearchBox = (props) => {
    const { data, onSearchAccount, isLoading, onUpdateSelection, selectedItem } = props;
    const allAccountsSelectedItems = useSelector((state: RootState) => state.userManagement.selectedItems);
    const userOrgType = useSelector((state: RootState) => state.userManagement.userData.orgType);
    const [searchValue, setSearchValue] = useState('');
    const [selected, setSelected] = useState(selectedItem);
    const [eventSelected, setEventSelected] = useState(true)
    const inputRef = useRef(null);
    const maxLength = 45; // Define max visible length

    const isTruncated = searchValue.length > maxLength


    useEffect(() => {
        if (selectedItem) {
            let datafilter = data.filter((item) => item.id === selectedItem)
            if (datafilter && datafilter[0]) {
                setSearchValue(datafilter[0].value)
                setEventSelected(false)
            }
            else {
                setSearchValue("")
            }
        }
        else {
            if (data.length === 0) {
                setSearchValue("")
            }

        }
        //setEventSelected(false)
    }, [userOrgType[0], allAccountsSelectedItems]);

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            onSearchAccount(e.target.value, false)
        }
        else if (e.type === 'click') {
            onSearchAccount(searchValue, false)
        }
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
    };

    const handleClear = () => {
        setSearchValue('');
        onSearchAccount("", true)
        setEventSelected(true)
    };

    const handleEvent = (item) => {
        setSelected(item.id)
        onUpdateSelection(item.id ?? "");
        setEventSelected(false)
        setSearchValue(item.value)
    }



    return (

        <><div className={styles.searchcontainer}>
            <div className={styles.searchbox}>
                <Tooltip
                    tooltip={isTruncated ? searchValue : ""}
                    placement="bottom"
                    trigger="hover"
                    containerProps={{
                        boundary: inputRef?.current || "scrollParent",
                        rootBoundary: "viewport", // Prevents escaping the viewport
                        preventOverflow: true,
                    }}
                    disable={!(searchValue.length > maxLength)}
                >
                    <FilterField
                        value={isTruncated ? searchValue.substring(0, maxLength) + "..." : searchValue}
                        onChange={handleChange}
                        variant="outlined"
                        textInputProps={{
                            placeholder: "Search the account name",
                            showClearButton: true,
                            clearButtonProps: {
                                onClick: handleClear,
                            },
                            inputProps: {
                                'aria-label': 'Search',
                                onKeyDown: handleSearch,
                                ref: inputRef, // Ref applied directly to the input element
                            },
                        }}
                        renderIcon={<FilterFieldIcon title="click enter to search" />} />
                </Tooltip>

                {/* <input type="text" placeholder="Search the account name"> */}
            </div>
            <Button variant="call-to-action" disabled={searchValue != "" ? eventSelected ? false : true : true} onClick={handleSearch} ><ButtonText>Search</ButtonText></Button>
        </div>
            <div className={styles.listData}>
                {isLoading && (
                    <Loader />
                )}
                {eventSelected && <List>
                    {data.map((item, x) => {
                        return (
                            <ListItemBasic
                                key={`item-${x}`}
                                selected={selectedItem ? selectedItem === item.id : false}
                                onClick={() => handleEvent(item)}
                                role="option"
                            >
                                <ListItemText>{item.value}</ListItemText>
                            </ListItemBasic>
                        )
                    })}
                    {!isLoading && data.length === 0 && <div className={styles.emptystate}>
                        <img src={noSerachList} alt="no-items-image" style={{ height: '200px', maxWidth: "100%" }} />
                        <div className={styles.emptyContent}>
                            <h3>Nothing to show</h3>
                            <p>Search the account to see the results</p>
                        </div>
                    </div>}
                </List>}
            </div>
        </>

    )
}


SelectSearchBox.propTypes = {
    data: PropTypes.array,
    onSearchAccount: PropTypes.func,
    isLoading: PropTypes.bool,
    onUpdateSelection: PropTypes.func,
    selectedItem: PropTypes.string
};

export default SelectSearchBox;

