import React, { useEffect, useState, useRef } from "react";
import DataGrid from '@nokia-csf-uxr/ccfk/DataGrid/DataGrid';
import { Typography } from "@nokia-csf-uxr/ccfk";
import Card from '@nokia-csf-uxr/ccfk/Card';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import editIcon from '../../Assets/Icons/inputedit.svg';
import eyeIcon from '../../Assets/Icons/VectorEye.svg';
import clockIcon from '../../Assets/Icons/Vectorclock.svg';
import userStyles from './Style/RadioNetworkCiq.module.css';
import pdfIcon from '../../Assets/Icons/pdf_icon.svg'
import { setProp } from "Store/Actions";
import useRadioNetworkFetchPlan from "../radio-network-ciq/hooks/useRadioNetworkFetchPlan";
import { useNavigate } from 'react-router-dom';
import OOT from "Constants/services/oot";
import { setDataConfig } from "Store/contentState/configuration_stepperSlice";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CaptureRadioNetworkPlanInvoice from "./CaptureRadioNetworkPlanInvoice";
import { formatDate } from '../marketplace/Utils/commons';
import Loader from "Components/Elements/Loader";
import Pagination from "Pages/marketplace/common/Pagination";


const RadioNetworkPlans = () => {
    const gridApi = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pdfDownloadLinkRef = useRef(null);
    const radioNetworkCIQ = useSelector((stete: RootState) => stete.radioNetworkCIQ)
    const configstepperSelectorData = useSelector((state: RootState) => state.config_stepper)
    const paginationSelector = useSelector((state: RootState) => state.pagination);
    const { currentPage } = paginationSelector.paginationProps;
    const limitPerpage = 10


    const { getRadioNetworkPlans,prepSteps } = useRadioNetworkFetchPlan();

    useEffect(() => {
        const offset = (currentPage - 1) * limitPerpage;
        dispatch(setProp({ slice: "radioNetworkCIQ", key: "radioNetworkPlanLoading", value: true}))
        getRadioNetworkPlans(offset, limitPerpage)
        getSegmentDataList()
        
    }, [currentPage, limitPerpage])




    const getSegmentDataList = async () => {
        try {
            const response = await OOT.getSegmentForm("pre_cio_radio");
            const configSteps = prepSteps(response.data);
            const dataConfigRes = [
                { key: "solution_form_data", value: response.data },
                { key: "stepper_data", value: configSteps }
            ];
            dispatch(setDataConfig(dataConfigRes));
            dispatch(setProp({ slice: "radioNetworkCIQ", key: "radioNetworkPlanLoading", value: false}))
        } catch (error) {
            console.error('Error fetching data:', error);

        }
    };



    const commonColumnDefs = [
        {
            headerName: 'sCRM ID',
            field: 'opportunity_id',
            minWidth: 200,
            flex: 1,

        },
        {
            headerName: 'Case name',
            field: 'casename',
            minWidth: 115,
            flex: 1,

        },
        {
            headerName: 'Market',
            field: 'market',
            minWidth: 115,
            flex: 1,

        },
    ]


    const handleEditStepper = (user) => {
        dispatch(setProp({ slice: "radioNetworkCIQ", key: "selectedNetworkPlan", value: user }))
        dispatch(setProp({ slice: "radioNetworkCIQ", key: "captureType", value: "edit" }))
        dispatch(setProp({ slice: "radioNetworkCIQ", key: "captureNetworkPlanModal", value: true }))
        dispatch(setProp({ slice: "radioNetworkCIQ", key: "scrmValidation", value: true }))
        dispatch(setProp({ slice: "radioNetworkCIQ", key: "stepperLoader", value: true }))
    }

    const handleUpdateFlowStatus = (paln) => {
        dispatch(setProp({ slice: "radioNetworkCIQ", key: "selectedNetworkPlan", value: paln }))
        dispatch(setProp({ slice: "radioNetworkCIQ", key: "createdUpdateStatusModal", value: true }))
    }

    const handleSelectedRadiotNetwork = (plan) => {
        dispatch(setProp({ slice: "radioNetworkCIQ", key: "selectedNetworkPlan", value: plan }))
        navigate('plan');
    }

    const handlePdfDownload = (plan) => {
        dispatch(setProp({ slice: "radioNetworkCIQ", key: "selectedNetworkPlan", value: plan }))
        if (pdfDownloadLinkRef.current) {
            pdfDownloadLinkRef.current.onClick();
        }
    }

    

    const actionButtonsColumn = {
        minWidth: 30,
        cellRenderer: (params) => {
            const isDisabled = params.data.isDisabled;
            return (
                <div className={userStyles.dFlex5}>
                    {params.data && configstepperSelectorData && <PDFDownloadLink document={<CaptureRadioNetworkPlanInvoice radioNetworkCIQ={params.data} configstepperSelector={configstepperSelectorData} formDate={formatDate(new Date(), 'yyyy')} /> } fileName={'CaptureRadioNetworkPlan.pdf'} style={{ textDecoration: 'none', color:'#FFFFFF !important', marginTop: '15px' }}>
                    <img
                        src={pdfIcon}
                        height={25}
                        className="action-button"
                        onClick={(event) => {
                            handlePdfDownload(params.data);
                        }}
                    />
                            </PDFDownloadLink> }

                    
                    <img
                        src={editIcon}
                        className="action-button"
                        onClick={(event) => {
                            handleEditStepper(params.data);
                        }}

                    />
                    <img
                        src={clockIcon}
                        onClick={(event) => {
                            handleUpdateFlowStatus(params.data);
                        }}

                    />
                    <img
                        src={eyeIcon}
                        onClick={(event) => {
                            handleSelectedRadiotNetwork(params.data);
                        }}

                    />
                </div>
            );
        }
    };


    const columnDefs = [...commonColumnDefs, actionButtonsColumn];

    return (
        <>
            {radioNetworkCIQ.radioNetworkPlanLoading && <div style={{ position: "absolute", inset: "50% 0" }}>
        <Loader />
      </div>}
           {!radioNetworkCIQ.radioNetworkPlanLoading && <Card style={{ padding: '1rem', outline: 'none' }}>
                <Typography style={{ color: '#333333', fontSize: '18px', borderBottom: "1px solid #0000001F", fontWeight: 'bold' }}>Captured data sets and network plans</Typography>
                <div style={{ width: '100%', height: '100%', marginTop: '1rem' }}>
                    <DataGrid
                        rowData={radioNetworkCIQ.networkPlans}
                        //@ts-ignore
                        columnDefs={columnDefs}
                        suppressMovableColumns={false}
                        //variant="compact"
                        suppressColumnMoveAnimation={false}
                        suppressRowClickSelection={false}
                        isRowDrag={false}
                        wrapperProps={{
                            style: {
                                width: "100%",
                            },
                        }}
                        disableAutoloadStyles
                        suppressContextMenu
                        onGridReady={(params) => {
                            gridApi.current = params.api;
                        }}
                        key={"roles-table"}
                        defaultColDef={{
                            editable: false,
                            sortable: false,
                            resizable: false,
                            // filter: true,
                            flex: 0,
                            minWidth: 100,

                        }}
                        overlayNoRowsTemplate="No applications available"
                        domLayout="autoHeight"
                        isInlineRowActions={false}

                    />
                </div>
                 {radioNetworkCIQ.networkPlans.length > 10 && <Pagination disableProp={radioNetworkCIQ.networkPlans} />}
            </Card>}
        </>
    )
}

export default RadioNetworkPlans;