import React, { useState, useEffect, useRef } from "react";
import Dialog, {
    DialogContent,
    DialogFooter,
    DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";
import CloseCloseIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';
import { useDispatch, useSelector } from "react-redux";
import {  clearUserModel,  setUserInputFields } from "Store/contentState/userManagementSlice";
import { RootState } from "Store/mainStore";
import OOT from "Constants/services/oot";

import _findIndex from 'lodash/findIndex';
// FF icon
import InlineFeedbackMessage from '@nokia-csf-uxr/ccfk/InlineFeedbackMessage';
import { showModal } from "Store/auth";
// import { useUserActions } from "./hooks/useUserActions";
import useFetchUsers from "../hooks/useFetchUsers";
import { setProp } from "Store/Actions"

import userStyles from "../Style/UserManagement.module.css";
import UserTypeSelection from "./UserDialogContainer/UserTypeSelection";
import UserFormDetails from "./UserDialogContainer/UserFormDetails";
import MachineFormDetails from "./UserDialogContainer/MachineFormDetails";
import FooterButtons from "./UserDialogContainer/FooterButtons";
import WhiteListedClient from "./UserDialogContainer/WhiteListedClient";
import {generateUserPayload} from "./utils/helper";

import { replaceUnderscoreWithSpace } from "./utils/constants";

const DIALOG_HEIGHT = "95%";
const DIALOG_WIDTH = "90%";

const CONFIRM_ALERT_STYLE = {
    top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
    height: DIALOG_HEIGHT,
    minHeight: DIALOG_HEIGHT,
    left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    width: DIALOG_WIDTH,
};

type IUserModalProp = {
    onClose: () => void;
    mode: string;
    selectedUserData: any;
    typeOfUser: string;
};

const UserDialogModal = ({ onClose, mode, selectedUserData, typeOfUser }: IUserModalProp) => {
    const dispatch = useDispatch();
    const authSlice = useSelector((state: RootState) => state.authSlice);
    const userSliceData = useSelector((state: RootState) => state.userManagement.userData);
    const selectedUserType = useSelector((state: RootState) => state.userManagement.selectedUserType);
    const selectedOrgType = useSelector((state: RootState) => state.userManagement.userData.orgType);
    const paginationSelector = useSelector((state: RootState) => state.pagination);
    const { limitPerpage, offset } = paginationSelector.paginationProps;
    const [disableBtn, setDisableBtn] = useState(false);
    const [userSuccessMsg, setUserSuccessMsg] = useState("");
    const [userErrorMsg, setErrorMsg] = useState("");
    const [successShow, setSuccessShow] = useState(false);
    const [errorShow, setErrorShow] = useState(false);

    //const getAllUsersList = useUserActions(typeOfUser)
    const { getUsersList } = useFetchUsers();

    const handleDialogClose = () => {
        dispatch(clearUserModel());
        dispatch(setProp({ 'slice': 'userManagement', 'key': 'selectedUserType', 'value': 'user' }));
         dispatch(setProp({ slice: 'userManagement', key: 'nokiaCertificationStatus', value: "" }));
        if (authSlice.system.modal.variant == "ERROR") {
            dispatch(
                showModal({
                    msg: "",
                    panel: { title: "", msg: "" },
                    show: false
                })
            );
        }
        onClose();

    }

    const handleSubmit = async () => {
        // Submit logic goes here
        setDisableBtn(true);
        let dataPayload = {};
        if (mode === 'add') {
            dataPayload = generateUserPayload(userSliceData, mode, selectedUserType, selectedUserData, false);
        } else {
            dataPayload = generateUserPayload(userSliceData, mode, typeOfUser, selectedUserData, false);
        }
        try {
            const response = await OOT.addEditUser(dataPayload, mode, selectedUserType)
            if (response.status) {
                setSuccessShow(true);
                setUserSuccessMsg(response.msg);
                setTimeout(() => {
                    getUsersList(limitPerpage, offset, typeOfUser);
                    handleDialogClose();
                }, 3000)
            } else {
                setErrorShow(true);
                setErrorMsg(response.errorMessage ? response.errorMessage : response.msg);
            }
        } catch (error) {
            setErrorShow(true);
            setErrorMsg(error.message);
        } finally {
            setDisableBtn(false);
        }

    };

    function isButtonDisabledWithValidations() {
        const isNokia = selectedOrgType.includes("nokia");
        const isUser = selectedUserType === "user";
        const isMachine = selectedUserType === "machine";
        const isWhitelistedClient = selectedUserType === "whitelisted_client";

        // Check mandatory fields for the "user" type when the organization type is "company".
        if (isNokia && isUser) {

            if (isUserInvalid("isNokiaisUser")) {
                return true
            } else {
                console.log('ddddd',userSliceData.fullName === "" ? "test" : "ee")
                return userSliceData.fullName != "" ? false : true
            }
            // return (
            //     !userSliceData.fullName ||
            //     !userSliceData.emailAddress ||
            //     userSliceData.roles.length === 0 ||
            //     // !userSliceData.threeStarNumber.length ||
            //     // !userSliceData.markets ||
            //     userSliceData.roles[0] != "sales_view_only_sales" ?  userSliceData.oot.roles.length === 0 ||
            //     (
            //         userSliceData.oot.roles.includes('budgetary_approver') &&
            //         userSliceData.oot.approver_role_group?.length === 0 // This depends on roles
            //     ) : false
            //     //userSliceData.oot.roles.length === 0 || ((userSliceData.oot.roles.includes('approver') || userSliceData.oot.roles.includes('budgetary_approver')) ? userSliceData.oot.approver_role_group.length !== 0 : false)
            // );
        }


        // Check mandatory fields for the "user" type when the organization type is NOT "company".
        if (!isNokia && isUser && !(userSliceData.orgType.includes("merchant") || userSliceData.orgType.includes("supplier"))) {
            if (isUserInvalid("!isNokiaisUser")) {
                return true
            } else {
                return userSliceData.fullName != "" ? false : true
            }
            // return (
            //     !userSliceData.fullName ||
            //     !userSliceData.emailAddress ||
            //     userSliceData.threeStarNumber.length === 0 ||
            //     userSliceData.oneStarNumber.length === 0 ||
            //     !userSliceData.orgType.length ||
            //     userSliceData.roles.length === 0 ||
            //     userSliceData.roles[0] === "sales_view_only_sales" ?  
            //     userSliceData.orgType.includes("direct_reseller") ? userSliceData.threeStarNumber.length === 0 || userSliceData.roles.length === 0 :  userSliceData.orgType.includes("distributor") ?  userSliceData.threeStarNumber.length === 0 || userSliceData.roles.length === 0 : userSliceData.orgType.includes("direct_end_customer") ?  userSliceData.threeStarNumber.length === 0 || userSliceData.roles.length === 0  : false : 
            //     userSliceData.orgType.includes("direct_reseller") ? userSliceData.threeStarNumber.length === 0 || userSliceData.oot.roles.length === 0 || userSliceData.roles.length === 0 :  userSliceData.orgType.includes("distributor") ?  userSliceData.threeStarNumber.length === 0 || userSliceData.oot.roles.length === 0 || userSliceData.roles.length === 0 :  userSliceData.orgType.includes("direct_end_customer") ?  userSliceData.threeStarNumber.length === 0 || userSliceData.oot.roles.length === 0 || userSliceData.roles.length === 0 : false
            // );
        }

        if (!isNokia && isUser && (userSliceData.orgType.includes("merchant") || userSliceData.orgType.includes("supplier"))) {
            return (
                !userSliceData.fullName ||
                !userSliceData.emailAddress ||
                userSliceData.roles.length === 0 ||
                userSliceData.supplier_account_id.length === 0 ||
                !userSliceData.orgType.length ||
                userSliceData.roles.length === 0
            )
        }

        // Check mandatory fields for the "machine" type.
        if (isMachine) {
            const { ip_list, ip_ranges } = userSliceData.whitelisted_ip;
            return (
                !userSliceData?.fullName ||
                !userSliceData?.roles || !userSliceData.emailAddress ||
                (ip_list?.length === 0 || ip_ranges?.length === 0)
                // Object.keys(userSliceData.roles).length === 0
            );
        }

        if (isWhitelistedClient) {

            return (
                !userSliceData.fullName || !userSliceData.emailAddress
                // Object.keys(userSliceData.roles).length === 0
            );
        }

        // Default case, button should not be disabled
        return false;
    }

    const isUserInvalid = (type) => {
        const { orgType, fullName, emailAddress, threeStarNumber, oneStarNumber, roles, oot } = userSliceData;

        if(type === "isNokiaisUser"){
                    // Basic validations
                if (!fullName || !emailAddress || roles.length === 0) {
                    return true;
                }

                // If the first role is NOT "sales_view_only_sales"
                if (roles[0] !== "sales_view_only_sales") {
                    return oot.roles.length === 0 || 
                        (oot.roles.includes("budgetary_approver") && (!oot.approver_role_group || oot.approver_role_group.length === 0));
                }
            }
        else{           
    
        // Check for missing required fields
        const isMissingRequiredFields =
            fullName.trim() === "" ||  // Check if fullName is empty
            !emailAddress ||  // Check if email is missing
            threeStarNumber.length === 0 ||  // Check if threeStarNumber is empty
            oneStarNumber.length === 0 ||  // Check if oneStarNumber is empty
            orgType.length === 0 ||  // Check if orgType is empty
            roles.length === 0;  // Check if roles are missing
    
        // Check if user has a restricted role
        const isSalesViewOnly = roles[0] === "sales_view_only_sales";
    
        // Check if the user belongs to a specific org type
        const isDirectReseller = orgType.includes("direct_reseller");
        const isDistributor = orgType.includes("distributor");
        const isDirectEndCustomer = orgType.includes("direct_end_customer");
    
        // Function to check role and number validation
        const isInvalidOrgType = (requiresOOT = false) => {
            return (
                threeStarNumber.length === 0 ||
                roles.length === 0 ||
                (requiresOOT && (!oot || oot.roles.length === 0))  // Ensure oot.roles is checked safely
            );
        };
    
        // Final validation logic
        if (isMissingRequiredFields || isSalesViewOnly) {
            if (isDirectReseller || isDistributor || isDirectEndCustomer) {
                return isInvalidOrgType(false); // No OOT check needed
            }
            return true; // Missing required fields
        }
    
        if (isDirectReseller || isDistributor || isDirectEndCustomer) {
            return isInvalidOrgType(true); // Check OOT roles
        }
        }
    
        return false; // If none of the conditions match, user is valid
    };



    useEffect(() => {
        if (mode === 'edit' && selectedUserData) {
            dispatch(setProp({ 'slice': 'userManagement', 'key': 'selectedUserType', 'value': typeOfUser }));
            // Initialize state with selectedUserData
            dispatch(setUserInputFields({ field: 'id', value: selectedUserData.id }));
            dispatch(setUserInputFields({ field: 'fullName', value: selectedUserData.name }));
            dispatch(setUserInputFields({ field: 'emailAddress', value: selectedUserData.email }));
            dispatch(setUserInputFields({ field: 'orgType', value: selectedUserData.userType || [] }));
            dispatch(setUserInputFields({ field: 'roles', value: selectedUserData.userRoles || {} }));
            dispatch(setUserInputFields({ field: 'oneStarNumber', value: selectedUserData.oneStarNumber || [] }));
            dispatch(setUserInputFields({ field: 'threeStarNumber', value: selectedUserData.threeStarNumber || [] }));
            dispatch(setUserInputFields({ field: 'whitelisted_ip', value: selectedUserData.whitelisted_ip || {} }));
            dispatch(setUserInputFields({ field: 'markets', value: selectedUserData.markets || {} }));
            dispatch(setUserInputFields({ field: 'business_name', value: selectedUserData.business_name || [] }));
            dispatch(setUserInputFields({ field: 'nsa_authorized', value: selectedUserData.nsa_authorized || false }));
            dispatch(setUserInputFields({ field: 'approver', value: selectedUserData.approver || false }));
            dispatch(setUserInputFields({ field: 'oot', value: selectedUserData.oot || {} }));
            dispatch(setUserInputFields({ field: 'supplier_account_id', value: selectedUserData.supplier_account_id || [] }));
            dispatch(setProp({ slice: 'userManagement', key: 'mailDomain', value: selectedUserData.mailDomain }));


        }

    }, [mode, selectedUserData, dispatch]);


    return (
        <>
            <Dialog isOpen={true} ariaHideApp={false} style={{ content: CONFIRM_ALERT_STYLE }}>
                <DialogTitle
                    title={mode === "add" ? `Add ${replaceUnderscoreWithSpace(selectedUserType)}` : `Edit ${replaceUnderscoreWithSpace(typeOfUser)}`}
                    buttonContent={<CloseCloseIcon />}
                    buttonProps={{ onClick: handleDialogClose }}
                />
                <DialogContent isTopDividerVisible={true} isBottomDividerVisible={true}>
                    <div className={userStyles.marginTop}>

                        {
                            mode === 'add' && <UserTypeSelection />
                        }

                        {
                            (selectedUserType === 'user' || typeOfUser === 'user') && <UserFormDetails mode={mode} typeOfUser={typeOfUser} selectedData={selectedUserData} />
                        }

                        {
                            (selectedUserType === 'machine' || typeOfUser === 'machine') && <MachineFormDetails mode={mode} typeOfUser={typeOfUser} />
                        }
                        {
                            selectedUserType === 'whitelisted_client' && <WhiteListedClient mode={mode} typeOfUser={typeOfUser} />
                        }


                        <div style={{ position: 'absolute', bottom: '20px', width: '50%' }}>
                            {userSuccessMsg && <InlineFeedbackMessage
                                variant="success"
                                size="medium"
                                closeButton
                                show={successShow}
                                onClose={() => setSuccessShow(false)}
                            >
                                {userSuccessMsg}
                            </InlineFeedbackMessage>}
                            {userErrorMsg && <InlineFeedbackMessage
                                variant="error"
                                size="medium"
                                closeButton
                                show={errorShow}
                                onClose={() => setErrorShow(false)}
                            >
                                {userErrorMsg}
                            </InlineFeedbackMessage>}
                        </div>

                    </div>

                </DialogContent>
                <DialogFooter>
                    <FooterButtons onDialogClose={handleDialogClose} onSubmit={handleSubmit} disabled={isButtonDisabledWithValidations() || disableBtn} />
                </DialogFooter>
            </Dialog>
        </>
    )
}


export default UserDialogModal;