import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, store } from "Store/mainStore";
import { Typography } from "@nokia-csf-uxr/ccfk";
import CartListItem from "./cart/CartListItem";
import CartDiscountsActions from "./cart/CartDiscountsActions";
import { PDFViewer } from '@react-pdf/renderer';
import OOT from "Constants/services/oot";
import { setAgreementDetails, setAgreementValidity } from "Store/contentState/marketplaceSlice";



const CartItemsList = () => {
  const dispatch = useDispatch()
  const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
  const cart = marketplaceSelector.cart;
  let isCartChangedFromStore = store.getState();

  
  const nokiaProducts = cart.filter(item => item.is_agent_model === 0);
  const merchantProducts = cart.filter(item => item.is_agent_model === 1);


  useEffect(() => {
    if (
       isCartChangedFromStore.marketplace.isCartChanged && 
        marketplaceSelector.agreementDetails.agreements.length > 0
    ) {
        validateAgreementConditions(marketplaceSelector.agreementDetails.agreements);
    }
}, [isCartChangedFromStore.marketplace.cart, marketplaceSelector.agreementDetails.agreements]); // Runs when `isCartChanged` changes

  



  const validateAgreementConditions = (agreements) => {
    const formatDate = (date) => date.toISOString().replace('T', ' ').slice(0, 19);
    const currentDate = formatDate(new Date());
  
    const isAgreementValid = agreements.every(agreement => {
      const startDate = formatDate(new Date(agreement.field_part_agre_term_agreement_start_date));
      const endDate = formatDate(new Date(agreement.field_part_agre_term_agreement_end_date));
  
      const isDateValid = currentDate > startDate && currentDate <= endDate;
      const isStatusEffective = agreement.field_part_agre_agree_status === 'Effective';
  
      let isAgreementTypeValid = false;
      let isSubTypeValid = false;

      //Yet to be refactored
      if(agreement.field_part_agre_partner_role === 'Partner'){
        isAgreementTypeValid = agreement.field_part_agre_agree_type === 'PPA' || agreement.field_part_agre_agree_type === 'NDAC Reseller' 
                               || agreement.field_part_agre_agree_type === 'PFA' || agreement.field_part_agre_agree_type === 'Project Specific';
      }else if(agreement.field_part_agre_partner_role === 'Direct Customer'){
        isAgreementTypeValid = agreement.field_part_agre_agree_type === 'ndac_direct_customer_agreement' || agreement.field_part_agre_agree_type === 'NDAC Reseller' 
                               || agreement.field_part_agre_agree_type === 'Customer contract';
      }else{
        isAgreementTypeValid = false;
      }

  
      const subtypes = agreement.field_part_agre_agree_subtype
        ? agreement.field_part_agre_agree_subtype.split(',').map(s => s.trim())
        : [];
  
      const hasNDAC = subtypes.includes('NDAC');
      const hasMarketplace = subtypes.includes('Marketplace');
  
      if (merchantProducts.length > 0 && nokiaProducts.length > 0) {
        isSubTypeValid = hasNDAC && hasMarketplace;
      } else if (merchantProducts.length > 0) {
        isSubTypeValid = hasMarketplace;
      } else {
        isSubTypeValid = hasNDAC;
      }
  
      return isDateValid && isAgreementTypeValid && isSubTypeValid && isStatusEffective;
    });
    dispatch(setAgreementValidity(isAgreementValid));
  };

  return (
    <React.Fragment>
      <div style={{ padding: '0.75rem' }}>
        {nokiaProducts.length > 0 && (
          <div>
            <div style={{ padding: '.3rem', backgroundColor: "#E4F0FF", marginBottom: '.3rem' }}>
              <Typography style={{ color: '#333333', fontSize: '14px', fontWeight: 'bold' }}>Nokia items</Typography>
            </div>
            {nokiaProducts.map(item => (
              <CartListItem key={item.id} cartItem={item}/>
            ))}
          </div>
        )}

        {merchantProducts.length > 0 && (
          <div>
            <div style={{ padding: '.3rem', backgroundColor: "#E4F0FF", marginBottom: '.3rem' }}>
              <Typography style={{ color: '#333333', fontSize: '14px', fontWeight: 'bold' }}>Merchant items</Typography>
            </div>
            {merchantProducts.map(item => (
              <CartListItem key={item.id} cartItem={item}/>
            ))}
          </div>
        )}

        <>
          <CartDiscountsActions />

          {/* <PDFViewer width="100%" height="600">
        <MyDocument data={marketplaceSelector.cart} accountDetails={marketplaceSelector.accountDetails[0]} offerId={marketplaceSelector.cartResponse.cartOfferId} cartResponse={marketplaceSelector.cartResponse} orderType={marketplaceSelector.orderType} createdDate={formatDate(new Date())}/>
      </PDFViewer> */}
        </>
      </div>

    </React.Fragment>
  )


}

export default CartItemsList;