
import React, {useEffect} from "react";
import Chip, { ChipLabel, ChipIcon } from '@nokia-csf-uxr/ccfk/Chip';
import partnerDiscountIcon from '../../../Assets/Icons/partner_discount_icon.svg';
import timeBasedDiscountIcon from '../../../Assets/Icons/timebased_discount_icon.svg';
import noDiscountIcon from '../../../Assets/Icons/no_discount_icon.svg';
import classes from '../Style/Cart.module.css'



const CartDiscounts = (prop) => {
    const { discount } = prop;

    const discounts = [
        { label: 'Deal original discount', value: discount.dod_percentage, icon: partnerDiscountIcon, backgroundcolor:"#EAE4FF", border:"1px solid #C0A5FF"},
        { label: 'Partner discount', value: discount.ptd_percentage, icon: partnerDiscountIcon, backgroundcolor:"#E8FBFF", border:"1px solid #80E6FF"},
        { label: 'Volume discount', value: discount.vd_percentage, icon: partnerDiscountIcon, backgroundcolor:"#E8FBFF", border:"1px solid #80E6FF"},
        { label: 'Time based discount', value: discount.tbd_percentage, icon: timeBasedDiscountIcon, backgroundcolor:"#F0FDDC", border:"1px solid #C4F07D" }
    ];


    // Other discount types
    const otherDiscountValues = [
        discount.sbd_percentage,
        discount.cad_percentage,
        discount.ddp_percentage
    ].filter(value => value !== undefined && value !== 0);
    
   
    
    const validDiscounts = discounts.filter(discount => discount.value !== undefined && discount.value !== 0);
    

    return (
        <div style={{ width: '100%' }}>
            <div className={classes.cartDiscountChipContainer}>

            {validDiscounts.length > 0 || otherDiscountValues.length > 0 ? (
                    <>
                        {validDiscounts.map((discount, index) => (
                            <Chip key={index} size="small" style={{ backgroundColor: discount.backgroundcolor, border: discount.border, marginRight: '.5rem', borderRadius: '15px' }}>
                                <ChipIcon><img src={discount.icon} /></ChipIcon>
                                <ChipLabel label={`${discount.label} ${discount.value}%`} />
                            </Chip>
                        ))}
                        {validDiscounts.length === 0 && otherDiscountValues.length > 0 && (
                            <Chip size="small" style={{ backgroundColor: '#FFF1E4', border: '1px solid #FFD0A5', marginRight: '.5rem', borderRadius: '15px' }}>
                                {/* <ChipIcon><img src={noDiscountIcon} style={{ height: '10px', margin: '3px' }} /></ChipIcon> */}
                                <ChipLabel label="Other discount" />
                            </Chip>
                        )}
                    </>
                ) : (
                    <Chip size="small" style={{ backgroundColor: '#FFF1E4', border: '1px solid #FFD0A5', marginRight: '.5rem', borderRadius: '15px' }}>
                        <ChipIcon><img src={noDiscountIcon} style={{ height: '10px', margin: '3px' }} /></ChipIcon>
                        <ChipLabel label="No discount" />
                    </Chip>
                )}
                {/* {validDiscounts.length > 0 ? (
                    validDiscounts.map((discount, index) => (
                        <Chip key={index} size="small" style={{ backgroundColor: discount.backgroundcolor, border: discount.border, marginRight: '.5rem', borderRadius: '15px' }}>
                            <ChipIcon><img src={discount.icon} /></ChipIcon>
                            <ChipLabel label={`${discount.label} ${discount.value}%`} />
                        </Chip>
                    ))
                ) : (
                    <Chip size="small" style={{ backgroundColor: '#FFF1E4', border: '1px solid #FFD0A5', marginRight: '.5rem', borderRadius: '15px' }}>
                         <ChipIcon><img src={noDiscountIcon} style={{height:'10px', margin: '3px'}}/></ChipIcon>
                        <ChipLabel label="No discount" />
                    </Chip>
                )} */}
            </div>
        </div>
    );
}

export default CartDiscounts;