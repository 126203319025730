//@ts-ignore
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _findIndex from 'lodash/findIndex';
import _cloneDeep from 'lodash/cloneDeep';
import _uniqueId from 'lodash/uniqueId';
import _concat from 'lodash/concat';
import OOT from 'Constants/services/oot';
// Dropdown Icons for legacy and latest themes
import ArrowTriangleDownIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ArrowTriangleDownIcon';
import ArrowTriangleDownIconLatest from '@nokia-csf-uxr/ccfk-assets/latest/TriangleArrowDownFillIcon';
import { useDispatch } from 'react-redux';
import { setUserInputFields } from 'Store/contentState/userManagementSlice';
import { Tree } from '@nokia-csf-uxr/ccfk';
import Dialog, {
    DialogContent,
    DialogFooter,
    DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";
import Checkbox, { CheckboxLabelContent } from '@nokia-csf-uxr/ccfk/Checkbox';
import {
    AppBanner,
    Avatar,
    ButtonsRow,
    Tooltip,
    Typography,
} from "@nokia-csf-uxr/ccfk";
// import FilterField, { FilterFieldIcon, FILTER_HIGHLIGHT_CLASSNAME } from "@nokia-csf-uxr/ccfk/FilterField";
import { Button, TextInput } from "@nokia-csf-uxr/ccfk";
import Label from '@nokia-csf-uxr/ccfk/Label';
import { OOT_MARKETS_MAPPING } from '../utils/constants';
import
FilterField,
{
    FilterFieldIcon,
    FindMatchContainer,
    HIGHLIGHT_CLASSNAME,
    SELECTED_HIGHLIGHT_CLASSNAME
    //@ts-ignore
} from '@nokia-csf-uxr/ccfk/FilterField';
import {
    TreeBranch, TreeText,
    TreeNode,
    TreeDropDownButton,
    TreeContainer,
    TreeTitle,
    TreeFooter,
    TreeFilterContainer,
    TreeMoreButton,
    TreeMoreButtonItem,
    TreeMoreButtonItemText,
    TreeCheckbox,
} from '@nokia-csf-uxr/ccfk/Tree';

import { AlertBar, AlertMessage } from '@nokia-csf-uxr/ccfk/Alert';
import _isEmpty from 'lodash/isEmpty'
import _toLower from 'lodash/toLower';
import _replace from 'lodash/replace';
import { set } from 'lodash';
import { setProp } from 'Store/Actions';
//import { markItem, filterTreeItems, getWholeFlattenedTree, getTreeData } from './common';
import userStyles from '../../Style/UserManagement.module.css';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';

import ChevronRightIcon from '@nokia-csf-uxr/ccfk-assets/latest/ChevronRightIcon';
import ChevronLeftIcon from '@nokia-csf-uxr/ccfk-assets/latest/ChevronLeftIcon';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/mainStore';
import _ from 'lodash';
import { setOotRoles } from 'Store/contentState/userManagementSlice';
// in rem units
// in rem units
const TITLE_HEIGHT = 2;
const FOOTER_HEIGHT = 5;
const FILTER_HEIGHT = 3.375;
const FILTER_MATCHES_HEIGHT = 2.3125;
const DIV_PADDING_HEIGHT = 1;
const FOOTER_DIVIDER_HEIGHT = 0.0625;
const ROW_HEIGHT = 2;

const DIALOG_STYLE = {
    top: 'calc((100vh - 16.25rem) / 2)',
    height: '17.0rem',
    minHeight: '17.0rem',
    left: 'calc((100vw - 25rem) / 2)',
    right: 'calc((100vw - 25rem) / 2)',
    width: '25rem'
};

const ENTER_KEY = 'Enter';
const SPACE_KEY = ' ';
const TAB = 'Tab';
const ARROW_LEFT = 'ArrowLeft';
const ARROW_RIGHT = 'ArrowRight';
const isSelectionKeyPressed = key => key && (key === ENTER_KEY || key === SPACE_KEY);
const isLeftRightArrowPressed = key => key && (key === ARROW_LEFT || key === ARROW_RIGHT);





//@ts-ignore
const HIGHLIGHT_MARK_END = '</span>';
// const isSelectionKeyPressed = key => key && (key === ENTER_KEY || key === SPACE_KEY);
// case insensitive matching all occurrences

//@ts-ignore
const markItem = (item, searchPattern, highlightClassName) => {
    //console.log(item, searchPattern, highlightClassName);
    if (!item) {
        return null;
    }
    if (!searchPattern || searchPattern.length === 0) {
        return item;
    }
    const HIGHLIGHT_MARK_START = `<span class=${highlightClassName}>`;
    let endingIndex = 0;
    const searchPatternLength = searchPattern.length;
    let markedText = item;
    while (true) {
        const startIndex = (markedText).indexOf(searchPattern, endingIndex);
        if (startIndex === -1) {
            break;
        }
        const actualText = markedText.substr(startIndex, searchPatternLength);
        const replacementText = `${HIGHLIGHT_MARK_START}${actualText}${HIGHLIGHT_MARK_END}`;
        const beginningText = startIndex > 0 ? markedText.slice(0, startIndex) : '';
        const endingText = markedText.slice(startIndex + searchPatternLength);
        markedText = beginningText + replacementText + endingText;
        endingIndex = startIndex + searchPatternLength + HIGHLIGHT_MARK_START.length + HIGHLIGHT_MARK_END.length;
    }
    return <div dangerouslySetInnerHTML={{ __html: markedText }}></div>;
};

// exact matching
const isMatchedExactly = (item, searchPattern) => {
    const itemIsIncluded = item && item.indexOf(searchPattern) >= 0;
    return _isEmpty(searchPattern) || itemIsIncluded;
};
// mark all matching TreeItems and the parents of the matchingTreeItems
const filterTreeItems = (data, filterValue) => {
    if (filterValue !== undefined && filterValue.length === 0) {
        return;
    }
    let hasMatches = false;
    for (let treeIndex = 0; treeIndex < data.length; treeIndex += 1) {
        const isTextMatched = isMatchedExactly(data[treeIndex].text, filterValue);
        if (isTextMatched) {
            hasMatches = true;
            data[treeIndex].isMatched = true;
        }
        if (data[treeIndex].isBranch) {
            const hasNodeMatches = filterTreeItems(data[treeIndex].nodes, filterValue);
            if (hasNodeMatches) {
                data[treeIndex].childIsMatched = true;
                hasMatches = true;
            }
        }
    }
    return hasMatches;
};




// mark all matching TreeItems and the parents of the matchingTreeItems
// const filterTreeItems = (data, filterValue) => {
//     if (filterValue !== undefined && filterValue.length === 0) {
//         return;
//     }
//     let hasMatches = false;
//     for (let treeIndex = 0; treeIndex < data.length; treeIndex += 1) {
//         const isTextMatched = isMatchedExactly(data[treeIndex].text, filterValue);
//         if (isTextMatched) {
//             hasMatches = true;
//             data[treeIndex].isMatched = true;
//         }
//         if (data[treeIndex].isBranch) {
//             const hasNodeMatches = filterTreeItems(data[treeIndex].nodes, filterValue);
//             if (hasNodeMatches) {
//                 data[treeIndex].childIsMatched = true;
//                 hasMatches = true;
//             }
//         }
//     }
//     return hasMatches;
// };

const isNodeExpanded = node => node.state && node.state.expanded;
const nodeHasChildren = node => node.children && node.children.length;
// return array of opened items in the Tree, all at one level
const getFlattenedTree = (nodes, parents = []) =>
    nodes.reduce((flattenedTree, node) => {
        const deepness = parents.length;
        const nodeWithHelpers = { ...node, deepness, parents };

        if (!nodeHasChildren(node) || !isNodeExpanded(node)) {
            return [...flattenedTree, nodeWithHelpers];
        }

        return [...flattenedTree, nodeWithHelpers, ...getFlattenedTree(node.children, [...parents, node.id])];
    }, []);

// return array of all items in the Tree, all at one level
const nodeHasNodes = node => node.nodes && node.nodes.length;
const getWholeFlattenedTree = (nodes, parents = []) =>
    nodes.reduce((flattenedTree, node) => {
        const deepness = parents.length;
        const nodeWithHelpers = { ...node, deepness, parents };

        if (!nodeHasNodes(node)) {
            return [...flattenedTree, nodeWithHelpers];
        }

        return [...flattenedTree, nodeWithHelpers, ...getWholeFlattenedTree(node.nodes, [...parents, node.id])];
    }, []);


/**
 * Example of a Tree with optinal filtering and drag and drop of treeitems.
 */

//@ts-ignore
const HierachyTree = (props) => {
    const dispatch = useDispatch();
    const [level4Data, setLevel4Data] = useState([]);
    const [level5Data, setLevel5Data] = useState([]);
    const [level6Data, setLevel6Data] = useState([]);
    const [level7Data, setLevel7Data] = useState([]);
    const [uniqLevelData, setUniqLevelData] = useState([]);

    //@ts-ignore
    const getTreeData = ({ isLegacy }) => {
        const TREE_DATA = buildTree();
        return TREE_DATA;
    };






    const buildTree = () => {
        //console.log("Building tree with data:", { level4Data, level5Data, level6Data, uniqLevelData });

        const level4 = level4Data || [];
        const level5 = level5Data || [];
        const level6 = level6Data || [];

        // Create maps for easy lookup by ID
        const level4Map = Object.fromEntries(level4.map(l4 => [l4.id, l4.level]));
        const level5Map = Object.fromEntries(level5.map(l5 => [l5.id, l5.level]));
        const level6Map = Object.fromEntries(level6.map(l6 => [l6.id, l6.level]));

        // Root node for the entire tree structure
        const treeData = [];

        // Map to track all nodes by their ID for easy parent-child linking
        const nodeMap = {};

        // Loop through unique data to create the tree structure
        uniqLevelData.forEach(uniq => {
            // Level 4 Node
            if (!nodeMap[uniq.level4_id]) {
                nodeMap[uniq.level4_id] = {
                    id: uniq.level4_id,
                    text: level4Map[uniq.level4_id] || `Unknown Level 4 (${uniq.level4_id})`,
                    level: 0,
                    isBranch: true,
                    nodes: [], // Level 4 should have child nodes
                };
                treeData.push(nodeMap[uniq.level4_id]);
            }

            // Level 5 Node (Only add if Level 5 ID is present and parent Level 4 exists)
            if (uniq.level5_id && !nodeMap[uniq.level5_id]) {
                if (!nodeMap[uniq.level4_id]) {
                    console.warn(`Skipping Level 5 node ${uniq.level5_id}: Missing parent Level 4 node ${uniq.level4_id}`);
                    return; // Skip if parent node is not present
                }
                nodeMap[uniq.level5_id] = {
                    id: uniq.level5_id,
                    text: level5Map[uniq.level5_id] || `Unknown Level 5 (${uniq.level5_id})`,
                    level: 1,
                    isBranch: true,
                    nodes: [],
                };
                nodeMap[uniq.level4_id].nodes.push(nodeMap[uniq.level5_id]);
            }

            // Level 6 Node (Only add if Level 6 ID is present and parent Level 5 exists)
            if (uniq.level6_id && !nodeMap[uniq.level6_id]) {
                if (!nodeMap[uniq.level5_id]) {
                    console.warn(`Skipping Level 6 node ${uniq.level6_id}: Missing parent Level 5 node ${uniq.level5_id}`);
                    return; // Skip if parent node is not present
                }
                nodeMap[uniq.level6_id] = {
                    id: uniq.level6_id,
                    text: level6Map[uniq.level6_id] || `Unknown Level 6 (${uniq.level6_id})`,
                    level: 2,
                    isBranch: true,
                    nodes: [],
                };
                nodeMap[uniq.level5_id].nodes.push(nodeMap[uniq.level6_id]);
            }

            // Level 7 Node (Leaf node, no further children, taken directly from uniqLevelData)
            if (uniq.id && !nodeMap[uniq.id]) {
                if (!nodeMap[uniq.level6_id]) {
                    console.warn(`Skipping Level 7 node ${uniq.id}: Missing parent Level 6 node ${uniq.level6_id}`);
                    return; // Skip if parent node is not present
                }
                nodeMap[uniq.id] = {
                    id: uniq.id,
                    text: uniq.level || `Unknown Level 7 (${uniq.id})`,
                    level: 3,
                    isBranch: false, // Level 7 is a leaf node
                    nodes: [], // No child nodes
                };
                nodeMap[uniq.level6_id].nodes.push(nodeMap[uniq.id]);
            }
        });

        return treeData;
    };




    //@ts-ignore
    const { useFilter, allowDnd, dndDropIsOverTimeout, isLegacy, mode } = props;
    const TREE_DATA = getTreeData({ isLegacy });
    const marketsData = useSelector((state: RootState) => state.userManagement.userData.markets);
    const preventFocusHandling = false;
    const [treeData, setTreeData] = useState(TREE_DATA);  // complete Tree
    const [filteredTreeData, setFilteredTreeData] = useState([]); // results of filtering the Tree
    const [branchOpenStates, setBranchOpenStates] = useState([]); // open state of each TreeBranch
    const [moreButtonOpenStates, setMoreButtonOpenStates] = useState([]); // open states of each TreeItem context menu
    // const [selectedNodeId, setSelectedNodeId] = useState(undefined);
    const [selected, setSelected] = useState(undefined); // selected Tree item id

    const [showAddTreeItemDialog, setShowAddTreeItemDialog] = useState(false);
    const [showRenameTreeItemDialog, setShowRenameTreeItemDialog] = useState(false);
    const [showDeleteTreeItemDialog, setShowDeleteTreeItemDialog] = useState(false);
    const [addBranchItem, setAddBranchItem] = useState(false);
    const [filterFieldValue, setFilterFieldValue] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(undefined);  // value of text typed during filtering
    const treeItemToUpdate = useRef(undefined); // id of current TreeItem being updated
    const treeMatchedCount = useRef(0); // number of matches when filtering
    const treeRef = useRef(null);
    const listRef = useRef(null);
    const filterFieldInputRef = useRef();





    // Fetch hierarchy data for each level
    const getHierarchyLevel = async (setLevelData, level) => {
        try {
            const response = await OOT.getHierarchyData("", level);
            if (response.status) {
                setLevelData(response.data);
            }
        } catch (error) {
            console.log(`Error fetching ${level} data:`, error);
        }
    };

    const getHierarchyUniqueLevel = async () => {
        try {
            const response = await OOT.getHierarchyDataUniq("", "uniq_level");
            if (response.status) {
                setUniqLevelData(response.data);
            }
        } catch (error) {
            console.log("Error fetching unique level data:", error);
        }
    };


    let mainArray = [];
    // initialize array to hold tree branchs open/close state
    const initializeStates = (data, dataArray = []) => {
        for (let treeIndex = 0; treeIndex < data.length; treeIndex += 1) {
            const treeItem = data[treeIndex];
            if (treeItem.isBranch) {
                dataArray.push({
                    id: treeItem.id,
                    isOpen: treeItem.isOpen !== undefined ? treeItem.isOpen : false,
                });
                if (treeItem.nodes && treeItem.nodes.length > 0) {
                    initializeStates(treeItem.nodes, dataArray);
                }
            }
        }
        setBranchOpenStates(dataArray);
    };

    useEffect(() => {
        // Fetch all levels and unique data in parallel
        const fetchAllLevels = async () => {
            await Promise.all([
                getHierarchyLevel(setLevel4Data, "level4"),
                getHierarchyLevel(setLevel5Data, "level5"),
                getHierarchyLevel(setLevel6Data, "level6"),
                // getHierarchyLevel(setLevel7Data, "level7"),
                getHierarchyUniqueLevel()
            ]);
        };

        fetchAllLevels();
    }, []);




    // Update the tree data whenever any of the level data or unique level data changes
    useEffect(() => {
        if (level4Data.length && level5Data.length && level6Data.length && uniqLevelData.length) {
            // If in edit mode, retrieve and handle the markets data
            if (mode === 'edit' && marketsData) {
                const updatedTreeData = TREE_DATA.map(node => checkNodeMarkets(node, marketsData));
                setTreeData(updatedTreeData);
                let accountListQueryFilter = generateFilterQuery(updatedTreeData);
                dispatch(setProp({ slice: 'userManagement', key: 'hierachyFilterQuery', value: accountListQueryFilter }));
                dispatch(setProp({ slice: 'userManagement', key: 'treeData', value: updatedTreeData }));
            } else {
                setTreeData(TREE_DATA);
                dispatch(setProp({ slice: 'userManagement', key: 'treeData', value: TREE_DATA }));
            }

            initializeStates(TREE_DATA); // This was missing in the new implementation
        }
    }, [level4Data, level5Data, level6Data, uniqLevelData]);



    const checkNodeMarkets = (node, markets) => {
        const { level4, level5, level6, level7 } = markets;
        // Check if the node text is present in the respective level array based on its level
        if ((node.level === 0 && level4 && level4.includes(node.id)) ||
            (node.level === 1 && level5 && level5.includes(node.id)) ||
            (node.level === 2 && level6 && level6.includes(node.id)) ||
            (node.level === 3 && level7 && level7.includes(node.id))) {
            // If found in any level, mark isChecked as true
            node.isChecked = true;
        }
        // Recursively check child nodes if any
        if (node.nodes && node.nodes.length > 0) {
            node.nodes = node.nodes.map(child => checkNodeMarkets(child, markets));
        }

        return node;
    };




    //@ts-ignore
    const isBranchOpen = (id) => {
        const currentIndex = _findIndex(branchOpenStates, elem => elem.id === id);
        return currentIndex >= 0 && branchOpenStates[currentIndex].isOpen;
    };

    // Update the isOpen branch state
    //@ts-ignore
    const updateBranchState = (id, isBranchOpen) => {
        const newStates = _cloneDeep(branchOpenStates);
        const currentIndex = _findIndex(newStates, elem => elem.id === id);

        if (currentIndex >= 0) { // Ensure the index exists
            newStates[currentIndex].isOpen = isBranchOpen;
            setBranchOpenStates(newStates);
        } else {
            console.warn(`Branch state for ID ${id} not found. Check your branchOpenStates array.`);
        }
    };

    // Toggle the isOpen branch state
    //@ts-ignore
    const toggle = (id) => {
        // console.log('toggle', id);
        const newStates = _cloneDeep(branchOpenStates);
        const currentIndex = _findIndex(newStates, elem => elem.id === id);
        // console.log('currentIndex', currentIndex);
        // console.log('newStates', newStates);
        if (currentIndex >= 0) { // Ensure the index exists
            const currentOpenState = newStates[currentIndex].isOpen;
            newStates[currentIndex].isOpen = !currentOpenState;
            setBranchOpenStates(newStates);
        } else {
            console.warn(`Cannot toggle branch state for ID ${id} as it does not exist.`);
        }
    };

    // Toggle isOpen state for a given ID based on event type
    //@ts-ignore
    const toggleIsOpen = (id) => (event) => {
        const { type, key } = event;

        if (type === 'keydown' && event.target.tagName === 'INPUT') {
            // Do not toggle branch expanded state when a checkbox is in focus
            return;
        }

        switch (type) {
            case 'keydown':
                if (isSelectionKeyPressed(key)) {
                    event.stopPropagation();
                    event.preventDefault();
                    toggle(id);
                    setSelected(id);
                } else if (isLeftRightArrowPressed(key)) {
                    event.preventDefault();
                    key === ARROW_LEFT && event.stopPropagation();
                    if (isBranchOpen(id) && key === ARROW_LEFT) {
                        // If the branch is open and left arrow is pressed, close the node
                        event.stopPropagation();
                        updateBranchState(id, false);
                    } else if (!isBranchOpen(id) && key === ARROW_RIGHT) {
                        // If the branch is closed and right arrow is pressed, open the node
                        event.stopPropagation();
                        updateBranchState(id, true);
                    }
                }
                break;
            case 'dblclick':
                toggle(id);
                break;
            case 'click':
                event.stopPropagation();
                toggle(id);
                break;
            default:
                console.warn(`Unhandled event type: ${type}`);
        }
    };


    //@ts-ignore
    const handleClickEvent = (id) => (e) => {
        setSelected(id);
        e.stopPropagation();
    };

    //@ts-ignore
    const handleNodeKeyDownEvent = (id) => (event) => {
        const { key, type } = event;
        if (type === 'keydown' && event.target.tagName === 'INPUT') {
            // do not toggle selected state when checkbox is in focus
            return;
        }
        if (type === 'keydown') {
            if (isSelectionKeyPressed(key)) {
                setSelected(id);
                event.stopPropagation();
                event.preventDefault();
            } else if (isLeftRightArrowPressed(key)) {
                event.stopPropagation();
                event.preventDefault();
            }
        }
    };

    //@ts-ignore
    const isFiltering = () => filterFieldValue && filterFieldValue.length > 0;


    //old query

    // const generateFilterQuery = (data) => {
    //     // Map level to corresponding l*_id
    //     const levelToFieldMap = {
    //         0: 'l4_id',
    //         1: 'l5_id',
    //         2: 'l6_id',
    //         3: 'l7_id'
    //     }
    //     // Store the collected IDs for each level
    //     const selectedIdsByLevel = {};
    //     const selectedMarketNames = {};

    //     // Helper function to traverse the tree recursively and collect selected IDs
    //     const traverseNodes = (nodes) => {
    //         nodes.forEach((node) => {
    //             if (node.isChecked) {
    //                 // Collect IDs based on the node's level
    //                 if (!selectedIdsByLevel[node.level]) {
    //                     selectedIdsByLevel[node.level] = [];
    //                     selectedMarketNames[node.level] = [];
    //                 }
    //                 selectedIdsByLevel[node.level].push(node.id);
    //                 selectedMarketNames[node.level].push(node.text);
    //             }

    //             // Traverse deeper if there are child nodes
    //             if (node.nodes && node.nodes.length > 0) {
    //                 traverseNodes(node.nodes);
    //             }
    //         });
    //     };

    //     // Start traversal from the root level
    //     traverseNodes(data);

    //     // console.log(selectedIdsByLevel, 'selectedIdsByLevel');
    //     // console.log(selectedMarketNames, 'selectedMarketNames');

    //     if (_.isEmpty(selectedIdsByLevel) || _.isEmpty(selectedMarketNames)) {
    //         dispatch(setUserInputFields({ field: 'markets', value: {} }));
    //         dispatch(setProp({ slice: 'userManagement', key: 'hierachyFilterQuery', value: "" }));
    //     } else {
    //         let marketsObj = extractSelectedMarketNames(selectedIdsByLevel, selectedMarketNames);
    //         dispatch(setUserInputFields({ field: 'markets', value: marketsObj }));

    //         // Formulate the query for each level, using the mapped field names
    //         const filterQueries = Object.keys(selectedIdsByLevel).map((level) => {

    //             // Determine the correct field name using the custom level-to-field mapping
    //             const fieldName = levelToFieldMap[level];
    //             const ids = selectedIdsByLevel[level].join(', ');
    //             return `${fieldName} in (${ids})`;
    //             //return `l4_id in (4009)`
    //         });

    //         // Combine all the filter conditions using AND to match all selected levels
    //         return filterQueries.length > 0 ? filterQueries.join(' AND ') : '';
    //     }
    // };




    function extractSelectedMarketNames(idsByLevel, namesByLevel) {
        return {
            // markets: namesByLevel[0] ? [...namesByLevel[0]] : [], // Level 4 names go into markets
            markets: [],
            level4: idsByLevel[0] ? [...idsByLevel[0]] : [],      // Level 4 IDs
            level5: idsByLevel[1] ? [...idsByLevel[1]] : [],      // Level 5 IDs
            level6: idsByLevel[2] ? [...idsByLevel[2]] : [],      // Level 6 IDs
            level7: idsByLevel[3] ? [...idsByLevel[3]] : []       // Level 7 IDs
        };
    }



    // count the number of matched filterd items
    //@ts-ignore
    // count the number of matched filtered items
    const countMatches = (data, searchText) => {
        for (let treeIndex = 0; treeIndex < data.length; treeIndex += 1) {
            const treeItem = data[treeIndex];
            if (treeItem.isMatched) {
                // the searchTerm may appear multiple times per tree node name
                const pattern = new RegExp(`${searchText}`, 'g');
                const appearanceNumber = treeItem.text.match(pattern, "").length;
                treeMatchedCount.current += appearanceNumber;
            }
            if (treeItem.nodes && treeItem.nodes.length > 0) {
                countMatches(treeItem.nodes, searchText);
            }
        }
    };
    // set all branches with filtered children to open
    const updateBranchOpenStates = (data, newStates) => {
        for (let treeIndex = 0; treeIndex < data.length; treeIndex += 1) {
            const treeItem = data[treeIndex];
            if (treeItem.childIsMatched) {
                //@ts-ignore
                const currentIndex = _findIndex(newStates, elem => elem.id === treeItem.id);
                newStates[currentIndex].isOpen = true;
                if (treeItem.nodes && treeItem.nodes.length > 0) {
                    updateBranchOpenStates(treeItem.nodes, newStates);
                }
            }
        }
    };

    //  const generateFilterQuery = (data) => {
    //     const levelToFieldMap = {
    //         0: 'l4_id',
    //         1: 'l5_id',
    //         2: 'l6_id',
    //         3: 'l7_id'
    //     };

    //     const selectedIdsByLevel = {};
    //     const selectedMarketNames = {};

    //     // Initialize all_selected variables for each level
    //     let all_selected_L4 = true;
    //     let all_selected_L5 = true;
    //     let all_selected_L6 = true;
    //     let all_selected_L7 = true;

    //     const traverseNodes = (nodes, level = 0) => {
    //         nodes.forEach((node) => {
    //             if (node.isChecked) {
    //                 if (!selectedIdsByLevel[level]) {
    //                     selectedIdsByLevel[level] = [];
    //                     selectedMarketNames[level] = [];
    //                 }
    //                 selectedIdsByLevel[level].push(node.id);
    //                 selectedMarketNames[level].push(node.text);
    //             } else {
    //                 // Set the all_selected flag to false if any node at this level is unchecked
    //                 if (level === 0) all_selected_L4 = false;
    //                 if (level === 1) all_selected_L5 = false;
    //                 if (level === 2) all_selected_L6 = false;
    //                 if (level === 3) all_selected_L7 = false;
    //             }

    //             if (node.nodes && node.nodes.length > 0) {
    //                 traverseNodes(node.nodes, level + 1);
    //             }
    //         });
    //     };

    //     traverseNodes(data);

    //     if (_.isEmpty(selectedIdsByLevel) || _.isEmpty(selectedMarketNames)) {
    //         dispatch(setUserInputFields({ field: 'markets', value: {} }));
    //         dispatch(setProp({ slice: 'userManagement', key: 'hierachyFilterQuery', value: "" }));
    //         return;
    //     }

    //     let marketsObj = extractSelectedMarketNames(selectedIdsByLevel, selectedMarketNames);
    //     dispatch(setUserInputFields({ field: 'markets', value: marketsObj }));

    //     const filterQueries = [];
    //     const levels = [0, 1, 2, 3];
    //     const allSelectedFlags = [all_selected_L4, all_selected_L5, all_selected_L6, all_selected_L7];

    //     for (let i = 0; i < levels.length; i++) {
    //         const level = levels[i];
    //         if (!allSelectedFlags[i]) {
    //             const fieldName = levelToFieldMap[level];
    //             const ids = selectedIdsByLevel[level]?.join(', ') || '';
    //             if (ids) {
    //                 filterQueries.push(`${fieldName} in (${ids})`);
    //             }
    //             break;
    //         }
    //     }

    //     return filterQueries.length > 0 ? filterQueries.join(' AND ') : '';
    // };



    const generateFilterQuery = (data) => {
        const levelToFieldMap = {
            0: 'l4_id',
            1: 'l5_id',
            2: 'l6_id',
            3: 'l7_id'
        };

        let all_selected_L4 = true;
        let all_selected_L5 = true;
        let all_selected_L6 = true;
        let all_selected_L7 = true;

        const selectedIdsByLevel = {};
        const selectedMarketNames = {};

        const traverseNodes = (nodes, level = 0) => {
            nodes.forEach((node) => {
                if (!node.isChecked) {
                    if (level === 0) all_selected_L4 = false;
                    if (level === 1) all_selected_L5 = false;
                    if (level === 2) all_selected_L6 = false;
                    if (level === 3) all_selected_L7 = false;
                } else {
                    if (!selectedIdsByLevel[level]) {
                        selectedIdsByLevel[level] = [];
                        selectedMarketNames[node.level] = [];
                    }
                    selectedIdsByLevel[level].push(node.id);
                    selectedMarketNames[node.level].push(node.text);
                }

                if (node.nodes && node.nodes.length > 0) {
                    traverseNodes(node.nodes, level + 1);
                }
            });
        };

        traverseNodes(data);

        if (_.isEmpty(selectedIdsByLevel) || _.isEmpty(selectedMarketNames)) {
            dispatch(setUserInputFields({ field: 'markets', value: {} }));
            dispatch(setProp({ slice: 'userManagement', key: 'hierachyFilterQuery', value: "" }));
            dispatch(setOotRoles({ type: 'approver_market', value: [] }));
        } else {
            let marketsObj = extractSelectedMarketNames(selectedIdsByLevel, selectedMarketNames);
            console.log(marketsObj)
            ///mapping of portal markets to OOT markets
            const approverMarkets = marketsObj?.level4?.map((item) => {
                return OOT_MARKETS_MAPPING[item];
            })
            dispatch(setOotRoles({ type: 'approver_market', value: approverMarkets }));
            console.log(approverMarkets, 'approverMarkets');
            dispatch(setUserInputFields({ field: 'markets', value: marketsObj }));
            const filterQueries = [];
            const allSelectedFlags = [
                { level: 3, flag: all_selected_L7 },
                { level: 2, flag: all_selected_L6 },
                { level: 1, flag: all_selected_L5 },
                { level: 0, flag: all_selected_L4 }
            ];

            for (const { level, flag } of allSelectedFlags) {
                if (!flag && selectedIdsByLevel[level]) {
                    const fieldName = levelToFieldMap[level];
                    const ids = selectedIdsByLevel[level].join(', ');
                    filterQueries.push(`${fieldName} in (${ids})`);
                    break;  // Exit the loop after adding the first non-selected level
                }
            }

            // console.log(filterQueries, 'filterQueries');

            return filterQueries.length > 0 ? filterQueries.join(' AND ') : '';
        };
    }


        const filterData = (filterText, data) => {
            treeMatchedCount.current = 0;
            const treeDataCopy = _cloneDeep(data);
            filterTreeItems(treeDataCopy, filterText);
            const newStates = _cloneDeep(branchOpenStates);
            updateBranchOpenStates(treeDataCopy, newStates);
            setBranchOpenStates(newStates);
            countMatches(treeDataCopy, filterText);
        }

        // scroll the container to show the selected highlighted search text
        const scrollToView = (selectHighlightedTextEl) => {
            if (listRef.current && treeRef.current) {
                //@ts-ignore
                const listRect = listRef.current.getBoundingClientRect();
                const highlightedBlockRect = selectHighlightedTextEl.getBoundingClientRect();

                // check if new item is hidden below the list or above the list
                const isInvisibleBelowList = (highlightedBlockRect.top + highlightedBlockRect.height) > (listRect.height + listRect.top);
                const isInvisibleAboveList = highlightedBlockRect.top < listRect.top;

                // adjust scrollTop based on the condition.
                if (isInvisibleBelowList) {
                    const delta = (highlightedBlockRect.top + highlightedBlockRect.height) - (listRect.height + listRect.top);
                    //@ts-ignore
                    listRef.current.scrollTop = listRef.current.scrollTop + delta + 60;
                } else if (isInvisibleAboveList) {
                    const delta = listRect.top - highlightedBlockRect.top + 60;
                    //@ts-ignore
                    listRef.current.scrollTop = listRef.current.scrollTop - delta > 0 ? listRef.current.scrollTop - delta : 0;
                }
            }
        }

        const highlightSelectedFindText = (index) => {
            if (listRef.current && treeRef.current) {
                //@ts-ignore
                const highlightedTextArray = treeRef.current.querySelectorAll(`span.${HIGHLIGHT_CLASSNAME}`);
                // remove selected highlight for all searched text.
                if (highlightedTextArray.length > 0) {
                    //@ts-ignore
                    highlightedTextArray.forEach(element => element.classList.remove(SELECTED_HIGHLIGHT_CLASSNAME));
                    const selectHighlightedTextEl = highlightedTextArray[index];
                    // add the selected highlight for new selected text.
                    //
                    selectHighlightedTextEl.classList.add(SELECTED_HIGHLIGHT_CLASSNAME);

                    // scroll the element into view.
                    scrollToView(selectHighlightedTextEl);
                }
            }
        }

        const selectNextMatch = () => {
            let nextMatch;
            if (selectedIndex == null) {
                nextMatch = 0
            } else {
                nextMatch = selectedIndex + 1 > treeMatchedCount.current - 1 ? 0 : selectedIndex + 1;
            }
            setSelectedIndex(nextMatch);
            highlightSelectedFindText(nextMatch);
        }
        const selectPreviousMatch = () => {
            let previousMatch;
            if (selectedIndex == null) {
                previousMatch = treeMatchedCount.current - 1;
            } else {
                previousMatch = selectedIndex - 1 < 0 ? treeMatchedCount.current - 1 : selectedIndex - 1;
            }
            setSelectedIndex(previousMatch);
            highlightSelectedFindText(previousMatch);
        }

        const clearFind = () => {
            if (!treeRef.current) return;
            //@ts-ignore
            const highlightedTextArray = treeRef.current.querySelectorAll(`span.${HIGHLIGHT_CLASSNAME}`);
            // remove selected highlight for all searched text.
            highlightedTextArray.forEach(element => {
                element.classList.remove(HIGHLIGHT_CLASSNAME);
                element.classList.remove(SELECTED_HIGHLIGHT_CLASSNAME);
            });
            treeMatchedCount.current = 0;
            setSelectedIndex(0);
            setFilterFieldValue('');

        }


        const handleClearButtonCLick = () => {
            clearFind();
            //@ts-ignore
            filterFieldInputRef.current.focus()
        }

        const handleFilterTree = (event) => {
            const filterValue = event.target.value;
            setSelectedIndex(0);
            setFilterFieldValue(filterValue);
            filterData(filterValue, treeData);

            setTimeout(() => highlightSelectedFindText(0));
        };






        // delete the entire contents of Tree item with id

        const treeFilter = (
            <TreeFilterContainer style={{padding: '0px'}}>
                <FilterField
                    variant='outlined-small'
                    textInputProps={{
                        placeholder: 'Search',
                        showClearButton: true,
                        inputProps: {
                            ref: filterFieldInputRef,
                            'aria-label': 'Search',
                            style: { paddingLeft: '2.0rem',height:'32px' },
                        },
                        clearButtonProps: {
                            'aria-label': "Clear Find",
                            onClick: () => {
                                handleClearButtonCLick();
                            }
                        },
                    }}
                    value={filterFieldValue}
                    onChange={handleFilterTree}
                    renderIcon={<FilterFieldIcon style={{paddingTop: '8px'}} />}
                />
                {isFiltering() &&
                    <>
                        {treeMatchedCount.current > 0 &&
                            <FindMatchContainer aria-label={`${treeMatchedCount.current} matched found`} matchNumberContent={`${selectedIndex + 1 || 0}/${treeMatchedCount.current}`}>
                                {treeMatchedCount.current > 0 &&
                                    (
                                        <>
                                            <IconButton size='small' aria-label={`Next previous item. Number ${selectedIndex + 1} matched item focused`} onClick={selectPreviousMatch}>
                                                <ChevronLeftIcon />
                                            </IconButton>
                                            <IconButton size='small' aria-label={`Next next item. Number ${selectedIndex + 1} matched item focused`} onClick={selectNextMatch}>
                                                <ChevronRightIcon />
                                            </IconButton>
                                        </>
                                    )
                                }
                            </FindMatchContainer>
                        }
                        {treeMatchedCount.current === 0 &&
                            <>
                                <TreeText aria-label=" No matching items" style={{ paddingTop: '1rem', textAlign: 'center' }}>No matching items</TreeText>
                                <br />
                                <TreeText aria-label="Try to adjust your criteria to find the items" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>
                                    Try to adjust your criteria to find the items.
                                </TreeText>
                            </>
                        }
                    </>
                }
            </TreeFilterContainer>
        );



        const deleteTreeItem = (data, id) => {
            if (!data || data[0] === undefined) return [];
            for (let treeIndex = 0; treeIndex < data.length; treeIndex += 1) {
                const treeItem = data[treeIndex];
                // remove the treeitem with matching id
                if (treeItem.id === id) {
                    data.splice(treeIndex, 1);
                    return;
                }
                if (treeItem.nodes && treeItem.nodes.length > 0) {
                    deleteTreeItem(treeItem.nodes, id);
                }
            }
            return;
        };
        // Delete Branch along with all its sub nodes
        const handleDeleteTreeItemClick = (branchId) => (event) => {
            const newTreeData = _cloneDeep(treeData);
            // find branchId in treeData and delete the branch entry
            deleteTreeItem(newTreeData, branchId);
            // update parent checkbox state
            const flatTree = getWholeFlattenedTree(newTreeData);
            mainArray = [];
            updateIndeterminateStates(newTreeData, flatTree);
            markAsIndeterminate(newTreeData, mainArray, true, false);
            setTreeData(newTreeData);
            // if filtering, delete from that dataset
            if (isFiltering()) {
                filterData(filterFieldValue, newTreeData);
            }
            // close dialog
            setShowDeleteTreeItemDialog(false);
        };
        // return the id and text values of the nodes and nested nodes in data
        const getBranchContents = (data, returnedItems = []) => {
            if (!data || data[0] === undefined) return [];
            for (let treeIndex = 0; treeIndex < data.length; treeIndex += 1) {
                const treeItem = data[treeIndex];
                returnedItems.push({
                    id: treeItem.id,
                    text: treeItem.text,
                });
                if (treeItem.nodes && treeItem.nodes.length > 0) {
                    getBranchContents(treeItem.nodes, returnedItems);
                }
            }
            return returnedItems;
        };

        // search all data nodes and nested nodes and return the node with the matching id
        const getTreeItem = (data, id, returnedItems = []) => {
            if (!data || data[0] === undefined) return [];
            for (let treeIndex = 0; treeIndex < data.length; treeIndex += 1) {
                const treeItem = data[treeIndex];
                if (treeItem.id === id) {
                    returnedItems.push(treeItem);
                }
                if (treeItem.nodes && treeItem.nodes.length > 0) {
                    getTreeItem(treeItem.nodes, id, returnedItems);
                }
            }
            return returnedItems;
        };
        // Delete a TreeBranch and all sub nodes
        const renderDeleteTreeItemDialog = () => {
            const branchContents = getTreeItem(treeData, treeItemToUpdate.current);
            const branchItems = getBranchContents(branchContents);
            return (
                <Dialog ariaHideApp={false} isOpen style={{ content: DIALOG_STYLE }}>
                    <AlertBar />
                    <DialogTitle title={`Do you want to delete id: ${treeItemToUpdate.current} name: '${branchItems[0].text}'?`} />
                    <DialogContent>
                        <Typography typography="BODY">Continuing will permanently remove:</Typography>
                        {branchItems.map(branchItem => { return <Typography key={`${branchItem.id}`} typography="BODY">{branchItem.text}</Typography> })}
                    </DialogContent>
                    <DialogFooter>
                        <Button onClick={() => { setShowDeleteTreeItemDialog(false); }}>CANCEL</Button>
                        <Button onClick={handleDeleteTreeItemClick(treeItemToUpdate.current)}>DELETE</Button>
                    </DialogFooter>
                </Dialog>
            );
        }

        // find the id and change the name
        const renameTreeItem = (data, id, name) => {
            if (!data || data[0] === undefined) return [];
            for (let treeIndex = 0; treeIndex < data.length; treeIndex += 1) {
                const treeItem = data[treeIndex];
                // remove the treeitem with matching id
                if (treeItem.id === id) {
                    // update with new name
                    treeItem.text = name;
                    return;
                }
                if (treeItem.nodes && treeItem.nodes.length > 0) {
                    renameTreeItem(treeItem.nodes, id, name);
                }
            }
            return;
        };




        // find parent (branch) of childId
        const findParent = (data, childId, dataArray = []) => {
            for (let treeIndex = 0; treeIndex < data.length; treeIndex += 1) {
                const treeItem = data[treeIndex];
                if (treeItem.isBranch) {
                    const treeNodes = treeItem.nodes;
                    for (let nodeIndex = 0; nodeIndex < treeNodes.length; nodeIndex += 1) {
                        const nodeItem = treeNodes[nodeIndex];
                        if (nodeItem.id === childId) {
                            dataArray.push(treeItem);
                            return dataArray;
                        }
                    }
                    findParent(treeNodes, childId, dataArray);
                }
            }
            return dataArray;
        };
        // update any branch levels in the branches in newTreeItem
        const updateBranchLevels = (data, level) => {
            for (let treeIndex = 0; treeIndex < data.length; treeIndex += 1) {
                const treeItem = data[treeIndex];
                if (treeItem.isBranch) {
                    treeItem.level = level + 1;
                    if (treeItem.nodes && treeItem.nodes.length > 0) {
                        updateBranchLevels(treeItem.nodes, level + 1);
                    }
                }
            }
            return;
        }

        // add saved copy of dragged tree item to the Tree. If dragging to a TreeBranch and the branch is open,
        // add the dragged item to the branch children. Otherwise add after the dragged to item.




        // MoreButton list item was selected
        //@ts-ignore
        const handleMoreButtonItemEvent = moreButtonAction => (event) => {
            const { type, key } = event;
            const { id, contextMenuItem } = moreButtonAction;
            if (type === 'click' || (type === 'keydown' && isSelectionKeyPressed(key))) {
                if (contextMenuItem === 'Add Tree Item') {
                    treeItemToUpdate.current = id;
                    setShowAddTreeItemDialog(true);
                } else if (contextMenuItem === 'Delete') {
                    treeItemToUpdate.current = id;
                    setShowDeleteTreeItemDialog(true);
                } else if (contextMenuItem === 'Rename') {
                    treeItemToUpdate.current = id;
                    setShowRenameTreeItemDialog(true);
                }
            }
        };
        // MoreButton was opened; stop the event spreading to the rest of the Tree item
        //@ts-ignore
        const handleMoreButtonKeyDown = id => (event) => {
            const { key } = event;
            if (isSelectionKeyPressed(key)) {
                event.stopPropagation();
            } else if (key === TAB) {
                setMoreButtonOpenStateForId({ id, isOpen: false });
            }
        };
        const getMoreButtonOpenStateForId = (id) => {
            const moreButtonIndex = _findIndex(moreButtonOpenStates, elem => elem.id === id);
            return moreButtonIndex >= 0 && moreButtonOpenStates[moreButtonIndex].isOpen;
        };
        const setMoreButtonOpenStateForId = (data) => {
            const { id, isOpen } = data;
            const newMoreButtonOpenStates = _cloneDeep(moreButtonOpenStates);
            const moreButtonIndex = _findIndex(newMoreButtonOpenStates, elem => elem.id === id);
            if (moreButtonIndex > -1) {
                newMoreButtonOpenStates[moreButtonIndex].isOpen = isOpen;
            } else {
                // add new entry for this id
                newMoreButtonOpenStates.push(data);
            }
            setMoreButtonOpenStates(newMoreButtonOpenStates);
        };


        //@ts-ignore
        const renderContextMenu = (data) => {
            const { id, contextMenuItems } = data;

            const ContextMenuComponent = () => (
                <TreeMoreButton
                    treeItemId={id}
                    moreButtonProps={{
                        //@ts-ignore
                        isOpen: getMoreButtonOpenStateForId(id),
                        'aria-label': `${id} context menu`,
                        onOpen() {
                            setMoreButtonOpenStateForId({ id, isOpen: true });
                        },
                        onClose() {
                            setMoreButtonOpenStateForId({ id, isOpen: false });
                        },
                        onKeyDown: handleMoreButtonKeyDown(id),
                    }}
                >
                    {contextMenuItems.map((item, x) => (
                        <TreeMoreButtonItem
                            key={`id-${item}`}
                            onClick={handleMoreButtonItemEvent({ id, contextMenuItem: item })}
                            onKeyDown={handleMoreButtonItemEvent({ id, contextMenuItem: item })}
                            aria-label={item}
                        >
                            <TreeMoreButtonItemText>{item}</TreeMoreButtonItemText>
                        </TreeMoreButtonItem>
                    ))}
                </TreeMoreButton>
            );

            // Assign a display name
            ContextMenuComponent.displayName = `ContextMenu_${id}`;

            return ContextMenuComponent;
        };


        // update any branch indeterminate states
        const updateIndeterminateStates = (data, flatTree) => {
            if (!data || data[0] === undefined) return [];
            for (let treeIndex = data.length - 1; treeIndex >= 0; treeIndex -= 1) {
                const treeItem = data[treeIndex];
                if (treeItem.nodes && treeItem.nodes.length > 0) {
                    updateIndeterminateStates(treeItem.nodes, flatTree,);
                }
                const treeNodes = treeItem.nodes;
                if (treeNodes === undefined || treeNodes.length === 0) continue;
                let isAllChildChecked = true;
                let isAllChildUnChecked = true;
                let isChildIndeterminate = false;
                for (let nodeIndex = treeNodes.length - 1; nodeIndex >= 0; nodeIndex -= 1) {
                    const childNode = treeNodes[nodeIndex];
                    if (childNode.isChecked) {
                        isAllChildUnChecked = false;
                    } else {
                        isAllChildChecked = false;
                    }
                    if (childNode.isIndeterminate) {
                        isChildIndeterminate = true;
                    }
                }
                treeItem.isChecked = isAllChildChecked;
                treeItem.isIndeterminate = (!isAllChildChecked && !isAllChildUnChecked) || isChildIndeterminate;
                if (treeItem.isIndeterminate) {
                    // get parentIds of the indeterminate treeitem
                    //@ts-ignore
                    const pIndex = _findIndex(flatTree, elem => elem.id === treeItem.id);
                    const parentsOfIndeterminate = flatTree[pIndex].parents;
                    mainArray = _concat(mainArray, parentsOfIndeterminate);
                }
            }
            return;
        };
        // find the id and updata checked status of id and its children nodes
        const updateCheckedValue = (data, id, flatTree, checked, indeterminate = false) => {
            for (let treeIndex = 0; treeIndex < data.length; treeIndex += 1) {
                const treeItem = data[treeIndex];
                // update the treeitem with matching id
                if (treeItem.id === id) {
                    // update with new value
                    treeItem.isIndeterminate = indeterminate;
                    treeItem.isChecked = checked;
                    // get parentIds of the treeitem
                    //@ts-ignore
                    const pIndex = _findIndex(flatTree, elem => elem.id === treeItem.id);
                    const parentNodes = flatTree[pIndex].parents;
                    mainArray = _concat(mainArray, parentNodes);
                    // update all children nodes
                    const checkEveryNode = (node) => {
                        node.isChecked = checked;
                        if (node.nodes && node.nodes.length > 0) {
                            node.nodes.forEach(checkEveryNode);
                        }
                    };
                    !indeterminate && checkEveryNode(treeItem);
                }
                if (treeItem.nodes && treeItem.nodes.length > 0) {
                    updateCheckedValue(treeItem.nodes, id, flatTree, checked, indeterminate);
                }
            }
            return;
        };
        // every id in idArray will be set as indeterminate
        //@ts-ignore
        const markAsIndeterminate = (data, idArray, indeterminate, checked) => {
            for (let treeIndex = 0; treeIndex < data.length; treeIndex += 1) {
                const treeItem = data[treeIndex];
                // update the treeitem with matching id
                if (idArray.includes(treeItem.id)) {
                    // update with new value
                    treeItem.isIndeterminate = indeterminate;
                    treeItem.isChecked = checked;
                }
                if (treeItem.nodes && treeItem.nodes.length > 0) {
                    markAsIndeterminate(treeItem.nodes, idArray, indeterminate, checked);
                }
            }
        };

        //@ts-ignore
        const setCheckBox = (id, checked) => {
            const newTreeData = _cloneDeep(treeData);
            // find and update checked state of treeitem
            let flatTree = getWholeFlattenedTree(newTreeData);
            mainArray = [];
            updateCheckedValue(newTreeData, id, flatTree, checked);
            if (mainArray.length > 1) {
                markAsIndeterminate(newTreeData, mainArray, true, checked);
            }
            // check for indeterminate branches
            flatTree = getWholeFlattenedTree(newTreeData);
            mainArray = [];
            updateIndeterminateStates(newTreeData, flatTree);
            if (mainArray.length > 1) {
                markAsIndeterminate(newTreeData, mainArray, true, checked);
            }

            setTreeData(newTreeData);
            let accountListQueryFilter = generateFilterQuery(newTreeData);
            // console.log(accountListQueryFilter, '************88')
            dispatch(setProp({ slice: 'userManagement', key: 'hierachyFilterQuery', value: accountListQueryFilter }));
            // console.log('accountListQueryFilter', accountListQueryFilter);
            // if filtering, update that dataset
            if (filterFieldValue && filterFieldValue.length) {
                filterData(filterFieldValue, newTreeData);
            }
        };



        const renderBranchContent = (id, text, isChecked, isIndeterminate, node) => {
            const Component = () => {
                return (
                    <>
                        <TreeDropDownButton
                            iconButtonProps={{
                                'aria-label': 'Toggle',
                                onClick: toggleIsOpen(id),
                            }}
                        >
                            {isLegacy ? (
                                <ArrowTriangleDownIcon size={24} aria-label={null} />
                            ) : (
                                <ArrowTriangleDownIconLatest size={24} aria-label={null} />
                            )}
                        </TreeDropDownButton>
                        <TreeCheckbox
                            checkboxProps={{
                                checked: !isIndeterminate ? isChecked : false,
                                indeterminate: isIndeterminate,
                                onChange: (event) => {
                                    event.persist();
                                    setCheckBox(id, event.target.checked);
                                },
                                inputProps: { 'aria-label': `checkbox for ${text}` },
                            }}
                        />
                        <TreeText>{markItem(text, filterFieldValue, HIGHLIGHT_CLASSNAME)}</TreeText>
                    </>
                );
            };

            Component.displayName = `BranchContent_${id}`;
            return Component;
        };



        //@ts-ignore
        const renderTreeData = (data) => {
            const treeNodes = [];
            let treeNode = undefined;
            for (let treeIndex = 0; treeIndex < data.length; treeIndex += 1) {
                const node = data[treeIndex];
                if (node.isBranch) {
                    treeNode = (
                        <TreeBranch
                            key={node.id}
                            id={node.id}
                            level={node.level}
                            disabled={node.disabled}
                            selected={selected === node.id}
                            error={node.error}
                            errorMessage={node.errorMessage}
                            onClick={!node.disabled && handleClickEvent(node.id)}
                            onDoubleClick={!node.disabled && toggleIsOpen(node.id)}
                            onKeyDown={!node.disabled && toggleIsOpen(node.id)}
                            isOpen={isBranchOpen(node.id)}
                            role="presentation"
                            treeBranchContentProps={{
                                'aria-label': selected === node.id ? `${node.text} selected` : `${node.text}`,
                                'aria-posinset': node.posinset,
                                'aria-setsize': node.setsize,
                                'aria-selected': selected === node.id,
                            }}
                            renderBranchContent={renderBranchContent(node.id, node.text, node.isChecked, node.isIndeterminate, node)}
                            renderContextMenu={node.contextMenuItems && renderContextMenu({ id: node.id, contextMenuItems: node.contextMenuItems })}

                        >
                            {node.nodes && node.nodes.length > 0 && renderTreeData(node.nodes)}
                        </TreeBranch>
                    );
                } else {
                    treeNode = (
                        <TreeNode
                            key={node.id}
                            id={node.id}
                            aria-posinset={node.posinset}
                            aria-setsize={node.setsize}
                            aria-selected={selected === node.id}
                            aria-label={selected === node.id ? `${node.text} selected` : `${node.text}`}
                            onClick={!node.disabled && handleClickEvent(node.id)}
                            onKeyDown={!node.disabled && handleNodeKeyDownEvent(node.id)}
                            selected={selected === node.id}
                            disabled={node.disabled}
                            renderContextMenu={node.contextMenuItems && renderContextMenu({ id: node.id, contextMenuItems: node.contextMenuItems })}
                        >
                            <TreeCheckbox
                                checkboxProps={{
                                    'aria-label': node.isChecked ? `${node.text} checked` : `${node.text} not checked`,
                                    checked: node.isChecked,
                                    onChange: (event) => { event.persist(); setCheckBox(node.id, event.target.checked); }
                                }}
                            />
                            <TreeText>{markItem(node.text, filterFieldValue, HIGHLIGHT_CLASSNAME)}</TreeText>
                        </TreeNode>
                    );
                }
                treeNodes.push(treeNode);
            }
            return treeNodes;
        }



        //@ts-ignore
        // const haveFilterMatches = useFilter ? treeMatchedCount.current > 0 : false;
        // const treeContainerPartsHeight = TITLE_HEIGHT + FOOTER_HEIGHT + (useFilter ? FILTER_HEIGHT : 0) +
        //     DIV_PADDING_HEIGHT + FOOTER_DIVIDER_HEIGHT + 0.625;
        // const treeContainerPartsHeightInRem = `${treeContainerPartsHeight}rem`;
        // const windowInnerHeightInRem = window.innerHeight * 0.0625;
        // const treeitemsPerPage = Math.floor((windowInnerHeightInRem - treeContainerPartsHeight) / ROW_HEIGHT) - 1;
        // return (
        //     <div style={{ width: '33vw', height: '100vh', padding: '.5rem', boxSizing: 'border-box' }}>
        //         <Label className={userStyles.userLabel}>Markets</Label>
        //         <TreeContainer
        //             dndProviderProps={{ context: window }}
        //             style={{ width: '100%', height: 'auto', minHeight: 'auto' }}
        //         >
        //             {/* <TreeTitle id="ccfk-tree-label" htmlFor="ccfk-tree-id">Markets</TreeTitle> */}
        //             {useFilter && treeFilter}
        //             <Tree
        //                 id="ccfk-tree-id"
        //                 aria-labelledby="ccfk-tree-label"
        //                 listProps={{ ref: listRef, preventFocusHandling: true, ulProps: { role: "group" }, style: {height: '100%', maxHeight: `calc(100vh - ${treeContainerPartsHeightInRem})` }}}
        //                 dnd={allowDnd}
        //                 dndCallback={handleDndCallback}
        //                 itemsPerPage={treeitemsPerPage}
        //             >
        //                 {filterFieldValue !== '' ? renderTreeData(filteredTreeData, true) : renderTreeData(treeData, false)}
        //             </Tree>

        //         </TreeContainer>

        //         {showDeleteTreeItemDialog && renderDeleteTreeItemDialog()}

        //     </div>
        // );


        const haveFilterMatches = useFilter ? treeMatchedCount.current > 0 : false;
        const PADDING = 0.5;
        const treeContainerPartsHeight = PADDING + TITLE_HEIGHT + FOOTER_HEIGHT + (useFilter ? FILTER_HEIGHT : 0) +
            (haveFilterMatches ? FILTER_MATCHES_HEIGHT : 0) + DIV_PADDING_HEIGHT + FOOTER_DIVIDER_HEIGHT + 0.625;
        const treeContainerPartsHeightInRem = `${treeContainerPartsHeight}rem`;
        const windowInnerHeightInRem = window.innerHeight * 0.0625;
        const treeitemsPerPage = Math.floor((windowInnerHeightInRem - treeContainerPartsHeight) / ROW_HEIGHT) - 1;
        return (
            <div style={{ height: 'calc(100vh - 3.5rem)', boxSizing: 'border-box' }}>
                <Label className={userStyles.userLabel}>Markets
                    {/* <span style={{ color: '#C91514' }}>*</span> */}
                </Label>
                <TreeContainer
                    dndProviderProps={{ context: window }}
                    style={{ width: '100%', height: 'auto', minHeight: 'auto', padding:'0px' }}
                >
                    {/* <TreeTitle id="ccfk-tree-label" htmlFor="ccfk-tree-id">TREE DEMO</TreeTitle> */}
                    {useFilter && treeFilter}
                    <Tree
                        id="ccfk-tree-id"
                        ref={treeRef}
                        aria-labelledby="ccfk-tree-label"
                        preventFocusHandling={preventFocusHandling}
                        listProps={{ ref: listRef, preventFocusHandling: true, ulProps: { role: "group" }, style: { height: '100%', maxHeight: `calc(100vh - ${treeContainerPartsHeightInRem})` } }}
                        // dnd={allowDnd}
                        // dndCallback={handleDndCallback}
                        itemsPerPage={treeitemsPerPage}
                    >
                        {((isFiltering() && treeMatchedCount.current !== 0) || !isFiltering()) && renderTreeData(treeData)}
                    </Tree>
                </TreeContainer>

            </div>
        );
    };


    HierachyTree.propTypes = {
        useFilter: PropTypes.bool,
        allowDnd: PropTypes.bool,
        dndDropIsOverTimeout: PropTypes.number,
        isLegacy: PropTypes.bool,
        mode: PropTypes.string
    };
    HierachyTree.defaultProps = {
        useFilter: true,
        allowDnd: false,
        dndDropIsOverTimeout: 1200,
        isLegacy: true,
        mode: 'add'
    };


    export default HierachyTree;




