import React, { useState, useRef, useEffect } from 'react';
import userStyles from "../../Style/UserManagement.module.css";
import Label from '@nokia-csf-uxr/ccfk/Label';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import MultiSelect from "Pages/marketplace/common/MultiSelect";
import { setOotRoles, updateRoles } from "Store/contentState/userManagementSlice";
import { ootRoles, distributorOotRoles, approverRoles } from '../utils/constants';
import { setProp } from "Store/Actions";


const CommonFormOOTApprover = (prop) => {
    const selectedUserOrgType = useSelector((state: RootState) => state.userManagement.userData.orgType);
    const selectedOOTRoles = useSelector((state: RootState) => state.userManagement.userData.oot.roles);
    const selectedRoles = useSelector((state: RootState) => state.userManagement.userData.roles);
    const ootlist = useSelector((state: RootState) => state.userManagement.userData.oot)
    const rolesBasedOnOrgType = useSelector((state: RootState) => state.userManagement.rolesBasedOnOrgType);
    const dispatch = useDispatch();
    const [dataListItems, setDataListItems] = useState([]);
    const [dataListItemsOrgType, setDataListItemsOrgType] = useState([])
    const [ootApprover, setOotApprover] = useState(true);
     const nokiaCertificationStatus = useSelector((state: RootState) => state.userManagement.nokiaCertificationStatus);
   
    useEffect(() => {
      
        
        if (selectedUserOrgType[0] == "distributor" || selectedUserOrgType[0] == "direct_reseller") {
            if (selectedOOTRoles.includes("owner")) {

                setDataListItems(distributorOotRoles.filter((item) => item.id != "sales_owner"))
                setDataListItemsOrgType(distributorOotRoles.filter((item) => item.id != "sales_owner"))
            }
            else if (selectedOOTRoles.includes("sales_owner")) {
                setDataListItems(distributorOotRoles.filter((item) => item.id != "owner"))
                setDataListItemsOrgType(distributorOotRoles.filter((item) => item.id != "owner"))
            }
            else {
                if(prop.mode === "add" && nokiaCertificationStatus === "Active"){
                    setDataListItems(distributorOotRoles.filter((item) => item.id != "sales_owner"))
                    setDataListItemsOrgType(distributorOotRoles.filter((item) => item.id != "sales_owner"))
                }
                else if((prop.mode === "add" && nokiaCertificationStatus === "Inactive") || prop.mode === "add" && nokiaCertificationStatus === "No certification data"){
                    setDataListItems(distributorOotRoles.filter((item) => item.id != "owner"))
                    setDataListItemsOrgType(distributorOotRoles.filter((item) => item.id != "owner"))
                }
                else{
                setDataListItems(distributorOotRoles)
                setDataListItemsOrgType(distributorOotRoles)
                }
            }
        }
        else {
            if (selectedOOTRoles.includes("owner")) {
                setDataListItems(ootRoles.filter((item) => item.id != "sales_owner"))
            }
            else if (selectedOOTRoles.includes("sales_owner")) {
                setDataListItems(ootRoles.filter((item) => item.id != "owner"))
            }
            else {
                if(prop.mode === "add" && nokiaCertificationStatus === "Active"){
                    setDataListItems(distributorOotRoles.filter((item) => item.id != "sales_owner"))
                    setDataListItemsOrgType(distributorOotRoles.filter((item) => item.id != "sales_owner"))
                }
                else if((prop.mode === "add" && nokiaCertificationStatus === "Inactive") || prop.mode === "add" && nokiaCertificationStatus === "No certification data"){
                    setDataListItems(distributorOotRoles.filter((item) => item.id != "owner"))
                    setDataListItemsOrgType(distributorOotRoles.filter((item) => item.id != "owner"))
                }
                else{
                setDataListItems(ootRoles)
                }
            }
        }
        

    }, [selectedUserOrgType[0],selectedRoles[0]]);

    const getSelectedItemsForOOtRole = (type) => {
        let ootRolesList = type === 'roles' ? dataListItems : approverRoles
        const selectedDataList = ootRolesList.filter(item =>
            ootlist[type]?.includes(item.id)
        );
        return selectedDataList
    };




    const handleOOTRolesSelection = (selected, type) => {
        if (type === "roles") {
            let selectedValues = selected.map((a) => a.id)
            if (selectedValues.includes("sales_owner")) {
                setDataListItems(dataListItems.filter((item) => item.id != "owner"))
                selected.filter((item) => item.id != "owner")
            }
            else if (selectedValues.includes("owner")) {
                setDataListItems(dataListItems.filter((item) => item.id != "sales_owner"))
                selected.filter((item) => item.id != "owner")
            }
            else {
                if (selectedUserOrgType[0] == "distributor" || selectedUserOrgType[0] == "direct_reseller") {
                    setDataListItems(dataListItemsOrgType)
                }
                else {
                    setDataListItems(dataListItems)
                }
                
            }
            if(selectedValues.includes("approver")){
                setOotApprover(false)
            }
            else{
                setOotApprover(true)
            }
        
        const isAdminOOTRoles = selected.filter((item) => item.id === "helpdesk_agent" || item.id === "user_management_")
        if(selectedRoles.includes("iam_admin") && isAdminOOTRoles.length === 0 || selectedRoles.includes("business_platform_team") && isAdminOOTRoles.length === 0){
            dispatch(updateRoles([]));
        }
        }
        dispatch(setOotRoles({ type: type, value: selected.map((a) => a.id) }));
    }

    return (
        <>
            {selectedUserOrgType[0] === 'nokia' && <> <div className={userStyles.gridItem}>
                <Label className={userStyles.userLabel}>OOT roles <span style={{ color: '#C91514' }}>*</span></Label>
                <MultiSelect
                    placeholder={"Select OOT roles"}
                    label={""}
                    data={dataListItems.sort((a,b) => a.value.localeCompare(b.value))}
                    selectedItems={getSelectedItemsForOOtRole("roles")}
                    disabled={false}
                    onSelected={(selectedItems) => handleOOTRolesSelection(selectedItems, "roles")}
                />

            </div>
                {(selectedOOTRoles.includes("approver") || selectedOOTRoles.includes("budgetary_approver")) && <div className={userStyles.gridItem}>
                    <Label className={userStyles.userLabel}>Approver role group {selectedOOTRoles.includes("budgetary_approver") && <span style={{ color: '#C91514' }}>*</span>}</Label>
                    <MultiSelect
                        placeholder={"Select approver roles"}
                        label={""}
                        data={approverRoles}
                        selectedItems={getSelectedItemsForOOtRole("approver_role_group")}
                        disabled={false}
                        onSelected={(selectedItems) => handleOOTRolesSelection(selectedItems, "approver_role_group")}
                    />

                </div>}</>
            }
            {(selectedUserOrgType[0] === 'distributor' || selectedUserOrgType[0] === 'direct_reseller' || selectedUserOrgType[0] === 'direct_end_customer') && <> <div className={userStyles.gridItem}>
                <Label className={userStyles.userLabel}>OOT roles <span style={{ color: '#C91514' }}>*</span></Label>
                <MultiSelect
                    placeholder={"OOT Roles"}
                    label={""}
                    data={dataListItems.sort((a,b) => a.value.localeCompare(b.value))}
                    selectedItems={getSelectedItemsForOOtRole("roles")}
                    disabled={selectedUserOrgType[0] == "direct_end_customer" ? true : false}
                    onSelected={(selectedItems) => handleOOTRolesSelection(selectedItems, "roles")}
                />

            </div></>
            }
        </>
    )
}
export default CommonFormOOTApprover;