import React, { useEffect, useState } from "react";
import OverlayPanel from '@nokia-csf-uxr/ccfk/OverlayPanel';
import {
    OverlayPanelHeader
} from '@nokia-csf-uxr/ccfk/OverlayPanel';
import HorizontalDivider from '@nokia-csf-uxr/ccfk/HorizontalDivider';
import Typography from '@nokia-csf-uxr/ccfk/Typography';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import CloseIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';
import userStyles from '../../Style/UserManagement.module.css';
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import { ootRoles } from '../utils/constants';


const CONTENT_STYLE = {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
};

const SCROLLABLE_CONTENT = {
    maxHeight: '700px',  // Set a fixed max height for the scrollable area
    overflowY: 'auto',   // Enable vertical scrolling
    padding: '2rem',     // Optional: Add padding for better content spacing
};



type IDUserDetailsOverlayProp = {
    onClose: () => void;
    selectedUserData: any;

};



const UserDetailsOverlay = ({ onClose, selectedUserData }: IDUserDetailsOverlayProp) => {
    const hierarchyLevel4 = useSelector((state: RootState) => state.userManagement.hierarchyLevel4Data);
    const accountInfo = selectedUserData.business_name;
    const selectedUserOOTRoles = selectedUserData?.oot?.roles || [];
    const rolesList = useSelector((state: RootState) => state.userManagement.roleInfo.rolesData);
    // const viewInfo = selectedUserData.viewAccess;

    const [marketNames, setMarketNames] = useState([]);
    const [viewInfo, setViewInfo] = useState([]);


    const renderButtons = () => (
        <>
            <IconButton onClick={onClose} aria-label="close">
                <CloseIcon />
            </IconButton>
        </>
    );

    useEffect(() => {
        getFilteredData();
        getViewInfo();
    }, [])

    const getFilteredData = () => {
        const filteredData = hierarchyLevel4.filter((item) =>
            selectedUserData.markets?.level4?.includes(item.id)
        );

        // Map the filtered data to extract levels
        const data = filteredData.map((item) => item.level);
        setMarketNames(data);
    }

    const getViewInfo = () => {
        const viewData = rolesList.filter((item) => selectedUserData?.userRoles?.includes(item.id));
        setViewInfo(viewData[0]?.data_objects?.view);
    }


    return (

        <><div className={userStyles.userOverLayPanelBlock}>
            <OverlayPanel
                elevationProps={{ elevationIndex: 6 }}
                visible={true}
                style={{ minWidth: '20rem' }}
                className={userStyles.userOverleay}
            >
                <OverlayPanelHeader
                    title={selectedUserData.name}
                    buttons={renderButtons()}
                    typographyProps={{ tabIndex: 0 }}
                />
                <HorizontalDivider />
                {/* @ts-ignore */}
                <div style={SCROLLABLE_CONTENT}>
                    <div className={userStyles.marginBottom}>
                        <Typography className={userStyles.userInfoLabel}>Markets</Typography>
                        {Array.isArray(selectedUserData.markets['level 1']) && selectedUserData.markets['level 1'].length > 0 ? (
                            // Display "level 1" data if it exists and has items
                            <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                                {selectedUserData.markets['level 1'].map((item, idx) => (
                                    <Typography key={idx}>{item}</Typography>
                                ))}
                            </div>
                        ) : (
                            // If "level 1" is empty or doesn't exist, display "markets.markets" if it has items
                            Array.isArray(marketNames) && marketNames.length > 0 && (
                                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                                    {marketNames.map((item, idx) => (
                                        <Typography key={idx}>{item}</Typography>
                                    ))}
                                </div>
                            )
                        )}
                    </div>
                    {/* account info */}
                    <div className={userStyles.marginBottom}>
                        <Typography className={userStyles.userInfoLabel}>Account</Typography>
                        {
                            Array.isArray(accountInfo) && accountInfo.length > 0 ? (
                                accountInfo.map((item, index) => (
                                    <div key={index} style={{ marginBottom: '5px', marginTop: '5px' }}>
                                        <Typography>{item}</Typography>
                                    </div>
                                ))
                            ) : (
                                <Typography>-</Typography>
                            )
                        }
                    </div>
                    <div className={userStyles.marginBottom}>
                        <Typography className={userStyles.userInfoLabel}>Permissions</Typography>
                        {
                            Array.isArray(viewInfo) && viewInfo.length > 0 ? (
                                viewInfo.map((item, index) => (
                                    <div key={index} style={{ marginBottom: '5px', marginTop: '5px' }}>
                                        <Typography>{item}</Typography>
                                    </div>
                                ))
                            ) : (
                                <Typography>-</Typography>
                            )
                        }
                    </div>
                    <div className={userStyles.marginBottom}>
                        <Typography className={userStyles.userInfoLabel}>OOT roles</Typography>
                        {Array.isArray(selectedUserOOTRoles) && selectedUserOOTRoles.length > 0 ? (
                            selectedUserOOTRoles.map((roleKey, index) => {
                                const role = ootRoles.find((r) => r.id === roleKey);
                                return (
                                    <div key={index} style={{ marginBottom: '5px', marginTop: '5px' }}>
                                        <Typography>{role ? role.value : roleKey}</Typography>
                                    </div>
                                );
                            })
                        ) : (
                            <Typography>-</Typography>
                        )}
                    </div>
                </div>
                <HorizontalDivider />
            </OverlayPanel>
            </div>
        </>

    )
}


export default UserDetailsOverlay;