import React from 'react';
import ExpansionPanelsContainer, {
    ExpansionPanel,
    ExpansionPanelTitle,
    ExpansionPanelHeader,
    ExpansionPanelBody,
    ExpansionPanelButton
} from '@nokia-csf-uxr/ccfk/ExpansionPanels';

import { useSelector } from 'react-redux';
import { RootState } from 'Store/mainStore';
import userStyles from './Style/RadioNetworkCiq.module.css'




const ExpansionPanelRadioNetworkPlan = () => {
    const [expanded, setExpanded] = React.useState(undefined);
    const radioNetworkCIQ = useSelector((stete: RootState) => stete.radioNetworkCIQ)
    const configstepperSelector = useSelector((state: RootState) => state.config_stepper)

    const headerRef = React.useRef(null);

    const handleExpansion = id => (e) => {
        const newId = expanded === id ? undefined : id;
        if (e.type === 'keydown') {
            if (
                e.target.getAttribute('data-test') === 'header' && // check if keydown from header
                (e.key === ' ' || e.key === 'Enter')
            ) {
                setExpanded(newId);
            }
        }
        if (e.type === 'click') {
            setExpanded(newId);
        }
    };


    const compositeViewDetails = (composite, stepName) => {
        return (
            <div>
                {Object.keys(composite.field_details).map((key) => {
                    return (<>
                        <div className={userStyles.captureViewLabel} style={{ marginBottom: '10px', textDecorationLine: 'underline' }}>{key}</div>
                        <div style={{ padding: '20px' }}>
                            {Object.entries(composite.field_details[key]).map((data, index) => {
                                switch (data[1]['type']) {
                                    case 'select':
                                        return (
                                            <div className={userStyles.pTRBL20}>
                                                <div className={userStyles.captureViewLabel}>{data[1]['label']}</div>
                                                <div className={userStyles.captureViewValue}>
                                                {radioNetworkCIQ.selectedNetworkPlan['plan_info'][stepName][composite.id][key][data[0]]}
                                                </div>
                                            </div>
                                        )
                                    case 'range':
                                        return (
                                            <div className={userStyles.pTRBL20}>
                                                <div className={userStyles.captureViewLabel}>{data[1]['label']}</div>
                                                <div className={userStyles.captureViewValue}>
                                                {radioNetworkCIQ.selectedNetworkPlan['plan_info'][stepName][composite.id][key][data[0]].value}
                                                </div>
                                            </div>
                                        )
                                    case 'number':
                                        return (
                                            <div className={userStyles.pTRBL20}>
                                                <div className={userStyles.captureViewLabel}>{data[1]['label']}</div>
                                                <div className={userStyles.captureViewValue}>
                                                {radioNetworkCIQ.selectedNetworkPlan['plan_info'][stepName][composite.id][key][data[0]].value}
                                                </div>
                                            </div>
                                        )
                                    case 'checkbox':
                                        return (
                                            <div className={userStyles.pTRBL20}>
                                                <div className={userStyles.captureViewLabel}>{data[1]['label']}</div>
                                                <div className={userStyles.captureViewValue}>
                                                {radioNetworkCIQ.selectedNetworkPlan['plan_info'][stepName][composite.id][key][data[0]].map(item => item.value).join(", ")}
                                                </div>
                                            </div>
                                        )
                                    case 'text':
                                        return (
                                            <div className={userStyles.pTRBL20}>
                                                <div className={userStyles.captureViewLabel}>{data[1]['label']}</div>
                                                <div className={userStyles.captureViewValue}>
                                                {radioNetworkCIQ.selectedNetworkPlan['plan_info'][stepName][composite.id][key][data[0]]}
                                                </div>
                                            </div>
                                        )
                                    case 'radio':
                                        return (
                                            <div className={userStyles.pTRBL20}>
                                                <div className={userStyles.captureViewLabel}>{data[1]['label']}</div>
                                                <div className={userStyles.captureViewValue}>
                                                {radioNetworkCIQ.selectedNetworkPlan['plan_info'][stepName][composite.id][key][data[0]]}
                                                </div>
                                            </div>
                                        )
                                    case 'textarea':
                                        return (
                                            <div className={userStyles.pTRBL20}>
                                                <div className={userStyles.captureViewLabel}>{data[1]['label']}</div>
                                                <div className={userStyles.captureViewValue}>
                                                {radioNetworkCIQ.selectedNetworkPlan['plan_info'][stepName][composite.id][key][data[0]]}
                                                </div>
                                            </div>
                                        )
                                }

                            })}
                        </div>
                    </>)
                })}
            </div>
        )
    }

    return (
        <ExpansionPanelsContainer>
            {configstepperSelector.data.stepper_data.map((panel, index) => (
                <ExpansionPanel
                    expanded={expanded === panel.stepname}
                    key={index}
                    id={panel.stepname}
                    hoverEffect
                    focusEffect
                    mountContent={false}
                    style={{marginTop: '20px',border: '1px solid #D9D9D9', background: expanded != panel.stepname ? '#F5F5F5' : ''}}
                >
                    <ExpansionPanelHeader
                        data-test="header"
                        ref={headerRef}
                        role="button"
                        aria-expanded={expanded === panel.stepname}
                        style={{ justifyContent: 'space-between' }}
                        onKeyDown={handleExpansion(panel.stepname)}
                        onClick={handleExpansion(panel.stepname)}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '18.75rem' }}>
                            <ExpansionPanelTitle style={{color: '#1E1E1E', fontWeight: '700'}}>{panel.name}</ExpansionPanelTitle>
                        </div>
                        <ExpansionPanelButton
                            onClick={handleExpansion(panel.stepname)}
                            iconButtonProps={{
                                'aria-hidden': true,
                            }}
                        />
                    </ExpansionPanelHeader>
                    <ExpansionPanelBody>
                        <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 0.4fr)", gap: "2rem", padding: '10px 20px 20px 20px' }}>

                            {Object.entries(configstepperSelector.data.solution_form_data['packages'][panel.stepname].field_details).map((data, index) => {
                                if (radioNetworkCIQ.selectedNetworkPlan['plan_info'][panel.stepname][data[0]] === "") return
                                switch (data[1]['type']) {
                                    case 'text':
                                        return (
                                            <div>
                                                <div className={userStyles.captureViewLabel}>{data[1]['label']}</div>
                                                <div className={userStyles.captureViewValue}>{radioNetworkCIQ.selectedNetworkPlan['plan_info'][panel.stepname][data[0]]}</div>
                                            </div>
                                        )
                                    case 'checkbox':
                                        return (
                                            <div>
                                                <div className={userStyles.captureViewLabel}>{data[1]['label']}</div>
                                                <div className={userStyles.captureViewValue}>
                                                    {radioNetworkCIQ.selectedNetworkPlan['plan_info'][panel.stepname][data[0]].map(item => item.value).join(", ")}
                                                </div>
                                            </div>
                                        )
                                    case 'select':
                                        return (
                                            <div>
                                                <div className={userStyles.captureViewLabel}>{data[1]['label']}</div>
                                                <div className={userStyles.captureViewValue}>
                                                    {data[1]['multiple'] ? radioNetworkCIQ.selectedNetworkPlan['plan_info'][panel.stepname][data[0]].map(item => item.value).join(", ") : radioNetworkCIQ.selectedNetworkPlan['plan_info'][panel.stepname][data[0]]}
                                                </div>
                                            </div>
                                        )
                                    case 'radio':
                                        return (
                                            <div>
                                                <div className={userStyles.captureViewLabel}>{data[1]['label']}</div>
                                                <div className={userStyles.captureViewValue}>
                                                    {radioNetworkCIQ.selectedNetworkPlan['plan_info'][panel.stepname][data[0]]}
                                                </div>
                                            </div>
                                        )
                                    case 'number':
                                        return (
                                            <div>
                                                <div className={userStyles.captureViewLabel}>{data[1]['label']}</div>
                                                <div className={userStyles.captureViewValue}>
                                                    {radioNetworkCIQ.selectedNetworkPlan['plan_info'][panel.stepname][data[0]].value}
                                                </div>
                                            </div>
                                        )
                                    case 'range':
                                        return (
                                            <div>
                                                <div className={userStyles.captureViewLabel}>{data[1]['label']}</div>
                                                <div className={userStyles.captureViewValue}>
                                                {radioNetworkCIQ.selectedNetworkPlan['plan_info'][panel.stepname][data[0]].value}
                                                </div>
                                            </div>
                                        )
                                    case 'textarea':
                                        return (
                                            <div>
                                                <div className={userStyles.captureViewLabel}>{data[1]['label']}</div>
                                                <div className={userStyles.captureViewValue}>
                                                    {radioNetworkCIQ.selectedNetworkPlan['plan_info'][panel.stepname][data[0]]}
                                                </div>
                                            </div>
                                        )
                                    case 'composite':
                                        return (
                                            <>{index % 2 !== 0 && (<div>

                                            </div>)}
                                                <div>
                                                    {compositeViewDetails(data[1], panel.stepname)}
                                                </div>
                                            </>
                                        )

                                }
                            })}

                        </div>
                    </ExpansionPanelBody>
                </ExpansionPanel>
            ))}
        </ExpansionPanelsContainer>
    );
};


export default ExpansionPanelRadioNetworkPlan;