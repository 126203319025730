import React from "react";
import classes from '../../marketplace/Style/Cart.module.css';
import productDefaultImage from '../../../Assets/Icons/product-image-placeholder.svg';
import useImageRender from "../hooks/useImageRender";


const CartImageContainer = (prop) => {
    const [productImageSrc, handleImageError] = useImageRender(
        prop.listItem.product_image,
        prop.listItem.image_url,
        productDefaultImage,
      );
    return (
        <>
            <div className={classes.productImage}>
                <img src={productImageSrc}  onError={handleImageError} style={{ height: '50px', objectFit: "contain", maxWidth: "100%" }}/>
            </div>
        </>
    )
}

export default CartImageContainer;