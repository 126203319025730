import React, { useState, useEffect } from "react";
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import Label from '@nokia-csf-uxr/ccfk/Label';
import { setProp } from "Store/Actions";
import userStyles from "../../Style/UserManagement.module.css";
import { Button, Skeleton, TextInput } from "@nokia-csf-uxr/ccfk";
import SelectBox from "Pages/subscription/Custom/SelectBox";
import { modifyData, distributorOotRoles } from "../utils/constants"
import { updateSelectedPersonas, setUserNSA, setUserInputFields } from "Store/contentState/userManagementSlice";
import Checkbox from '@nokia-csf-uxr/ccfk/Checkbox';
import { setOotRoles } from "Store/contentState/userManagementSlice";
import OOT from "Constants/services/oot";
import Chip, {
    ChipLabel,
} from '@nokia-csf-uxr/ccfk/Chip';



const CommonOrganizationType = (prop) => {
    const isAddingNokiaUser = useSelector((state: RootState) => state.userManagement.isAddingNokiaUser);
    const isEmailEntered = useSelector((state: RootState) => state.userManagement.isEmailEntered);
    const userOrgType = useSelector((state: RootState) => state.userManagement.userData.orgType);
    const organizationTypes = useSelector((state: RootState) => state.userManagement.metaData.persona);
    const modifiedOrganizationTypes = modifyData(organizationTypes, 'id', 'name');
    const filteredExternalTypes = modifiedOrganizationTypes.filter((type) => type.id !== "nokia");
    //const allAccountsForExternal = useSelector((state: RootState) => state.userManagement.allAccountsForExternal);
    const auth = useSelector((state: RootState) => state.authSlice.login.adToken.accessToken)
    const email = useSelector((state: RootState) => state.userManagement.userData.emailAddress);
    const nsa_authorized = useSelector((state: RootState) => state.userManagement.userData.nsa_authorized);
    const dispatch = useDispatch();
    const nokiaCertificationStatus = useSelector((state: RootState) => state.userManagement.nokiaCertificationStatus);
    const [checkedValue, setcheckedValue] = useState(nsa_authorized);
    const [checkdisable, setcheckdisable] = useState(false);
    const mailDomain = useSelector((state: RootState) => state.userManagement.mailDomain);
    const isMailDomainLoading = useSelector((state: RootState) => state.userManagement.isMailDomainLoading);


    const handleOrgTypeSelection = (orgType) => {
        if (userOrgType[0] !== orgType) {
            dispatch(setProp({ slice: 'userManagement', key: 'allAccountsForExternal', value: [] }));
            dispatch(setOotRoles({ type: "roles", value: [] }));
            dispatch(setOotRoles({ type: "approver_role_group", value: [] }));
            dispatch(setUserInputFields({ field: 'supplier_account_id', value: [] }));
            dispatch(setUserInputFields({ field: 'threeStarNumber', value: [] }));
            dispatch(setUserInputFields({ field: 'oneStarNumber', value: [] }));
            dispatch(setUserInputFields({ field: 'business_name', value: [] }));
            dispatch(setUserInputFields({ field: 'roles', value: [] }));
        }
        if (orgType) {
            if (orgType === "direct_end_customer") {
                const ootRolesDirectEndCustomer = distributorOotRoles.filter((item) => item.id === "sales_owner" || item.id === "external_user")
                dispatch(setOotRoles({ type: "roles", value: ootRolesDirectEndCustomer.map((a) => a.id) }));
            }
            dispatch(updateSelectedPersonas([orgType]))
        } else {
            dispatch(updateSelectedPersonas([]))
        }

    }

    useEffect(() => {
        if (isAddingNokiaUser === false && prop.mode === "add" && isEmailEntered === true) {
            fetchEDUCertificationDetailsForExternals(email);
        }
        if (userOrgType && userOrgType.length > 0 && userOrgType[0] == "nokia") {
            fetchNSACheck(email);
            setcheckdisable(true)
        }
        else {
            setcheckdisable(false)
        }

    }, [isAddingNokiaUser, isEmailEntered]);



    // useEffect(() => {
    //     if (isAddingNokiaUser) {
    //         if (userOrgType && userOrgType.length > 0 && userOrgType[0] == "nokia") {
    //             fetchNSACheck(email);
    //             setcheckdisable(true);
    //         } else{
    //             setcheckdisable(false);
    //         }
    //     } else {
    //         fetchEDUCertificationDetailsForExternals(email);
    //     }
    // }, [isAddingNokiaUser]);





    const fetchNSACheck = async (email) => {
        const headers = new Headers();
        const bearer = `Bearer ${auth}`;
        headers.append("Authorization", bearer);
        headers.append("ConsistencyLevel", "eventual")
        const options = {
            method: "GET",
            headers: headers
        };
        let resp = await fetch(`https://graph.microsoft.com/v1.0//groups/2557f067-cbb8-4e30-8cc7-6f6733926866/transitiveMembers?$select=id,displayName,userType,appId,mail,onPremisesSyncEnabled,deviceId&$search="mail:${email}"`, options)
            .then(response => response.json())
            .catch(error => console.log(error));
        if (resp?.value?.length > 0) {
            setcheckedValue(true)
            dispatch(setUserInputFields({ field: 'nsa_authorized', value: true }));
        }
        else {
            setcheckedValue(false)
            dispatch(setUserInputFields({ field: 'nsa_authorized', value: false }));
        }
    };

    const fetchEDUCertificationDetailsForExternals = async (email) => {
        let nokiaCertifiedDetails = "No certification data";
        try {
            const response = await OOT.getCertificationDetails(email);
            if (response.status && Array.isArray(response.data) && response.data.length > 0) {
                const user = response.data[0];
                if (Array.isArray(user.certification_details) && user.certification_details.length > 0) {
                    for (const cert of user.certification_details) {
                        if (cert.Certification_Name__c === "Global Partner Program Nokia DAC Solution - Sales Engineer Specialist | GPP97530K_V1.0" || cert.Certification_Name__c === "Global Partner Program Nokia DAC Solution - Sales Engineer Specialist | GPP97562K_V1.0") {
                            if (cert.Certification_Status__c === "Completed") {
                                nokiaCertifiedDetails = "Active";
                                break; // No need to check further, as "Active" is the highest priority
                            } else if (["In Progress", "Expired"].includes(cert.Certification_Status__c)) {
                                console.log("Certification_Name__c", cert)
                                nokiaCertifiedDetails = "Inactive";
                            }
                        }
                    }
                    dispatch(setProp({ slice: 'userManagement', key: 'nokiaCertificationStatus', value: nokiaCertifiedDetails }));
                    //return nokiaCertifiedDetails;
                }
                else {
                    dispatch(setProp({ slice: 'userManagement', key: 'nokiaCertificationStatus', value: "No certification data" }));
                }
            }

            // console.log("No certification data");
            // dispatch(setProp({ slice: 'userManagement', key: 'nokiaCertificationStatus', value: "No certification data" }));
            //return "No certification data";

        } catch (error) {
            console.error(error);
            dispatch(setProp({ slice: 'userManagement', key: 'nokiaCertificationStatus', value: "No certification data" }));
        }
    };


    const handleDataElementsCheckboxChange = (event) => {
        setcheckedValue(event.target.checked)
        dispatch(setUserNSA(event.target.checked))
    }


    return (
        <>
            {isMailDomainLoading && <div style={{ padding: '20px' }}><Skeleton height={30} width={500} /></div>}
            { (isEmailEntered && mailDomain === "gmail" || !isEmailEntered && mailDomain === "gmail")? <div className={userStyles.gridItemNSA}>
                {
                    ((isEmailEntered && isAddingNokiaUser) || (prop.mode === "edit" && userOrgType[0] === "nokia")) ? (
                        <div className={`${userStyles.marginBottomWidth}`}>
                            <Label className={userStyles.userLabel}>Organization type <span style={{ color: '#C91514' }}>*</span></Label>
                            <TextInput
                                id="organization-type"
                                disabled={true}
                                value={modifiedOrganizationTypes.find((type) => type.id === "nokia").value}
                                variant={"outlined"}
                                placeholder=""
                            />
                        </div>
                    ) : ((isEmailEntered && !isAddingNokiaUser) || (prop.mode === "edit" && userOrgType[0] != "nokia")) && (<div className={`${userStyles.marginBottomWidth}`}>
                        <Label className={userStyles.userLabel}>Organization type <span style={{ color: '#C91514' }}>*</span></Label>
                        <SelectBox
                            placeholder="Select"
                            maxWidth={true}
                            caseInsentiveMatching={true}
                            selectedItem={userOrgType[0]}
                            data={filteredExternalTypes}
                            onUpdateSelection={handleOrgTypeSelection}
                            isLoading={false}
                        />
                    </div>)


                }{email && userOrgType[0] === "nokia" && (<div style={{ paddingLeft: '20px' }}>
                    <Label className={userStyles.userLabel}>NSA</Label>
                    <Checkbox
                        checked={checkedValue}
                        onChange={(event) =>
                            handleDataElementsCheckboxChange(event)
                        }
                        disabled={checkdisable}
                    />
                </div>)}
                {(nokiaCertificationStatus !== "" && !isAddingNokiaUser && isEmailEntered) && (<div style={{ paddingLeft: '20px' }}>
                    <Label className={userStyles.userLabel}>Nokia Certification</Label>
                    <Chip size="small" style={{ backgroundColor: '#E4F0FF', border: '1px solid #8EC2FF', marginRight: '.5rem', borderRadius: '15px' }}>
                        <ChipLabel label={nokiaCertificationStatus} />
                    </Chip>
                </div>)}
                </div> :
            userOrgType[0] && <div className={userStyles.gridItemNSA}>
                {isMailDomainLoading ? <div style={{ padding: '20px' }}><Skeleton height={30} width={500} /></div> : (
                    ((isEmailEntered && isAddingNokiaUser) || (prop.mode === "edit" && userOrgType[0] === "nokia") || (prop.mode === "edit" && mailDomain != "") || (isEmailEntered && mailDomain != "")) ? (
                        <div className={`${userStyles.marginBottomWidth}`}>
                            <Label className={userStyles.userLabel}>Organization type <span style={{ color: '#C91514' }}>*</span></Label>
                            <TextInput
                                id="organization-type"
                                disabled={true}
                                value={userOrgType[0] ? modifiedOrganizationTypes.find((type) => type.id === userOrgType[0]).value : ""}
                                variant={"outlined"}
                                placeholder=""
                            />
                        </div>
                    ) : ((isEmailEntered && !isAddingNokiaUser) || (prop.mode === "edit" && userOrgType[0] != "nokia")) && (<div className={`${userStyles.marginBottomWidth}`}>
                        <Label className={userStyles.userLabel}>Organization type <span style={{ color: '#C91514' }}>*</span></Label>
                        <SelectBox
                            placeholder="Select"
                            maxWidth={true}
                            caseInsentiveMatching={true}
                            selectedItem={userOrgType[0]}
                            data={filteredExternalTypes}
                            onUpdateSelection={handleOrgTypeSelection}
                            isLoading={false}
                        />
                    </div>))


                }{email && userOrgType[0] === "nokia" && (<div style={{ paddingLeft: '20px' }}>
                    <Label className={userStyles.userLabel}>NSA</Label>
                    <Checkbox
                        checked={checkedValue}
                        onChange={(event) =>
                            handleDataElementsCheckboxChange(event)
                        }
                        disabled={checkdisable}
                    />
                </div>)}
                {(nokiaCertificationStatus !== "" && !isAddingNokiaUser && isEmailEntered) && (<div style={{ paddingLeft: '20px' }}>
                    <Label className={userStyles.userLabel}>Nokia Certification</Label>
                    <Chip size="small" style={{ backgroundColor: '#E4F0FF', border: '1px solid #8EC2FF', marginRight: '.5rem', borderRadius: '15px' }}>
                        <ChipLabel label={nokiaCertificationStatus} />
                    </Chip>
                </div>)}


            </div>}
        </>
    )
}

export default CommonOrganizationType;