import React from "react";
import classes from "../../Style/Cart.module.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import _ from "lodash";
import PriceDisplay from "Pages/marketplace/UIElements/PriceDisplay";




const CartSummaryItemsTable = () => {
    const cart = useSelector((state: RootState) => state.marketplace.cart);
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace)
    const cartSummary = marketplaceSelector.cartResponse.cartSummary || {};

    const companyProducts = cart.filter(item => item.is_agent_model === 0);
    const thirdPartyProducts = cart.filter(item => item.is_agent_model === 1);

    const calculateTotalByCategory = (products) => {
        let totalItems = 0;
        products.forEach(product => {
            // Items calculation
            if (product.market_list_price) {
                console.log(product.quantity)
                //totalItems += parseFloat(String(product.market_list_price)) * product.product_quantity;
                totalItems += parseFloat(String(product.market_list_price)) * product.quantity;
            } else {
                totalItems += parseFloat(product.product_total_cost || 0) 
            }
        })
        return totalItems.toFixed(2);
    }

  
    
    const calculateTotalShipmentCostByCategory = (products) => {
        const totalCustomsAndLogistics = _.sumBy(products, (item) => {
            //@ts-ignore
            const logisticsValue = item?.customs_and_logistics
            //@ts-ignore
                ? parseFloat(item.customs_and_logistics.replace("+", "")) || 0
                : 0;
            //@ts-ignore
            return logisticsValue * (item.quantity || 1);
        });
    
        return totalCustomsAndLogistics.toFixed(2);
    };

    const calculateDiscountsByCategory = (products) => {
        let totalMarketListPrice = 0;
        let totalPnpAfterDod = 0;
        products.forEach((product) => {
            const quantity = parseFloat(product.quantity) || 1; // Default quantity to 1 if missing
            totalMarketListPrice += (parseFloat(product.market_list_price || "0") * quantity);
            totalPnpAfterDod += (parseFloat(product.pnp_after_dod || "0") * quantity);
        });

        return (totalPnpAfterDod - totalMarketListPrice).toFixed(2) ;
    };


    const calculateSubTotalByCategory = (products) => {
        const totalItems = parseFloat(calculateTotalByCategory(products)) || 0;
        const totalShipment = parseFloat(calculateTotalShipmentCostByCategory(products)) || 0;
        const totalDiscounts = parseFloat(calculateDiscountsByCategory(products)) || 0;

        return (totalItems + totalShipment + totalDiscounts).toFixed(2);
    };

    const calculateGrandSubTotal = (companyProducts, merchantProducts) => {
        const companySubtotal = companyProducts?.length ? parseFloat(calculateTotalByCategory(companyProducts)) || 0 : 0;
        const merchantSubtotal = merchantProducts?.length ? parseFloat(calculateTotalByCategory(merchantProducts)) || 0 : 0;

        return (companySubtotal + merchantSubtotal).toFixed(2);
    };

    const calculateGrandShippingCost = (companyProducts, merchantProducts) => {
        const companyShippingTotal = companyProducts?.length ? parseFloat(calculateTotalShipmentCostByCategory(companyProducts)) || 0 : 0;
        const merchantShippingTotal = merchantProducts?.length ? parseFloat(calculateTotalShipmentCostByCategory(merchantProducts)) || 0 : 0;

        return (companyShippingTotal + merchantShippingTotal).toFixed(2);
    };

    const calculateGrandDiscounts = (companyProducts, merchantProducts) => {
        const companyDiscounts = companyProducts?.length ? parseFloat(calculateDiscountsByCategory(companyProducts)) || 0 : 0;
        const merchantDiscounts = merchantProducts?.length ? parseFloat(calculateDiscountsByCategory(merchantProducts)) || 0 : 0;

        return (companyDiscounts + merchantDiscounts).toFixed(2);
    }

    const calculateFinalPrice = (companyProducts, merchantProducts) => {
        const grandSubTotal = parseFloat(calculateGrandSubTotal(companyProducts, merchantProducts)) || 0;
        const grandShippingCost = parseFloat(calculateGrandShippingCost(companyProducts, merchantProducts)) || 0;
        const grandDiscounts = parseFloat(calculateGrandDiscounts(companyProducts, merchantProducts)) || 0;

        return (grandSubTotal + grandShippingCost + grandDiscounts).toFixed(2);
    };



    return (
            <>
                {companyProducts.length > 0 && (<div>
                    <table className={classes.cartSummaryTable}>
                        <thead>
                            <tr className={classes.cartSummaryTableRow}>
                                <th className={classes.cartSummaryTableRowHead}>Nokia items</th>
                                <th className={classes.cartSummaryTableRowHead}>Price</th>
                            </tr>
                        </thead>
                        <tbody className={classes.cartSummaryTableBody}>
                            <tr className={classes.cartSummaryTableBodyRow}>
                                <td className={classes.cartSummaryTableBodyColumns}>Items</td>
                                <td className={classes.cartSummaryTableBodyColumns}><PriceDisplay price={calculateTotalByCategory(companyProducts)} /></td>
                            </tr>
                            <tr className={classes.cartSummaryTableBodyRow}>
                                <td className={classes.cartSummaryTableBodyColumns}>Shipment cost</td>
                                <td className={classes.cartSummaryTableBodyColumns}><PriceDisplay price={calculateTotalShipmentCostByCategory(companyProducts)} /></td>
                            </tr>
                            <tr className={classes.cartSummaryTableBodyRow}>
                                <td className={classes.cartSummaryTableBodyColumns}>Discounts</td>
                                <td className={classes.cartSummaryTableBodyColumns}><PriceDisplay price={calculateDiscountsByCategory(companyProducts)} /></td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #E8E8E8' }}></tr>
                            <tr className={classes.cartSummarySubTotal}>
                                <td style={{ color: '#333333', fontSize: '13px', padding: '5px', fontWeight: 'bold' }}>Nokia items subtotal</td>
                                <td style={{ color: '#333333', fontSize: '13px', padding: '5px', fontWeight: 'bold' }}><PriceDisplay price={calculateSubTotalByCategory(companyProducts)} /></td>
                            </tr>
                        </tbody>

                    </table>
                </div>
                )
                }

                {thirdPartyProducts.length > 0 && (<div>
                    <table className={classes.cartSummaryTable}>
                        <thead>
                            <tr className={classes.cartSummaryTableRow}>
                                <th className={classes.cartSummaryTableRowHead}>Merchant items</th>
                                <th className={classes.cartSummaryTableRowHead}>Price</th>
                            </tr>
                        </thead>
                        <tbody className={classes.cartSummaryTableBody}>
                            <tr className={classes.cartSummaryTableBodyRow}>
                                <td className={classes.cartSummaryTableBodyColumns}>Items</td>
                                <td className={classes.cartSummaryTableBodyColumns}><PriceDisplay price={calculateTotalByCategory(thirdPartyProducts)} /></td>
                            </tr>
                            <tr className={classes.cartSummaryTableBodyRow}>
                                <td className={classes.cartSummaryTableBodyColumns}>Shipment cost</td>
                                <td className={classes.cartSummaryTableBodyColumns}><PriceDisplay price={calculateTotalShipmentCostByCategory(thirdPartyProducts)} /></td>
                            </tr>
                            <tr className={classes.cartSummaryTableBodyRow}>
                                <td className={classes.cartSummaryTableBodyColumns}>Discounts</td>
                                <td className={classes.cartSummaryTableBodyColumns}><PriceDisplay price={calculateDiscountsByCategory(thirdPartyProducts)} /></td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #E8E8E8' }}></tr>
                            <tr className={classes.cartSummarySubTotal}>
                                <td style={{ color: '#333333', fontSize: '13px', padding: '5px', fontWeight: 'bold' }}>Merchant items subtotal</td>
                                <td style={{ color: '#333333', fontSize: '13px', padding: '5px', fontWeight: 'bold' }}><PriceDisplay price={calculateSubTotalByCategory(thirdPartyProducts)} /></td>
                            </tr>

                            <tr style={{ borderBottom: '1px solid #E8E8E8' }}></tr>
                        </tbody>

                    </table>
                </div>

                )
                }

                <div className={classes.cartGrandTotalContainer}>
                    <div style={{ color: '#333333', fontSize: '13px', padding: '5px', fontWeight: 'bold' }}>
                        Total for all items
                    </div>
                    <div>
                        <PriceDisplay price={calculateGrandSubTotal(companyProducts, thirdPartyProducts)} /> {marketplaceSelector.accountDetails[0].currency}
                    </div>
                </div>

                <div className={classes.cartGrandShippingContainer}>
                    <div style={{ color: '#333333', fontSize: '13px', padding: '5px', fontWeight: 'bold' }}>
                        Total shipping cost
                    </div>
                    <div>
                        {calculateGrandShippingCost(companyProducts, thirdPartyProducts)} {parseFloat(calculateGrandShippingCost(companyProducts, thirdPartyProducts)) !== 0 && (
                            <span> {marketplaceSelector.accountDetails[0].currency}</span>
                        )}
                    </div>
                </div>

                <div className={classes.cartGrandShippingContainer}>
                    <div style={{ color: '#333333', fontSize: '13px', padding: '5px', fontWeight: 'bold' }}>
                        Total discounts
                    </div>
                    <div>
                        <PriceDisplay price={calculateGrandDiscounts(companyProducts, thirdPartyProducts)} />  {parseFloat(calculateGrandDiscounts(companyProducts, thirdPartyProducts)) !== 0 && (
                            <span> {marketplaceSelector.accountDetails[0].currency}</span>
                        )}
                    </div>
                </div>

                <div className={classes.cartGrandShippingContainer}>
                    <div style={{ color: '#333333', fontSize: '18px', padding: '5px', fontWeight: 'bold' }}>
                        Final price
                    </div>
                    <div style={{ color: '#333333', fontSize: '18px', padding: '5px', fontWeight: 'bold' }}>
                        <PriceDisplay price={calculateFinalPrice(companyProducts, thirdPartyProducts)} /> {marketplaceSelector.accountDetails[0].currency}
                    </div>
                </div>

            </>
    )
}

export default React.memo(CartSummaryItemsTable);