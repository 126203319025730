import { useState, useEffect, useCallback, useRef } from 'react';
import OOT from '../../../Constants/services/oot'
import { useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import exp from 'constants';
import { setNetworkPlans } from 'Store/contentState/radioNetworkCIQSlice';
import _ from 'lodash';

const useRadioNetworkFetchPlan = () => {
    const dispatch = useDispatch();
    const getRadioNetworkPlans = async (currentPage, limitPerpage) => {
        try {

            const response = await OOT.getRadioNetworkPlan(currentPage, limitPerpage);
            const processedData = response.data.map(row => ({
                ...row,
                casename: row.plan_info.general_details.case_name
            }));
            dispatch(setNetworkPlans(processedData));

        } catch (error) {
            console.error('Error fetching data:', error);

        }

    }

    
        /** Prepare Steps */
        const prepSteps = (responsedata) => {
            // temp remove after api mpw implementation
            const steps = Object.keys(responsedata.packages);
            // let dynamicSteps = [{ name: 'Business', stepNumber: 1,isChildActive:false, status: 'current', isComplete: false, selected: true, isValid: true, id: 'business', stepname: 'business' }];
            let dynamicSteps = [];
            let stepno = 2;
    
            steps.forEach(eachStep => {
                const sub_steps = getSubSteps(responsedata.packages[eachStep]['field_details']);
                const is_child_active = sub_steps && sub_steps.length > 0;
                let status = 'future'
                if (responsedata.packages[eachStep].id === "general_details") {
                    status = 'current'
                }
                let stepobj = { name: responsedata.packages[eachStep].label, isChildActive: is_child_active, stepNumber: stepno, status: status, isComplete: false, selected: false, isValid: true, id: 'config_' + eachStep, stepname: eachStep, subSteps: sub_steps, states: responsedata.packages[eachStep]['states'] ? responsedata.packages[eachStep]['states'] : [] };
    
                stepno++;
                dynamicSteps.push(stepobj);
    
            });
            //const lastStep = { name: "Review", stepNumber: dynamicSteps.length + 1, isChildActive: false, status: 'future', isComplete: false, selected: false, isValid: true, id: 'config_review', stepname: 'review' }
            // dynamicSteps.push(lastStep);
            return dynamicSteps;
        }
    
        /** getSubSteps */
        const getSubSteps = (stepFieldDetails) => {
            let sub_steps = [];
    
            if (stepFieldDetails) {
                let childSteps = _.filter(stepFieldDetails, (obj) => obj.type === 'details');;
                let child_stepno = 1;
                _.each(childSteps, (child) => {
                    const childStep = { name: child.label, stepNumber: child_stepno, status: 'future', isComplete: false, selected: false, isValid: true, id: 'config_' + child.id, stepname: child.id, isChildActive: false, states: child.states ? child.states : [] };
                    sub_steps.push(childStep);
                    child_stepno++;
                });
                return sub_steps;
            }
        }

    return { getRadioNetworkPlans, prepSteps }
}

export default useRadioNetworkFetchPlan;