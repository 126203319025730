import React, { memo, useEffect } from "react";
import { App } from "@nokia-csf-uxr/ccfk";
import { Body } from "Components/Body";
import Header from "Components/Header";
import LoadingOverlay from "react-loading-overlay-ts";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import MarketplaceBody from "./MarketplaceBody";
import { Outlet, useLocation } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { useMarketplaceFilter } from "../marketplace/hooks/useMarketplaceFilter";
import { setPartnerList as setMarketplacePartnerList } from "Store/contentState/marketplaceSlice";
import {
  setLoader,
} from "Store/contentState/subscriptionSlice";
import OOT from "Constants/services/oot";


const crumItems = [{ title: 'Marketplace', path: '/marketplace' }]

const Marketplace = () => {
  const dispatch = useDispatch();
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const marketplacePartnerList = useSelector((state: RootState) => state.marketplace.partnerList);
  const { fetchMarketplaceData } = useMarketplaceFilter();
  const location = useLocation();


  let crumbs = ["Marketplace"];
  let items = [{ title: 'Marketplace Store', path: '/marketplace' }];

  if (location.pathname.includes("/marketplace/cart")) {
    crumbs = ["Marketplace / Cart"];
    items = [{ title: 'Marketplace Store', path: '/marketplace' },
    { title: 'Cart', path: '' }]
  } else if (location.pathname.includes("/marketplace/product")) {
    crumbs = ["Marketplace / Product"];
    items = [
      { title: 'Marketplace Store', path: '/marketplace' },
      { title: 'Product', path: '' }
    ];
  }




  useEffect(() => {
    if (marketplacePartnerList.length === 0) {
      fetchMarketplacePartnerList();
    }
  }, [])

  const fetchMarketplacePartnerList = async () => {
    dispatch(
      setLoader({
        key: "isPartnerLoading",
        value: true,
      })
    );
    try {
      const response = await OOT.getSubscriptionPartnerData(true);
      const __partnerList = response.data.partnerList
        ? response.data.partnerList
        : [];
      dispatch(
        setLoader({
          key: "isPartnerLoading",
          value: false,
        })
      );
      dispatch(
        setMarketplacePartnerList({
          partnerList: __partnerList,
        })
      );
      if (__partnerList.length === 1) {
        fetchMarketplaceData("fetch", __partnerList[0].id, __partnerList);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      dispatch(
        setLoader({
          key: "isPartnerLoading",
          value: false,
        })
      );
    }
  }


  return (
    <>
      <LoadingOverlay
        active={authSelector.system.isLoading}
        spinner
        classNamePrefix="MyLoader_"
        text="Please wait "
      >
        <App>
          <Header />
          <Body
            crumbs={crumbs}
            bodyContent={<Outlet />}
            items={items}
          />
        </App>
        {/* <AppFooter /> */}
      </LoadingOverlay>
    </>
  )
}

export default memo(Marketplace);