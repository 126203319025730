import React, {useState} from "react";
import RadioNetworkPlans from "./RadioNetworkPlans";
import CaptureRadioNetworkCIQDialog from "./CaptureRadioNetworkCIQDialog";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import { setProp } from "Store/Actions";
import CaptureUpdateStatusDialog from "./CaptureUpdateStatusDialog";



const RadioNetworkCIQBody  = () => {
const dispatch = useDispatch();
const showCaptureDialog = useSelector((state: RootState) => state.radioNetworkCIQ.captureNetworkPlanModal);
const showCaptureUpdateDialog = useSelector((state: RootState) => state.radioNetworkCIQ.createdUpdateStatusModal);


    return (
        <div>
            {
                showCaptureDialog && <CaptureRadioNetworkCIQDialog onClose={() => dispatch(setProp({ slice: "radioNetworkCIQ", key: "captureNetworkPlanModal", value: false }))}/>
            }
            {
                showCaptureUpdateDialog && <CaptureUpdateStatusDialog onClose={() => dispatch(setProp({ slice: "radioNetworkCIQ", key: "createdUpdateStatusModal", value: false }))}/>
            }
           <RadioNetworkPlans/>
        </div>
    )
}

export default RadioNetworkCIQBody;