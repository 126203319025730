import React from "react";
import classes from '../marketplace/Style/ProductSpecification.module.css';
import sellerLogo from '../../Assets/Icons/nokia_logo.svg';
import { Label, Typography } from "@nokia-csf-uxr/ccfk";
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import Loader from "Components/Elements/Loader";

import { getSupplierImage,getImageHeight } from './Utils/commons';
import DOMPurify from 'dompurify';


const ProductDescription = () => {
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
    const selectedData = marketplaceSelector.selectedDeviceSpecification;
    const imageSource = getSupplierImage(selectedData[0]?.is_agent_model, selectedData[0]?.supplier_text)

    if(!selectedData || selectedData.length === 0){
        <Loader/>
    }

    return (
        <>
           {
               selectedData && (
                <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '.5rem' }} >
                    <div style={{ fontWeight: 'bold', fontSize: '.85rem', padding: '.5rem' }}>
                        <div>{selectedData[0]?.product_description}</div>
                        <Typography>ID: {selectedData[0]?.id}</Typography>
                        </div>
                    <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}><img className={(getImageHeight(selectedData[0]?.supplier_text) === true) ? classes.etraTelecomLogo : classes.productSupplierLogo} src={imageSource} /></div>
                </div>
                <div style={{ borderBottom: '1px solid #D7D7D7' }}></div>

              {selectedData[0]?.product_specifications !== null && 
              <section style={{minHeight:'600px', maxHeight:'700px', overflowY:'auto'}}>
              <div style={{ padding: '1rem', marginTop: '1rem' }}>
                  <Typography style={{ color: '#000' }} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(selectedData[0]?.product_specifications.description)}}></Typography>
              </div>

             {selectedData[0]?.product_specifications.advantages !== '' && <div style={{ padding: '1rem', marginTop: '1rem' }}>
                <Label style={{fontSize:'14px', fontWeight:'700'}}>Advantages</Label>
                <Typography style={{ color: '#000' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedData[0]?.product_specifications.advantages) }}></Typography>
                
              </div>}

              {selectedData[0]?.product_specifications.technical_details !== '' && <div style={{  padding: '1rem', marginTop: '1rem' }}>
                <Label style={{fontSize:'14px', fontWeight:'700'}}>Technical Details</Label>
              <Typography style={{ color: '#000' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedData[0]?.product_specifications.technical_details) }}></Typography>
              </div>}

              {selectedData[0]?.product_specifications.link !== '' && <div style={{ display: 'flex', padding: '1rem', marginTop: '1rem'}}>
                <a href={selectedData[0]?.product_specifications.link} target="_blank" rel="noopener noreferrer">Link to {selectedData[0]?.id}</a>
              </div>}
              </section>
              } 
               
                    {/* <div style={{padding: '1rem'}}>
                        <div style={{height:'100%', width:'100%'}}>
                        <img src={productImageSrc}  onError={handleImageError} style={{height:'200px'}}/>
                         <Typography>{selectedData[0]?.product_description}</Typography>
                        </div>
                    </div> */}
            </div>
               )
           } 


        </>
    )
}

export default ProductDescription;
