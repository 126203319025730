import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProp } from "Store/Actions";
import { RootState, store } from "Store/mainStore";
import { useStatesOfElement } from "./Configurator/hooks/useStatesOfElement";
import SelectFieldsStep from "./Configurator/DynamicField/selectfieldStep";
import { TextInputLabelContent } from "@nokia-csf-uxr/ccfk/TextInput";
import RangeField from "./Configurator/DynamicField/rangefield";
import SpinnerField from "./Configurator/DynamicField/spinnerfield";
import CheckboxField from "./Configurator/DynamicField/checkboxfield";
import TextField from "./Configurator/DynamicField/textfield";
import RadioButtonField from "./Configurator/DynamicField/radiobuttonfield";
import TextAreaField from "./Configurator/DynamicField/textareafield";

const CompositeStepper = (prop) => {
    const dispatch = useDispatch();
   
    const configstepperSelector = useSelector((state: RootState) => state.config_stepper)
    const radioNetworkCIQ = useSelector((stete: RootState) => stete.radioNetworkCIQ)
    const dynamicSelector = useSelector((state: RootState) => state.dynamic);

    const [optionsData, setOptionsData] = useState({});
    const [isDisabled, setisDisabled] = useState(false)
    const allRefs = React.useRef([]);
    const { getElementsStates } = useStatesOfElement(allRefs);

    useEffect(() => {
        addEelement()
    }, [])


    const listStyle = {
        marginBottom: '15px'
    }

    const formatSentence = (text) => {
        let data = text + " cannot be empty"
        return data.substring(0,1).toUpperCase()+data.substring(1).toLowerCase(); 
    };


    const errorMessageText = (text) => {
        return (
            <div className="fztuMa">
                <span role="alert" className="wBUPn" style={{ color: '#C91514', fontSize: '13px' }}>{formatSentence(text)}</span>
            </div>)
    }



    const addEelement = () => {
        let element = {}
        let storeElement = {}
        let storElementActiveData = {}
        let storeElementStep = {}
        Object.keys(prop.compositeField.field_details).forEach(function (key) {
            let selectElement = {}
            let storeselectElement = {}
            if (prop.compositeField.field_details[key]) {
                Object.entries(prop.compositeField.field_details[key]).map((data, _index) => {
                    selectElement[data[0]] = ""
                    if (data[1]['type'] === 'range' || data[1]['type'] === 'number') {
                        selectElement[data[0]] = { key: data[1]['min'], value: data[1]['min'] }
                        if (data[1]['default'] != "") {
                            selectElement[data[0]] = { key: data[1]['default'], value: data[1]['default'] }
                        }
                    }
                    if (data[1]['type'] === 'checkbox') {
                        selectElement[data[0]] = []
                    }
                    if (data[1]['type'] === 'select' || data[1]['type'] === 'radio' || data[1]['type'] === 'checkbox') {
                        storeselectElement[data[0]] = getSelectOptions(data[1]['options'])
                        if (data[1]['default'] != "") {
                            if (!(_.has(data[1], 'multiple'))) {
                                selectElement[data[0]] = { key: data[1]['default'], value: data[1]['default'] }
                            } else {
                                selectElement[data[0]] = getKeyvalueObj(data[1]['default'])
                            }
                        }
                    }
                });
            }

            element[key] = selectElement;
            storeElement[key] = storeselectElement;

        });
        storeElementStep[prop.compositeField.id] = storeElement
        storElementActiveData[prop.active_step] = storeElementStep
        setOptionsData(storElementActiveData);
      
        //    dispatch(setProp({ slice: 'dynamic', key: `element.${prop.active_step}.${[prop.compositeField.id]}`, value: element }))
        
        // console.log("element", element)
        // console.log("storeElement", storeElement)


    }

    const getSelectOptions = (options) => {
        let data_list = []
        if(options){
        Object.keys(options).map(key => {
            data_list.push({
                id: key,
                value: options[key]
            })
        })
        }
        return data_list
    }

    const getKeyvalueObj = (optionList) => {
        const arrayOfObjects = Object.entries(optionList).map(([key, value]) => ({
            key: key,
            value,
        }))
        return arrayOfObjects;
    }


    const updateDispatchStore = (compositeField,stepkey,item, value) => { 
        dispatch(setProp({ slice: "dynamic", key: `element.${prop.active_step}.${[compositeField]}.${[stepkey]}.${[item]}`, value: value }));
        prop.isFormValid()
    }


    return (
        <div>

            {Object.keys(prop.compositeField.field_details).map((key) => {
                return (<>
                <div style={{marginBottom: '10px', textDecorationLine: 'underline'}}>
                    <TextInputLabelContent>
                        {key}
                    </TextInputLabelContent>
                    </div>
                    <div>
                        {Object.entries(prop.compositeField.field_details[key]).map((data, index) => {
                            const dataStore = store.getState().dynamic.data.element?.[prop.active_step]?.[prop.compositeField.id]?.[key] || {};
                            const newRenderConfig = { dataStore };
                            const allStatesForElement = getElementsStates(data[1], newRenderConfig, prop.compositeField.id,key);
                            const visibilityStyle = allStatesForElement['visibility'] || {};

                            // Static toggle adjustment
                            if (data[0] === 'ndac_accessories') {
                                visibilityStyle['display'] = 'none';
                            }

                            // console.log("optionsDataoptionsData", key, optionsData)

                            const allStyles = { ...listStyle, ...visibilityStyle };

                            switch (data[1]['type']) {
                                case 'select':
                                    return (
                                        <>
                                            {optionsData != undefined && dataStore && <div style={allStyles}>
                                                {data[1]['multiple'] ?
                                                    <></> : <SelectFieldsStep optionsData={optionsData} active_step={prop.active_step} data={data} updateDispatchStore={updateDispatchStore} isDisabled={isDisabled} errorMessageText={errorMessageText} stepKey={key} compositeField={prop.compositeField.id} />
                                                }
                                            </div>}
                                        </>
                                    )
                                case 'range':
                                        return (
                                            <div style={allStyles}>
                                                <RangeField data={data} active_step={prop.active_step} updateDispatchStore={updateDispatchStore} isDisabled={isDisabled} errorMessageText={errorMessageText} stepKey={key} compositeField={prop.compositeField.id}/>
                                            </div>
                                ) 
                                case 'number':
                                    return (
                                        <div style={allStyles}>
                                            <SpinnerField data={data} active_step={prop.active_step} updateDispatchStore={updateDispatchStore} isDisabled={isDisabled} errorMessageText={errorMessageText} stepKey={key} compositeField={prop.compositeField.id}
                                            />
                                        </div>
                                    )
                                case 'checkbox':
                                    return (
                                         <div style={allStyles}>
                                            <CheckboxField optionsData={optionsData} active_step={prop.active_step} data={data} updateDispatchStore={updateDispatchStore} isDisabled={isDisabled} errorMessageText={errorMessageText} stepKey={key} compositeField={prop.compositeField.id}/>
                                        </div>
                                    )  
                                case 'text':
                                    return (
                                        <div style={allStyles}>
                                            <TextField data={data} active_step={prop.active_step} updateDispatchStore={updateDispatchStore} isDisabled={isDisabled} errorMessageText={errorMessageText} stepKey={key} compositeField={prop.compositeField.id}/>
                                         </div>
                                    )  
                                case 'radio':
                                    return (
                                        <div style={allStyles}>
                                            <RadioButtonField optionsData={optionsData} active_step={prop.active_step} data={data} updateDispatchStore={updateDispatchStore} isDisabled={isDisabled} errorMessageText={errorMessageText} stepKey={key} compositeField={prop.compositeField.id}
                                                />
                                        </div>
                                    )
                                case 'textarea':
                                    return (
                                        <div style={allStyles}>
                                            <TextAreaField data={data} active_step={prop.active_step} updateDispatchStore={updateDispatchStore} isDisabled={isDisabled} errorMessageText={errorMessageText} stepKey={key} compositeField={prop.compositeField.id}/>
                                        </div>
                                    )            
                            }
                        })}
                    </div>
                </>)
            })}
        </div>
    );
}

export default CompositeStepper;