
import React, { useState, useRef, useEffect } from "react";
import DataGrid from '@nokia-csf-uxr/ccfk/DataGrid/DataGrid';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import Checkbox, {
    CheckboxLabelContent,
} from '@nokia-csf-uxr/ccfk/Checkbox';
import Card from '@nokia-csf-uxr/ccfk/Card';
import { Typography } from "@nokia-csf-uxr/ccfk";
import ExpansionPanelsContainer, {
    ExpansionPanel,
    ExpansionPanelTitle,
    ExpansionPanelHeader,
    ExpansionPanelBody,
    ExpansionPanelButton
} from '@nokia-csf-uxr/ccfk/ExpansionPanels';
import businessHubIcon from "../../../Assets/Icons/roles-business-hub-icon.svg"
import dataFieldsIcon from "../../../Assets/Icons/data-fields-icon.svg"
import RolesActions from "./RolesActions";
import MultiSelect from "Pages/marketplace/common/MultiSelect";
import { setProp } from "Store/Actions";
import { modifyData } from "../user-management/utils/constants";
import SnackBar from "Pages/marketplace/common/SnackBar";
import AddRoleDialog from "./AddRoleDialog";
import { setEditMode } from "Store/contentState/userManagementSlice";
import Loader from "Components/Elements/Loader";
import useFetchRoles from "../hooks/useFetchRoles";
import { setRolesInfo } from "Store/contentState/userManagementSlice";
import MoreButtonVertical from "Pages/marketplace/common/MoreButtonVertical";
import editIcon from "../../../Assets/Icons/pencil_icon.svg";
import AddEditFinerDetails from "./AddEditFinerDetails";
import FinerDetailsView from "./FinerDetailsView";

const DATA = [
    {
        id: '2',
        name: 'Data fields',
    },
];
const PANELData = [
    {
        id: '1',
        name: 'DAC Business Hub',
    },
]


const UserRoles = () => {
    const dispatch = useDispatch();
    const [snackbarMessage, setSnackbarMessage] = useState('');
    // const applicationList = useSelector((state: RootState) => state.userManagement.metaData.applicationList[0]['applications']);
    // const dataElements = useSelector((state: RootState) => state.userManagement.metaData.dataElements[0]['dataElement']);
    // const applicationsNames = useSelector((state: RootState) => state.userManagement.metaData.applicationListNameMapping);
    // const dataElementsNames = useSelector((state: RootState) => state.userManagement.metaData.dataElementNameMapping);
    const userRolesColumns = useSelector((state: RootState) => state.userManagement.userRolesFilterColumns);
    const gridApi = useRef(null);
    const [expanded, setExpanded] = React.useState(undefined);
    const [modifiedRoles, setModifiedRoles] = useState([]);
    const headerRef = React.useRef(null);
    const isEditMode = useSelector((state: RootState) => state.userManagement.isEditMode);
    const showRoleDialog = useSelector((state: RootState) => state.userManagement.showAddRoleDialog);
    const { rolesData, loading } = useSelector((state: RootState) => state.userManagement.roleInfo);
    const [showFinerDetails, setShowFinerDetails] = useState(false);
    const [selectedDataElement, setSelectedDataElement] = useState(null);

    const generalData = useSelector((state: RootState) => state.userManagement.metaData.general);

    // Ensure that generalData exists and has at least one item before accessing properties

    const applicationListNew = generalData?.flatMap(item => item.applications || []) || [];
    const dataElementsNew = generalData?.flatMap(item => item.dataElement || []) || [];
    const personaList = generalData?.flatMap(item => item.persona || []) || [];


    const { fetchUserRoles } = useFetchRoles();


    const createFineDetailsItems = (data) => [
        {
            label: 'Add/Edit finer',
            onClick: () => {
                setSelectedDataElement(data); // Set the selected row data
                setShowFinerDetails(true); // Show the finer details modal
            },
            icon: <img src={editIcon} />,
        }
    ];


    // let filteredDataElements = Object.keys(dataElements).filter(key => dataElements[key].length > 0).map(key => ({
    //     id: key,
    //     name: dataElementsNames && Object.keys(dataElementsNames).length > 0
    //         ? dataElementsNames[key] : key.charAt(0).toUpperCase() + key.slice(1),
    //     actions: {
    //         values: dataElements[key]
    //     }
    // }))

    let filteredDataElements = dataElementsNew.map(item => ({
        id: item.id,
        name: item?.name 
        ? (item.name.includes('_') 
            ? item.name.replace(/_/g, " ") 
            : item.name
          ).charAt(0).toUpperCase() + (item.name.includes('_') 
            ? item.name.replace(/_/g, " ") 
            : item.name
          ).slice(1)
        : "",
        actions: {
            values: item?.actions?.values || []
        }
    }))

    //console.log(filteredDataElements)

    // const filteredApplicationList = applicationList.map((item) => {
    //     return {
    //         id: item,
    //         name: applicationsNames && Object.keys(applicationsNames).length > 0 ? applicationsNames[item] : item.charAt(0).toUpperCase() + item.slice(1)
    //     };
    // });

    const filteredApplicationList = applicationListNew.map((item) => {
        return {
            id: item.id,
            name: item.name
        };
    });

    const organizationTypeList = [
        {
            id: "1",
            name: "Organization type "
        }
    ]

    const modifiedPersonaData = modifyData(personaList, 'id', 'name');


    useEffect(() => {
        dispatch(setEditMode(false))
        fetchUserRoles("");
    }, [])





    useEffect(() => {
        if (modifiedRoles.length > 0) {
            dispatch(setProp({
                slice: 'userManagement', key: 'modifiedRoles', value: modifiedRoles
            }));
        }
    }, [modifiedRoles]);

    const handleExpansion = id => (e) => {
        const newId = expanded === id ? undefined : id;
        if (e.type === 'keydown') {
            if (
                e.target.getAttribute('data-test') === 'header' && // check if keydown from header
                (e.key === ' ' || e.key === 'Enter')
            ) {
                setExpanded(newId);
            }
        }
        if (e.type === 'click') {
            setExpanded(newId);
        }
    };


    const handleCloseSnackBar = () => {
        setSnackbarMessage("");
    };

    const filteredRolesData = userRolesColumns.length === 0
        ? rolesData // Show all if array is empty
        : rolesData.filter(role =>
            userRolesColumns.includes(role.id)
        );

    // Generate columns for DataGrid dynamically based on roles
    const columns = [
        {
            field: "name", headerName: "Pages", minWidth: 200, editable: false, pinned: "left", enableRowGroup: true
        },
        ...filteredRolesData.map((role) => ({
            field: role.id,
            headerName: role.name,
            minWidth: 300,
            cellRenderer: (params) => {
                // Check if data_objects or view is undefined
                const isChecked = role?.data_objects?.view?.includes(params.data.id) || false;
                return (
                    <Checkbox
                        checked={isChecked}
                        onChange={() => handleCheckboxChange(role.id, params.data.id)}
                        disabled={!isEditMode}
                    />
                );
            },
        })),
    ];

    // Columns for Data Elements Grid
    const dataElementsColumns = [
        { field: "name", headerName: "Data Elements", minWidth: 200, editable: false, pinned: "left" },
        ...filteredRolesData.map((role) => ({
            field: role.id,
            headerName: role.name,
            minWidth: 300,
            cellRenderer: (params) => {

                // Determine which actions are allowed for the data element
                const { data } = params;
                const hasCreate = data?.actions?.values?.includes("create") || false;
                const hasRead = data?.actions?.values?.includes("view") || false;
                const hasUpdate = data?.actions?.values?.includes("update") || false;
                const hasDelete = data?.actions?.values?.includes("delete") || false;

                // Check if the role already has these permissions for the data element
                const isCreateChecked = role?.data_objects?.data_elements?.[data.id]?.includes("create") || false;
                const isReadChecked = role?.data_objects?.data_elements?.[data.id]?.includes("view") || false;
                const isUpdateChecked = role?.data_objects?.data_elements?.[data.id]?.includes("update") || false;
                const isDeleteChecked = role?.data_objects?.data_elements?.[data.id]?.includes("delete") || false;

                let dataTobeSent = {
                    dataElementInfo: data,
                    roleInfo: role
                }
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {hasCreate && (
                            <div style={{ display: 'flex', marginRight: 4 }}>
                                <Checkbox
                                    checked={isCreateChecked}
                                    onChange={() =>
                                        handleDataElementsCheckboxChange(role.id, data.id, "create")
                                    }
                                    disabled={!isEditMode}
                                />
                                <CheckboxLabelContent>C</CheckboxLabelContent>
                            </div>
                        )}
                        {hasRead && (
                            <div style={{ display: 'flex', marginRight: 4 }}>
                                <Checkbox
                                    checked={isReadChecked}
                                    onChange={() =>
                                        handleDataElementsCheckboxChange(role.id, data.id, "view")
                                    }
                                    disabled={!isEditMode}
                                />
                                <CheckboxLabelContent>R</CheckboxLabelContent>
                            </div>
                        )}
                        {hasUpdate && (
                            <div style={{ display: 'flex', marginRight: 4 }}>
                                <Checkbox
                                    checked={isUpdateChecked}
                                    onChange={() =>
                                        handleDataElementsCheckboxChange(role.id, data.id, "update")
                                    }
                                    disabled={!isEditMode}
                                />
                                <CheckboxLabelContent>U</CheckboxLabelContent>
                            </div>
                        )}
                        {hasDelete && (
                            <div style={{ display: 'flex' }}>
                                <Checkbox
                                    checked={isDeleteChecked}
                                    onChange={() =>
                                        handleDataElementsCheckboxChange(role.id, data.id, "delete")
                                    }
                                    disabled={!isEditMode}
                                />
                                <CheckboxLabelContent>D</CheckboxLabelContent>
                            </div>
                        )}
                        {
                            role?.data_objects?.finer_details && role?.data_objects?.finer_details[data.id] && (
                                <div style={{ display: 'flex' }}>
                                    <FinerDetailsView finerDetails={role?.data_objects?.finer_details[data.id]} />
                                </div>
                            )
                        }
                        <div style={{ display: 'flex', marginLeft: '4px' }}>
                            <MoreButtonVertical items={createFineDetailsItems(dataTobeSent)} isDisabled={!isEditMode} />
                        </div>
                    </div>
                );
            },
        })),
    ];



    const handleOrganizationTypeSelection = (selected, roleId) => {
        const selectedIds = selected.map(item => item.id);
        const updatedRolesData = rolesData.map(role => {
            if (role.id === roleId) {
                const modifiedRole = {
                    ...role,
                    data_objects: {
                        ...role.data_objects,
                        allowed_org_type: selectedIds,
                    }
                };
                // Track modified roles
                setModifiedRoles(prev => {
                    const existingRoleIndex = prev.findIndex(modifiedRole => modifiedRole.id === roleId);
                    if (existingRoleIndex !== -1) {
                        return prev.map((role, index) =>
                            index === existingRoleIndex ? modifiedRole : role
                        );
                    } else {
                        return [...prev, modifiedRole];
                    }
                });

                return modifiedRole;
            }
            return role;
        });
        //setRolesData(updatedRolesData);
        dispatch(setRolesInfo({ field: 'rolesData', value: updatedRolesData }));
    };

    // Organization data columns

    const getSelectedItemsForRole = (roleId) => {
        const role = rolesData.find(role => role.id === roleId);
        return modifiedPersonaData.filter(persona => role?.data_objects?.allowed_org_type?.includes(persona.id));
    };

    const organizationData = [
        {
            field: "name", headerName: "Permissions", minWidth: 200, editable: false, pinned: "left", cellStyle: {
                backgroundColor: '#F6EFFF',

            }
        },
        ...filteredRolesData.map((role) => ({
            field: role.id,
            headerName: role.name,
            minWidth: 300,
            innerHeight: 200,
            cellStyle: {
                backgroundColor: '#F6EFFF',

            },
            cellRenderer: (params) => {
                //multiselect dropdown for organization type

                const selectedOrgTypes = getSelectedItemsForRole(role.id);
                return (
                    <MultiSelect
                        placeholder={"Select org type"}
                        label={""}
                        data={modifiedPersonaData}
                        selectedItems={selectedOrgTypes}
                        disabled={!isEditMode}
                        onSelected={(selectedItems) => handleOrganizationTypeSelection(selectedItems, role.id)}
                    />
                )
            },
        })),
    ]

    const handleCheckboxChange = (roleId, pageId) => {
        const updatedRoles = rolesData.map((role) => {
            if (role.id === roleId) {
                // Determine if we are adding or removing the pageId from the views
                const updatedViews = role?.data_objects?.view?.includes(pageId)
                    ? role.data_objects.view.filter((viewId) => viewId !== pageId) // Remove if exists
                    : [...(role.data_objects?.view || []), pageId]; // Add if not exists

                // Create a modified role object
                const modifiedRole = {
                    ...role,
                    data_objects: {
                        ...role.data_objects,
                        view: updatedViews,
                    },
                };

                // Track modified roles
                setModifiedRoles(prev => {
                    // Check if the role already exists in modifiedRoles
                    const existingRoleIndex = prev.findIndex(modifiedRole => modifiedRole.id === roleId);

                    // If it exists, update it; otherwise, add it to the array
                    if (existingRoleIndex !== -1) {
                        return prev.map((role, index) =>
                            index === existingRoleIndex ? modifiedRole : role
                        );
                    } else {
                        // Add the new modified role
                        return [...prev, modifiedRole];
                    }
                });
                return modifiedRole; // Return the updated role
            }

            return role; // Return unchanged roles
        });

        // setRolesData(updatedRoles); // Update roles data with modified values
        dispatch(setRolesInfo({ field: 'rolesData', value: updatedRoles })); // Update the store with the modified roles
    };

    const handleDataElementsCheckboxChange = (roleId, dataFieldId, action) => {
        const updatedRoles = rolesData.map((role) => {
            if (role.id === roleId) {
                const existingDataElements = role?.data_objects?.data_elements || {};
                const existingActions = existingDataElements[dataFieldId] || [];

                let updatedValues;
                if (existingActions.includes(action)) {
                    updatedValues = existingActions.filter((existingAction) => existingAction !== action);
                } else {
                    updatedValues = [...existingActions, action];
                }

                const updatedDataElements = {
                    ...existingDataElements,
                    [dataFieldId]: updatedValues,
                };

                const modifiedRole = {
                    ...role,
                    data_objects: {
                        ...role.data_objects,
                        data_elements: updatedDataElements,
                    },
                };

                // Track modified roles
                setModifiedRoles(prev => {
                    const existingRoleIndex = prev.findIndex(modifiedRole => modifiedRole.id === roleId);
                    if (existingRoleIndex !== -1) {
                        return prev.map((role, index) =>
                            index === existingRoleIndex ? modifiedRole : role
                        );
                    } else {
                        return [...prev, modifiedRole];
                    }
                });

                return modifiedRole;
            }
            return role;
        });
        // setRolesData(updatedRoles);
        dispatch(setRolesInfo({ field: 'rolesData', value: updatedRoles }));
    };

    const showSnackbar = (message) => {
        setSnackbarMessage(message);
    };

    const handleSaveFinerDetails = (finerDetails, roleId) => {
        const updatedRoles = rolesData.map((role) => {
            if (role.id === roleId) {
                // Update finer_details for the specific role
                const updatedFinerDetails = {
                    ...role.data_objects.finer_details,
                    ...finerDetails,
                };

                // Create a modified role object with updated finer_details
                const modifiedRole = {
                    ...role,
                    data_objects: {
                        ...role.data_objects,
                        finer_details: updatedFinerDetails,
                    },
                };

                // Track modified roles
                setModifiedRoles((prev) => {
                    const existingRoleIndex = prev.findIndex(
                        (modifiedRole) => modifiedRole.id === roleId
                    );

                    if (existingRoleIndex !== -1) {
                        // Update the existing modified role
                        return prev.map((role, index) =>
                            index === existingRoleIndex ? modifiedRole : role
                        );
                    } else {
                        // Add the new modified role
                        return [...prev, modifiedRole];
                    }
                });
                return modifiedRole;
            }

            return role; // Return unchanged roles
        });


        // // Dispatch the updated roles to the store
        dispatch(setRolesInfo({ field: 'rolesData', value: updatedRoles }));
    };

    return (
        <>
            {
                showRoleDialog && (
                    <AddRoleDialog onClose={() => dispatch(setProp({ slice: "userManagement", key: "showAddRoleDialog", value: false }))} showSnackbar={showSnackbar} />
                )
            }
            {showFinerDetails && <AddEditFinerDetails onClose={() => setShowFinerDetails(false)} data={selectedDataElement} onSave={handleSaveFinerDetails} />}
            <Card style={{ padding: '1rem', outline: 'none' }}>
                <RolesActions showSnackbar={showSnackbar} />
                <Typography style={{ color: '#333333', fontSize: '18px', borderBottom: "1px solid #0000001F", fontWeight: 'bold' }}>User Roles</Typography>

                {(rolesData.length === 0 || loading) ? (<Loader />) : (<React.Fragment>
                    <div style={{ width: '100%', height: '100%', marginTop: '1rem' }}>
                        <DataGrid
                            rowData={organizationTypeList}
                            //@ts-ignore
                            columnDefs={organizationData}
                            suppressMovableColumns={true}
                            //variant="compact"
                            rowHeight={120}
                            suppressColumnMoveAnimation={false}
                            suppressRowClickSelection={false}
                            isRowDrag={false}
                            wrapperProps={{
                                style: {
                                    width: "100%",
                                },
                            }}
                            disableAutoloadStyles
                            suppressContextMenu
                            onGridReady={(params) => {
                                gridApi.current = params.api;
                            }}
                            key={"roles-table"}
                            defaultColDef={{
                                editable: false,
                                sortable: false,
                                resizable: false,
                                // filter: true,
                                flex: 0,
                                minWidth: 100,

                            }}
                            overlayNoRowsTemplate="No organization types available"
                            domLayout="autoHeight"
                            isInlineRowActions={false}
                        // onFirstDataRendered={(params) => {
                        //     params.columnApi.autoSizeAllColumns();
                        // }}
                        />
                    </div>
                    <div style={{ width: '100%', height: '100%', marginTop: '1rem' }}>


                        <ExpansionPanelsContainer>
                            {PANELData.map(panel => (
                                <ExpansionPanel
                                    expanded={expanded === panel.id}
                                    key={panel.id}
                                    id={panel.id}
                                    hoverEffect
                                    focusEffect
                                    mountContent={false}
                                >
                                    <ExpansionPanelHeader
                                        data-test="header"
                                        ref={headerRef}
                                        role="button"
                                        aria-expanded={expanded === panel.id}
                                        style={{ justifyContent: 'space-between' }}
                                        onKeyDown={handleExpansion(panel.id)}
                                        onClick={handleExpansion(panel.id)}
                                    >
                                        <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center', width: '18.75rem' }}>
                                            <img src={businessHubIcon} />
                                            <ExpansionPanelTitle>{panel.name}</ExpansionPanelTitle>
                                        </div>
                                        <ExpansionPanelButton
                                            onClick={handleExpansion(panel.id)}
                                            iconButtonProps={{
                                                'aria-hidden': true,
                                            }}
                                        />
                                    </ExpansionPanelHeader>

                                    <ExpansionPanelBody>
                                        <DataGrid
                                            rowData={filteredApplicationList}
                                            //@ts-ignore
                                            columnDefs={columns}
                                            suppressMovableColumns={true}
                                            //variant="compact"
                                            suppressColumnMoveAnimation={false}
                                            suppressRowClickSelection={false}
                                            isRowDrag={false}
                                            wrapperProps={{
                                                style: {
                                                    width: "100%",
                                                },
                                            }}
                                            disableAutoloadStyles
                                            suppressContextMenu
                                            onGridReady={(params) => {
                                                gridApi.current = params.api;
                                            }}
                                            key={"roles-table"}
                                            defaultColDef={{
                                                editable: false,
                                                sortable: false,
                                                resizable: false,
                                                // filter: true,
                                                flex: 0,
                                                minWidth: 100,

                                            }}
                                            overlayNoRowsTemplate="No applications available"
                                            domLayout="autoHeight"
                                            isInlineRowActions={false}
                                            onFirstDataRendered={(params) => {
                                                params.columnApi.autoSizeAllColumns();
                                            }}
                                        />

                                    </ExpansionPanelBody>
                                </ExpansionPanel>
                            ))}
                        </ExpansionPanelsContainer>

                    </div>

                    <div style={{ marginTop: '1rem' }}>
                        <ExpansionPanelsContainer>
                            {DATA.map(panel => (
                                <ExpansionPanel
                                    expanded={expanded === panel.id}
                                    key={panel.id}
                                    id={panel.id}
                                    hoverEffect
                                    focusEffect
                                    mountContent={false}
                                >
                                    <ExpansionPanelHeader
                                        data-test="header"
                                        ref={headerRef}
                                        role="button"
                                        aria-expanded={expanded === panel.id}
                                        style={{ justifyContent: 'space-between' }}
                                        onKeyDown={handleExpansion(panel.id)}
                                        onClick={handleExpansion(panel.id)}
                                    >
                                        <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center', width: '18.75rem' }}>
                                            <img src={dataFieldsIcon} />
                                            <ExpansionPanelTitle>{panel.name}</ExpansionPanelTitle>
                                        </div>
                                        <ExpansionPanelButton
                                            onClick={handleExpansion(panel.id)}
                                            iconButtonProps={{
                                                'aria-hidden': true,
                                            }}
                                        />
                                    </ExpansionPanelHeader>

                                    <ExpansionPanelBody>
                                        <DataGrid
                                            rowData={filteredDataElements}
                                            //@ts-ignore
                                            columnDefs={dataElementsColumns}
                                            suppressMovableColumns={true}
                                            wrapperProps={{ style: { width: "100%" } }}
                                            overlayNoRowsTemplate="No data elements available"
                                            domLayout="autoHeight"
                                            isInlineRowActions={false}
                                            onFirstDataRendered={(params) => {
                                                params.columnApi.autoSizeAllColumns();
                                            }}
                                            disableAutoloadStyles
                                            suppressContextMenu
                                            onGridReady={(params) => {
                                                gridApi.current = params.api;
                                            }}
                                            key={"elements-table"}
                                            defaultColDef={{
                                                editable: true,
                                                sortable: true,
                                                resizable: true,
                                                filter: true,
                                                flex: 0,
                                                minWidth: 100,
                                            }}
                                            isRowDrag={false}
                                        />

                                    </ExpansionPanelBody>
                                </ExpansionPanel>
                            ))}
                        </ExpansionPanelsContainer>
                    </div>
                </React.Fragment>)}

            </Card>

            <SnackBar message={snackbarMessage} onClose={handleCloseSnackBar} />
        </>
    );
};

export default UserRoles;
