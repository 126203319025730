import React from 'react'
import Card from '@nokia-csf-uxr/ccfk/Card';
import Button from '@nokia-csf-uxr/ccfk/Button';
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import marketplaceOrderIcon from '../../Assets/Icons/marketplace_order_icon.svg';
import classes from '../marketplace/Style/ProductCard.module.css';
import { useNavigate } from 'react-router-dom';
import { Hyperlink, Typography } from "@nokia-csf-uxr/ccfk";
import shipIcon from '../../Assets/Icons/shipping_icon.png'
import Chip, {
  ChipLabel,
} from '@nokia-csf-uxr/ccfk/Chip';
import productDefaultImage from '../../Assets/Icons/product-image-placeholder.svg';
import useImageRender from './hooks/useImageRender';
import { getSupplierImage, getFulFillmentType,getImageHeight } from './Utils/commons';
import { useDispatch } from 'react-redux';
import { setSelectedDeviceSpecification } from 'Store/contentState/marketplaceSlice';
import { formatNumberWithCommas } from './Utils/commons';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import n1TestedIcon from '../../Assets/Icons/nokia_tested_n1.svg';
import indexStyles from '../marketplace/Style/Index.module.css'




type IProductCardProp = {
  card: any;
  onAddToCart: (product: any) => void;
  isDeliverable: boolean;
};


const ProductCard = (prop) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formattedPrice = formatNumberWithCommas(prop.card.price_amount)
  // const productId = prop.card.product_id ? prop.card.product_id.replace('_', ' ') : 'Product';
  const imageSource = getSupplierImage(prop.card.is_agent_model, prop.card.supplier_text)
  const [productImageSrc, handleImageError] = useImageRender(
    prop.card.product_image,
    prop.card.image_url,
    productDefaultImage,

  );

  const handleAddToCart = () => {
    prop.onAddToCart(prop.card);
  };


  const handleCardClick = (event) => {
    // Prevent default behavior to avoid navigation on card click
    event.preventDefault();
    const isButtonClicked = event.target.outerText === 'Add to cart';
    const isContactUsClicked = event.target.outerText === 'Contact us';
    if (!isButtonClicked && !isContactUsClicked) {
      dispatch(setSelectedDeviceSpecification({ selectedProduct: [prop.card] }))
      navigate(`product/${prop.card.id}`);
    }
  };




  return (
    <Card className={classes.productCardContainer} onClick={(event) => handleCardClick(event)}>
      <div className={classes.productCard}>
        <div className={classes.productCardImageContainer}>
          <div className={classes.productCardImageSectionHeader}>
            <div style={{ minHeight: "auto" }}>
              <img src={imageSource} alt="Icon" className={(getImageHeight(prop.card.supplier_text) === true) ? classes.etraTelecomLogo : classes.productSupplierLogo} />
            </div>
          </div>
          <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <img
              src={productImageSrc}
              onError={() => handleImageError()}
              style={{ height: '150px', objectFit: "contain", maxWidth: "100%" }} />
          </div>
        </div>
        <div style={{ padding: '1rem' }}>
          <div className={`${classes.marketplaceProductCardName} ${classes.marginBottom}`}>
            <div style={{ minHeight: "auto" }}>
              {/* <img src={imageSource} alt="Icon" className={(getImageHeight() === true) ? classes.etraTelecomLogo : classes.productSupplierLogo} /> */}
              <Typography style={{ fontSize: '14px', color: '#777777', fontWeight: 700 }}>{prop.card.id}</Typography>
            </div>
            <Chip size="small" style={{ backgroundColor: '#fff', border: '1px solid #000', borderRadius: '15px' }}><ChipLabel label={prop.card.hasOwnProperty('price_amount') ? `${formattedPrice} ${prop.card.priceamount_currencycode}` : ""} /></Chip>
          </div>
          <div style={{ marginTop: '10px' }}>
            <Typography style={{ fontSize: '14px', color: '#212121', fontWeight: 700, marginTop: '10px', marginBottom: '10px' }}>{prop.card.product_description}</Typography>
          </div>

          {getFulFillmentType(prop.card) && (<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '15px' }}>
            <img src={shipIcon} alt='ship_icon' />
            <Typography style={{ fontSize: '12px', color: '#757575', fontWeight: 'bold', marginLeft: '2px' }}>
              Ships from: <span style={{ fontWeight: 'normal' }}>{getFulFillmentType(prop.card)}</span>
            </Typography>

          </div>)}

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', marginTop: ' 50px' }}>
            {/* this div will be for the buttons */}
            <div style={{display:'flex', gap:'.5rem', position: 'absolute', left: '17px', bottom: '15px'}}>
              <img src={n1TestedIcon}/>
             {prop.card.nokia_tested === '2' && <img src={n1TestedIcon}/>}
            
            </div>
            <div style={{ position: 'absolute', right: '17px', bottom: '5px' }} className={classes.marginBottom}>
              <div className={classes.mlAuto}>
                {prop.card.isDeliverable ? (
                  <Button
                    variant="secondary-small"
                    onClick={handleAddToCart}
                  >
                    <ButtonIcon><img src={marketplaceOrderIcon} alt="order-icon" /></ButtonIcon>
                    <ButtonText>Add to cart</ButtonText>
                  </Button>
                ) : (<Tooltip
                  closeOnReferenceHidden={false}
                  placement="bottom"
                  // fallbackPlacements={['right', 'left']}
                  modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}
                  trigger="hover"
                  tooltip={"This product is not available in this region, please contact us for further details"}>
                  <Button
                    onClick={() => {
                      window.open("https://www.dac.nokia.com/contact/ ", "_blank"); // Replace with the target URL
                    }}
                  >
                    {/* <ButtonIcon><img src={marketplaceOrderIcon} alt="order-icon" /></ButtonIcon> */}
                    <ButtonText>Contact us</ButtonText>
                  </Button></Tooltip>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>


  )

}

export default ProductCard;