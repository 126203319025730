import React from 'react';
import {
    Document,
    Page,
    View,
    Text,
    Image,
    StyleSheet,
} from '@react-pdf/renderer';
//import pdfLogo from '../../../Assets/Icons/pdf-logo.svg';
import pdfLogo from '../../Assets/Icons/logo.png';
import PropTypes from "prop-types";




const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        justifyContent: 'space-between', // Ensures spacing between main content and footer
        minHeight: '100%',
    },
    container: {
        flexGrow: 1, // Expands dynamically, pushing footer down
        marginBottom: 40, // ADD SPACE before footer
        padding: 20
    },
    section: {
        marginBottom: 10, // Space between sections
    },
    footer: {
        borderTop: '1pt solid #C7CDD6',
        fontSize: 10,
        textAlign: 'center',
    },
    footerContent: {
        flexDirection: 'row',
        justifyContent: 'space-between', // Distribute content horizontally
        fontSize: 12,
        color: 'grey',
        padding: 20
    },
    header: {
        backgroundColor: '#005AFF',
        flexDirection: 'row',
        justifyContent: 'space-between',
        color: '#FFF',
        padding: 10,
    },
    logo: {
        width: 100, // Adjust width as needed
        height: 20, // Adjust height as needed
    },
    borderBottom: {
        // marginTop: 8,
        marginBottom: 8,
        borderBottom: '1px solid #C7CDD6'
    },

    headerText: {
        textAlign: 'justify',
        fontWeight: 'bold',
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
    },
    table: {
        flexDirection: 'column',
        width: '100%',
        // border:'1px solid #C7CDD6',
        padding: 5
    },
    tableHeader: {
        backgroundColor: '#E4F0FF',
        flexDirection: 'row',
        color: '#000',
        padding: 15,
        fontSize: 10,
        fontWeight: 'bold',
        width: '100%'
    },
    tableRow: {
        flexDirection: 'row',
        marginTop: 5,
    },
    tableCellLeft: {
        flex: 1,
        textAlign: 'left',
        fontSize: 10,
        fontWeight: 400,
        padding: 5,

    },

    tableCellRight: {
        flex: 1,
        textAlign: 'right',
        fontSize: 10,
        fontWeight: 400,
        padding: 5

    },
    tableCellHeadingLeft: {
        flex: 1,
        textAlign: 'left',
        fontSize: 13,
        fontWeight: 'bold'
    },
    tableCellHeadingRight: {
        flex: 1,
        textAlign: 'right',
        fontSize: 13,
        fontWeight: 'bold'
    },

    content: {
        padding: 10,
        backgroundColor: '#E4F0FF',
        fontSize: 14
    },
    btRow: {
        flexDirection: "row",
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 5,
        marginBottom: 5
    },
    colSm2: {
        flexDirection: "column",
        width: '20%',
        fontWeight: 'bold',
        fontSize: 13
    },
    colSm10: {
        flexDirection: "column",
        width: '80%',
        fontWeight: 400,
        color: '#333333',
        fontSize: 12
    },
    costQuantity: {
        flexDirection: "row",
        justifyContent: 'space-between',
        marginTop: 15,
        marginBottom: 15
    },
    costQuantityText: {
        color: '#757575',
        fontSize: 14,
        fontWeight: 'bold'
    },
    termsConditionsText: {
        fontSize: 12,
        fontWeight: 400,
        color: '#333333'
    },
    termsConditionsLabel: {
        fontSize: 12,
        fontWeight: 700,
        color: '#333333'
    },
    termsConditionsSection: {
        marginTop: 10,
        marginBottom: 10
    },


    containerView: {
        padding: 0,
    },
    sectionTitle: {
        fontSize: 15,
        fontWeight: 'bold',
        marginTop: 10,
        borderBottom: '1px solid #ddd',
        paddingBottom: 5,
        color: '#000000'
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: 4,
    },
    label: {
        fontWeight: 'bold',
        fontSize: 15,
        color: '#757575'
    },
    value: {
        fontWeight: 'bold',
        fontSize: 12,
        color: '#757575',
        marginTop: 2,
        marginBottom: 10
    },
    highlight: {
        backgroundColor: '#e8f0ff',
        padding: 10,
        borderRadius: 5,
        marginBottom: 10,
    },
    brow: {
        marginTop: 10
    },
    mainView: {
        paddingTop: 0,

    },
    compositeView: {
        paddingLeft: 20
    },
    compositekey: {
        textDecoration: 'underline',
        fontWeight: 'bold',
        fontSize: 15,
        color: '#757575'
    },
    containerViewTerms: {
        paddingLeft: 0,
        paddingBottom: 10
    },
    divider: {
        height: 1,
        backgroundColor: '#C7CDD6',
        marginBottom: 10,
    },
    paragraph: {
        fontSize: 14,
        color: '#333',
        fontWeight: "bold",
        marginBottom: 10,
    },
    listContainer: {
        marginTop: 5,
        marginLeft: 5
    },
    bullet: {
        fontSize: 14,
        fontWeight: "bold",
        marginBottom: 5,
    },
});


const CaptureRadioNetworkPlanInvoice = ({ configstepperSelector, radioNetworkCIQ, formDate }) => {

  
    const compositeViewDetails = (composite, stepName) => {
        return (
            <View >
                {Object.keys(composite.field_details).map((key, index) => {
                    return (
                        <View key={index} style={styles.compositeView}>
                            <Text style={styles.compositekey}>{key}</Text>
                            {Object.entries(composite.field_details[key]).map((data) => {
                                switch (data[1]['type']) {
                                    case 'select':
                                        return (
                                            <View style={styles.brow}>
                                                <Text style={styles.label}>{data[1]['label']}</Text>
                                                <Text style={styles.value}> {radioNetworkCIQ['plan_info'][stepName][composite.id][key][data[0]]}</Text>
                                            </View>
                                        )
                                    case 'range':
                                        return (
                                            <View style={styles.brow}>
                                                <Text style={styles.label}>{data[1]['label']}</Text>
                                                <Text style={styles.value}> {radioNetworkCIQ['plan_info'][stepName][composite.id][key][data[0]].value}</Text>
                                            </View>
                                        )
                                    case 'number':
                                        return (
                                            <View style={styles.brow}>
                                                <Text style={styles.label}>{data[1]['label']}</Text>
                                                <Text style={styles.value}> {radioNetworkCIQ['plan_info'][stepName][composite.id][key][data[0]].value}</Text>
                                            </View>
                                        )
                                    case 'checkbox':
                                        return (
                                            <View style={styles.brow}>
                                                <Text style={styles.label}>{data[1]['label']}</Text>
                                                <Text style={styles.value}>{radioNetworkCIQ['plan_info'][stepName][composite.id][key][data[0]].map(item => item.value).join(", ")}</Text>
                                            </View>
                                        )
                                    case 'text':
                                        return (
                                            <View style={styles.brow}>
                                                <Text style={styles.label}>{data[1]['label']}</Text>
                                                <Text style={styles.value}> {radioNetworkCIQ['plan_info'][stepName][composite.id][key][data[0]]}</Text>
                                            </View>
                                        )
                                    case 'radio':
                                        return (
                                            <View style={styles.brow}>
                                                <Text style={styles.label}>{data[1]['label']}</Text>
                                                <Text style={styles.value}>{radioNetworkCIQ['plan_info'][stepName][composite.id][key][data[0]]}</Text>
                                            </View>
                                        )
                                    case 'textarea':
                                        return (
                                            <View style={styles.brow}>
                                                <Text style={styles.label}>{data[1]['label']}</Text>
                                                <Text style={styles.value}> {radioNetworkCIQ['plan_info'][stepName][composite.id][key][data[0]]}</Text>
                                            </View>
                                        )
                                }
                            })}
                        </View>
                    )
                })}
            </View>
        )

    }



    return (
        <Document>

            {configstepperSelector.data.stepper_data.map(panel => (
                <Page size="A4" style={styles.page} key={panel.stepname}>
                    <View style={styles.header} fixed>
                        <Image
                            style={styles.logo}
                            src={pdfLogo}
                        />
                        <Text>DAC - NetWork Plan</Text>
                    </View>
                    {/* Main Content */}
                    <View style={styles.container}>


                        <View style={styles.mainView}>
                        {panel.stepname === "general_details" && <View style={styles.content}>
                                <View style={styles.btRow}>
                                    <Text style={styles.colSm2}>Case name</Text>
                                    <Text style={styles.colSm10}>{radioNetworkCIQ['plan_info']?.general_details?.case_name}</Text>
                                </View>
                                <View style={styles.btRow}>
                                    <Text style={styles.colSm2}>sCRM ID</Text>
                                    <Text style={styles.colSm10}>{radioNetworkCIQ['opportunity_id']}</Text>
                                </View>
                            </View>}
                            <View style={styles.containerView} key={panel.stepname}>
                                <Text style={styles.sectionTitle}>{panel.name}</Text>
                                {Object.entries(configstepperSelector.data.solution_form_data['packages'][panel.stepname].field_details).map((data, index) => {
                                    if (radioNetworkCIQ['plan_info'][panel.stepname][data[0]] === "") return
                                    switch (data[1]['type']) {
                                        case 'text':
                                            return (
                                                <View style={styles.brow}>
                                                    <Text style={styles.label}>{data[1]['label']}</Text>
                                                    <Text style={styles.value}>{radioNetworkCIQ['plan_info'][panel.stepname][data[0]]}</Text>
                                                </View>
                                            )
                                        case 'checkbox':
                                            return (
                                                <View style={styles.brow}>
                                                    <Text style={styles.label}>{data[1]['label']}</Text>
                                                    <Text style={styles.value}>{radioNetworkCIQ['plan_info'][panel.stepname][data[0]].map(item => item.value).join(", ")}</Text>
                                                </View>
                                            )
                                        case 'select':
                                            return (
                                                <View style={styles.brow}>
                                                    <Text style={styles.label}>{data[1]['label']}</Text>
                                                    <Text style={styles.value}> {data[1]['multiple'] ? radioNetworkCIQ['plan_info'][panel.stepname][data[0]].map(item => item.value).join(", ") : radioNetworkCIQ['plan_info'][panel.stepname][data[0]]}</Text>
                                                </View>
                                            )
                                        case 'radio':
                                            return (
                                                <View style={styles.brow}>
                                                    <Text style={styles.label}>{data[1]['label']}</Text>
                                                    <Text style={styles.value}>{radioNetworkCIQ['plan_info'][panel.stepname][data[0]]}</Text>
                                                </View>
                                            )
                                        case 'number':
                                            return (
                                                <View style={styles.brow}>
                                                    <Text style={styles.label}>{data[1]['label']}</Text>
                                                    <Text style={styles.value}>{radioNetworkCIQ['plan_info'][panel.stepname][data[0]].value}</Text>
                                                </View>
                                            )
                                        case 'range':
                                            return (
                                                <View style={styles.brow}>
                                                    <Text style={styles.label}>{data[1]['label']}</Text>
                                                    <Text style={styles.value}>{radioNetworkCIQ['plan_info'][panel.stepname][data[0]].value}</Text>
                                                </View>
                                            )
                                        case 'textarea':
                                            return (
                                                <View style={styles.brow}>
                                                    <Text style={styles.label}>{data[1]['label']}</Text>
                                                    <Text style={styles.value}>{radioNetworkCIQ['plan_info'][panel.stepname][data[0]]}</Text>
                                                </View>
                                            )
                                        case 'composite':
                                            return (
                                                <>
                                                    <View>
                                                        {compositeViewDetails(data[1], panel.stepname)}
                                                    </View>
                                                </>
                                            )
                                    }
                                })}
                                {panel.stepname === "capacity_other" && <View>
                                <Text style={styles.containerViewTerms}>Terms and Conditions</Text>
                                 <View style={styles.divider} />
                                <Text style={styles.paragraph}>
                                    This document contains preliminary network planning parameters for the specified site location.
                                    The information provided herein is for informational and planning purposes only and should not be considered as final network deployment specifications.
                                </Text>

                                <View style={styles.listContainer}>
                                    <Text style={styles.bullet}>{'\u2022'} The details in this document are subject to change based on further site assessments, regulatory compliance, and technical feasibility.</Text>
                                    <Text style={styles.bullet}>{'\u2022'} The recipient of this document is responsible for validating the information before making any network deployment decisions.</Text>
                                    <Text style={styles.bullet}>{'\u2022'} The company is not liable for any discrepancies, omissions, or changes arising after the initial assessment.</Text>
                                    <Text style={styles.bullet}>{'\u2022'} Unauthorized distribution, reproduction, or modification of this document is strictly prohibited.</Text>
                                </View>
                                </View>}
                            </View>


                        </View>
                       
                    </View>

                    {/* Footer */}
                    <View style={styles.footer} fixed>
                        <View style={styles.footerContent}>
                            <Text render={({ pageNumber, totalPages }) => `${pageNumber} | ${totalPages}`} />
                            <Text style={{ flexDirection: 'row', justifyContent: 'flex-end' }}><Text style={{ color: "#005AFF" }}>Marketplace</Text> | Ω   {formDate}  Nokia</Text>
                        </View>
                    </View>
                </Page>
            ))}




        </Document>

    )
}

export default CaptureRadioNetworkPlanInvoice;

CaptureRadioNetworkPlanInvoice.propTypes = {
    radioNetworkCIQ: PropTypes.object,
    configstepperSelector: PropTypes.object,
    formDate: PropTypes.string
}
