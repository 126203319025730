import React from "react";
import Dialog, {
    DialogContent,
    DialogFooter,
    DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";
import CloseCloseIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';
import noProductsImage from "../../../Assets/Icons/product_not_found.svg";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "Store/mainStore";
import { Button, Typography } from "@nokia-csf-uxr/ccfk";




const DIALOG_HEIGHT = "60%";
const DIALOG_WIDTH = "40%";

const CONFIRM_ALERT_STYLE = {
    top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
    height: DIALOG_HEIGHT,
    minHeight: DIALOG_HEIGHT,
    left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    width: DIALOG_WIDTH,
};

type IscrmIDQModalProp = {
    onClose: () => void;
    withoutScrmid: () => void;
};


const ScrmIDInvalidDialog = ({ onClose, withoutScrmid }: IscrmIDQModalProp) => {
    const scrmID = useSelector((state: RootState) => state.marketplace.filters.selection.scrmID);
    const handleDialogClose = () => {
        onClose();
    }
    const handleDialogCloseWithoutScrmid = () => {
        withoutScrmid();
        onClose();
    }

    return (
        <>
            <Dialog isOpen={true} ariaHideApp={false} style={{ content: CONFIRM_ALERT_STYLE }}>
                <DialogTitle
                    title="sCRM ID not valid"
                    buttonContent={<CloseCloseIcon />}
                    buttonProps={{ onClick: handleDialogClose }}
                />
                <DialogContent isTopDividerVisible={false} isBottomDividerVisible={false} style={{ padding: '0', overflow:'hidden' }}>
                    <div style={{ marginTop: '4rem', minHeight: '500px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={noProductsImage} alt="no-items-image" style={{ height: '200px', maxWidth: "100%" }} />
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '5px' }}>
                            <h5 style={{ fontSize: '16px', fontWeight: 'bold', marginBlockStart: '0', marginBlockEnd: '0', marginBottom: '.5rem', textAlign: 'center', }}>Invalid sCMRD ID {`"${scrmID}"`}</h5>
                            <Typography style={{color:'#252B34'}}>Sales gate not matching, proceed without sCRM ID or enter another ID</Typography>
                        </div>
                    </div>
                </DialogContent>
                <DialogFooter>
                    <Button onClick={() => onClose()}>Cancel</Button>
                    <Button variant="call-to-action" onClick={() => handleDialogCloseWithoutScrmid()} >
                        Proceed without sCRM ID
                    </Button>
                </DialogFooter>
            </Dialog>

        </>
    )
}

export default ScrmIDInvalidDialog;