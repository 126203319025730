import React, { useEffect } from "react";
import classes from './../marketplace/Style/Cart.module.css'
import CartItemsList from "./CartItemsList";
import CartSummary from "./CartSummary";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import CartEmptyPage from "./Components/CartEmptyPage";
import { setProp } from "Store/Actions";
import OOT from "Constants/services/oot";
import Loader from "Components/Elements/Loader";
import InlineFeedbackMessage from '@nokia-csf-uxr/ccfk/InlineFeedbackMessage';
import { setAgreementDetails, setAgreementValidity } from "Store/contentState/marketplaceSlice";


const CartBody = () => {
    const dispatch = useDispatch();
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
    const isAgreementValid = marketplaceSelector?.agreementDetails?.isAgreementValid;
    const cart = marketplaceSelector.cart;

    useEffect(() => {
        if (marketplaceSelector.isNokiaUser && marketplaceSelector.agreementDetails['agreements'].length == 0) {
          fetchAgreementData();
        }
      }, [])

    const fetchAgreementData = async () => {
        try {
          const response = await OOT.getAgreementDetails(marketplaceSelector.accountDetails[0]['1*_number']);
          if (!response.status || !response.data || response.data.agreements.length === 0) {
            dispatch(setAgreementDetails({ agreements: [], isAgreementValid: false }));
            return;
          }
          dispatch(setAgreementDetails({ agreements: response.data.agreements, isAgreementValid: false }));
        } catch (error) {
          console.error('Error fetching agreement data', error)
          dispatch(setAgreementDetails({ agreements: [], isAgreementValid: false }));
        }
      }

    if (cart.length === 0) {
        return <CartEmptyPage />;
    }

    if (marketplaceSelector.isCartLoading) {
        return <Loader />;
    }

    return (
        <div className={classes.cartBody}>
            <div className={classes.cartList}>
                <div style={{ borderBottom: '1px solid #0000001F' }}>
                    <div style={{ fontWeight: 'bold', fontSize: '1rem', padding: '.5rem' }}>Items in the cart</div>
                    <div style={{padding:'.5rem'}}>
                   {(!isAgreementValid && marketplaceSelector.agreementDetails['agreements'].length != 0) && <InlineFeedbackMessage
                            variant="error"
                            size="medium"
                            // closeButton
                            show={true}
                            //onClose={() => setErrorShow(false)}
                        >
                            Ordering is unavailable for this account due to a missing NDAC/Marketplace agreement. Please ensure the required agreement is in place.
                        </InlineFeedbackMessage>} 
                    </div>
                </div>
                <CartItemsList />
            </div>
            <div className={classes.cartSummary}>
                <div style={{ borderBottom: '1px solid #0000001F' }}>
                    <div style={{ fontWeight: 'bold', fontSize: '1rem', padding: '.5rem' }}>Cart summary</div>
                </div>
                <CartSummary />
            </div>
        </div>
    );
};

export default CartBody;

