import React from "react";
import Dialog, {
    DialogContent,
    DialogFooter,
    DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";
import CloseCloseIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';
import SCRMValidation from "./SCRMValidation";
import userStyles from './Style/RadioNetworkCiq.module.css'
import DialogActions from "./CaptureRadioNetworkCIQDialogContainer/DialogActions";
import CaptureDialogEmptyPage from "./CaptureRadioNetworkCIQDialogContainer/CaptureDialogEmptyPage";
import { resetCaptureDialogModal } from "Store/contentState/radioNetworkCIQSlice";
import { useDispatch, useSelector } from "react-redux";
import Stepper from "./Stepper";
import { RootState } from "Store/mainStore";
import Loader from "Components/Elements/Loader";
import StepForm, {
    Step,
    StepConfigurationArea,
    StepFormArea,
} from "@nokia-csf-uxr/ccfk/StepForm";





const DIALOG_HEIGHT = "70%";
const DIALOG_WIDTH = "30%";

const CONFIRM_ALERT_STYLE = {
    top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
    height: DIALOG_HEIGHT,
    minHeight: DIALOG_HEIGHT,
    left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    width: DIALOG_WIDTH,
};

type ICaptureUpdateStatusDialogModalProp = {
    onClose: () => void;
    // mode: string;
    // selectedUserData: any;
    // typeOfUser: string;
};


const STEP_FORM_WIDTH = '40rem';
const BUTTON_ROWS_HEIGHT = `${5 + 0.0625}rem`;

const CaptureUpdateStatusDialog = ({ onClose }: ICaptureUpdateStatusDialogModalProp) => {
    const dispatch = useDispatch();
    const radioNetworkCIQ = useSelector((stete: RootState) => stete.radioNetworkCIQ)
    const handleDialogClose = () => {
        onClose();
        dispatch(resetCaptureDialogModal());
    }

    const formatStepDate = (date) => {
        const parts = date.split(" ");
        const dateString = `${parts[1]} ${parts[2]} ${parts[3]} ${parts[4]}`;
        const extraText = parts.slice(5).join(" ");
        const dateObj = new Date(dateString);
        const day = String(dateObj.getDate()).padStart(2, "0");
        const month = String(dateObj.getMonth() + 1).padStart(2, "0"); 
        const year = dateObj.getFullYear();
        const hours = String(dateObj.getHours()).padStart(2, "0");
        const minutes = String(dateObj.getMinutes()).padStart(2, "0");

        return `${day}-${month}-${year} ${hours}:${minutes} ${extraText}`;
    };


    const getUpdateVersion = () => {
        const createdEditedList = radioNetworkCIQ.selectedNetworkPlan['plan_info'].create_edit_details
        const modifiedCreated = createdEditedList.created.map(item => `Current Version - ${formatStepDate(item)}`);
        const mergedArray = [...modifiedCreated, ...createdEditedList.edited];
        return mergedArray
    }

    return (
        <>
            <Dialog isOpen={true} ariaHideApp={false} style={{ content: CONFIRM_ALERT_STYLE }}>
                <DialogTitle
                    title={radioNetworkCIQ.selectedNetworkPlan['opportunity_id'] + " - " + radioNetworkCIQ.selectedNetworkPlan['plan_info'].general_details.case_name}
                    buttonContent={<CloseCloseIcon />}
                    buttonProps={{ onClick: handleDialogClose }}
                />
                <DialogContent isTopDividerVisible={true} isBottomDividerVisible={true} style={{ padding: '0' }}>
                    <div>

                        <StepFormArea style={{ height: `calc(100% - ${BUTTON_ROWS_HEIGHT})` }} >
                            <StepForm
                                style={{
                                    height: "100%",
                                    minHeight: "100%",
                                    width: STEP_FORM_WIDTH,
                                }}
                            >
                                {getUpdateVersion().map((item, index) => (
                                    <Step
                                        key={index}
                                        title={index != 0 ? formatStepDate(item) : item}
                                        className={index != 0 ? userStyles.stepVariantLast : ""}
                                        lastStep={index === getUpdateVersion().length - 1 ? true : false}
                                    />
                                ))}

                            </StepForm>
                        </StepFormArea>
                    </div>
                </DialogContent>
                <DialogFooter >
                    <DialogActions />
                </DialogFooter>
            </Dialog>

        </>
    )

}

export default CaptureUpdateStatusDialog;