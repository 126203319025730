import React, { useEffect } from "react";
import catalogueStyles from '../../Style/ProductCatalogueBody.module.css';
import classes from '../../Style/MarketplaceFilter.module.css';
import filterIcon from '../../../../Assets/Icons/filter.svg';
import { Typography } from "@nokia-csf-uxr/ccfk";
import { Label } from "@nokia-csf-uxr/ccfk";
import refreshIcon from '@nokia-csf-uxr/ccfk-assets/icons/latest/refresh-alt.svg';
import ToggleButton from '@nokia-csf-uxr/ccfk/ToggleButton';
import { Tooltip } from "@nokia-csf-uxr/ccfk";
import OOT from "Constants/services/oot";
import { useDispatch, useSelector } from "react-redux";
import { setLoader, setProducts, setFilters, setProductTypes, setProductSubTypes, setProductSubCategoriesList } from "Store/contentState/marketplaceSlice";
import { RootState } from "Store/mainStore";
import { getAccountCurrency } from "Pages/marketplace/Utils/commons";
import MultiCheckboxList from "Pages/marketplace/common/MultiCheckboxList";
import ExpansionPanelsContainer, {
    ExpansionPanel,
    ExpansionPanelTitle,
    ExpansionPanelHeader,
    ExpansionPanelBody,
    ExpansionPanelButton
} from '@nokia-csf-uxr/ccfk/ExpansionPanels';
import productCategoryIcon from '../../../../Assets/Icons/product-category.svg';
import portfolioIcon from '../../../../Assets/Icons/portfolio-icon.svg';
import productTypesIcon from '../../../../Assets/Icons/product-type.svg';
import Checkbox, { CheckboxLabelContent } from '@nokia-csf-uxr/ccfk/Checkbox';
import { resetPaginationsProps } from "Store/contentState/paginationSlice";
import nokiaTestedIcon from '../../../../Assets/Icons/nokia_tested_filter_icon.svg';
import _ from "lodash";

const filterContentData = [
    {
        id: '1',
        name: 'Product category',
        isOpen: true,
        icon: productCategoryIcon
    },
    {
        id: '2',
        name: 'Product types',
        isOpen: false,
        icon: productTypesIcon
    },
    {
        id: '3',
        name: 'Portfolio',
        isOpen: false,
        icon: portfolioIcon
    },
    // {
    //     id: '4',
    //     name: 'Nokia tested',
    //     isOpen: false,
    //     icon: nokiaTestedIcon
    // }
];

const LeftPanelFilters: React.FC = () => {

    const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
    const dispatch = useDispatch();
    const accountId = marketplaceSelector.accountDetails[0]['id'];
    const accountDetails = marketplaceSelector.accountDetails;
    const { showMerchantProducts, filters: { selection: { onlyDeliverable } }, productSubCategoriesList } = marketplaceSelector;
    const [expanded, setExpanded] = React.useState(['1', '3', '4']);
    const headerRef = React.useRef(null);
    let productPortfolios = [{ "id": "0", "value": "Nokia Portfolio", "status": false }, { "id": "1", "value": <>3<sup>rd</sup> Party Merchant Portfolio</>, "status": false }];
    const nokiaTestedValues = [{ "id": "1", "value": "N1" }, { "id": "2", "value": "N2" }];


    const specificKeys = ['productType', 'productSubCategory', 'productPortfolio', 'nokiaTested'];
    const totalFilterSelected = specificKeys.reduce((sum, key) => {
        const value = marketplaceSelector.filters.selection[key];
        // If value is an array, add its length; otherwise, add 0
        return sum + (Array.isArray(value) ? value.length : 0);
    }, 0);


    const handleExpansion = (id) => (e) => {
        if (e.type === 'keydown') {
            if (
                e.target.getAttribute('data-test') === 'header' && // Check if keydown from header
                (e.key === ' ' || e.key === 'Enter') // Only handle Space or Enter keys
            ) {
                togglePanel(id);
            }
        }

        if (e.type === 'click') {
            togglePanel(id);
        }
    };

    // Helper function to toggle panels
    const togglePanel = (id) => {
        setExpanded((prevExpanded) => {
            return prevExpanded.includes(id)
                ? prevExpanded.filter((panelId) => panelId !== id) // Remove panel ID if it's already expanded
                : [...prevExpanded, id]; // Add panel ID if it's not expanded
        });
    };


    useEffect(() => {
        if(productSubCategoriesList.length === 0){
            getProductSubCategoriesList();
        }
    }, [])


    //method to get product types based on selected product sub categories
    const getProductTypes = async (values) => {
        dispatch(
            setLoader({
                key: "isProductTypeLoading",
                value: true,
            })
        );
        try {
            let baseFilter = `${marketplaceSelector.productQueryFilter}`   //base query to be added here
            let currency = getAccountCurrency(accountDetails[0]['currency'], accountDetails[0]['currencyisocode']);
            let filter = baseFilter + ` AND product_data.product_sub_category IN (${values.map(value => `'${value}'`).join(', ')})`;

            const productTypesRes = await OOT.getProductTypes(accountId, currency, filter);
            if (productTypesRes.status) {
                const updatedData = productTypesRes.data
                    .filter((item) => item.product_id && item.product_id.trim() !== "")
                    .map((item) => {
                        return {
                            "id": item.product_id,
                            "value": item.product_id.includes("_") ? item.product_id.replace(/_/g, " ") : item.product_id
                        };
                    }).sort((a, b) => a.value.localeCompare(b.value));;
                dispatch(
                    setLoader({
                        key: "isProductTypeLoading",
                        value: false,
                    })
                );
                dispatch(setProductTypes({
                    productTypes: updatedData,
                }))
            } else {
                dispatch(
                    setLoader({
                        key: "isProductTypeLoading",
                        value: false,
                    })
                );
            }
        } catch (error) {
            console.error(error)
            dispatch(
                setLoader({
                    key: "isProductTypeLoading",
                    value: false,
                })
            );
        }
    }

    // method to get product sub categories list
    const getProductSubCategoriesList = async () => {
        dispatch(
            setLoader({
                key: "isProductSubCategoryLoading",
                value: true,
            })
        );
        try {
            let filter = `${marketplaceSelector.productQueryFilter}`   //base query to be added here
            let currency = getAccountCurrency(accountDetails[0]['currency'], accountDetails[0]['currencyisocode'])
            const productSubCategoriesRes = await OOT.getProductSubCategoriesList(accountId, currency, filter);
            if (productSubCategoriesRes.status) {
                // console.log(productSubCategoriesRes.data, "productSubCategoriesRes.data")
                const updatedData = productSubCategoriesRes.data
                    .filter((item) => item.product_sub_category !== null && item.product_sub_category.trim() !== "")
                    .map((item) => {
                        return {
                            "id": item.product_sub_category,
                            "value": item.product_sub_category.includes("_") ? item.product_sub_category.replace(/_/g, " ") : item.product_sub_category
                        };
                    }).sort((a, b) => a.value.localeCompare(b.value));
                // console.log(updatedData, "updatedData")
                dispatch(
                    setLoader({
                        key: "isProductSubCategoryLoading",
                        value: false,
                    })
                );
                dispatch(setProductSubCategoriesList({
                    productSubCategoriesList: updatedData,
                }))
            } else {
                dispatch(
                    setLoader({
                        key: "isProductSubCategoryLoading",
                        value: false,
                    })
                );
            }
        } catch (error) {
            console.error(error)
            dispatch(
                setLoader({
                    key: "isProductSubCategoryLoading",
                    value: false,
                })
            );
        }
    }


    // method to handle filters selection
    const handleFilterSelection = (filterName: string, selectedValue: string) => {
        const currentSelection = marketplaceSelector.filters?.selection?.[filterName] || [];
        const updatedSelection = currentSelection.includes(selectedValue)
            ? currentSelection.filter((id) => id !== selectedValue)
            : [...currentSelection, selectedValue];

        if (filterName === 'productSubCategory' && updatedSelection.length > 0) {
            getProductTypes(updatedSelection);
        } else if (filterName === 'productSubCategory') {
            dispatch(setProductTypes({ productTypes: [] }));
            dispatch(setFilters({ filterName: 'productType', value: [] }));
        }

        dispatch(setFilters({ filterName: filterName, value: updatedSelection }));
        dispatch(resetPaginationsProps());
    };

    // method to reset all filters
    const resetCatalogueFilters = () => {
        specificKeys.forEach((key) =>
            dispatch(setFilters({ filterName: key, value: [] }))
        );
        dispatch(setProductTypes({ productTypes: [] }));
    };



     const disableMerchantCheckboxForNonUSEUR = () => {
        // Check if the merchantPortfolio exists and matches the conditions
            if (!showMerchantProducts && onlyDeliverable) {
                productPortfolios = productPortfolios.map((item) =>
                    item.id === "1" ? { ...item, status: true } : item
                  );
                  // uncheck the checkbox if merchant portfolio is selected in case of only deliverable true
                  if(marketplaceSelector?.filters?.selection?.productPortfolio?.includes("1")){
                    dispatch(setFilters({ filterName: 'productPortfolio', value: [] }));
                  }
            }
        return productPortfolios;
    };	


    return (
        <>
            <div style={{ padding: '1rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '.5rem', alignItems: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={filterIcon} style={{ height: '20px' }} /></div>
                        <Label style={{ color: '#757575' }}>Filters ({totalFilterSelected})</Label>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div className={classes.verticalDivider} style={{ margin: '0rem 1.5rem' }}></div>
                        <Tooltip tooltip="Reset" placement="bottom">
                            <ToggleButton
                                aria-label="settings"
                                onClick={resetCatalogueFilters}
                                size="large"
                            // active={isActive}
                            >
                                <img src={refreshIcon} />
                            </ToggleButton>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div style={{ borderBottom: '1px solid #0000001F' }}></div>

            <div>
                {/* <div style={{ marginBottom: '1rem', padding: '1rem' }}>
            <Label style={{ color: '#757575' }}>Price range</Label>
          </div> */}

                <div style={{ marginBottom: '1rem' }}>
                    <ExpansionPanelsContainer>
                        {filterContentData.map(panel => (
                            <ExpansionPanel
                                expanded={expanded.includes(panel.id)}
                                key={panel.id}
                                id={panel.id}
                                hoverEffect
                                focusEffect
                                mountContent={false}
                            >
                                <ExpansionPanelHeader
                                    data-test="header"
                                    ref={headerRef}
                                    role="button"
                                    // aria-expanded={expanded.includes(panel.id)}
                                    style={{ justifyContent: 'space-between' }}
                                    onKeyDown={handleExpansion(panel.id)}
                                    onClick={handleExpansion(panel.id)}
                                >
                                    <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center', width: '18.75rem' }}>
                                        <img src={panel.icon} />
                                        <ExpansionPanelTitle>{panel.name}</ExpansionPanelTitle>
                                    </div>
                                    <ExpansionPanelButton
                                        onClick={() => handleExpansion(panel.id)}
                                        iconButtonProps={{
                                            'aria-hidden': true,
                                        }}
                                    />
                                </ExpansionPanelHeader>

                                <ExpansionPanelBody>
                                    <div style={{ padding: '1rem' }}>
                                        {(marketplaceSelector?.productSubCategoriesList && panel.id === "1") && (
                                            <MultiCheckboxList
                                                //@ts-ignore
                                                data={marketplaceSelector.productSubCategoriesList}
                                                selectedItems={marketplaceSelector.filters?.selection?.productSubCategory || []}
                                                onCheckboxSelect={(value) =>
                                                    handleFilterSelection('productSubCategory', value)
                                                }

                                            />
                                        )}
                                        {marketplaceSelector?.productTypes && panel.id === "2" && (
                                            <MultiCheckboxList
                                                //@ts-ignore
                                                data={marketplaceSelector.productTypes}
                                                selectedItems={marketplaceSelector.filters?.selection?.productType || []}
                                                onCheckboxSelect={(value) =>
                                                    handleFilterSelection('productType', value)
                                                }
                                            />
                                        )}
                                        {productPortfolios && panel.id === "3" && (
                                            <MultiCheckboxList
                                                //@ts-ignore
                                                data={disableMerchantCheckboxForNonUSEUR()}
                                                selectedItems={marketplaceSelector?.filters?.selection?.productPortfolio || []}
                                                onCheckboxSelect={(value) =>
                                                    handleFilterSelection('productPortfolio', value)
                                                }
                                                
                                            />
                                        )}
                                        {nokiaTestedValues && panel.id === "4" && (
                                            <MultiCheckboxList
                                                //@ts-ignore
                                                data={nokiaTestedValues}
                                                selectedItems={marketplaceSelector?.filters?.selection?.nokiaTested || []}
                                                onCheckboxSelect={(value) =>
                                                    handleFilterSelection('nokiaTested', value)
                                                }
                                            />
                                        )}
                                    </div>


                                </ExpansionPanelBody>
                            </ExpansionPanel>
                        ))}
                    </ExpansionPanelsContainer>
                </div>
            </div>
        </>
    )
}

export default LeftPanelFilters;