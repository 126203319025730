import React, { useEffect, useState, useCallback, useMemo } from "react";

import classes from '../merchant/Style/Merchant.module.css';
import { RootState, store } from "Store/mainStore";
import { useSelector } from "react-redux";
import Loader from "Components/Elements/Loader";
import Card from '@nokia-csf-uxr/ccfk/Card';
import totalorders from '../../Assets/Icons/totalorders.png';
import totalquotation from '../../Assets/Icons/totalquotation.png'
import totalsales from '../../Assets/Icons/totalsales.png';
import trendup from '../../Assets/Icons/trendup.png';
import trenddown from '../../Assets/Icons/trenddown.png';
import SparkLine from "./Graph/SparkLine";

import TabsSection from '../marketplace/common/TabsSection';

import OOT from "Constants/services/oot";
import { setLoader, setOrders, setDashboard, setDashboardData,setSelectedSalesOrderID } from "Store/contentState/merchantSlice";
import { useDispatch } from 'react-redux';

import { formatNumberWithCommas } from '../marketplace/Utils/commons';
import OfferQuotationDetails from "./OfferQuotationDetails";
import ReportList from "./ReportList";
import { Skeleton, Tabs } from "@nokia-csf-uxr/ccfk";
import { Tab } from "@nokia-csf-uxr/ccfk/Tabs";
import { formatDate } from "../marketplace/Utils/commons";

const MerchanteBody = () => {
  const dispatch = useDispatch();
  const merchantSelector = useSelector((state: RootState) => state.merchant);
  const analytics = merchantSelector.dashboard.analytics.id
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const limitPerpage = 100
  const limitPerpageItems = 500
  const limitPerpageItem = 500
  const limitPerpageProduct = 50
  const [orderData, setOrderData] = useState([]);
  const [orderItemData, setOrderItemData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [weeklyorderData, setWeeklyOrderData] = useState([]);
  const [quotationProductList, setQuotationProductList] = useState([])
  const [weeklyQuotationData, setWeeklyQuotationData] = useState([]);
  const selectedMerchant = store.getState().merchant.selectedMerchant
  const persona = useSelector((state: RootState) => state.marketplace.persona);
  const limitPerpageOrderItems = 10000
  const limitPerpageOrder = 5000

  const [tabSelected, setTabSelected] = useState("Ordres")

  let fromDate = ''
  let toDate = ""
  let lastToDate = ""
  let lastFromDate = ""
  let salesOrderIds = ""
  let productIds = ""
  let OrderListItems = []
  let salesOrderItemsIDsList = []



  useEffect(() => {
    getToFromDate(analytics)
    if(persona === "nokia" ){
      const fetchItemData = async () => {
        dispatch(setLoader({ key: "isProductsLoading", value: true }));
        if(selectedMerchant != ""){
        const allData = await fetchAllOrderItems();
        let salesOrderIdsList = allData.map((orderItem) => orderItem.sales_order_id)
        let salesOrderIdsFinallist = new Set(salesOrderIdsList)
        salesOrderItemsIDsList = Array.from(salesOrderIdsFinallist)
        dispatch(setSelectedSalesOrderID(salesOrderItemsIDsList));
        getSalesOrderItemsList()
        }
      };
      fetchItemData()
    }
    else{
      getSalesOrderItemsList()
    }
  }, [analytics,selectedMerchant])


  const getSalesOrderItemsList = () => {
    const fetchData = async () => {
      dispatch(setLoader({ key: "isProductsLoading", value: true }));
      const allData = await fetchAllPages();
      let salesOrderIdsList = allData.map((order) => order.sales_order_id)
      salesOrderIds = salesOrderIdsList.join("','")
      OrderListItems = allData
      dispatch(setDashboardData({ field: 'orders', filterName: 'ordersData', value: OrderListItems }));
      fetchDataItems()
      setOrderData(allData);
    };

    const fetchDataItems = async () => {
      const allDataItems = await fetchAllItemPages();
      setOrderItemData(allDataItems)
      dispatch(setDashboardData({ field: 'orders', filterName: 'orderItemData', value: allDataItems }));
      let productList = allDataItems.map((product) => product.product_id)
      productIds = productList.join("','")
      fetchDataProduct(allDataItems)
      fetchQuotationData();
    }

    const fetchDataProduct = async (allDataItems) => {
      const allDataProducts = await fetchAllProductPages();
      setProductData([])
      const merged = allDataItems.map((order) => {
        const matchingProduct = allDataProducts.find(
          (product) => product.product_id === order.product_id
        );
        return {
          ...order,
          product_sub_type_id: matchingProduct
            ? matchingProduct.product_sub_type_id
            : null, // Assign null if no match is found
        };
      });
      //getallOffer()
      dispatch(setDashboardData({ field: 'orders', filterName: 'productDataList', value: merged }));
      setProductData(merged)
    }

    fetchData();

    const fetchLastWeekData = async () => {
      const allWeeklyData = await fetchAllPages("weekly");
      setWeeklyOrderData(allWeeklyData)
    }
    if (analytics === "weekly") {
      fetchLastWeekData()
    }

    const fetchQuotationData = async () => {
      const quotationProductDatalList = await fetchQuotationAllPages();
      const quotationProductData = Object.values(quotationProductDatalList.reduce((acc, item) => {
        if (!acc[item.offer_id]) {
            acc[item.offer_id] = {
                offer_id: item.offer_id,
                offer_created_date: item.offer_created_date,
                summary_total_list_price: item.summary_total_list_price,
                network_location: item.network_location,
                products: []
            };
        }
        acc[item.offer_id].products.push({
            product_id: item.product_id,
            product_sub_type: item.product_sub_type,
            price: item.price,
            quantity: item.quantity
        });
        return acc;
    }, {}));
      dispatch(setDashboardData({ field: 'quotations', filterName: 'quotationProductList', value: quotationProductData }));
      setQuotationProductList(quotationProductData)
      dispatch(setLoader({ key: "isProductsLoading", value: false }));
    }

    const fetchQuationLastWeekData = async () => {
      const allWeeklyQuotationData = await fetchQuotationAllPages("weekly");
      setWeeklyQuotationData(allWeeklyQuotationData)
    }
    if (analytics === "weekly") {
      fetchQuationLastWeekData()
    }
  }

  

  const fetchAllOrderItems = async (type = "", page = 0, accumulatedData = []) => {
    let date = new Date(toDate)
    date.setDate(date.getDate() + 1);
    let updatedDate = date.toISOString().split('T')[0]; 
    let filterConditions = ` createdAt BETWEEN '${fromDate}' AND '${updatedDate}' AND supplier_id = '${selectedMerchant}'`
    try {
      const response1 = await OOT.getAllMerchantOrderItems(filterConditions, limitPerpageOrderItems, page, true);
      const newData = [...accumulatedData, ...response1.data];
      if (response1.data.length >= limitPerpageOrder) {
        return fetchAllOrderItems(type, page + response1.data.length, newData);
      } else {
        return newData;
      }
      
    } catch (error) {
      console.error('Error fetching data:', error);
      return accumulatedData;
    }
  };

  const fetchAllPages = async (type = "", page = 0, accumulatedData = []) => {
    let filterConditions = persona === "nokia" ? ` STR_TO_DATE(posting_date, '%m/%d/%Y') >= '${fromDate}' AND STR_TO_DATE(posting_date, '%m/%d/%Y') <= '${toDate}' AND sales_order_id in ('${salesOrderItemsIDsList.join("','")}')` :` STR_TO_DATE(posting_date, '%m/%d/%Y') >= '${type === "weekly" ? lastFromDate : fromDate}' AND STR_TO_DATE(posting_date, '%m/%d/%Y') <= '${type === "weekly" ? lastToDate : toDate}'`
    filterConditions = filterConditions + ' and market is not null'
    try {
      const response = await OOT.getAllMerchantOrders(filterConditions, limitPerpage, page);
      const newData = [...accumulatedData, ...response.data];
      if (response.data.length >= limitPerpage) {
        return fetchAllPages(type, page + response.data.length, newData);
      } else {
        return newData;
      }
      
    } catch (error) {
      console.error('Error fetching data:', error);
      return accumulatedData;
    }
  };

  const fetchAllItemPages = async (page = 0, accumulatedData = []) => {
    let filterConditions = `offer_sales_order_items.sales_order_id IN('${salesOrderIds}')`
    if(persona === "nokia" ){
      let date = new Date(toDate)
      date.setDate(date.getDate() + 1);
      let updatedDateItem = date.toISOString().split('T')[0]; 
      filterConditions = `offer_sales_order_items.sales_order_id IN('${salesOrderIds}') AND offer_sales_order_items.createdAt BETWEEN '${fromDate}' AND '${updatedDateItem}' AND offer_sales_order_items.supplier_id = '${selectedMerchant}'`
    }
    try {
      const response = await OOT.getAllMerchantOrderItems(filterConditions, limitPerpageItems, page);
      const newData = [...accumulatedData, ...response.data];
      if (response.data.length >= limitPerpageItem) {
        return fetchAllItemPages(page + response.data.length, newData);
      } else {
        return newData;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return accumulatedData;
    }
  };

  const fetchAllProductPages = async (page = 0, accumulatedData = []) => {
    const filterConditions = `product_id IN('${productIds}') and product_sub_type_id is not null`
    const currency =""
    try {
      const response = await OOT.getAllMerchantProducts(persona === "nokia" ? selectedMerchant : merchantSelector.supplier_account_id, filterConditions,currency, limitPerpageProduct, page, persona);
      const newData = [...accumulatedData, ...response.data];
      if (response.data.length >= limitPerpage) {
        return fetchAllProductPages(page + response.data.length, newData);
      } else {
        return newData;
      }
    } catch (error) {
      // console.error('Error fetching data:', error);
      return accumulatedData;
    }
  };

  const fetchQuotationAllPages = async (type = "", page = 0, accumulatedData = []) => {
   const filterConditions = `offer.status IN('pendingapproval') AND offer.source = 'marketplace' AND supplier_id = '${persona === "nokia" ? selectedMerchant : merchantSelector.supplier_account_id}' AND offer.offer_created_date BETWEEN '${type === "weekly" ? lastFromDate : fromDate}' AND '${type === "weekly" ? lastToDate : toDate}'`
   //const filterConditions = `offer.status IN('pendingapproval') AND supplier_id = '1000001903' AND offer.offer_created_date BETWEEN '2024-03-20' AND '2025-03-20'",
   try {
      const response = await OOT.getDashboardQuotationAPI(filterConditions, limitPerpage, page);
      const newData = [...accumulatedData, ...response.data];
      if (response.data.length >= limitPerpage) {
        return fetchQuotationAllPages(type, page + response.data.length, newData);
      } else {
        return newData;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return accumulatedData;
    }
  }





  const groupedSalesOrder = useMemo(() => {
    if (orderData.length === 0) return {};
    return orderData.reduce((acc, order) => {
      const { postingDateFormate, net_value_transactional_currency } = order;
      if (!acc[postingDateFormate]) {
        acc[postingDateFormate] = {
          orderCount: 0,
          salesAmount: 0,
          country_id: 0
        }
      }
      acc[postingDateFormate].salesAmount += net_value_transactional_currency
      acc[postingDateFormate].orderCount += 1
      acc[postingDateFormate].country_id += 1
      return acc;
    }, {});
  }, [orderData]);


  const groupedWeeklySalesOrder = useMemo(() => {
    if (weeklyorderData.length === 0) return {};
    return weeklyorderData.reduce((acc, order) => {
      const { postingDateFormate, net_value_transactional_currency } = order;
      if (!acc[postingDateFormate]) {
        acc[postingDateFormate] = {
          orderCount: 0,
          salesAmount: 0,
          country_id: 0
        }
      }
      acc[postingDateFormate].salesAmount += net_value_transactional_currency
      acc[postingDateFormate].orderCount += 1
      acc[postingDateFormate].country_id += 1
      return acc;
    }, {});
  }, [weeklyorderData]);


  const groupedWeeklyQuotationOrder = useMemo(() => {
    const resultQuotation = {};
    weeklyQuotationData.forEach((offer) => {
      const { offer_created_date, summary_total_list_price } = offer;
      const [year, month, day] = offer_created_date.split(" ")[0].split("-");
      const offer_date = `${month}/${day}/${year}`;
      if (!resultQuotation[offer_date]) {
        resultQuotation[offer_date] = {
          quotationCount: 0,
          salesAmount: 0,
          quotations: 0,
        };
      }
      resultQuotation[offer_date].salesAmount += parseFloat(summary_total_list_price) || 0;
      resultQuotation[offer_date].quotationCount += 1;
      resultQuotation[offer_date].country_id += 1;
    });

    return resultQuotation;
  }, [weeklyQuotationData]);




  const groupSalesOrderQuotations = useMemo(() => {
    const resultQuotation = {};
    quotationProductList.forEach((offer) => {
      const { offer_created_date, field_ofr_order_original_price } = offer;
      const [year, month, day] = offer_created_date.split(" ")[0].split("-");
      const offer_date = `${month}/${day}/${year}`;
      if (!resultQuotation[offer_date]) {
        resultQuotation[offer_date] = {
          quotationCount: 0,
          salesAmount: 0,
          quotations: 0,
        };
      }
      resultQuotation[offer_date].salesAmount += parseFloat(field_ofr_order_original_price) || 0;
      resultQuotation[offer_date].quotationCount += 1;
      resultQuotation[offer_date].country_id += 1;
    });

    return resultQuotation;
  }, [quotationProductList]);


  const groupOrderDataCount = (type) => {
    let totalCount = 0
    const numbers: unknown[] = Object.entries(type === "quotationCount" ? groupSalesOrderQuotations : groupedSalesOrder).map((key, value) => key[1][type])
    numbers.forEach((value) => {
      totalCount += value as number;
    });
    return totalCount
  }
  const groupWeeklyOrderDataCount = (type) => {
    let totalCount = 0
    const numbers: unknown[] = Object.entries(type === "quotationCount" ? groupedWeeklyQuotationOrder : groupedWeeklySalesOrder).map((key, value) => key[1][type])
    numbers.forEach((value) => {
      totalCount += value as number;
    });
    return totalCount
  }

  const weeklyOrderCount = (type) => {
    let weeklyCountOrder = {}
    let weeklyCount = (groupOrderDataCount(type) - groupWeeklyOrderDataCount(type)) / groupWeeklyOrderDataCount(type) * 100
    weeklyCount = weeklyCount === Infinity ? 0 : weeklyCount
    weeklyCount = isNaN(weeklyCount) ? 0 : weeklyCount;
    let weeklyType = "Zero"
    if (weeklyCount > 0) {
      weeklyType = "Positive";
    } else if (weeklyCount < 0) {
      weeklyType = "Negative";
    }

    weeklyCountOrder["weeklyType"] = weeklyType
    weeklyCountOrder["weeklyCount"] = Math.round(weeklyCount)
    return weeklyCountOrder

  }


  const formatDateList = (date) => {
    const year = date.getFullYear(); // Get the year (e.g., 2024)
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month (1-12), zero-padded
    const day = String(date.getDate()).padStart(2, '0'); // Day (1-31), zero-padded

    return `${year}-${month}-${day}`;
  };

  const getToFromDate = (analyticsType) => {
    const today = new Date()
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    const LastYear = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    if (analyticsType === "weekly") {
      toDate = formatDateList(today)
      fromDate = formatDateList(lastWeek)
      lastToDate = fromDate
      let lastFromDateDate = new Date(lastWeek.getFullYear(), lastWeek.getMonth(), lastWeek.getDate() - 7);
      lastFromDate = formatDateList(lastFromDateDate)
    }
    else if (analyticsType === "month") {
      let date = today.setMonth(today.getMonth() - 1)
      let month = new Date(date)
      let todaydate = new Date
      toDate = formatDateList(todaydate)
      fromDate = formatDateList(month)
    }
    else if (analyticsType === "year") {
      toDate = formatDateList(today)
      fromDate = formatDateList(LastYear)
    }
    dispatch(setDashboard({ field: 'todate', value: toDate }));
    dispatch(setDashboard({ field: 'fromDate', value: fromDate }));

  }


  // if (merchantSelector.isProductsLoading) {
  //   return <Loader />
  // }

  const DATA2 = (type) => {
    let dataList  = type === "quotationCount" ? groupSalesOrderQuotations : groupedSalesOrder
    const sortedData = Object.keys(dataList)
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    .reduce((acc, key) => { 
        acc[key] = dataList[key];
        return acc;
    }, {});



    const data = Object.entries(sortedData).map(([key, value]) => ({
      year: key,
      value: value[type]
    }))
    return data
  }




  const customTabData = [
    { tab: { children: 'Orders', label: 'Tab One offers' } },
    { tab: { children: 'Quotations', label: 'Tab Two quotations' } }
  ];

  const tabContentComponents = [
    <ReportList key={0} type="Orders" isActive={activeTabIndex === 0} />,
    <ReportList key={1} type="Quotations" isActive={activeTabIndex === 1} />
  ];

  const handleTabSelect = (index) => {
    setActiveTabIndex(index);
  };

  const tabData = [{ label: "Orders", tab: "Ordres", content: null }, { label: "Quotations", tab: "Quotations", content: null }]

  const getWeekMonthYearDataFormat = (analyticsType) =>{
    const today = new Date()
    let fromDate = ''
    let toDate = ""
    let lastToDate = ""
    let lastFromDate = ""
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    const LastYear = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    if (analyticsType === "weekly") {
      toDate = formatDate(new Date(today), 'dd-MMM-yyyy')
      fromDate = formatDate(new Date(lastWeek), 'dd-MMM-yyyy')
      lastToDate = fromDate
      let lastFromDateDate = new Date(lastWeek.getFullYear(), lastWeek.getMonth(), lastWeek.getDate() - 7);
      lastFromDate = formatDate(new Date(lastFromDateDate),'dd-MMM-yyyy')
    }
    else if (analyticsType === "month") {
      let date = today.setMonth(today.getMonth() - 1)
      let month = new Date(date)
      let todaydate = new Date
      toDate = formatDate(new Date(todaydate), 'dd-MMM-yyyy')
      fromDate = formatDate(new Date(month), 'dd-MMM-yyyy')
    }
    else if (analyticsType === "year") {
      toDate = formatDate(new Date(today), 'dd-MMM-yyyy')
      fromDate = formatDate(new Date(LastYear), 'dd-MMM-yyyy')
    }
    let dataFormate = fromDate + '-' + toDate + `(Today's date minus 1 ${analyticsType})`
 
    return dataFormate

  }




  return (

    <div className={classes.marketplaceWrapper}>
      {/* <div style={{display:'flex', justifyContent: 'flex-end', paddingRight: '10px', paddingBottom:'10px'}}><p>{getWeekMonthYearDataFormat(analytics)}</p></div> */}
      <div className={classes.marketplaceProductsCatalogContainer}>
        <div className={classes.gridContainer}>
          <Card style={{ borderRadius: '10px', display: 'flex', marginRight: '1rem', height: 'fit-content', marginBottom: '1rem', cursor: 'pointer', minHeight: "auto" }} >
            {merchantSelector.isProductsLoading ? (<div style={{ padding: '20px' }}><Skeleton height={20} width={500} /></div>) : (<div className={classes.card}>
              <div className={classes.cardheader}>
                <div className={classes.cardheaderleftsection}>
                  <div className={classes.carddetails}>
                    <div className={classes.carddetailsleftsect}>
                      <img src={totalorders} />
                    </div>
                    <div className="card-details-rightsect">
                      <div className={classes.cardcount}>{groupOrderDataCount("orderCount")}</div>
                      <div className={classes.cardlabel}>Total orders</div>
                    </div>
                  </div>
                </div>
                {analytics === "weekly" && <div className={classes.cardheaderrightsection}>
                  <div className={weeklyOrderCount("orderCount")["weeklyType"] === "Negative" ? classes.trenddown : classes.trend}><img src={weeklyOrderCount("orderCount")["weeklyType"] === "Negative" ? trenddown : trendup} className={classes.trendimg} />{weeklyOrderCount("orderCount")["weeklyCount"]}%</div>
                  <div className={classes.trendstatus}>Since last week</div>
                </div>}
              </div>
              <div className={classes.cardfooter}>
                <SparkLine stroke={'#9567FE'} graphType={"orderCount"} data={DATA2("orderCount")} />
              </div>
            </div>)}
          </Card>
          <Card style={{ borderRadius: '10px', display: 'flex', marginRight: '1rem', height: 'fit-content', marginBottom: '1rem', cursor: 'pointer', minHeight: "auto" }} >
            {merchantSelector.isProductsLoading ? (<div style={{ padding: '20px' }}><Skeleton height={20} width={500} /></div>) : (<div className={classes.card}>
              <div className={classes.cardheader}>
                <div className={classes.cardheaderleftsection}>
                  <div className={classes.carddetails}>
                    <div className={classes.carddetailsleftsect}>
                      <img src={totalquotation} />
                    </div>
                    <div className="card-details-rightsect">
                      <div className={classes.cardcount}>{groupOrderDataCount("quotationCount")}</div>
                      <div className={classes.cardlabel}>Total quotation</div>
                    </div>
                  </div>
                </div>
                {analytics === "weekly" && <div className={classes.cardheaderrightsection}>
                  <div className={weeklyOrderCount("quotationCount")["weeklyType"] === "Negative" ? classes.trenddown : classes.trend}><img src={weeklyOrderCount("quotationCount")["weeklyType"] === "Negative" ? trenddown : trendup} className={classes.trendimg} />{weeklyOrderCount("quotationCount")["weeklyCount"]}%</div>
                  <div className={classes.trendstatus}>Since last week</div>
                </div>}
              </div>
              <div className={classes.cardfooter}>
                <SparkLine stroke={'#005AFF'} graphType={"quotationCount"} data={DATA2("quotationCount")} />
              </div>
            </div>)}

          </Card>
          <Card style={{ borderRadius: '10px', display: 'flex', marginRight: '1rem', height: 'fit-content', marginBottom: '1rem', cursor: 'pointer', minHeight: "auto" }} >
            {merchantSelector.isProductsLoading ? (<div style={{ padding: '20px' }}><Skeleton height={20} width={500} /></div>) : (<div className={classes.card}>
              <div className={classes.cardheader}>
                <div className={classes.cardheaderleftsection}>
                  <div className={classes.carddetails}>
                    <div className={classes.carddetailsleftsect}>
                      <img src={totalsales} />
                    </div>
                    <div className="card-details-rightsect">
                      <div className={classes.cardcount}>EUR {formatNumberWithCommas(groupOrderDataCount("salesAmount"))}</div>
                      <div className={classes.cardlabel}>Total sales</div>
                    </div>
                  </div>
                </div>
                {analytics === "weekly" && <div className={classes.cardheaderrightsection}>
                  <div className={weeklyOrderCount("salesAmount")["weeklyType"] === "Negative" ? classes.trenddown : classes.trend}><img src={weeklyOrderCount("salesAmount")["weeklyType"] === "Negative" ? trenddown : trendup} className={classes.trendimg} />{weeklyOrderCount("salesAmount")["weeklyCount"]}%</div>
                  <div className={classes.trendstatus}>Since last week</div>
                </div>}
              </div>
              <div className={classes.cardfooter}>
                <SparkLine stroke={'#00C9FF'} graphType={"salesAmount"} data={DATA2("salesAmount")} />
              </div>
            </div>)}

          </Card>

        </div>

        <div>
          {/* <TabsSection tabData={customTabData} tabContentComponents={tabContentComponents}  //@ts-ignore
            onTabChange={handleTabSelect}/> */}
          {tabData.length === 0 && <Skeleton />}
          {tabData.length > 0 && (
            <Tabs style={{ justifyContent: "start" }}>
              {tabData.map((tabs) => (
                <Tab key={`${tabs.tab}`} onSelect={() => { setTabSelected(tabs.tab) }} selected={tabs.tab === tabSelected}>
                  {tabs.label}
                </Tab>
              ))}
            </Tabs>
          )}

          {tabSelected === 'Ordres' && <div> <ReportList type="Orders"/></div>}
          {tabSelected === 'Quotations' && <div> <ReportList type="Quotations"/></div>}
        </div>

      </div>


    </div>
  )
}

export default MerchanteBody


