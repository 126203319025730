import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import SelectBox from "Pages/subscription/Custom/SelectBox";
import classes from '../../marketplace/Style/MarketplaceFilter.module.css';
import indexStyles from '../../marketplace/Style/Index.module.css';
import { RootState, store } from "Store/mainStore";
import CircularProgressIndicatorIndeterminate from "@nokia-csf-uxr/ccfk/CircularProgressIndicatorIndeterminate";
import { setPartnerList, setLoader, setProducts, setFilters, clearCart, setShowFilter, setCustomerList } from "Store/contentState/marketplaceSlice";
import { useQuery } from "@tanstack/react-query";
import OOT from "Constants/services/oot";
import { setProp } from "Store/Actions";
import { useMarketplaceFilter } from "../hooks/useMarketplaceFilter";
import { setAccountDetails } from "Store/contentState/marketplaceSlice";
import infoCircleIcon from '../../../Assets/Icons/info-circle-small-fill-white.svg';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import { resetPaginationsProps } from "Store/contentState/paginationSlice";
import Button, {
    ButtonText,
    ButtonIcon,
} from '@nokia-csf-uxr/ccfk/Button';
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { FilterField, Label } from "@nokia-csf-uxr/ccfk";
import searchIcon from '../../../Assets/Icons/magnify-icon.svg'
import { FilterFieldIcon } from "@nokia-csf-uxr/ccfk/FilterField";
import ScrmIDInvalidDialog from "../Components/ScrmIDInvaildDialog";
import ToggleButton from '@nokia-csf-uxr/ccfk/ToggleButton';

const shipToValues = [
    {
        "id": "sellToParty",
        "value": "Sell to party"
    },
    {
        "id": "reseller",
        "value": "Reseller"
    },
    {
        "id": "endCustomer",
        "value": "End customer"
    },
]

const targetSalesModeValues = [
    {
        "id": "NLSM",
        "value": "NLSM",
        "toolTipText": "NDAC Local Sales Mode"
    },
    {
        "id": "NCSM",
        "value": "NCSM",
        "toolTipText": "NDAC Corporate Sales Mode"
    }
]


const sCRMRegex = /(^\d{2})\.([\w]{2})\.(\d{6,})/;

const CommonFilter = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
    const { fetchMarketplaceData, setCustomerState, updateAccountData, prepareProductApiFilter, setShippingCountry, } = useMarketplaceFilter();
    const { billingCountry, shippingCountry, scrmID } = marketplaceSelector.filters.selection;
    const accountData = marketplaceSelector.accountDetails
    const [scrmidLoader, setScrmidLoader] = React.useState(false);
    const [scrmidLoading, setscrmidLoading] = React.useState(false)
    const inputRef = useRef(null);


    const handleCustomerSelection = (__customer) => {
        if (__customer !== '') {
            setCustomerState(__customer);
            //updateAccountData(__customer, marketplaceSelector.accountDetails, marketplaceSelector.customerList, marketplaceSelector.accountDropdown);
            updateAccountData(__customer, marketplaceSelector.accountDetails, marketplaceSelector.customerList);

        } else {
            dispatch(setFilters({ filterName: 'customer', value: '' }))
            dispatch(resetPaginationsProps());
            dispatch(setShowFilter(false));
            dispatch(setProducts([]));
            dispatch(setFilters({ filterName: 'productType', value: "" }));
            dispatch(clearCart());
        }
    };



    const handlePartnerSelection = (__partner) => {
        if (__partner !== '') {
            dispatch(setFilters({ filterName: 'partner', value: __partner }));
            // getAccountDetails(__partner)
            fetchMarketplaceData('fetch', __partner, marketplaceSelector.partnerList);
            dispatch(setFilters({ filterName: 'productType', value: "" }));
            dispatch(setProp({ slice: 'marketplace', key: 'searchQuery', value: "" }));
            dispatch(clearCart());

        } else {
            dispatch(setFilters({ filterName: 'partner', value: '' }))
            dispatch(setFilters({ filterName: 'customer', value: '' }))
            dispatch(setShowFilter(false));
            dispatch(setProp({ slice: 'marketplace', key: 'showShipTo', value: false }))
            dispatch(
                setCustomerList({
                    customerList: [],
                })
            );
            dispatch(
                setLoader({
                    key: "isCustomerLoading",
                    value: false,
                })
            );
            dispatch(setProducts([]));
            dispatch(setFilters({ filterName: 'productType', value: "" }));
            dispatch(clearCart());
        }

    }


    const handleCountrySelection = (_country) => {
        if (_country !== '') {
            //dispatch(setFilters({ filterName: 'shippingCountry', value: _country }));
            //getCountryDetails(_country, marketplaceSelector.countryList)
            //getSelectCountryDetails(_country, 'shipping', marketplaceSelector.countryList)
            setShippingCountry(_country, marketplaceSelector.countryList)
            dispatch(setFilters({ filterName: 'productType', value: "" }));
            dispatch(setProp({ slice: 'marketplace', key: 'searchQuery', value: "" }));
            // dispatch(clearCart());

        } else {
            dispatch(setFilters({ filterName: 'shippingCountry', value: '' }))
            dispatch(setShowFilter(false));
            dispatch(resetPaginationsProps());
            dispatch(setProducts([]));
            dispatch(setFilters({ filterName: 'productType', value: "" }));
            dispatch(clearCart());
            // dispatch(setProp({ slice: 'marketplace', key: 'showShipTo', value: false }))
        }
    }

    const handleShipToSelection = (_value) => {
        if (_value != '') {
            dispatch(setFilters({ filterName: 'shipTo', value: _value }));
        } else {
            dispatch(setFilters({ filterName: 'shipTo', value: "" }));
        }
    }


    const handleProceed = () => {
        prepareProductApiFilter(billingCountry, shippingCountry, marketplaceSelector.countryList, accountData[0].accountType);
        dispatch(setProp({ slice: 'marketplace', key: 'isGetProducts', value: true }))
    }


    const selectedToolTipText = targetSalesModeValues.find(mode => mode.id === marketplaceSelector?.filters?.selection?.targetSalesMode)?.toolTipText || "Select target sales mode";

    const checkBtnDisable = () => {
        return (marketplaceSelector.filters.selection.shippingCountry === '' || marketplaceSelector.filters.selection.partner === '' || marketplaceSelector.filters.selection.customer === '' || marketplaceSelector.accountDetails.length === 0 || (marketplaceSelector.showShipTo && marketplaceSelector.filters.selection.shipTo === ''))
    }

    const handleSCRMIDChange = ({ debounce = true, text }) => {
        dispatch(setFilters({ filterName: 'scrmID', value: text }));
    }

    // const handleInputKeyDown = (event) => {
    //     const { key, keyCode } = event;
    //     if (key === "Enter" || keyCode === 13) {
    //         let filter = `opportunity_id = '${event.target.value}'`;
    //         getScrmid(filter, event.target.value)
    //     }

    // };

    const handleSCRMIDSearch = (e) => {
        let scrmIDFromStore = store.getState()
        if ((e.key === 'Enter' || e.keyCode === 13 || e.type === 'click') && scrmID.trim() !== '') {
            let filter = `opportunity_id = '${scrmIDFromStore.marketplace.filters.selection.scrmID}'`;
            getScrmid(filter, scrmIDFromStore.marketplace.filters.selection.scrmID)
        }
    }

    const getScrmid = async (filter, scrmId) => {
        setscrmidLoading(true)
        dispatch(setProp({ slice: 'marketplace', key: 'scrmidLoading', value: true }))
        try {
            const response = await OOT.getScrmidValid(filter, scrmId);
            if (response.data) {
                if (response.data && response.data[0]) {
                    dispatch(setFilters({ filterName: 'customer', value: response.data[0].end_customer_le__r_accountnumber }));
                    handlePartnerSelection(response.data[0].customer_account_number)
                    // handleCustomerSelection(response.data[0].end_customer_le__r_accountnumber)
                    setScrmidLoader(false)
                    setscrmidLoading(false)
                    dispatch(setProp({ slice: 'marketplace', key: 'scrmidLoading', value: false }))
                }
                else {
                    setScrmidLoader(true)
                    setscrmidLoading(false)
                    dispatch(setProp({ slice: 'marketplace', key: 'scrmidLoading', value: false }))
                }
            }
        } catch (error) {
            // console.log(marketplaceSelector.isPartnerLoading, scrmidLoading)
            setScrmidLoader(true)
            setscrmidLoading(false)
            dispatch(setProp({ slice: 'marketplace', key: 'scrmidLoading', value: false }))
            console.error('Error fetching data:', error);

        }
    };

    const handleClear = () => {
        handlePartnerSelection("")
        handleCustomerSelection("")
        dispatch(setFilters({ filterName: 'scrmID', value: "" }));
        dispatch(setProp({ slice: 'marketplace', key: 'scrmidLoading', value: false }))
    }



    return (
        <>
            {/* <ToastContainer /> */}

            {scrmidLoader && <ScrmIDInvalidDialog onClose={() => setScrmidLoader(false)} withoutScrmid={() => handleClear()} />}
            <div className={classes["mainFilterContainer"]} >
                {location.pathname.includes("marketplace") &&
                    <div className={indexStyles.dFlex}>
                        <div style={{ width: '200px', paddingRight: '10px' }}>
                            <Label className={classes.userLabel}>sCRM ID </Label>
                            <FilterField
                                value={scrmID}
                                onChange={(e) => {
                                    handleSCRMIDChange({
                                        text: e.target.value.trim(),
                                        debounce: false,
                                    });
                                }}
                                variant="outlined"
                                textInputProps={{
                                    placeholder: "sCRM ID",
                                    showClearButton: true,
                                    clearButtonProps: {
                                        onClick: handleClear,
                                    },
                                    inputProps: {
                                        'aria-label': 'Search',
                                        onKeyDown: handleSCRMIDSearch,
                                        ref: inputRef, // Ref applied directly to the input element

                                    },
                                }}
                                renderIcon={<FilterFieldIcon title="click enter to search" />}

                            />
                            {/* <span style={{ color: 'red' }}>{!sCRMRegex.test(scrmID.trim()) &&
                                scrmID.length > 0 &&
                                "Invalid sCRM ID"}</span> */}
                        </div>

                        <div style={{ margin: '21px 13px 0 0' }}>
                            {/* <Button><ButtonIcon><img src={searchIcon} /></ButtonIcon></Button> */}

                            <ToggleButton
                                aria-label="settings"
                                onClick={handleSCRMIDSearch}
                                size="large"
                                disabled={scrmID.trim() === ''}
                            // active={isActive}
                            >
                                <img src={searchIcon} />
                            </ToggleButton>
                        </div>
                    </div>
                }
                <div className={classes.selection}>
                    {marketplaceSelector?.filters?.selection?.partner ? (
                        <Tooltip tooltip={accountData[0]?.name || "Select account"} placement="bottom">
                            <SelectBox
                                labelText={location.pathname.includes("marketplace") ? "Account" : ""}
                                placeholder="Select account"
                                maxWidth={true}
                                caseInsentiveMatching={true}
                                selectedItem={marketplaceSelector?.filters?.selection?.partner}
                                data={marketplaceSelector.partnerList}
                                onUpdateSelection={handlePartnerSelection}
                                isLoading={marketplaceSelector.isPartnerLoading || scrmidLoading}
                                disabled={scrmID != "" ? true : false}
                            />
                        </Tooltip>
                    ) : (
                        <SelectBox
                            labelText={location.pathname.includes("marketplace") ? "Account" : ""}
                            placeholder="Select account"
                            maxWidth={true}
                            caseInsentiveMatching={true}
                            selectedItem={marketplaceSelector?.filters?.selection?.partner}
                            data={marketplaceSelector.partnerList}
                            onUpdateSelection={handlePartnerSelection}
                            disabled={scrmID != "" ? true : false}
                            isLoading={marketplaceSelector.isPartnerLoading || scrmidLoading}
                        />
                    )}
                    {marketplaceSelector.isPartnerLoading && (
                        <div className={classes.optionLoader}>
                            <CircularProgressIndicatorIndeterminate
                                strokeWidth={6}
                                role="alert"
                            />
                        </div>
                    )}
                </div>

                <div className={classes.selection}>
                    {marketplaceSelector?.filters?.selection?.customer ? (
                        <Tooltip tooltip={accountData[0]?.end_customer_name || "Select customer"} placement="bottom">
                            <SelectBox
                                labelText={location.pathname.includes("marketplace") ? "Customer" : ""}
                                placeholder="Select customer"
                                maxWidth={true}
                                caseInsentiveMatching={true}
                                selectedItem={marketplaceSelector?.filters?.selection?.customer}
                                data={marketplaceSelector.customerList}
                                onUpdateSelection={(e) => handleCustomerSelection(e)}
                                disabled={scrmID != "" ? true : false}
                                isLoading={marketplaceSelector.isCustomerLoading || scrmidLoading}
                            />
                        </Tooltip>
                    ) : (
                        <SelectBox
                            labelText={location.pathname.includes("marketplace") ? "Customer" : ""}
                            placeholder="Select customer"
                            maxWidth={true}
                            caseInsentiveMatching={true}
                            selectedItem={marketplaceSelector?.filters?.selection?.customer}
                            data={marketplaceSelector.customerList}
                            onUpdateSelection={(e) => handleCustomerSelection(e)}
                            disabled={scrmID != "" ? true : false}
                            isLoading={marketplaceSelector.isCustomerLoading || scrmidLoading}
                        />
                    )}
                    {marketplaceSelector.isCustomerLoading && (
                        <div className={`${classes.optionLoader}`}>
                            <CircularProgressIndicatorIndeterminate
                                strokeWidth={6}
                                role="alert"
                            />
                        </div>
                    )}
                </div>

                {(location.pathname.includes("marketplace") && marketplaceSelector.showShipTo) && <div className={classes.selection}>
                    <SelectBox
                        // labelText="(Indirect) End customer"
                        labelText={"Ship to"}
                        placeholder="Select Ship to"
                        maxWidth={true}
                        caseInsentiveMatching={true}
                        selectedItem={marketplaceSelector?.filters?.selection?.shipTo}
                        data={shipToValues}
                        onUpdateSelection={(e) => handleShipToSelection(e)}
                        isLoading={false}
                        // disabled={scrmID != "" ? true : false}
                    />
                </div>}

                {location.pathname.includes("marketplace") && <div className={classes.selection}>
                    {marketplaceSelector?.filters?.selection?.shippingCountry ? (
                        <Tooltip tooltip={marketplaceSelector.shippingCountryText || "Select shipping country"} placement="bottom">
                            <SelectBox
                                labelText="Shipping country"
                                placeholder="Select shipping country"
                                maxWidth={true}
                                caseInsentiveMatching={true}
                                selectedItem={marketplaceSelector?.filters?.selection?.shippingCountry}
                                data={marketplaceSelector.countryList}
                                onUpdateSelection={handleCountrySelection}
                                isLoading={marketplaceSelector.isCountryLoading}
                                disabled={scrmID != "" && scrmidLoading ? true : false}
                            />
                        </Tooltip>
                    ) : (
                        <SelectBox
                            labelText="Shipping country"
                            placeholder="Select shipping country"
                            maxWidth={true}
                            caseInsentiveMatching={true}
                            selectedItem={marketplaceSelector?.filters?.selection?.shippingCountry}
                            data={marketplaceSelector.countryList}
                            onUpdateSelection={handleCountrySelection}
                            isLoading={marketplaceSelector.isCountryLoading}
                            disabled={scrmID != "" && scrmidLoading ? true : false}
                        />
                    )}
                    {marketplaceSelector.isCountryLoading && (
                        <div className={classes.optionLoader}>
                            <CircularProgressIndicatorIndeterminate
                                strokeWidth={6}
                                role="alert"
                            />
                        </div>
                    )}
                </div>}
                {location.pathname.includes("marketplace") && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '.5rem', marginTop: '23px' }}>
                    <Tooltip
                        closeOnReferenceHidden={false}
                        placement="bottom"
                        // fallbackPlacements={['right', 'left']}
                        modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}
                        trigger="hover"
                        tooltip={"Changing the country/region you shop from may affect factors including price, shipping options and product availability in DAC Marketplace."}
                    >
                        <img src={infoCircleIcon} />
                    </Tooltip>
                </div>}

                {/* {location.pathname.includes("marketplace") && <div className={classes.selection}>
                    {marketplaceSelector?.filters?.selection?.targetSalesMode ? (
                        <Tooltip tooltip={selectedToolTipText || "Select target sales mode"} placement="bottom"> <SelectBox
                            // labelText="(Indirect) End customer"
                            labelText={"Target sales mode"}
                            placeholder="Select target sales mode"
                            maxWidth={true}
                            caseInsentiveMatching={true}
                            selectedItem={marketplaceSelector?.filters?.selection?.targetSalesMode}
                            data={targetSalesModeValues}
                            onUpdateSelection={(e) => handleShipToSelection(e)}
                            isLoading={false}
                        />
                        </Tooltip>
                    ) : (<SelectBox
                        // labelText="(Indirect) End customer"
                        labelText={"Target sales mode"}
                        placeholder="Select target sales mode"
                        maxWidth={true}
                        caseInsentiveMatching={true}
                        selectedItem={marketplaceSelector?.filters?.selection?.targetSalesMode}
                        data={targetSalesModeValues}
                        onUpdateSelection={(e) => handleTargetModeSelection(e)}
                        isLoading={false}
                    />)}
                </div>} */}


                {location.pathname.includes("marketplace") && <div className={classes.selection} style={{ justifyContent: 'center', marginTop: '21px' }}>
                    <Button variant="call-to-action" disabled={checkBtnDisable()} onClick={handleProceed}>
                        <ButtonText>Proceed</ButtonText>
                    </Button>
                </div>}
            </div>
        </>

    )

}

export default CommonFilter;