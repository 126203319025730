//@ts-nocheck
import React, { useState } from 'react';
import { ResponsiveContainer } from 'recharts';
import { CartesianGrid, XAxis, YAxis, Tooltip, Legend, Label, Bar } from 'recharts';
import PropTypes from "prop-types";
import BarChart from '@nokia-csf-uxr/ccht/BarChart';
import ChartHeader, { ChartTitle, ChartSubtitle } from '@nokia-csf-uxr/ccht/ChartHeader';
import RechartsContainer from '@nokia-csf-uxr/ccht/RechartsContainer';
import { BarChartTickRenderer, TickRenderer, CombinationChartTickRenderer } from '@nokia-csf-uxr/ccht/ChartComponents';
import { getContext } from '@nokia-csf-uxr/ccht/common/index.js';
import { getAdvancedThemeContext, getComponentAdvancedTheme } from '@nokia-csf-uxr/ccht/AdvancedTheme';

import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import Dialog, { DialogContent } from '@nokia-csf-uxr/ccfk/Dialog';

import ArrowLineExpandIcon from '@nokia-csf-uxr/ccfk-assets/legacy/ArrowLineExpandIcon';
import CloseIcon from '@nokia-csf-uxr/ccfk-assets/legacy/CloseIcon';
import App from "@nokia-csf-uxr/ccht/App";
import AdvancedTheme from "@nokia-csf-uxr/ccht/AdvancedTheme";
import classes from '../Style/Merchant.module.css';
import { TooltipRenderer } from '@nokia-csf-uxr/ccht/ChartComponents';


import {
  CHART_MARGIN,
  VALUE_AND_NAME_FORMATTER,
  xTickFormatter,
  titleCase,
  HORIZONTAL_BAR,
  DEFAULT_Y_TICK_FORMATTER
} from './CommonFuntions';
import ColumnChart from '@nokia-csf-uxr/ccht/ColumnChart';
import { formatNumberWithCommas } from 'Pages/marketplace/Utils/commons';


const ChartDemo = (props) => {
  const {
    stack,
    title,
    subtitle,
    onClick,
    xLabel,
    yLabel,
    ariaLabel,
    renderIcon,
    resolvedTheme,
    data,
    ...otherProps
  } = props;
  const { RTL } = getContext((context) => context);

 
  const formatMarketValue = (value) => {
    let v = value;
    const suffixes = ['K', 'M', 'B']; // Suffixes for Thousand, Million, Billion
    let count = -1;

    // Loop until value is less than 1000
    while (v >= 1000 && count < suffixes.length - 1) {
      v = v / 1000;
      count++;
    }

    // Round the value to the nearest whole number
    const roundedValue = Math.round(v);

    // Format the result with the appropriate suffix
    const result = count >= 0 ? `${roundedValue}${suffixes[count]}` : `${roundedValue}`;

    return result;
  };

  return (
    <RechartsContainer style={{ height: '600px' }}>
      <ResponsiveContainer>
        <ColumnChart margin={CHART_MARGIN} data={data} {...otherProps}>
          {/* Grid for better visualization */}
          <CartesianGrid strokeDasharray="3 3" />

          {/* X-Axis Configuration */}
          <XAxis
            dataKey="year"
            height={100}
            reversed={RTL}
            interval={0} // Ensure all labels are displayed
            tickLine={false} // Hide the default tick line for cleaner visuals
            tick={({ x, y, payload, width, props }) => {
              const label = payload.value; // Extract the label text
              const MAX_WIDTH = 100; // Adjust this value based on bar width
              let lines = label.split(' '); // Split by spaces to manage long words
              const lineHeight = 10; // Adjust line height based on font size
              const SPACING = lines[0] != ""  ? 10 : 0
         

              return (
                <g transform={`translate(${x},${y + SPACING})`}>
                  {lines.map((line, index) => (
                    <text
                      key={index}
                      x={0}
                      y={index * lineHeight}
                      fontSize={12}
                      textAnchor="middle"
                      fill="#333"
                    >
                      {line.length > MAX_WIDTH
                        ? `${line.slice(0, MAX_WIDTH)}...` // Truncate if too wide
                        : line}
                    </text>
                  ))}
                </g>
              );
            }}
          >
            <Label value={xLabel} offset={-20} position="bottom" />
          </XAxis>

          {/* Left Y-Axis (Product Count) */}
          <YAxis
            tickLine={false}
            yAxisId="Product"
            tick={<TickRenderer ltl={16} axis="vertical" formatter={(value) => `${formatMarketValue(value)}`} />}
            orientation={RTL ? 'right' : 'left'}
            tickSize={20}
          >
            <Label
              offset={RTL ? -5 : 5}
              value="No. of Products"
              angle={-90}
              position={RTL ? "insideRight" : "insideLeft"}
              textAnchor="middle"
            />
          </YAxis>

          {/* Right Y-Axis (Market Revenue converted to Millions) */}
          <YAxis
            tickLine={false}
            yAxisId="Market"
            orientation={RTL ? 'left' : 'right'}

            tick={<CombinationChartTickRenderer rtl={RTL} axis="vertical" formatter={(value) => `${formatMarketValue(value)}`} />}
          >
            <Label
              offset={RTL ? 0 : 0}
              value="Revenue (Euro)"
              angle={-90}
              position={RTL ? "insideLeft" : "insideRight"}
              textAnchor="middle"
            />
          </YAxis>

          {/* Custom Tooltip - Showing Market Value in Millions */}
          <Tooltip
            cursor={{ fill: '#e6f9ff' }}
            labelFormatter={titleCase}
            content={({ payload }) => (
              payload?.length ? (
                <div style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                  <p>Market: EUR {formatNumberWithCommas(payload[0].payload.Market)}</p>
                  <p>Product: {payload[0].payload.Product}</p>
                </div>
              ) : null
            )}
          />

          {/* Legend */}
          <Legend align={RTL ? "right" : "left"} iconType="circle" verticalAlign="top" height={36} />

          {/* Single Bar - Displaying Product Count */}
          <Bar
            yAxisId="Product"
            dataKey="Product"
            fill="#00C9FF"
            shape={HORIZONTAL_BAR}
            name="Number of products"
          />

          {/* Additional Bar - Displaying Market Revenue */}
          <Bar
            yAxisId="Market"
            dataKey="Market"
            fill="#9765FE"
            name="Revenue"
          />
        </ColumnChart>
      </ResponsiveContainer>
    </RechartsContainer>
  );
}

const BarCharts = (props) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { advancedTheme, inlineGlobalVariables } = getAdvancedThemeContext((arg) => arg);
  const resolvedTheme = getComponentAdvancedTheme(advancedTheme, 'ColumnChart', 'default');

  return (
    <>
      <AdvancedTheme advancedTheme="CCHT Legacy - Light">
        <div className={classes.barchart}>
          <App>
            <ChartDemo
              resolvedTheme={resolvedTheme}
              onClick={() => setDialogOpen(!isDialogOpen)}
              renderIcon={<ArrowLineExpandIcon />}
              ariaLabel="ArrowLineExpandIcon"
              {...props}
            />
            <Dialog
              isOpen={isDialogOpen}

            >
              <DialogContent style={{ paddingTop: `1.5rem`, overflow: 'hidden', paddingBottom: `1.5rem` }}>
                <ChartDemo
                  resolvedTheme={resolvedTheme}
                  onClick={() => setDialogOpen(!isDialogOpen)}
                  ariaLabel="CloseIcon"
                  renderIcon={<CloseIcon />}
                  {...props}
                />
              </DialogContent>
            </Dialog>
          </App>
        </div>
      </AdvancedTheme>
    </>
  );
};

export default BarCharts;

ChartDemo.propTypes = {
  stack: PropTypes.bool, // stack is a boolean
  title: PropTypes.string.isRequired, // title is required
  subtitle: PropTypes.string, // subtitle is optional
  onClick: PropTypes.func, // function callback for click events
  xLabel: PropTypes.string, // label for the X-axis
  yLabel: PropTypes.string, // label for the Y-axis
  ariaLabel: PropTypes.string, // accessibility label
  renderIcon: PropTypes.node, // React node for rendering icons
  resolvedTheme: PropTypes.shape({
    barColors: PropTypes.arrayOf(
      PropTypes.shape({
        fill: PropTypes.string.isRequired, // barColors[].fill is required
      })
    ).isRequired,
  }).isRequired, // resolvedTheme is required
  titleFormatter: PropTypes.string,
  valueFormatter: PropTypes.string,
  data: PropTypes.any

};

