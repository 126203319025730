import React, { useState } from 'react';
import Card from '@nokia-csf-uxr/ccfk/Card';
import TabsSection from '../marketplace/common/TabsSection';
import ProductList from './ProductList';
import Chip, { ChipLabel } from '@nokia-csf-uxr/ccfk/Chip';
import { RootState } from "Store/mainStore";
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from "xlsx";
import useImageRender from 'Pages/marketplace/hooks/useImageRender';
import productDefaultImage from '../../Assets/Icons/product-image-placeholder.svg';

const MerchantProductsBody = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const merchantSelector = useSelector((state: RootState) => state.merchant);

  const customTabData = [
    { tab: { children: 'All Products', label: 'Tab One description' } },
    // { tab: { children: 'Draft', label: 'Tab Two description' } },
    // { tab: { children: 'Pending approval', label: 'Tab Three description' } }
  ];


  const ProductImageRenderer = (prop) => {
     const [productImageSrc, handleImageError] = useImageRender(
      prop.productImage, prop.backupImage, prop.defaultImage); 
      return (
      <div>
        <img src={productImageSrc} onError={handleImageError} style={{ height: '50px', objectFit: 'contain', maxWidth: '50%' }} alt="Product" />
        </div>
        ); 
  };

  const columnDefsList = [
    {
      headerName: '',
      field: 'product_image',
      minWidth: 115,
      cellRenderer: (params) => {
        return (
          <ProductImageRenderer productImage={params.data?.product_image} backupImage={params.data?.image_url} defaultImage={productDefaultImage}
          />
        );



      }

    },
    { headerName: 'Product ID', field: 'product_id', minWidth: 240 },
    { headerName: 'Product Description', field: 'product_description_id', minWidth: 240, flex: 2 },
    { headerName: 'Product sub-type', field: 'product_type_id', minWidth: 240, flex: 2 },
    { headerName: 'Status', field: 'product_lifecycle_status', minWidth: 240, flex: 2 },
    { headerName: 'Category', field: 'product_category_id', minWidth: 240, flex: 2 }
  ]

  let tabContentComponents = [
    <ProductList key={0} type="allproducts" isActive={activeTabIndex === 0} index={activeTabIndex} columnDefsList={columnDefsList} />,
    // <ProductList key={1} type="draftproducts" isActive={activeTabIndex === 1} index={activeTabIndex} columnDefsList={columnDefsList} />,
    // <ProductList key={2} type="pendingproducts" isActive={activeTabIndex === 2} index={activeTabIndex} columnDefsList={columnDefsList} />
  ];


  const getActiveSubscription = (subscriptionData) => {
    const activeSubscription = subscriptionData.filter(
      (row) => row
    );
    return activeSubscription;
  };

  const prepareExcelExportData = () => {
    let eeFileNmae = customTabData[activeTabIndex].tab.children ?? "Export";
    eeFileNmae += `_${Date.now()}`;
    let selectedFields = columnDefsList.map((row) => row.field);
    const filteredColDef = columnDefsList.filter((row) =>
      selectedFields.includes(row.field)
    );

    const xlsxExportData = getActiveSubscription(merchantSelector.products).map((row) => {
      const exportRow = {};
      filteredColDef.forEach(
        (selecteRow) => (selecteRow.headerName ? exportRow[selecteRow.headerName] = row[selecteRow.field] : "")
      );
      return exportRow;
    });
    const ws = XLSX.utils.json_to_sheet(xlsxExportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, `${eeFileNmae}.xlsx`);
  };



  const handleTabSelect = (index) => {
    tabContentComponents = []
    setActiveTabIndex(index);
  };

  const handleTabExportFile = () => {
    prepareExcelExportData()
  }


  return (
    <>
      <Card style={{ padding: '1rem' }}>
        <TabsSection tabData={customTabData} tabContentComponents={tabContentComponents}  //@ts-ignore
          onTabChange={handleTabSelect} pageTabName={"merchant"} onTabChangeExportFile={handleTabExportFile} />
      </Card>

    </>
  );
};

export default MerchantProductsBody;