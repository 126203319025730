import { useState, useEffect, useCallback, useRef } from 'react';
import OOT from 'Constants/services/oot';
import { setUsers, setUsersLoading } from "Store/contentState/userManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import {getDomainFromEmail } from "../user-management/utils/constants";
import exp from 'constants';

const useFetchUsers = () => {
    const dispatch = useDispatch();
    const paginationSelector = useSelector((state: RootState) => state.pagination);
    const userManagementSelector = useSelector((state: RootState) => state.userManagement);
    const globalSearchText = useSelector((state: RootState) => state.common.globalSearchText);
    const { limitPerpage, offset } = paginationSelector.paginationProps;
    const hasFetchedData = useRef(false);

    const prepareFilterConditions = () => {
        const filterConditions = [];

        // Global search text filter
        if (globalSearchText) {
            filterConditions.push(`(users.name LIKE '%${globalSearchText}%' OR users.email LIKE '%${globalSearchText}%')`);
        }

        // Role name filter
        const selectedRole = userManagementSelector.userFilters.role; // Assume this is a string like 'owner'

        if (selectedRole) {
            // Generate the SQL query to check for the selected role in the JSON column
            // const roleCondition = `JSON_EXTRACT(user_roles.role, '$.roles.${selectedRole}') IS NOT NULL`;
            const roleCondition = `JSON_CONTAINS(user_roles.role, '"${selectedRole}"', '$.roles')`
            filterConditions.push(roleCondition);
        }


        // Combine all conditions into a single query
        return filterConditions.length > 0 ? filterConditions.join(' AND ') : '1=1';
    };


    const getUsersList = async (limitPerpage, offset, type) => {
        dispatch(setUsersLoading(true));
        const filterConditions = prepareFilterConditions();
        try {
            const response = await OOT.getAllUsers(`${filterConditions} order by users.email, users.type desc`, limitPerpage, offset, type);
            if (response.status) {
                const updatedUserList = response.data.map((user) => {
                    const role = user.role || {};
                    return {
                        id: user.id,
                        name: user.name || '',
                        email: user.email || '',
                        status: user.active === 1 ? 'Active' : 'Inactive',
                        userTypeInList: role.persona,
                        userType: role.persona || [],
                        // viewAccess: role.view || [],
                        userRoles: role.roles || [],
                        // dataElement: role['data-element'] || {},
                        oneStarNumber: role.one_star || [],
                        threeStarNumber: role.three_star || [],
                        whitelisted_ip: role.whitelisted_ip || {},
                        markets: role.markets || [],
                        business_name: role.business_name || [],
                        nsa_authorized: role.nsa_authorized || false,
                        approver: role.approver || false,
                        //oot: type === "user" ? Object.keys(role.oot).length != 0 ? role.oot : { roles: [], oneStarNum: [], approver_role_group: [] } : { roles: [], oneStarNum: [], approver_role_group: []},
                        oot: type === "user"
                            ? Object.keys(role?.oot ?? {}).length !== 0
                                ? role.oot
                                : { roles: [], oneStarNum: [], approver_role_group: [] }
                            : { roles: [], oneStarNum: [], approver_role_group: [] },
                        supplier_account_id: role.supplier_account_id || [],
                        last_login_time: user.last_login_time || "",
                        mailDomain: user.email ? getDomainFromEmail(user.email.toLowerCase()) : ""
                    };
                })
                // console.log('updatedUserList:', updatedUserList);
                dispatch(setUsers(updatedUserList));
                //dispatch(setProp({ slice: 'userManagement', key: 'mailDomain', value: emailDomain }));
                hasFetchedData.current = true;
                dispatch(setUsersLoading(false));
            } else {
                dispatch(setUsersLoading(false));
                console.log("No users available")
            }
        } catch (error) {
            dispatch(setUsersLoading(false));
            console.error(error)
        }
    }

    return { getUsersList }

}

export default useFetchUsers;
