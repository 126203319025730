import React, { useState, useEffect } from "react";
import classes from '../marketplace/Style/MarketplaceFilter.module.css';
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import Button, {
    ButtonText,
    ButtonIcon,
} from '@nokia-csf-uxr/ccfk/Button';
import _ from "lodash";

import SearchBox from "Pages/marketplace/common/SearchBox";
import SelectBox from "Pages/subscription/Custom/SelectBox";
import userStyles from "../admin/Style/UserManagement.module.css";
import { setUserFilters } from "Store/contentState/userManagementSlice";
//import SplitButton
import { ListItemBasic, ListItemText, ListItemIcon } from "@nokia-csf-uxr/ccfk/List";
import {ReactComponent as AddIcon} from '@nokia-csf-uxr/ccfk-assets/icons/latest/add.svg';
import { setProp } from "Store/Actions";
import UserDialogModal from "./user-management/UserDialogModal";
import { modifyData } from "./user-management/utils/constants";
import useFetchRoles from "./hooks/useFetchRoles";



const AdminFilter = (prop) => {
    const userManagementSelector = useSelector((state: RootState) => state.userManagement);
    const showAddUserDialog = userManagementSelector.showUserModal;
    const rolesList = userManagementSelector.roleInfo.rolesData;
    const dispatch = useDispatch();
    //const [addUserModal, setAddUserModal] = useState(false);

    const {fetchUserRoles} = useFetchRoles();

    const handleRoleSelection = (selectedRole) => {
        if (selectedRole !== "") {
            dispatch(setUserFilters({ filterName: "role", filterValue: selectedRole }));
        } else {
            dispatch(setUserFilters({ filterName: "role", filterValue: '' }));
        }
    }

    useEffect(() => {
        // Call fetchUserRoles to get roles when the component mounts
        if(prop.data.isActive){
            fetchUserRoles("");
        }  
    }, []);

    const modifiedRoleData = modifyData(rolesList, 'id', 'name');

    return (
        <div className={userStyles.userFilterContainer}>
            {prop.data.isActive && showAddUserDialog && (
                <UserDialogModal onClose={() => dispatch(setProp({ slice: "userManagement", key: "showUserModal", value: false }))} mode="add" selectedUserData="" typeOfUser="" />
            )}
            <div className={userStyles.gridcontainersearch}>
                <div className={userStyles.searchcontainer}>
                    <div className={userStyles.searchcontainer}>
                        <SearchBox placeholder="Enter user name or email address" showSearchButton={true} />
 
                    </div>
                    <div className={userStyles.divider}></div>
                    {prop.data.type !== 'whitelisted_client' &&
                        <SelectBox
                            // disabled={marketplaceSelector.selectedDeviceID !== '' ? true : false }
                            placeholder="Role name"
                            maxWidth={true}
                            caseInsentiveMatching={true}
                            selectedItem={userManagementSelector.userFilters.role}
                            data={modifiedRoleData}
                            onUpdateSelection={handleRoleSelection}
                            isLoading={false}
                        />}
                </div>
            </div>
        </div>
    )
}

export default AdminFilter;