import React,{useEffect} from "react";
import { App } from "@nokia-csf-uxr/ccfk";
import { Body } from "Components/Body";
import Header from "Components/Header";
import LoadingOverlay from "react-loading-overlay-ts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import MerchantProductsBody from "./MerchantProductsBody";
import OOT from "Constants/services/oot";
import { setMerchantList, setSelectMerchantAccount, setSelectMerchant } from "Store/contentState/merchantSlice";


const crumItems = [{ title: 'Products', path: '/products' }]


const MerchantProducts = () => {
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const persona = useSelector((state: RootState) => state.marketplace.persona);

  const dispatch = useDispatch();

  useEffect(() => {
    if (persona === "nokia") {
      let filter = "is_merchant = 1";
      dispatch(setSelectMerchant(""));
      getMerchantList(filter, 10, 0, "merchant")
    }
  }, [])

  const getMerchantList = async (filter, limit, offset, requested_info) => {
    try {
      const responseMerchant = await OOT.getAccountDetailsMerchant(filter, 10, 0, [], 'merchant');
      let merchantListData = responseMerchant['data'].map(({ account_id, name }) => ({ id: account_id, value: name }))
      dispatch(setSelectMerchantAccount(merchantListData[0]?.id));
      dispatch(setMerchantList(merchantListData));
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <LoadingOverlay
        active={authSelector.system.isLoading}
        spinner
        classNamePrefix="MyLoader_"
        text="Please wait "
      >
        <App>
          <Header />
          <Body
            crumbs={[
              "Merchant Products",
            ]}
            bodyContent={<MerchantProductsBody />}
            items={crumItems}
          />
        </App>
        {/* <AppFooter /> */}
      </LoadingOverlay>
    </>
  )
}

export default MerchantProducts;