import React, {useEffect} from "react";
import { App } from "@nokia-csf-uxr/ccfk";
import { Body } from "Components/Body";
import Header from "Components/Header";
import LoadingOverlay from "react-loading-overlay-ts";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import OrdersBody from "./OrdersBody";
import { useMarketplaceFilter } from "../../marketplace/hooks/useMarketplaceFilter";
import { setPartnerList as setMarketplacePartnerList } from "Store/contentState/marketplaceSlice";
import {
  setLoader,
} from "Store/contentState/subscriptionSlice";
import OOT from "Constants/services/oot";


const crumItems = [{ title: 'Orders', path: '' }]


const Orders = () => {
   const dispatch = useDispatch();
    const authSelector = useSelector((state: RootState) => state.authSlice);
    const marketplacePartnerList = useSelector((state: RootState) => state.marketplace.partnerList);
    const { fetchMarketplaceData } = useMarketplaceFilter();

    useEffect(() => {
      if (marketplacePartnerList.length === 0) {
        fetchMarketplacePartnerList();
      }
    }, [])
  
    const fetchMarketplacePartnerList = async () => {
      dispatch(
        setLoader({
          key: "isPartnerLoading",
          value: true,
        })
      );
      try {
        const response = await OOT.getSubscriptionPartnerData(true);
        const __partnerList = response.data.partnerList
          ? response.data.partnerList
          : [];
        dispatch(
          setLoader({
            key: "isPartnerLoading",
            value: false,
          })
        );
        dispatch(
          setMarketplacePartnerList({
            partnerList: __partnerList,
          })
        );
        if (__partnerList.length === 1) {
          fetchMarketplaceData("fetch", __partnerList[0].id, __partnerList);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        dispatch(
          setLoader({
            key: "isPartnerLoading",
            value: false,
          })
        );
      }
    }
    
   return(
    <>
    <LoadingOverlay
        active={authSelector.system.isLoading}
        spinner
        classNamePrefix="MyLoader_"
        text="Please wait "
      >
        <App>
          <Header />
          <Body
            crumbs={[
              "Orders",
            ]}
            bodyContent={<OrdersBody />}
            items={crumItems}
          />          
        </App>
        {/* <AppFooter /> */}
      </LoadingOverlay>
    </>
   )
}

export default Orders;