import React from "react";
import { Button, TextInput } from "@nokia-csf-uxr/ccfk";
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import { setProp } from "Store/Actions";

const DialogActions = () => {
  const dispatch = useDispatch();
    return (
        <>
            <Button onClick={() => dispatch(setProp({ slice: "radioNetworkCIQ", key: "createdUpdateStatusModal", value: false }))}>Cancel</Button>
            {/* <Button variant="call-to-action" disabled={true}>
                Next
            </Button> */}
        </>
    )
}

export default DialogActions