import React, { useEffect, useState } from "react";
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import infoCircleIcon from '../../../Assets/Icons/info-circle-small-fill-white.svg';
import { Typography } from "@nokia-csf-uxr/ccfk";
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import Button from '@nokia-csf-uxr/ccfk/Button';
import { ReactComponent as DiscountIcon } from '../../../Assets/Icons/discount_icon.svg'
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import useCartActions from "../hooks/useCartActions";

const CartDiscountsActions = () => {
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace);

    const disableApplyDiscounts = () => {
        if(marketplaceSelector.isNokiaUser){
          return (!marketplaceSelector.isCartChanged || marketplaceSelector?.agreementDetails?.isAgreementValid === false)
        }else{
          return (!marketplaceSelector.isCartChanged)
        }
      }

    const conditionalStyle = {
        backgroundColor: !disableApplyDiscounts() ? "#13902F" : "#f3f3f3", // Replace 'defaultColor' with your default background color
        color: !disableApplyDiscounts() ? "#FFFFFFBF" : "#999999" // Replace 'defaultTextColor' with your default text color
    };

    const { syncOfferCall } = useCartActions();


    const handleApplyDiscounts = () => {
        syncOfferCall();
    }


    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '100%' }}>
                <section style={{ display: 'flex', justifyContent: "flex-end", alignItems: 'center', gap: '.5rem', marginTop: '20px' }}>
                    <div>
                        <Button
                            variant="call-to-action"
                            //disabled={!marketplaceSelector.isCartChanged || marketplaceSelector?.agreementDetails?.isAgreementValid === false}
                            //disabled = {isButtonDisabled}
                            disabled={disableApplyDiscounts()}    
                            style={conditionalStyle}
                        >
                            <ButtonIcon><DiscountIcon /></ButtonIcon>
                            <ButtonText style={{ fontSize: '12px' }} onClick={handleApplyDiscounts}>
                                Apply discounts
                            </ButtonText>
                        </Button>
                    </div>
                </section>

                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                    <Tooltip
                        closeOnReferenceHidden={false}
                        placement="bottom"
                        modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}
                        trigger="hover"
                        tooltip={""}
                    >
                        <img src={infoCircleIcon} />
                    </Tooltip>
                    <Typography style={{ margin: '3px 0 0 3px' }}>
                        Discount will not be auto applied, click Apply discount to avail discounted price
                    </Typography>
                </div>
            </div>


            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                <Typography style={{color:'#C91514'}}>Agreement data is invalid</Typography>
            </div> */}

        </>
    )

}

export default CartDiscountsActions;