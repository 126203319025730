import React, { useRef, useState, useEffect } from "react";
import FilterField, { FilterFieldIcon } from "@nokia-csf-uxr/ccfk/FilterField";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "Store/contentState/marketplaceSlice";
import { RootState } from "Store/mainStore";
import PropTypes from "prop-types";
import { setGlobalSearchText, clearGlobalSearchText } from "Store/contentState/commonSlice";
import { setCurrentPage } from "Store/contentState/paginationSlice";
import Button, {
    ButtonText,
    ButtonIcon,
} from '@nokia-csf-uxr/ccfk/Button';
import searchIcon from '../../../Assets/Icons/magnify-icon.svg'
import styles from "../../marketplace/Style/Index.module.css";

const SearchBox = ({ placeholder = 'Search...', showSearchButton = false }) => {
    const dispatch = useDispatch();
    const searchTextFromStore = useSelector((state: RootState) => state.common.globalSearchText);
    const [searchValue, setSearchValue] = useState(searchTextFromStore || '');
    const inputRef = useRef(null);


    // Sync local state with Redux state
    useEffect(() => {
        setSearchValue(searchTextFromStore);
    }, [searchTextFromStore]);

    // const handleSearch = (e) => {
    //     console.log(searchValue)
    //     if (e.key === 'Enter' || e.keyCode === 13 || e.type === 'click') {
    //         dispatch(setCurrentPage(1));
    //         dispatch(setGlobalSearchText(e.target.value));
    //     }
    // };

    const handleSearch = (e) => {
        const inputValue = e.target?.value || searchValue; // Use input value if available, otherwise fallback to state
        if ((e.key === 'Enter' || e.keyCode === 13 || e.type === 'click') && inputValue.trim() !== '') {
            dispatch(setCurrentPage(1));
            dispatch(setGlobalSearchText(inputValue));
        }
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        if (value === '') {
            dispatch(setGlobalSearchText(value));
        }
    };

    const handleClear = () => {
        setSearchValue('');
        dispatch(clearGlobalSearchText());
    };


    return (
        <>
            <FilterField
                value={searchValue}
                onChange={handleChange}
                variant="outlined"
                textInputProps={{
                    placeholder: placeholder,
                    showClearButton: true,
                    clearButtonProps: {
                        onClick: handleClear,
                    },
                    inputProps: {
                        'aria-label': 'Search',
                        // width: '15%',
                        onKeyDown: handleSearch,
                        ref: inputRef, // Ref applied directly to the input element

                    },
                }}
                renderIcon={<FilterFieldIcon title="click enter to search" />}

            />

            {showSearchButton && (
                <div>
                    <Button
                        variant="call-to-action"
                        onClick={handleSearch} // No need to pass event explicitly
                        disabled={searchValue.trim() === ''}
                    >
                        <ButtonText>Search</ButtonText>
                    </Button>
                </div>
            )}
            </>
    );
};

export default SearchBox;

SearchBox.propTypes = {
    placeholder: PropTypes.string,
    showSearchButton: PropTypes.bool,
};
