
export const generateUserPayload = ( userSliceData, mode, typeOfUser, selectedUserData, isEnableUser = false) => {
    let payload = {};
    if (mode === 'add') {
        payload = {
            "id": userSliceData['id'],
            "fullName": userSliceData['fullName'],
            "email": userSliceData['emailAddress'],
            "userType": typeOfUser === 'user' ? userSliceData['orgType'] : ['nokia'],
            "type": typeOfUser === 'user' ? 'user' : 'machine',
            "oneStarNum": userSliceData['oneStarNumber'] || [],
            "threeStarNum": userSliceData['threeStarNumber'] || [],
            "userRoles": userSliceData['roles'],
            "markets": userSliceData['markets'],
            "business_name": userSliceData['business_name'],
            "supplier_account_id": userSliceData['supplier_account_id'],
            "whitelisted_ip": userSliceData['whitelisted_ip'],
            "nsa_authorized": userSliceData['nsa_authorized'],
            "approver": userSliceData['approver'],
            "oot": userSliceData['oot']
        }
    }else {
        payload = {
            "id": selectedUserData['id'],
            "email": selectedUserData['email'],
            "fullName": selectedUserData['name'] !== "" ? selectedUserData['name'] : userSliceData['fullName'],
            "oneStarNum": !isEnableUser ? userSliceData['oneStarNumber'] : selectedUserData['oneStarNumber'],
            "threeStarNum": !isEnableUser ? userSliceData['threeStarNumber'] : selectedUserData['threeStarNumber'],
            "userRoles": !isEnableUser ? userSliceData['roles'] : selectedUserData['userRoles'],
            "active": !isEnableUser ? (selectedUserData['status'] === 'Active' ? 1 : 0) : 1,
            "role": {
                "roles": !isEnableUser ? userSliceData['roles'] : selectedUserData['userRoles'],
                "markets": !isEnableUser ? userSliceData['markets'] : selectedUserData['markets'],
                "persona": !isEnableUser ? userSliceData['orgType'] : selectedUserData['userType'],
                "one_star": !isEnableUser ? userSliceData['oneStarNumber'] || [] : selectedUserData['oneStarNumber'] || [],
                "three_star": !isEnableUser ? userSliceData['threeStarNumber'] || [] : selectedUserData['threeStarNumber'] || [],
                "business_name": !isEnableUser ? userSliceData['business_name'] : selectedUserData['business_name'],
                "supplier_account_id": !isEnableUser ? userSliceData['supplier_account_id'] : selectedUserData['supplier_account_id'],
                "whitelisted_ip": !isEnableUser ? userSliceData['whitelisted_ip'] : selectedUserData['whitelisted_ip'],
                "nsa_authorized": !isEnableUser ? userSliceData['nsa_authorized'] : selectedUserData['nsa_authorized'],
                "approver": !isEnableUser ? userSliceData['approver'] : selectedUserData['approver'],

            },
            "oot": !isEnableUser ? userSliceData['oot'] : selectedUserData['oot'],
            "type": typeOfUser === 'user' ? 'user' : 'machine',
            "supplier_account_id": !isEnableUser ? userSliceData['supplier_account_id'] : selectedUserData['supplier_account_id'],
            "userType": !isEnableUser ? (typeOfUser === 'user' ? userSliceData['orgType'] : ['nokia']) : (typeOfUser === 'user' ? selectedUserData['userType'] : ['nokia'])
        }
    }
    return payload;
}