import React, { useState } from "react";
import userStyles from "../../Style/UserManagement.module.css";
import { Button, InlineFeedbackMessage, TextInput } from "@nokia-csf-uxr/ccfk";
import Label from '@nokia-csf-uxr/ccfk/Label';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import { setProp } from "Store/Actions";
import { setUserInputFields, updateSelectedPersonas, updateRoles } from "Store/contentState/userManagementSlice";
import { nokiaUserEmailDomains, getDomainFromEmail } from "../utils/constants";
import OOT from "Constants/services/oot";



const CommonEmailField = (prop) => {
  const dispatch = useDispatch();
  const userEmailAddress = useSelector((state: RootState) => state.userManagement.userData.emailAddress);
  const personaList = useSelector((state: RootState) => state.userManagement.metaData.persona);
  const [userErrorMsg, setErrorMsg] = useState("");
  const [errorShow, setErrorShow] = useState(false);

  const handleEmailChange = async (newEmail) => {
    dispatch(setUserInputFields({ field: 'emailAddress', value: newEmail }));

    // Email validation pattern to check complete email structure
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(in|com|[a-zA-Z]{2,3}(?<!co))$/;
    //const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(in|com)$/;
    const isValidEmail = emailPattern.test(newEmail);
    const lowerCaseEmail = newEmail.toLowerCase();
    const emailDomain = getDomainFromEmail(lowerCaseEmail)

    if (!isValidEmail) {
      // Reset state when email is not valid
      dispatch(setProp({ slice: 'userManagement', key: 'isEmailEntered', value: false }));
      dispatch(setProp({ slice: 'userManagement', key: 'isAddingNokiaUser', value: false }));
      dispatch(updateSelectedPersonas([]));
      return;
    }

    // Set the email entered state to true
    dispatch(setProp({ slice: 'userManagement', key: 'isEmailEntered', value: emailPattern.test(newEmail) ? true : false }));

    // Check if the email belongs to the company
    //const isNokiaEmail = lowerCaseEmail.includes("nokia") || newEmail.toLowerCase().includes("nokia-bell-labs") || newEmail.toLowerCase().includes("nokia-sbell");

    const isNokiaEmail = nokiaUserEmailDomains.some(domain => lowerCaseEmail.endsWith(`@${domain}`));

    // const isNokiaEmail = nokiaUserEmailDomains.some(domain => lowerCaseEmail.includes(domain));
    // Update persona and related state based on email type
    if (isNokiaEmail) {
      dispatch(setProp({ slice: 'userManagement', key: 'isAddingNokiaUser', value: true }));
      dispatch(updateSelectedPersonas(["nokia"]));
    } else {
      dispatch(setProp({ slice: 'userManagement', key: 'isAddingNokiaUser', value: false }));
      if (isValidEmail && emailDomain != "") {
        dispatch(setProp({ slice: 'userManagement', key: 'mailDomain', value: emailDomain }));
        console.log("bharath", emailDomain)
        if(emailDomain != "gmail"){
        getDomainName(emailDomain)
        }
      }
      else {
        dispatch(updateSelectedPersonas([]));
      }
    }
    dispatch(setProp({ slice: 'userManagement', key: 'ootRoles', value: [] }));
    dispatch(setProp({ slice: 'userManagement', key: 'approverRole', value: [] }));
    dispatch(setUserInputFields({ field: 'supplier_account_id', value: [] }));
    dispatch(setUserInputFields({ field: 'threeStarNumber', value: [] }));
    dispatch(setUserInputFields({ field: 'oneStarNumber', value: [] }));
    dispatch(setUserInputFields({ field: 'business_name', value: [] }));
  };

  const getDomainName = async (domain) => {
    dispatch(setProp({ slice: 'userManagement', key: 'isMailDomainLoading', value: true }));
    try {
      const filter = `account_domains LIKE '%${domain}%'`
      const response = await OOT.getDomainNameList(filter, 10, 0);
      console.log("response", response)
      if (response.status && response.data.length != 0) {
        let org_type = response.data[0].record_type.replace(/"/g, "").toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
       //let org_type = "Direct Reseller"
        let person = personaList.filter((item) => item.name === org_type)
        if(person.length != 0){
        dispatch(updateSelectedPersonas([person[0].id]));
        setErrorShow(false);
        }
        else{
          setErrorShow(true);
        setErrorMsg("Email address might be wrong or unknown organization type detected, this user cannot be added to our tools");
        }
      }
      else {
        setErrorShow(true);
        setErrorMsg("Email address might be wrong or unknown organization type detected, this user cannot be added to our tools");
      }
      dispatch(setProp({ slice: 'userManagement', key: 'isMailDomainLoading', value: false }));

    } catch (error) {
      console.error('Error fetching data:', error);

    }
  };




  return (
    <>
      <div className={userStyles.gridItem}>
        <div className={`${userStyles.marginBottom}`}>
          {/* <div style={{width:'40%'}}> */}
          <Label className={userStyles.userLabel}>Email address <span style={{ color: '#C91514' }}>*</span></Label>
          <TextInput
            id="email-address"
            disabled={prop.mode === "edit"}
            value={userEmailAddress}
            variant={"outlined"}
            placeholder="Email address"
            onChange={(e) => handleEmailChange(e.target.value)}
            maxWidth={false}
          />
        </div>
      </div>
      {/* </div> */}
      <div style={{ position: 'absolute', bottom: '20px', width: '50%' }}>

        {userErrorMsg && <InlineFeedbackMessage
          variant="error"
          size="medium"
          closeButton
          show={errorShow}
          onClose={() => setErrorShow(false)}
        >
          {userErrorMsg}
        </InlineFeedbackMessage>}
      </div>
    </>
  )
}

export default CommonEmailField;