import React from "react";
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import RadioButton from '@nokia-csf-uxr/ccfk/RadioButton';
import Label from '@nokia-csf-uxr/ccfk/Label';
import { userRadioOptions } from "../utils/constants";
import { setProp } from "Store/Actions";
import userStyles from "../../Style/UserManagement.module.css";
import {clearUserModel } from "../../../../Store/contentState/userManagementSlice"

const UserTypeSelection = () => {
    const dispatch = useDispatch();
    const selectedUserType = useSelector((state: RootState) => state.userManagement.selectedUserType);

    const handleSelectedUserTypeChange = (value) => {
        const selectedValue = value;
        dispatch(setProp({ 'slice': 'userManagement', 'key': 'selectedUserType', 'value': selectedValue }));
        dispatch(clearUserModel());
    }

    return (
        <div className={userStyles.marginBottom}>
            <Label className={userStyles.userLabel}>Add user as</Label>
            <div className={userStyles.dFlex}>
                {userRadioOptions.map((option) => (
                    <div key={option.value} className={userStyles.dFlex5}>
                        <RadioButton
                            checked={selectedUserType === option.value}
                            onChange={(e) => handleSelectedUserTypeChange(e.target.value)}
                            inputProps={{
                                'aria-label': option.label,
                                value: option.value,
                                name: 'addUserRadio'
                            }}
                        />
                        <Label>{option.label}</Label>
                    </div>
                ))}
            </div>

        </div>
    )
}

export default UserTypeSelection;