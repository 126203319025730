import React, { useState } from "react";
import Dialog, {
    DialogContent,
    DialogFooter,
    DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";
import { Button, TextInput } from "@nokia-csf-uxr/ccfk";
import CloseCloseIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';
import {
    AlertIcon,
    AlertMessage,
} from '@nokia-csf-uxr/ccfk/Alert';
import OOT from "Constants/services/oot";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import InlineFeedbackMessage from '@nokia-csf-uxr/ccfk/InlineFeedbackMessage';
import { showModal } from "Store/auth";
import useFetchUsers from '../hooks/useFetchUsers';
import { generateUserPayload } from './utils/helper';


const DIALOG_HEIGHT = "40%";
const DIALOG_WIDTH = "40%";

const CONFIRM_ALERT_STYLE = {
    top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
    height: DIALOG_HEIGHT,
    minHeight: DIALOG_HEIGHT,
    left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    width: DIALOG_WIDTH,
};

type IDeleteUserModalCloseProp = {
    onClose: () => void;
    selectedUserData: object;
    typeOfUser: string;
    mode: string;
};



const DeleteUser = ({ onClose, selectedUserData, mode, typeOfUser }: IDeleteUserModalCloseProp) => {
    const dispatch = useDispatch();
    const authSlice = useSelector((state: RootState) => state.authSlice)
    const { limitPerpage, offset } = useSelector((state: RootState) => state.pagination.paginationProps);
    const userSliceData = useSelector((state: RootState) => state.userManagement.userData);

    // const getAllUsersList = useUserActions(typeOfUser);

    const { getUsersList } = useFetchUsers();

    const [responseMsg, setResponseMsg] = useState('');
    const [successShow, setSuccessShow] = useState(false)
    const [errorMsg, setErrorMsg] = useState('');
    const [errorShow, setErrorShow] = useState(false);



    const handleOnClose = () => {
        console.log(authSlice.system.modal.variant)
        dispatch(
            showModal({
                msg: "",
                variant: "",
                title: "",
                show: false,
                panel: {
                  msg: "",
                  title:"",
                },
              })
        );
        onClose();
    }


    const handleDisableEnableUser = async () => {
        try {
            let response;
            if (selectedUserData['status'] === "Active") {
                response = await OOT.disableUser(selectedUserData['id']);
            } else {
                console.log(selectedUserData, 'selectedUserData');
                const userData = generateUserPayload(userSliceData, 'edit', typeOfUser, selectedUserData, true);
                console.log(userData, 'userData');
                response = await OOT.addEditUser(userData, 'edit', typeOfUser);
            }
    
            if (response?.status) {
                setSuccessShow(true);
                setResponseMsg(response.msg);
            } else {
                setErrorShow(true);
                setErrorMsg(response?.errorMessage || response?.msg || "An error occurred");
            }
        } catch (error) {
            setErrorShow(true);
            setErrorMsg(error.message || "An unexpected error occurred");
        } finally {
            setTimeout(() => {
                getUsersList(limitPerpage, offset, typeOfUser);
                handleOnClose();
            }, 3000);
        }
    };

    return (
        <>
            <Dialog
                isOpen={true}
                ariaHideApp={false}
                style={{ content: CONFIRM_ALERT_STYLE }}
            >
                <DialogTitle
                    title={`${selectedUserData['status'] === 'Inactive' ? 'Enable' : 'Disable'} user`}
                    buttonContent={<CloseCloseIcon />}
                    buttonProps={{
                        onClick: handleOnClose,
                    }}
                />
                <DialogContent isTopDividerVisible={true} isBottomDividerVisible={true}>
                    <div style={{ padding: '1rem' }}>
                        {responseMsg && <InlineFeedbackMessage
                            variant="success"
                            size="medium"
                            closeButton
                            show={successShow}
                            onClose={() => setSuccessShow(false)}
                        >
                            {responseMsg}
                        </InlineFeedbackMessage>}
                        {errorMsg && <InlineFeedbackMessage
                            variant="error"
                            size="medium"
                            closeButton
                            show={errorShow}
                            onClose={() => setErrorShow(false)}
                        >
                            {errorMsg}
                        </InlineFeedbackMessage>}

                    </div>
                    <AlertMessage
                        iconProps={{ style: { display: 'none' } }}
                        message={`Are you sure you want to ${selectedUserData['status'] === 'Inactive' ? 'enable' : 'disable'} the user?`}
                        variant="CONFIRM"
                    />

                </DialogContent>
                <DialogFooter>
                    {/* <Button onClick={onClose}>Close</Button> */}
                    <Button onClick={handleOnClose}>Cancel</Button>
                    <Button variant="destructive" onClick={handleDisableEnableUser}>Submit</Button>
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default DeleteUser;