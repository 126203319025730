import React, { useState, useEffect, useCallback, useRef } from 'react';
import Card from '@nokia-csf-uxr/ccfk/Card';
import Pagination from "../marketplace/common/Pagination";
import { RootState, store } from "Store/mainStore";
import OOT from "Constants/services/oot";
import { setLoader, setProducts, } from "Store/contentState/merchantSlice";
import { useDispatch, useSelector } from 'react-redux';
import Loader from "Components/Elements/Loader";
import DataGrid from '@nokia-csf-uxr/ccfk/DataGrid/DataGrid';
import userStyles from '../admin/Style/UserManagement.module.css';
import Chip, { ChipLabel } from '@nokia-csf-uxr/ccfk/Chip';
import ExcelExport from "Components/Elements/ExcelExport/ExcelExport";

const ProductList = (prop) => {
  const dispatch = useDispatch();
  const merchantSelector = useSelector((state: RootState) => state.merchant);
  const paginationSelector = useSelector((state: RootState) => state.pagination);
  const { currentPage, limitPerpage } = paginationSelector.paginationProps;
  const globalSearchText = useSelector((state: RootState) => state.common.globalSearchText);
  const persona = useSelector((state: RootState) => state.marketplace.persona);
  const gridApi = useRef(null);
  const selectedMerchant = store.getState().merchant.selectedMerchantAccount
  const [activeTab, setActiveTab] = useState("");


  const tabList = ["allproducts", "draftproducts", "pendingproducts"]

  const getActiveTab = () => {
    let activeTab = tabList.filter((tab) => tab == prop.type && prop.isActive)
    return activeTab[0]
  }



  useEffect(() => {
    setActiveTab(getActiveTab())
    const offset = (currentPage - 1) * limitPerpage;
    if (getActiveTab()) {
      getProducts(offset, selectedMerchant);
    }
  }, [globalSearchText, prop.isActive, currentPage, limitPerpage, selectedMerchant])



  const prepareFilterConditions = () => {
    const filterConditions = [];  
    let userFilter = "is_marketplace=1"
    return filterConditions.length > 0 ? filterConditions.join(' AND ') : userFilter;
  };



  const getProducts = useCallback(async (offset, merchantId) => {
    const filterConditions = prepareFilterConditions();
    const useType = persona === "nokia" ?  merchantId : merchantSelector.supplier_account_id
    if(useType !== "" ){
    try {
     
      const currency = "EUR"
      const response = await OOT.getAllMerchantProducts(useType, filterConditions, currency,limitPerpage, offset, persona);
      if (response.data.length !== 0) {
        dispatch(setProducts(response.data));
      } else {
        dispatch(setProducts([]));
        // dispatch(setShowFilter(false));
      }

    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoader({ key: "isProductsLoading", value: false }));
    }
  }
  }, [currentPage, limitPerpage, globalSearchText]);

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  if (merchantSelector.isProductsLoading) {
    return (
      <div style={{ marginTop: '50px' }}>
        <Loader />
      </div>
    )
  }

  return (
    <>
      <div style={{ width: '100%', height: '100%', marginTop: '10px' }}>
        <DataGrid
          // variant={"compact"}
          suppressMovableColumns={false}
          suppressColumnMoveAnimation={false}
          suppressRowClickSelection={false}
          isRowDrag={false}
          wrapperProps={{
            style: {
              width: "100%"
              // width: 600,
            }
          }}
          sideBar
          rowHeight={80}
          suppressContextMenu
          onGridReady={(params) => {
            gridApi.current = params.api;
          }}
          rowData={merchantSelector.products}
          columnDefs={prop.columnDefsList}
          key={'products'}
          defaultColDef={{
            editable: false,
            sortable: true,
            flex: 0,
            minWidth: 30,
            filter: true,
            resizable: false,
          }}
          overlayNoRowsTemplate='No products are available'
          isInlineRowActions={false}
          domLayout='autoHeight'

          getRowClass={(params) => {
            return params.data.isDisabled ? userStyles.disabledRow : '';
          }}
        />
      </div>
      <Pagination disableProp={merchantSelector.products} />
    </>
  )

};

export default ProductList;

