import { createSlice } from "@reduxjs/toolkit";
import { setProp } from "Store/Actions";



const initialData = {
    users: [],
    isUsersLoading: false,
    selectedUserType: "user",
    userData: {
        id: "",
        fullName: "",
        emailAddress: "",
        orgType: [],
        roles: [],
        dataElement: {},
        viewAccess: [],
        oneStarNumber: [],    //it will be array later
        threeStarNumber: [],
        whitelisted_ip: {
            ip_list: [],
            ip_ranges: []
        },
        markets: {},
        business_name: [],
        supplier_account_id: [],
        nsa_authorized: false,
        approver: false,
        oot: { roles: [], oneStarNum:[], approver_role_group: [], approver_market : [] }
    },
    isEmailEntered: false,
    isAddingNokiaUser: false,
    requestedInfoBasedOnOrgType: "",
    treeData: [],
    accountListBasedOnMarket: [],
    allAccountsForExternal: [],
    allAccountList:[],
    rolesBasedOnOrgType: [],
    azureApplications: [],
    modifiedRoles: [], // Track modified roles here
    roleInfo: {
        rolesData: [],
        loading: false,
        error: null
    },
    isEditMode: false, // Track if in edit mode
    metaData: {
        applicationList: [],
        applicationListNameMapping:{},
        dataElementNameMapping:{},
        commonRoles: [],
        dataElements: [],
        persona: [],
        general:[]
    },
    userFilters: {
        role: "",
        market: "",
        accountGroup: ""
    },
    nokiaCertificationStatus: "",
    hierachyFilterQuery:"",
    userRolesFilterColumns:[],
    showUserModal:false,
    showEditUserDialog:false,
    showAddRoleDialog:false,
    loadDataList: false,
    selectedItems: [],
    // isRolesSavedSuccessfully: false,
    hierarchyLevel4Data:[],
    mailDomain: "",
    isMailDomainLoading: false

}


const userManagementSlice = createSlice({
    name: 'userManagement',
    initialState: initialData,
    reducers: {
        updateSelectedPersonas: (state, action) => {
            state.userData.orgType = action.payload;
        },
        updateSelectedViewAccess: (state, action) => {
            state.userData.viewAccess = action.payload;
        },
        setFullName: (state, action) => {
            state.userData.fullName = action.payload
        },
        setUserInputFields: (state, action) => {
            const { field, value } = action.payload;
            state.userData[field] = value;
        },
        updateDataElement: (state, action) => {
            state.userData.dataElement = action.payload;
        },
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        updateRoles(state, action) {
            state.userData.roles = action.payload; // Update the roles object directly
        },
        setUserMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        setUserNSA: (state, action) => {
            state.userData.nsa_authorized = action.payload;
        },
        clearUserModel: (state) => {
            state.userData = initialData.userData
            state.treeData = initialData.treeData
            state.accountListBasedOnMarket = initialData.accountListBasedOnMarket
            state.allAccountsForExternal = initialData.allAccountsForExternal
            state.hierachyFilterQuery = initialData.hierachyFilterQuery
            state.rolesBasedOnOrgType = initialData.rolesBasedOnOrgType
            state.azureApplications = initialData.azureApplications
            state.isAddingNokiaUser = initialData.isAddingNokiaUser
            state.isEmailEntered = initialData.isEmailEntered
            // state.selectedUserType = initialData.selectedUserType
        },
        setUsersLoading: (state, action) => {
            state.isUsersLoading = action.payload
        },
        setUserFilters(state, action) {
            
            const { filterName, filterValue } = action.payload;
            state.userFilters[filterName] = filterValue;
        },
        setEditMode: (state, action) => {
            state.isEditMode = action.payload;
        },
        saveModifiedRoles: (state) => {
            // Logic to save modified roles
            // Here you can call an API or update the state accordingly
            state.modifiedRoles = []; // Clear the modified roles after saving
        },
        cancelModifications: (state) => {
            state.modifiedRoles = [];
            state.isEditMode = false;
        },
        setWhiteListedIP: (state, action) => {
            const { type, value } = action.payload;
            state.userData.whitelisted_ip[type] = value;
        },
        clearUserData: (state) => {
            state.userData = initialData.userData;
        },
        setRolesInfo: (state, action) => {
            const { field, value } = action.payload;
            state.roleInfo[field] = value;
        },
        setOotRoles: (state, action) => {
            const { type, value } = action.payload;
            state.userData.oot[type] = value;
        }

    },
    extraReducers(builder) {
        builder.addCase(setProp, (state, action: {
            payload: any;
            type: string;
            slice: string;
        }) => {
            if (action.payload.slice === userManagementSlice.name) {
                state[action.payload.key] = action.payload.value;
            }
        });
    }
})


export const { updateSelectedPersonas, setRolesInfo, setEditMode, setWhiteListedIP, clearUserData, saveModifiedRoles, cancelModifications, setUsersLoading, updateSelectedViewAccess, setUserFilters, setUserMetaData, updateRoles, setUserInputFields, setFullName, updateDataElement, setUsers, clearUserModel, setOotRoles,setUserNSA } = userManagementSlice.actions;
export default userManagementSlice.reducer;
