import { useSelector, useDispatch } from "react-redux";
import { RootState, store } from "Store/mainStore";
import OOT from "Constants/services/oot";
import {
    getCustometList,
    getNetworktList,
} from "../../subscription/utility/utils";
import {
    setLoader,
    setCustomerList,
    setFilters,
    setNetworkList,
    setPartnerList,
    setAccountDropdown, setAccountDetails, setProductQueryFilter
} from "Store/contentState/marketplaceSlice";
import { setProp } from "Store/Actions";
import { toast } from "react-toastify";


export const useMarketplaceFilter = () => {
    const dispatch = useDispatch();
    const marketplaceSlice = useSelector(
        (state: RootState) => state.marketplace
    );

    const setPartnerState = async (type, partner, partnerList) => {
        // let __customerList = [];
        dispatch(setFilters({ filterName: 'partner', value: partner }))
        if(marketplaceSlice.filters.selection.scrmID == ""){
        dispatch(setFilters({ filterName: 'customer', value: '' }))
        }
        // dispatch(setNetworkList({ networkList: [] }));
        if (partner.trim().length > 0) {
            dispatch(
                setLoader({
                    key: "isCustomerLoading",
                    value: true,
                })
            );
            const accountInfo = await getAccountDetails(partner, partnerList);
            if (accountInfo.length > 0) {
                let updatedCustomersList = [];
                let account_nid = accountInfo[0]['nid']
                const response = await OOT.getCustomer({ account_nid });
                if (response.data.customers.length !== 0) {
                    updatedCustomersList = response.data.customers.map((customer) => {
                        return {
                            //"id": marketplaceSlice.sCRMID != "" ?  customer['1_star_number'] : customer.nid,
                            "id": customer['1_star_number'],
                            "nid": customer.nid,
                            "value": customer.name,
                            "3_star_number": customer['3_star_number'],
                            "1_star_number": customer['1_star_number'],
                            "billing_address": customer['billing_address'],
                            "shipping_address": customer.shipping_address
                        }
                    })
                    if (updatedCustomersList.length === 0) {
                        toast.error(
                            "There are no end customers for this account"
                        )
                    }
                } else {
                    updatedCustomersList = []
                    toast.error(
                        "There are no end customers for this account"
                    )
                    dispatch(
                        setLoader({
                            key: "isCustomerLoading",
                            value: false,
                        })
                    );
                }

                dispatch(
                    setLoader({
                        key: "isCustomerLoading",
                        value: false,
                    })
                );
                updateMarketplaceState(type, partner, updatedCustomersList, accountInfo);
            }

        } else {

        }
    };

    const setCustomerState = (customer, customerList = []) => {
        dispatch(setFilters({ filterName: 'customer', value: customer }));
    };

    const updateMarketplaceState = (type, __partner, updatedCustomersList, accountResponse) => {
        
        // reset customer list
        const __customerList = [...updatedCustomersList]
        dispatch(
            setCustomerList({
                customerList: __customerList,
            })
        );
        dispatch(setNetworkList({
            networkList: [],
        }));

        if(marketplaceSlice.filters.selection.scrmID !=""){
            const reduxStore = store.getState();
            updateAccountData(reduxStore.marketplace.filters.selection.customer, accountResponse, updatedCustomersList)
        }

        // Apply logic for selected partner , customer and network
        if (type === "reload") {
            const { partner, customer } = marketplaceSlice?.filters?.selection;
            if (partner !== "" && customer !== "") {
                preSelectOptions(customer, __customerList, accountResponse)
            }
        }
        // Handle single item in the customer list
        if (type === "fetch" && __customerList.length === 1) {
            preSelectOptions(__customerList[0].id, __customerList, accountResponse)
        }

        
    };

    const preSelectOptions = (customer, __customerList, accountResponse) => {
        setCustomerState(customer, __customerList);
        updateAccountData(customer, accountResponse, __customerList)
    }


    const fetchMarketplaceData = async (type, _partner, partnerList) => {
        const partner = _partner;
        setPartnerState(type, partner, partnerList);
    }


    const getAccountDetails = async (__partner, partnerList) => {
        try {
            const result = await OOT.getAccountDetailsApi(__partner);
            if (result.status) {
                const filteredPartner = partnerList.filter((item) => item.id === __partner);
                const updatedAccountData = result.data.map((item) => {
                    return {
                        "id": item.account_id,
                        "name": filteredPartner[0]['value'],
                        "nid": filteredPartner[0]['account_nid'],
                        "currency": item.currency_id,
                        "currencyisocode": item['currencyisocode'],
                        "account_type": item.alcr_record_type__c,
                        "billingCountry": item.billingcountrycode,
                        "billingCountryText": item.billingcountry,
                        "1*_number": item.one_star_id,
                        "3*_number": item.three_star_id
                    }
                })
                if (updatedAccountData[0]?.account_type === 'Distributor' && isCountryEUR27(updatedAccountData[0]?.billingCountry)) {
                    dispatch(setProp({ slice: 'marketplace', key: 'showShipTo', value: true }))
                } else {
                    dispatch(setProp({ slice: 'marketplace', key: 'showShipTo', value: false }))
                }
                setBillingCountry(updatedAccountData[0]?.billingCountry, marketplaceSlice.countryList);
                dispatch(setAccountDetails(updatedAccountData));

                return updatedAccountData
            } else {
                return []
            }
        } catch (error) {
            console.error(error)
            dispatch(setLoader({
                key: "isAccountLoading",
                value: false,
            }))
            dispatch(setLoader({
                key: "isCustomerLoading",
                value: false,
            }))
        }
    }

    const updateAccountData = (_customer, accountDetails, customerArr = []) => {
        const reduxStore = store.getState();
        const customers = customerArr.length === 1 ? customerArr : reduxStore.marketplace.customerList;
        const accountData = accountDetails.length !== 0 ? accountDetails : reduxStore.marketplace.accountDetails
        const selectedCustomer = customers.filter((item) => item.id === _customer);
        const updatedAccountData = accountData.map((item) => {
            return {
                ...item,
                end_customer_nid: selectedCustomer[0]['nid'],
                end_customer_name: selectedCustomer[0]['value'],
                customer_address: selectedCustomer[0]['shipping_address'],
                customer_one_star_num: selectedCustomer[0]['1_star_number'],
                customer_three_star_num: selectedCustomer[0]['3_star_number'],
                accountBillingAddress: selectedCustomer[0]['billing_address']
            }
        })
        dispatch(setAccountDetails(updatedAccountData));

    }

    const getCountryDetails = (selectedCountry, countryList, billingCountry = "") => {
        // Filter the countryList to find the matching country based on iso_2 code.
        const filteredData = countryList.filter((item) =>
            item.id === selectedCountry
        );

        dispatch(setProp({ slice: 'marketplace', key: 'countryText', value: filteredData[0]['value'] }))
        // Check if the filteredData has any country and if is_merchant_products_allowed is true
        if (filteredData.length > 0 && filteredData[0].is_merchant_products_allowed === 1) {

            dispatch(setProp({ slice: 'marketplace', key: 'showMerchantProducts', value: true }))
        } else {
            dispatch(setProp({ slice: 'marketplace', key: 'showMerchantProducts', value: false }))
            console.log('Merchant products are not allowed for this country or country not found.');
        }
    };


   
    const setShippingCountry = (selectedCountry, countryList) => {
        dispatch(setFilters({ filterName: 'shippingCountry', value: selectedCountry }));
        let filteredCountry = countryList.filter((item) => item.id === selectedCountry);
        dispatch(setProp({ slice: 'marketplace', key: 'shippingCountryText', value: filteredCountry[0]['value'] }))

    };

    const setBillingCountry = (selectedCountry, countryList) => {
        dispatch(setFilters({ filterName: 'billingCountry', value: selectedCountry }));
        //const shippingCountry = marketplaceSlice.filters.selection.shippingCountry;
        //checkMerchantProductsAllowed(shippingCountry, selectedCountry, countryList);
    };

    


    const prepareProductApiFilter = (billingCountry, shippingCountry, countryList, accountType) => {

        const filteredShippingData = countryList.filter((item) => item.id === shippingCountry);
        const filteredBillingData = countryList.filter((item) => item.id === billingCountry);

        //US, EUR countries filteration
        const isUSCountry = (country) => country.id === 'US';
        const isNonUS = (country) => country.is_allowed_for_marketplace === 1 && country.id !== 'US'
        const isEUR27 = (country) => country.short_region_code === 'EUROPE' && country.is_merchant_products_allowed === 1;
        const isNonEURO27 = (country) => country.is_allowed_for_marketplace === 1 && (country.is_merchant_products_allowed === 0 || country.short_region_code === 'NAM');

        // removed select query and added countries filtering logic with conditions
        const euroCountriesBase = countryList.filter((item) => (item.is_merchant_products_allowed === 1 && item.short_region_code === 'EUROPE')).map(value => `'${value.id}'`).join(',');

        const euroCountriesCondtional = countryList.filter((item) => (item.is_merchant_products_allowed === 1 && item.short_region_code === 'EUROPE' && item.id !== shippingCountry)).map(value => `'${value.id}'`).join(',');


        //conditions for checking billing and shipping countries
        //both are US
        const bothAreUS = isUSCountry(filteredBillingData[0]) && isUSCountry(filteredShippingData[0])
        //both are EURO 27
        const bothAreEURO27 = isEUR27(filteredBillingData[0]) && isEUR27(filteredShippingData[0]);
        //both Non US and Non EURO 27
        const nonUSNonEURO27 = isNonEURO27(filteredBillingData[0])
        //EURO 27 and Non EURO 27
        const euro27NonEURO27 = isEUR27(filteredBillingData[0]) && isNonEURO27(filteredShippingData[0]);
        //billing US and Shipping Non US
        const usNonUS = isUSCountry(filteredBillingData[0]) && isNonUS(filteredShippingData[0]);

        

        if (
            filteredShippingData.length > 0 &&
            filteredBillingData.length > 0
        ) {
            if (bothAreUS) {
                dispatch(setProductQueryFilter("product_data.is_marketplace = 1 and (supplier_master_sapbyd.address_info->>'$.data[0].country_region_id' = 'US' or product_data.is_agent_model = 0)"))
            } else if (bothAreEURO27) {

                //for DAC fulfillment [merchant hardware products]
                let fulfillmentQueryDAC = '';
                let fulfillmentQueryMerchant = '';

                // if (shippingCountry !== billingCountry && marketplaceSlice.accountDetails[0]?.account_type === 'Distributor' && marketplaceSlice.filters.selection.shipTo === 'endCustomer') {
                //     //UAC 7 when shipping and billing country do not match and sell to party is distributor 
                //     console.log("UAC 7")
                //     fulfillmentQueryDAC = `(product_data.is_agent_model = 1 and supplier_master_sapbyd.address_info->>'$.data[0].country_region_id' in (${euroCountriesBase}) and product_data.product_type_id != 'Hardware' and product_data.fulfillment_mode = 'DAC')`
                // }
                // else {
                //     //Fallback to UAC6
                //     console.log("UAC 6")
                //     fulfillmentQueryDAC = `(product_data.is_agent_model = 1 and supplier_master_sapbyd.address_info->>'$.data[0].country_region_id' in (${euroCountriesBase}) and product_data.product_type_id = 'Hardware' and product_data.fulfillment_mode = 'DAC')`
                // }

                if (shippingCountry !== billingCountry && marketplaceSlice.accountDetails[0]?.account_type === 'Distributor' && marketplaceSlice.filters.selection.shipTo === 'endCustomer') {
                    //UAC 7 when shipping and billing country do not match and sell to party is distributor
                    console.log("UAC 7 : do nothing as no merchant HW required for this condition");
                    fulfillmentQueryDAC = ''
                }
                else {
                    //Fallback to UAC6
                    console.log("UAC 6")
                    fulfillmentQueryDAC = `(product_data.is_agent_model = 1 and supplier_master_sapbyd.address_info->>'$.data[0].country_region_id' in (${euroCountriesBase}) and product_data.product_type_id = 'Hardware' and product_data.fulfillment_mode = 'DAC')`
                }

            
                //for merchant fulfillment [merchant hardware products] (UAC 9 and UAC 10 to be confirmed)

                if ((marketplaceSlice.accountDetails[0]?.account_type === 'Distributor' && marketplaceSlice.filters.selection.shipTo === 'sellToParty')
                    || (marketplaceSlice.accountDetails[0]?.account_type !== 'Distributor')) {
                    //UAC 10 and UAC 9
                    console.log("UAC 9, 10")
                    fulfillmentQueryMerchant = `(product_data.is_agent_model = 1 and supplier_master_sapbyd.address_info->>'$.data[0].country_region_id' in (${euroCountriesCondtional}) and product_data.product_type_id = 'Hardware' and product_data.fulfillment_mode = 'Merchant')`
                }
                // let baseQuery = `product_data.is_marketplace = 1 and (product_data.is_agent_model = 0  or (product_data.is_agent_model = 1 and supplier_master_sapbyd.address_info->>'$.data[0].country_region_id' in (select country.iso_2 from country where country.is_merchant_products_allowed = 1 and country.short_region_code = 'EUROPE') and product_data.product_type_id != 'Hardware') or (${conditionalQuery}))`
                let marketplaceFilter = `product_data.is_marketplace = 1`;
                let nokiaFilter = `product_data.is_agent_model = 0`
                let baseQuery = `(product_data.is_agent_model = 1 and supplier_master_sapbyd.address_info->>'$.data[0].country_region_id' in (${euroCountriesBase}) and product_data.product_type_id != 'Hardware')`;
                if (fulfillmentQueryDAC) {
                    console.log("base dac")
                    baseQuery = `${baseQuery} or ${fulfillmentQueryDAC}`;
                }
                if (fulfillmentQueryMerchant) {
                    console.log("base merchant")
                    baseQuery = `${baseQuery} or ${fulfillmentQueryMerchant}`;
                }
                baseQuery = `${marketplaceFilter} and (${nokiaFilter} or ${baseQuery})`
                dispatch(setProductQueryFilter(baseQuery))
            } else {
                dispatch(setProductQueryFilter("product_data.is_marketplace = 1 and product_data.is_agent_model = 0"));
                dispatch(setProp({ slice: 'marketplace', key: 'showMerchantProducts', value: false }));
            }
        } else {
            dispatch(setProductQueryFilter("product_data.is_marketplace = 1 and product_data.is_agent_model = 0"))
            dispatch(setProp({ slice: 'marketplace', key: 'showMerchantProducts', value: false }));
            console.log('Merchant products are not allowed for these countries or country not found.');
        }


    }

    const isCountryEUR27 = (country) => {
        let eurocountries = marketplaceSlice.countryList.filter((item) => (item.is_merchant_products_allowed === 1 && item.short_region_code === 'EUROPE')).map(value => `${value.id}`)
        return eurocountries.includes(country);
    }


    return {

        setPartnerState,
        setCustomerState,
        // setNetworkState,
        fetchMarketplaceData,
        updateAccountData,
        getCountryDetails,
        setShippingCountry,
        setBillingCountry,
        prepareProductApiFilter,
        isCountryEUR27
    };
}

