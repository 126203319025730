import React from "react";
import PriceDisplay from "../UIElements/PriceDisplay";

const CartDiscountBreakup = (prop) => {
    
    return (
        <>
            <div style={{
                boxSizing: 'border-box', direction: 'ltr', wordBreak: 'break-word', whiteSpace: 'pre-line',
                maxWidth: '30rem', padding: '.5rem', borderRadius: '.5rem', backgroundColor: "#101620d9", color: '#ffffff'
            }}>
                <div style={{ fontSize: '.8rem' }}>Discounts <span style={{fontSize:'.5rem'}}>(per unit)</span></div>
                <div>List price: {<PriceDisplay price={prop.discountBreakup.market_list_price} />}</div>
                {/* <div>Market listed price: {<PriceDisplay price={prop.discountBreakup.market_list_price} />}</div> */}
                {prop.discountBreakup.ptd_percentage > 0 && (
                    <div>Partner tier discount({prop.discountBreakup.ptd_percentage}%): {prop.discountBreakup.plp_after_ptd}</div>
                )}
                {prop.discountBreakup.vd_percentage > 0 && (
                    <div>Volume discount({prop.discountBreakup.vd_percentage}%): {prop.discountBreakup.plp_after_vd}</div>
                )}
                {prop.discountBreakup.tbd_percentage > 0 && (
                    <div>Time based discount({prop.discountBreakup.tbd_percentage}%): {prop.discountBreakup.plp_after_tbd}</div>
                )}
                {prop.discountBreakup.dod_percentage > 0 && (
                    <div>Deal original discount({prop.discountBreakup.dod_percentage}%): {prop.discountBreakup.pnp_after_dod}</div>
                )}
                 {prop.discountBreakup.sbd_percentage > 0 && (
                    <div>Other discount({prop.discountBreakup.sbd_percentage}%): {prop.discountBreakup.pnp_after_sbd}</div>
                )}
                {/* <div>Custom and logistics: {prop.discountBreakup.customs_and_logistics}</div> */}
                {/* <div>Total: {<PriceDisplay price={prop.discountBreakup.total} />}</div> */}
            </div>
        </>
    )

}

export default CartDiscountBreakup;
