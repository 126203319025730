import React, { useEffect, useState, useMemo } from "react";
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import { setProp } from "Store/Actions";
import userStyles from "../../Style/UserManagement.module.css";
import Label from '@nokia-csf-uxr/ccfk/Label';
import SelectBox from "Pages/subscription/Custom/SelectBox";
import { updateRoles } from "Store/contentState/userManagementSlice";
import OOT from "Constants/services/oot";
import { Button, TextInput } from "@nokia-csf-uxr/ccfk";
import { modifyData } from "../utils/constants";
import { setUserInputFields, updateSelectedPersonas } from "Store/contentState/userManagementSlice";
import CommonEmailField from "./CommonEmailField";
import { setOotRoles } from "Store/contentState/userManagementSlice";

const CommonFormFields = (prop) => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const selectedUserOrgType = useSelector((state: RootState) => state.userManagement.userData.orgType);
    const selectedUserType = useSelector((state: RootState) => state.userManagement.selectedUserType);
    const selectedRoles = useSelector((state: RootState) => state.userManagement.userData.roles);
    const rolesBasedOnOrgType = useSelector((state: RootState) => state.userManagement.rolesBasedOnOrgType);
    const applicationsList = useSelector((state: RootState) => state.userManagement.azureApplications);
    const selectedApplication = useSelector((state: RootState) => state.userManagement.userData.id);
    const selectedApplicationName = useSelector((state: RootState) => state.userManagement.userData.fullName);
    const ootRoles = useSelector((state: RootState) => state.userManagement.userData.oot.roles);
    let modifiedRoleData = [];

    const memoizedSelectedUserOrgType = useMemo(() => selectedUserOrgType, [selectedUserOrgType]);
    const memoizedSelectedUserType = useMemo(() => selectedUserType, [selectedUserType]);
    const memoizedSelectedOOTRoles = useMemo(() => ootRoles, [ootRoles.length === 0]);
    const nokiaCertificationStatus = useSelector((state: RootState) => state.userManagement.nokiaCertificationStatus);
    
    //const shouldExclude = (field) => prop.excludeFields.includes(field);

    useEffect(() => {
        if ((memoizedSelectedUserType === 'user' && memoizedSelectedUserOrgType.length > 0 ) || memoizedSelectedUserType === 'machine') {
            fetchRolesBasedOnOrgType();
        }
    }, [memoizedSelectedUserOrgType, memoizedSelectedUserType, ootRoles]);



    const fetchRolesBasedOnOrgType = async () => {
        try {
            
            let selectedOrgType = selectedUserType === 'user' ? selectedUserOrgType[0] : 'nokia';
            let filter = "JSON_CONTAINS(data_objects->'$.allowed_org_type', '\"" + selectedOrgType + "\"')";
            const response = await OOT.getUserRoles(filter);
            if (response.status) {
                modifiedRoleData = modifyData(response.data, 'id', 'name');
                if(prop.mode === "add" && nokiaCertificationStatus === "Active"){
                    const filteredRolesOwner = modifiedRoleData.filter(
                        (item) => item.id !== "sales_view_only_sales" && item.id !== "sales"
                      );
                      dispatch(setProp({ slice: 'userManagement', key: 'rolesBasedOnOrgType', value: filteredRolesOwner }))
                }
                else if((prop.mode === "add" && nokiaCertificationStatus === "Inactive") || prop.mode === "add" && nokiaCertificationStatus === "No certification data"){
                    const filteredRolesOwner = modifiedRoleData.filter(
                        (item) => item.id !== "technical_sales_sales"
                      );
                      dispatch(setProp({ slice: 'userManagement', key: 'rolesBasedOnOrgType', value: filteredRolesOwner }))
                }
                else{
                if(ootRoles.includes("owner")){
                    const filteredRolesOwner = modifiedRoleData.filter(
                        (item) => item.id !== "sales_view_only_sales" && item.id !== "sales"
                      );
                      dispatch(setProp({ slice: 'userManagement', key: 'rolesBasedOnOrgType', value: filteredRolesOwner }))
                }
                else if(ootRoles.includes("sales_owner")){
                    const filteredRolesSalesOwner = modifiedRoleData.filter(
                        (item) => item.id !== "technical_sales_sales"
                      );
                      dispatch(setProp({ slice: 'userManagement', key: 'rolesBasedOnOrgType', value: filteredRolesSalesOwner }))
                }
                else{
                    dispatch(setProp({ slice: 'userManagement', key: 'rolesBasedOnOrgType', value: modifiedRoleData }))
                }
                }
                
            }
        } catch (error) {
            console.log(error);
        }

    }



    const handleRolesSelection = (role) => {
        if (role) {
            //fetchRolesMetaData(role);
            dispatch(updateRoles([role]));
            if (role === "technical_sales_sales" || role === "technical_sales_no_price_info") {
                let valueTechnicalSelect = ['owner']
                const nonUniqueootRoles = ootRoles.filter((item) => item != "owner")
                dispatch(setOotRoles({ type: "roles", value: [...nonUniqueootRoles.filter((item) => item != "sales_owner"), ...valueTechnicalSelect] }));
            }
            else if (role === "sales") {
                let valueSalesSelect = ['sales_owner']
                const nonUniqueootRolesSales = ootRoles.filter((item) => item != "sales_owner")
                if(role === "sales"){
                dispatch(setOotRoles({ type: "roles", value: [...nonUniqueootRolesSales.filter((item) => item != "owner"), ...valueSalesSelect] }));
                }
                else{
                    dispatch(setOotRoles({ type: "roles", value: [] }));
                }
            }
            else if (role === "business_platform_team" || role === "iam_admin") {
                let valueSalesSelectRole = ['helpdesk_agent','user_management_']
                const nonUniqueootRoles = ootRoles.filter((item) => item != "helpdesk_agent" && item != "user_management_")
                dispatch(setOotRoles({ type: "roles", value: [...nonUniqueootRoles, ...valueSalesSelectRole] }));
            }
            else if (role != "business_platform_team" || role != "iam_admin") {
                const nonUniqueootRoles = ootRoles.filter((item) => item != "helpdesk_agent" && item != "user_management_")           
                dispatch(setOotRoles({ type: "roles", value: nonUniqueootRoles }));
            }
        }
        else {
            dispatch(updateRoles([]));
            const nonUniqueootRoles = ootRoles.filter((item) => item != "helpdesk_agent" && item != "user_management_")
             dispatch(setOotRoles({ type: "roles", value: nonUniqueootRoles }));
            //dispatch(setOotRoles({ type: "roles", value: [] }));
        }
    };


    const fetchAzureApplication = async () => {
        try {
            const response = await OOT.getAzureApplications(searchText)
            if (response.status) {

                let modifiedApplicationsList = modifyData(response.data, 'appId', 'displayName');
                dispatch(setProp({ slice: 'userManagement', key: 'azureApplications', value: modifiedApplicationsList }))
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            fetchAzureApplication(); // Call the API when Enter key is pressed
        }
    };


    const handleApplicationSelection = (item) => {
        if (item) {
            let filteredApplication = applicationsList.filter((app) => app.id === item);
            dispatch(setUserInputFields({ field: 'id', value: item }))
            dispatch(setUserInputFields({ field: 'fullName', value: filteredApplication[0].value }))
        }

    }

    return (
        <>

            {((selectedUserType === 'whitelisted_client' || selectedUserType === 'machine') && prop.mode === 'edit') ? <div className={userStyles.gridItem}>
                <div className={`${userStyles.marginBottom} `}>
                    <Label className={userStyles.userLabel}>Application name<span style={{ color: '#C91514' }}>*</span></Label>
                    <TextInput
                        id="search-applications"
                        disabled={true}
                        value={selectedApplicationName}
                        variant={"outlined"}
                        placeholder="Search application"
                        maxWidth={true}
                        style={{ width: '100%' }}
                    />
                </div>
            </div> : <div className={userStyles.gridItemEmpty}></div>}


            {((selectedUserType === 'whitelisted_client' || selectedUserType === 'machine') && prop.mode === 'add') ? <div className={userStyles.gridItem}>
                <div className={`${userStyles.marginBottom}`}>
                    <Label className={userStyles.userLabel}>Search applications <span style={{ color: '#C91514' }}>*</span></Label>
                    <TextInput
                        id="search-applications"
                        // disabled={mode === "edit" && selectedUserData.name.trim() !== ""}
                        value={searchText}
                        variant={"outlined"}
                        placeholder="Search application"
                        maxWidth={false}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={handleKeyDown}
                        style={{ width: '100%' }}
                    />
                </div>
            </div> : <div className={userStyles.gridItemEmpty}></div>}


            {(((selectedUserType === 'whitelisted_client' || selectedUserType === 'machine') && prop.mode === 'add') && applicationsList.length > 0 && searchText !== '') ? <div className={userStyles.gridItem}>
                <div className={userStyles.marginBottom}>
                    <Label className={userStyles.userLabel}>Select applications <span style={{ color: '#C91514' }}>*</span></Label>
                    <div style={{ width: '100%' }}>
                        <SelectBox
                            placeholder="Select application"
                            maxWidth={true}
                            caseInsentiveMatching={true}
                            selectedItem={selectedApplication}
                            data={applicationsList}
                            onUpdateSelection={handleApplicationSelection}
                            isLoading={false}
                        />
                    </div>

                </div>
            </div> : <div className={userStyles.gridItemEmpty}></div>}

            <div className={userStyles.gridItem}>
                {(selectedUserType === 'user' || selectedUserType === 'machine' || (prop.mode === 'edit' && selectedUserType !== 'whitelisted_client')) && (
                    <div className={userStyles.marginBottom}>
                        <Label className={userStyles.userLabel}>Role name <span style={{ color: '#C91514' }}>*</span></Label>
                        <div className={userStyles.selectBoxStyle}>
                            <SelectBox
                                placeholder="Select role name"
                                maxWidth={true}
                                caseInsentiveMatching={true}
                                selectedItem={selectedRoles[0]}  //it will be changed in future
                                data={rolesBasedOnOrgType}
                                onUpdateSelection={handleRolesSelection}
                                isLoading={false}
                            />
                        </div>
                    </div>
                )}
            </div>

        </>
    )
}

export default CommonFormFields;