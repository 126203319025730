import React, { useState, useEffect } from 'react';
import { setProp } from 'Store/Actions';
import { useDispatch, useSelector } from 'react-redux';
import CloseCloseIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import PropTypes from "prop-types";
import UploadPO from './orders/UploadPO';

import Checkbox, {
  CheckboxLabelContent,
} from '@nokia-csf-uxr/ccfk/Checkbox';

import { Button } from "@nokia-csf-uxr/ccfk";
import { RootState } from "Store/mainStore";
import TermsConditionsDailog from './TermsConditionsDialog';
import OOT from 'Constants/services/oot';


const DIALOG_HEIGHT = "60%";
const DIALOG_WIDTH = "60%";

const CONFIRM_ALERT_STYLE = {
  top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
  height: DIALOG_HEIGHT,
  minHeight: DIALOG_HEIGHT,
  left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
  right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
  width: DIALOG_WIDTH,
};

const TermsConditions = () => {
  const dispatch = useDispatch();
  const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
  const [nokiaTermsChecked, setNokiaTermsChecked] = useState(undefined);
  const [isAuthorizedChecked, setIsAuthorizedChecked] = useState(undefined);
  const [indeterminate, setIsIndeterminate] = useState(false);
  const [showTCDialog, setShowTCDialog] = useState(false);
  const [termsType, setTermsType] = useState('');
  const [showOrderDialog, setShowOrderDialog] = useState(false);
  const cart = useSelector((state: RootState) => state.marketplace.cart);
  let versionIds = [];

  

  const handleOrderButton = () => {
    setShowOrderDialog(true)
  }

  const hasMerchantProducts = () => {
    return marketplaceSelector.cart.some(item => item.is_agent_model !== 0);
  };

  useEffect(() => {
    if (marketplaceSelector.isCartChanged) {
      setNokiaTermsChecked(false);
      setIsAuthorizedChecked(false);
      

    }
  }, [marketplaceSelector.isCartChanged]);


  // const getSupplierIdsFromCart = (cart) => {
  //   // Extract unique supplier_ids from cart items where is_agent_model is 1
  //   const supplierIds = Array.from(new Set(cart.filter(item => item.is_agent_model === 1 || item.is_agent_model === 0).map(item => item.supplier_id)));
  //   return supplierIds;
  // };


// const getTermsAndConditions = async (type: string) => {
//     try {
//       let supplierIds = [];
      
//       if (type === 'merchant' || type === 'dac') {
//         supplierIds = getSupplierIdsFromCart(cart);
//       }
//       let filter = `order by merchant_terms_and_conditions.version_id desc`
//       const response = await OOT.getTermsConditions(type === 'dac' ? ["NFI85"] : supplierIds, filter);
//       if (response.status) {
//         // setTermsConditions(response.data[0]['terms_and_conditions']['info']);
//         versionIds.push(response.data[0]['version_id']);
//         //console.log(versionIds)
//         dispatch(setProp({ slice: 'marketplace', key: 'nokiaTermsConditions', value: versionIds.join(',') }))
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };


const checkAgreementData = async () => {
  try {
    const response = await OOT.getAgreementDetails(marketplaceSelector.accountDetails[0]['1*_number']);
    console.log(response, 'response')
  } catch (error) {
    console.error(error);
  } finally{

  }
}

  return (
    <>
      {/* <Label> */}
      {showOrderDialog && (
        <UploadPO onClose={() => setShowOrderDialog(false)} selectedOffer={""} orderMode="directOrder" modalTitle="Checkout" />
      )}

      {showTCDialog && (
        <TermsConditionsDailog onClose={() => setShowTCDialog(false)}  type={termsType}/>
      )}

      <>
        <div style={{ marginTop: '3rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button
                    variant="call-to-action"
                    onClick={() => handleOrderButton()}
                    disabled={marketplaceSelector.isCartChanged}>
                    {/* <ButtonIcon><img src={saveIcon} alt='delete-icon' /></ButtonIcon> */}
                    <ButtonText>Proceed to checkout</ButtonText>

                </Button> 
        </div>
      </>
      {/* disabled={checkMandatoryTerms()} */}  
    </>
  );
};

export default TermsConditions;

// TermsConditions.propTypes = {
//   onProceed: PropTypes.func
// }


