import React, { useState } from "react";
import Card from '@nokia-csf-uxr/ccfk/Card';
import TabsSection from '../marketplace/common/TabsSection';
import Users from "./user-management/Users";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import Pagination from "Pages/marketplace/common/Pagination";
import { resetPaginationsProps } from "Store/contentState/paginationSlice";
import {clearGlobalSearchText} from "Store/contentState/commonSlice"





const customTabData = [
  { tab: { children: 'Users', label: 'Tab One description' } },
  { tab: { children: 'Machines', label: 'Tab Two description' } },
  { tab: { children: 'White listed applications', label: 'Tab Three description' } }
];






const AdminBody = () => {
  const dispatch = useDispatch();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const userManagementSelector = useSelector((state: RootState) => state.userManagement);

  const tabContentComponents = [
    <Users key={0} type="user" isActive={activeTabIndex === 0} />,
    <Users key={1} type="machine" isActive={activeTabIndex === 1} />,
    <Users key={2} type="whitelisted_client" isActive={activeTabIndex === 2} />
];

  const handleTabSelect = (index) => {
    setActiveTabIndex(index);
    dispatch(resetPaginationsProps());
    dispatch(clearGlobalSearchText());
  };

 


  return (
    <>
      <Card style={{ padding: '1rem', outline:'none', minHeight:'900px' }}>
        <div>
          <TabsSection tabData={customTabData} tabContentComponents={tabContentComponents}  //@ts-ignore
            onTabChange={handleTabSelect}/>
        </div>
      </Card>

      <Pagination disableProp={userManagementSelector.users} />

    </>
  )
}

export default AdminBody;