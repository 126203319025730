import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Subscription from "../Pages/subscription/Subscription";
import Marketplace from "../Pages/marketplace/Marketplace";
import Account from "../Pages/subscription/Account/index";
import { Login } from "../Pages/Login";
import SelectedProductPage from "../Pages/marketplace/SelectedProductPage";
import Quotations from "../Pages/marketplace/quotations/Quotations";
import Admin from "../Pages/admin/Admin";
import Orders from "../Pages/marketplace/orders/Orders";
import MarketplaceBody from "../Pages/marketplace/MarketplaceBody";
import Roles from "../Pages/admin/roles-management/Roles";
import MerchantBody from "../Pages/merchant/MerchantBody";
import Dashboard from "../Pages/merchant/Dashboard";
import MerchantProducts from "../Pages/merchant/MerchantProducts";
import MerchantOrders from "../Pages/merchant/MerchantOrders";
import CartBody from "../Pages/marketplace/CartBody";
import FallbackPage from "../Pages/FallbackPage";
import RadioNetworkCIQ from "../Pages/radio-network-ciq/RadioNetworkCIQ";
import Unauthorized from "../Pages/Unauthorized";
import PropTypes from "prop-types";
import SelectedRadioNetworkPlanPage from "Pages/radio-network-ciq/SelectedRadioNetworkPlanPage";
import RadioNetworkCIQBody from "Pages/radio-network-ciq/RadioNetworkCIQBody";


interface AppRoutesProps {
    isAuthenticated: boolean;
    userAccessInfo: any[];
}


const AppRoutes = ({ isAuthenticated, userAccessInfo }) => {
    return (
        <Routes>
            {isAuthenticated && userAccessInfo.length > 0 && (
                <>
                    <Route
                        path="/"
                        element={
                            <Navigate
                                to={`/${userAccessInfo[0]}`}
                                replace={true}
                            />
                        }
                    />
                    <Route element={<ProtectedRoute requiredAccess="subscription" />}>
                        <Route path="/subscription" element={<Subscription />} />
                    </Route>

                    <Route element={<ProtectedRoute requiredAccess="marketplace" />}>
                        <Route path="/marketplace" element={<Marketplace />}>
                            <Route index path="" element={<MarketplaceBody />} />

                            <Route path="cart" element={<CartBody />} />
                            <Route path="product/:id" element={<SelectedProductPage />} />
                        </Route>
                    </Route>
                    <Route element={<ProtectedRoute requiredAccess="dashboard" />}>
                        <Route path="/dashboard" element={<Dashboard />}>
                            <Route index path="" element={<MerchantBody />} />
                        </Route>
                    </Route>

                    <Route element={<ProtectedRoute requiredAccess="products" />}>
                        <Route path="/products" element={<MerchantProducts />} />
                    </Route>

                    <Route element={<ProtectedRoute requiredAccess="quotations" />}>
                        <Route index path="/quotations" element={<Quotations />} />
                    </Route>

                    <Route element={<ProtectedRoute requiredAccess="account" />}>
                        <Route path="/account" element={<Account />} />
                    </Route>

                    <Route element={<ProtectedRoute requiredAccess="users" />}>
                        <Route path="/users" element={<Admin />} />
                        <Route path="/roles" element={<Roles />} />
                    </Route>
                    <Route element={<ProtectedRoute requiredAccess="radio_network_ciq" />}>
                        <Route path="/radio_network_ciq" element={<RadioNetworkCIQ />} >
                        <Route index path="" element={<RadioNetworkCIQBody />} />
                        <Route path="plan" element={<SelectedRadioNetworkPlanPage />} />
                        </Route>
                    </Route>

                    <Route element={<ProtectedRoute requiredAccess="orders" />}>
                        <Route path="/orders" element={<Orders />} />
                    </Route>


                    <Route path="/unauthorized" element={<Unauthorized />} />
                    <Route path="*" element={<FallbackPage />} />
                </>
            )}

            <Route
                path="/login"
                element={
                    isAuthenticated ? (
                        <Navigate
                            to={`/${userAccessInfo[0]}`}
                            replace={true}
                        />
                    ) : (
                        <Login />
                    )
                }
            />
        </Routes>
    );
};

AppRoutes.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    userAccessInfo: PropTypes.array.isRequired
}

export default AppRoutes;