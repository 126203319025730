import React from "react";
import { App } from "@nokia-csf-uxr/ccfk";
import { Body } from "Components/Body";
import Header from "Components/Header";
import LoadingOverlay from "react-loading-overlay-ts";
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import RadioNetworkCIQBody from "./RadioNetworkCIQBody";
import { Outlet, useLocation } from 'react-router-dom';




const RadioNetworkCIQ = () => {
    const authSelector = useSelector((state: RootState) => state.authSlice);
    const location = useLocation();
    const radioNetworkCIQ = useSelector((stete: RootState) => stete.radioNetworkCIQ)

    let crumbs = ["Radio_network"];
    let items = [{ title: 'Radio network', path: '/radio_network_ciq' , backIcon: false}];

    if (location.pathname.includes("/radio_network_ciq/plan")) {
        crumbs = ["Radio_network / Plan"];
        items = [
            { title: 'Data Captures List ', path: '/radio_network_ciq', backIcon: true },
            { title: radioNetworkCIQ.selectedNetworkPlan['opportunity_id'], path: '', backIcon: false }
        ];
    }

    return (
        <LoadingOverlay
            active={authSelector.system.isLoading}
            spinner
            classNamePrefix="MyLoader_"
            text="Please wait "
        >
            <App>
                <Header />
                <Body
                    crumbs={crumbs}
                    bodyContent={<Outlet />}
                    items={items}
                />
            </App>
            {/* <AppFooter /> */}
        </LoadingOverlay>
    )
}

export default RadioNetworkCIQ;