import React, { useRef, useState, useEffect, memo } from 'react';
import DataGrid from '@nokia-csf-uxr/ccfk/DataGrid/DataGrid';
import Button from '@nokia-csf-uxr/ccfk/Button';
import editIcon from '../../../Assets/Icons/pencil_icon.svg';
import userActiveIcon from '../../../Assets/Icons/user-check.svg';
import userInActiveIcon from '../../../Assets/Icons/user-delete.svg';
//import  from '../../../Assets/Icons/edit_icon.svg';
import trashIcon from '../../../Assets/Icons/trash_icon_new.svg';
import OOT from 'Constants/services/oot';
import { setUsers, setUsersLoading } from 'Store/contentState/userManagementSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "Store/mainStore";
import Chip, {
  ChipLabel,
} from '@nokia-csf-uxr/ccfk/Chip';
import Loader from 'Components/Elements/Loader';
import DeleteUser from './DeleteUser';
import Pagination from 'Pages/marketplace/common/Pagination';
import { setOffset, resetPaginationsProps } from 'Store/contentState/paginationSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography } from '@nokia-csf-uxr/ccfk';
import userStyles from '../Style/UserManagement.module.css';
import UserDialogModal from './UserDialogModal';
import UserDetailsOverlay from './components/UserDetailsOverlay';
import { setUser } from 'Store/auth';
import useFetchUsers from '../hooks/useFetchUsers';
import { ootRoles } from './utils/constants';
import { setProp } from 'Store/Actions';
import { formatDate } from "../../marketplace/Utils/commons";

const UsersList = (prop) => {
  const gridApi = useRef(null);
  const dispatch = useDispatch();
  const userManagementSelector = useSelector((state: RootState) => state.userManagement);
  const paginationSelector = useSelector((state: RootState) => state.pagination);
  const globalSearchText = useSelector((state: RootState) => state.common.globalSearchText);
  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);
  const [showEditUserDialog, setShowEditUserDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const { currentPage, limitPerpage } = paginationSelector.paginationProps;
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [clickedOnActionButton, setClickedOnActionButton] = useState(false);

  const { getUsersList } = useFetchUsers();


  useEffect(() => {
    const offset = (currentPage - 1) * limitPerpage;
    dispatch(setOffset(offset));
    if (prop.data.isActive) {
      getUsersList(limitPerpage, offset, prop.data.type);
    }

  }, [globalSearchText, currentPage, limitPerpage, userManagementSelector.userFilters, prop.data.isActive, prop.data.type]);

  const getHierarchyLevel4 = async () => {
    try {
      const response = await OOT.getHierarchyData("", "level4");
      if (response.status) {
        dispatch(setProp({ slice: 'userManagement', key: 'hierarchyLevel4Data', value: response.data }));
      }
    } catch (error) {
      console.log(`Error fetching level 4 data:`, error);
    }
  };

  useEffect(() => {
    if (prop.data.isActive) {
      getHierarchyLevel4();
    }
  }, [])


  if (userManagementSelector.isUsersLoading) {
    return <Loader />
  }


  const handleShowDeleteModal = (user) => {
    // setClickedOnActionButton(true);
    setShowDeleteUserDialog(true);
    setSelectedUser(user);
  }

  const handleEditUser = (user) => {
    setClickedOnActionButton(true);
    setShowEditUserDialog(true);
    setSelectedUser(user);
  }

  // const handleDisableUser = (user) => {
  //   // Toggle the `isDisabled` property
  //   const updatedUsers = userManagementSelector.users.map((u) =>
  //     u.id === user.id ? { ...u, isDisabled: !u.isDisabled } : u
  //   );

  //   // Dispatch the updated list to Redux store
  //   dispatch(setUsers(updatedUsers)); // Assuming `setUsers` is your Redux action to update users
  // };

  const handleGetUserDetails = (user) => {
    setClickedOnActionButton(true);
    setShowUserDetails(true);
    setSelectedUser(user);
  }

  const commonColumnDefs = [
    {
      headerName: 'Name',
      field: 'name',
      minWidth: 240
    },
    {
      headerName: 'Email',
      field: 'email',
      minWidth: 240,
      flex: 2
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 130,
      cellRenderer: (params) => (
        <div>
          {params.data.status === 'Active' && (
            <Chip size="small" style={{ backgroundColor: '#E4F0FF', border: '1px solid #8EC2FF', marginRight: '.5rem', borderRadius: '15px' }}>
              <ChipLabel label={params.data.status} />
            </Chip>
          )}
          {params.data.status === 'Inactive' && (
            <Chip size="small" style={{ backgroundColor: '#FFF1E4', border: '1px solid #FFD0A5', marginRight: '.5rem', borderRadius: '15px' }}>
              <ChipLabel label={params.data.status} />
            </Chip>
          )}
        </div>
      )
    }
  ];



  const specificColumnDefs = [
    ...(prop.data.type !== 'whitelisted_client' ? [
      // Type column for "user" and "machine"
      {
        headerName: 'Organization type',
        field: 'userTypeInList',
        minWidth: 180,
        flex: 2,
        cellRenderer: (params) => {
          const orgType = params?.data?.userTypeInList
          let typeOrg = []
          if (orgType.length != 0) {
            const rolesDatalist = orgType.map((a) => userManagementSelector.metaData.persona.find((b) => b.id === a))
            typeOrg = rolesDatalist.filter((oot) => oot).map((item) => item.name)
          }
          return (
            <>{typeOrg}</>
          )
        }
      },
      {
        headerName: 'Last login',
        field: 'last_login_time',
        minWidth: 150,
        flex: 2,
        cellRenderer: (params) => {
          const lastLoginDate = params?.data?.last_login_time ? formatDate(new Date(params?.data?.last_login_time), 'dd-MMM-yyyy', true) : "-"
          return (
            <div>
              {lastLoginDate}
            </div>
          )
        }
      },
      // Role Name column for "user" and "machine"
      {
        headerName: 'Role name',
        field: 'userRoles',
        minWidth: 130,
        flex: 1,
        cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },

        cellRenderer: (params) => {
          const roleslist = Array.isArray(params?.data?.userRoles) ? params.data.userRoles : []; // Ensure it's an array
          let roles = [];

          if (roleslist.length !== 0) {
            const rolesDatalist = roleslist.map((a) =>
              userManagementSelector.roleInfo.rolesData.find((b) => b.id === a)
            );
            roles = rolesDatalist.filter((oot) => oot).map((item) => item.name);
          }

          const maxRolesToShow = 2;

          return (
            <div title={roles.length > 0 ? roles.join(', ') : ' - '}>
              {roles.length > 0 ? roles.slice(0, maxRolesToShow).join(', ') : ' - '}
              {roles.length > maxRolesToShow ? '...' : ''}
            </div>
          );
        }
      },
      // Permissions column for "user" and "machine"
      ...(prop.data.type === 'machine' ? [] : [
        {
          headerName: 'NSA authorized',
          field: 'nsa_authorized',
          cellRenderer: (params) => (
            <div style={{ paddingLeft: '30px' }}>
              {params.data.nsa_authorized ? "YES" : "NO"}
            </div>
          )
        },
        {
          headerName: 'OOT permissions',
          field: 'oot',
          minWidth: 180,
          flex: 2,
          cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
          // cellRenderer: (params) => {
          //   const ootRolesList = params.value?.roles
          //   let value = []
          //   if (ootRolesList.length != 0) {
          //     const ootlist = ootRolesList.map((a) => ootRoles.find((b) => b.id === a))
          //     value = ootlist.filter((oot) => oot).map((item) => item.value)
          //   }
          //   const maxRolesToShow = 1;
          //   return (
          //     <div title={value?.length > 0 ? value?.join(', ') : ' - '}>
          //       {value?.length > 0 ? value?.slice(0, maxRolesToShow).join(', ') : ' - '}
          //       {value?.length > maxRolesToShow ? '...' : ''}
          //     </div>
          //   );
          // }
          cellRenderer: (params) => {
            const ootRolesList = Array.isArray(params.value?.roles) ? params.value.roles : []; // Ensure it's an array
            let value = [];

            if (ootRolesList.length !== 0) {
              const ootlist = ootRolesList.map((a) => ootRoles.find((b) => b.id === a));
              value = ootlist.filter((oot) => oot).map((item) => item.value);
            }

            const maxRolesToShow = 1;

            return (
              <div title={value.length > 0 ? value.join(', ') : ' - '}>
                {value.length > 0 ? value.slice(0, maxRolesToShow).join(', ') : ' - '}
                {value.length > maxRolesToShow ? '...' : ''}
              </div>
            );
          }
        }

      ])
    ] : []),
    // IP List column specifically for "machine" type
    ...(prop.data.type === 'machine' ? [
      {
        headerName: 'IP address/range',
        field: 'whitelisted_ip',
        minWidth: 240,
        cellRenderer: (params) => {
          const ipList = Array.isArray(params.value?.ip_list) ? params.value.ip_list : [];
          const ipRanges = Array.isArray(params.value?.ipranges) ? params.value.ip_ranges : [];
          const maxItemsToShow = 2;

          // Format IP ranges as "start - end"
          const formattedRanges = ipRanges.map(range => `${range.start} - ${range.end}`);

          // Combine IP list and formatted ranges, limiting to the max items to show
          const allIPs = [...ipList, ...formattedRanges];
          const displayedIPs = allIPs.slice(0, maxItemsToShow).join(', ');
          const hasMore = allIPs.length > maxItemsToShow;

          return (
            <Typography title={allIPs.join(', ')}>
              {displayedIPs}
              {hasMore ? ', ...' : ''}
            </Typography>
          );
        }
      }

    ] : [])
  ];


  // Define the action buttons column separately to apply it to all types
  const actionButtonsColumn = {
    minWidth: 20,
    cellRenderer: (params) => {
      //const isDisabled = params.data.isDisabled;
      return (
        <div className={userStyles.dFlex5}>
          <img
            src={editIcon}
            className="action-button"
            onClick={(event) => {
              setClickedOnActionButton(true);
              handleEditUser(params.data);
            }}
          />
          <img
            src={params.data.status === 'Inactive' ? userActiveIcon : userInActiveIcon}
            onClick={() => {
              setClickedOnActionButton(true);
              // handleDisableUser(params.data);
              handleShowDeleteModal(params.data);
            }}
            className={userStyles.toggleButton}
          />
          {/* <img
            src={trashIcon}
            onClick={() => {
              setClickedOnActionButton(true);
              handleShowDeleteModal(params.data);
            }}
          /> */}
        </div>
      );
    }
  };

  // Combine all column definitions
  const columnDefs = [...commonColumnDefs, ...specificColumnDefs, actionButtonsColumn];

  return (
    <>

      {showUserDetails && <UserDetailsOverlay onClose={() => setShowUserDetails(false)} selectedUserData={selectedUser} />
      }
      {showDeleteUserDialog && (
        <DeleteUser onClose={() => {
          setShowDeleteUserDialog(false)
        }} selectedUserData={selectedUser} mode="edit" typeOfUser={prop.data.type}/>
      )}

      {showEditUserDialog && (
        <UserDialogModal onClose={() => {
          setShowEditUserDialog(false)
        }} selectedUserData={selectedUser} mode="edit" typeOfUser={prop.data.type} />
      )}

      <div className={userStyles.userDataList}>
        <DataGrid
          isRowDrag={false}
          onRowClicked={(params) => {
            if (!clickedOnActionButton) {
              handleGetUserDetails(params.data);
            }
            setClickedOnActionButton(false);
          }}
          wrapperProps={{
            style: { width: '100%' }
          }}
          sideBar
          suppressContextMenu
          suppressMovableColumns={true}
          suppressColumnMoveAnimation={false}
          alwaysShowVerticalScroll={false}
          onGridReady={(params) => {
            gridApi.current = params.api;
          }}
          rowData={userManagementSelector.users}
          columnDefs={columnDefs}
          key={'users'}
          defaultColDef={{
            editable: false,
            sortable: true,
            flex: 0,
            minWidth: 30,
            filter: true,
            resizable: false
          }}
          overlayNoRowsTemplate="No users are available"
          isInlineRowActions={false}
          domLayout="autoHeight"
          getRowClass={(params) => {
            return params.data.status === 'Inactive' ? userStyles.disabledRow : '';
          }}
        />

      </div>
    </>
  )
}

export default memo(UsersList);