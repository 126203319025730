//@ts-nocheck


import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { RootState } from 'Store/mainStore';

// const ProtectedRoute = ({ requiredAccess }) => {
//   const userAccessInfo = useSelector((state: RootState) => state.marketplace.userAccessInfo);

//   return userAccessInfo.includes(requiredAccess) ? <Outlet /> : <Navigate to="/unauthorized" replace />;
// };

// export default ProtectedRoute;

// ProtectedRoute.propTypes = {
//   requiredAccess: PropTypes.string,
 
// };

const ProtectedRoute = ({ requiredAccess }) => {
  const userAccessInfo = useSelector(
    (state) => state.marketplace.userAccessInfo || []
  );

  // Check if the user has the required access
  const hasAccess = Array.isArray(userAccessInfo) && userAccessInfo.includes(requiredAccess);

  if (!requiredAccess) {
    console.error("ProtectedRoute: 'requiredAccess' is not defined.");
    return <Navigate to="/unauthorized" replace />;
  }

  return hasAccess ? <Outlet /> : <Navigate to="/unauthorized" replace />;
};

ProtectedRoute.propTypes = {
  requiredAccess: PropTypes.string.isRequired, // Marked as required
};

export default ProtectedRoute;

