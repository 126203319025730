import React from "react";
import { Label, Typography } from "@nokia-csf-uxr/ccfk";
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";


const AccountInfo = () => {
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace)
    return (
        <>
            {marketplaceSelector.isGetProducts && marketplaceSelector.filters.selection.scrmID !=="" &&<div style={{paddingRight: '15px'}}>
                <Label style={{ fontSize: '14px', color: "#757575", fontWeight: "700" }}>sCRM ID:</Label>
                <Typography>{marketplaceSelector.filters.selection.scrmID}</Typography>
            </div>}
            {marketplaceSelector.isGetProducts && <div style={{ display: "flex", gap: "1rem", alignItems: 'center', flexDirection: "row", marginRight: '1rem', padding: "0 1rem 0 1rem", borderRight: "1px solid #0000001F", borderLeft: "1px solid #0000001F" }}>
                <div>
                    <Label style={{ fontSize: '14px', color: "#757575", fontWeight: "700" }}>Country:</Label>
                    <Typography>{marketplaceSelector.shippingCountryText}</Typography>
                </div>
                <div>
                    <Label style={{ fontSize: '14px', color: "#757575", fontWeight: "700" }}>Account:</Label>
                    <Typography>{marketplaceSelector.accountDetails[0]?.name}</Typography>
                </div>
                <div>
                    <div>
                        <Label style={{ fontSize: '14px', color: "#757575", fontWeight: "700" }}>Customer:</Label>
                        <Typography>{marketplaceSelector.accountDetails[0] ? marketplaceSelector.accountDetails[0]['end_customer_name'] : ""}</Typography>
                    </div>

                </div>
            </div>}
        </>

    )
}

export default AccountInfo;