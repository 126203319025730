import React, { useState } from "react";
import { Button, TextInput } from "@nokia-csf-uxr/ccfk";
import Label from '@nokia-csf-uxr/ccfk/Label';
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import { setProp } from "Store/Actions";
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import labelStyles from '../admin/Style/UserManagement.module.css';
import Stepper from "./Stepper";
import OOT from "Constants/services/oot";




const sCRMRegex = /(^\d{2})\.([\w]{2})\.(\d{6,})/;

const SCRMValidation = () => {
    const dispatch = useDispatch();
    const radioNetworkCIQ = useSelector((stete: RootState) => stete.radioNetworkCIQ)
    const scrmId = useSelector((state: RootState) => state.radioNetworkCIQ.sCRMID);




    const validateSCRMID = () => {
        let filter = `opportunity_id = '${scrmId}'`;
        dispatch(setProp({ slice: 'radioNetworkCIQ', key: 'solutionFormLoading', value: true }))
        dispatch(setProp({ slice: 'radioNetworkCIQ', key: 'stepperLoader', value: true }))
        getScrmid(filter, scrmId)
    }

    const getScrmid = async (filter, scrmId) => {
        dispatch(setProp({ slice: 'radioNetworkCIQ', key: 'scrmLoading', value: true }))
        try {
            const response = await OOT.getScrmidValid(filter, scrmId);
            if (response.data) {
                if (response.data && response.data[0]) {
                    if (response.data.length > 0)
                        dispatch(setProp({ slice: 'radioNetworkCIQ', key: 'scrmValidation', value: true }))

                }
                else {
                    dispatch(setProp({ slice: 'radioNetworkCIQ', key: 'stepperLoader', value: false }))
                }
            }
            dispatch(setProp({ slice: 'radioNetworkCIQ', key: 'scrmLoading', value: false }))

        } catch (error) {
            console.error('Error fetching data:', error);

        }
    };




    const isButtonDisabled = () => {
        return !sCRMRegex.test(scrmId.trim()) && scrmId.length > 0 || scrmId === '' || radioNetworkCIQ.stepperLoader;
    }

    const handleSCRMIDChange = ({ debounce = true, text }) => {
        dispatch(setProp({ slice: 'radioNetworkCIQ', key: 'sCRMID', value: text }))
    };


    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '1rem', flexDirection: 'row' }}>
                <div style={{ width: '20%' }}>
                    <Label className={labelStyles.userLabel}>sCRM ID <span style={{ color: '#C91514' }}>*</span></Label>
                    <TextInput
                        id="scrmId"
                        // disabled={true}
                        value={radioNetworkCIQ.captureType === "edit" ? radioNetworkCIQ.selectedNetworkPlan['opportunity_id'] : scrmId}
                        variant={"outlined"}
                        placeholder="Enter the sCRM ID"
                        error={
                            !sCRMRegex.test(scrmId.trim()) &&
                            scrmId.length > 0
                        } //
                        errorMessage={
                            !sCRMRegex.test(scrmId.trim()) &&
                            scrmId.length > 0 &&
                            "Invalid sCRM ID"
                        }
                        maxWidth={true}
                        disabled={radioNetworkCIQ.stepperLoader}
                        onChange={(e) => {
                            handleSCRMIDChange({
                                text: e.target.value.trim(),
                                debounce: false,
                            });
                        }}

                    // style={{ width: '100%' }}
                    />
                </div>
                <div style={{ marginTop: '25px' }}>
                    <Button
                        variant="call-to-action"
                        disabled={isButtonDisabled()}
                        onClick={validateSCRMID}
                    >
                        <ButtonText>Validate</ButtonText>
                    </Button>
                </div>

            </div>

            {/* <div>
                {scrmIdErrMsg && <span style={{ color: '#C91514', marginTop: '2px' }}>Please enter valid sCRM ID</span>}
            </div> */}
        </>
    )
}

export default SCRMValidation;