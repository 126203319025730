import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OOT from "Constants/services/oot";

import {
  setLoader,
} from "Store/contentState/subscriptionSlice";

import { useSubscriptionAuth } from "./subscription/hooks/useSubscriptionAuth";
import Unauthorized from "./Unauthorized";
import { RootState } from "Store/mainStore";
import {
  setUserAccessInfo,
  setCountryList,
  setUserPersona
} from "Store/contentState/marketplaceSlice";
import Loader from "Components/Elements/Loader";
import { setUserMetaData } from "Store/contentState/userManagementSlice";
import { setProp } from "Store/Actions";
import { setSupplierAcccountId } from "Store/contentState/merchantSlice";
import AppRoutes from "Routes/AppRoutes";


// const Orders = React.lazy(() => import('./marketplace/orders/Orders'));
// const Quotations = React.lazy(() => import('./marketplace/quotations/Quotations'));
// const Admin = React.lazy(() => import('./admin/Admin'));


export const Landing = () => {
  const { useAuthState, useIsAuthorized } = useSubscriptionAuth();
  const { isAuthenticated, email, inProgress } = useAuthState();
  const isAuthorized = useIsAuthorized(email);
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const loginUserId = useSelector(
    (state: RootState) => state.authSlice.login.id
  );

  const marketplaceSelector = useSelector(
    (state: RootState) => state.marketplace
  );

  const userPersonaInfo = useSelector(
    (state: RootState) => state.userManagement?.metaData?.persona
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const getUserInfo = async (loginUserId) => {
    // Handle default user role view
    let userRoleView = [];
    setLoading(true)
    try {
      // <Get configured user access

      const response = await OOT.getUserAccessControlInfo(loginUserId);
      if (response.status && response.data.length > 0) {
        dispatch(setUserPersona(response.data[0]['role']['persona'][0]))
        if (response.data[0]['role']['persona'].includes("merchant")) {

          dispatch(setSupplierAcccountId((response.data[0]['role']['supplier_account_id'][0])));
        }
        // else {
        // Handle user role view
        userRoleView = [...response.data[0]["metadata"]["applicationList"][0]['applications']];
        if (userRoleView.includes("marketplace")) {
          getCountriesList();
          // let cartInfo =
          //   response.data[0]["cartInfo"].length > 0
          //     ? response.data[0]["cartInfo"][0]["cart"]
          //     : [];
          // dispatch(
          //   setProp({
          //     slice: "marketplace",
          //     key: "savedCartInfo",
          //     value: cartInfo,
          //   })
          // );
          // dispatch(setProp({slice:'marketplace', key: 'cart', value: cartInfo.cart_items}))
        }
        if (response.data[0]["metadata"]) {
          dispatch(setUserMetaData(response.data[0]["metadata"]));
        }

      }
      //}
    } catch (error) {
      console.error(
        "Error has occured on get user access control info :",
        error
      );
    } finally {
      setLoading(false)
      // Handle data format
      dispatch(setUserAccessInfo(userRoleView));
    }
  };

  useEffect(() => {
    if (loginUserId.length > 0) {
      getUserInfo(loginUserId);
      findUserType(authSelector.login.mail);
    }
  }, [loginUserId]);

  const findUserType = (mail) => {
    // Extract the domain from the email address
    const domain = mail.split("@")[1];
    // Check if the domain is 'nokia.com'
    if (
      domain === "nokia.com" ||
      domain === "nokia-bell-labs.com" ||
      domain === "nokia-sbell.com"
    ) {
      dispatch(
        setProp({ slice: "marketplace", key: "isNokiaUser", value: true })
      );
    } else {
      dispatch(
        setProp({ slice: "marketplace", key: "isNokiaUser", value: false })
      );
    }
  };

  const getCountriesList = async () => {
    dispatch(
      setLoader({
        key: "isCountryLoading",
        value: true,
      })
    );
    try {
      let filterCondition = "is_allowed_for_marketplace = 1";
      const response = await OOT.getAllCountries(filterCondition);
      if (response.status) {
        const sortedData = response.data.filter(item => item.name && item.name.trim() !== "").sort((a, b) => a.name.localeCompare(b.name));
        const updatedData = sortedData.map((item) => {
          return {
            id: item.iso_2,
            value: item.name,
            is_merchant_products_allowed: item.is_merchant_products_allowed,
            short_region_code: item.short_region_code,
          };
        });
        dispatch(setCountryList(updatedData));
        dispatch(
          setLoader({
            key: "isCountryLoading",
            value: false,
          })
        );
      } else {
        dispatch(
          setLoader({
            key: "isCountryLoading",
            value: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        setLoader({
          key: "isCountryLoading",
          value: false,
        })
      );
      console.error(error);
    }
  };



  if ((loading)) {
    return (
      <div style={{ position: "absolute", inset: "50% 0" }}>
        <Loader />
      </div>
    );
  }

  if (isAuthenticated && marketplaceSelector.userAccessInfo.length === 0 && !loading) {
    return <Unauthorized />;
  }




  return (
    <>
      <AppRoutes
        isAuthenticated={isAuthenticated}
        userAccessInfo={marketplaceSelector.userAccessInfo}
      />
    </>
  );
};
