import React from 'react';
import classes from '../../Style/Cart.module.css';
import TermsConditions from 'Pages/marketplace/TermsConditions';
import quotationImage from '../../../../Assets/Icons/terms.svg';
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import Button from '@nokia-csf-uxr/ccfk/Button';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/mainStore';


const OrderCreation = () => {
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
    return (
        <>
            <div style={{ padding: '2rem' }}>
                {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={quotationImage} />
                </div>
           
                {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                        variant="call-to-action"
                        onClick={() => console.log()}
                        disabled={marketplaceSelector.isCartChanged}>
                        <ButtonText>Proceed to checkout</ButtonText>

                    </Button>
                </div> */}
                 <TermsConditions /> 
            </div>

        </>
    )
}

export default OrderCreation;