import React, { useState, useEffect, useCallback, useRef } from 'react';
import Pagination from "../marketplace/common/Pagination";
import { RootState, store } from "Store/mainStore";
import OOT from "Constants/services/oot";
import { setLoader, setOrders, setDashboard } from "Store/contentState/merchantSlice";
import { useDispatch, useSelector } from 'react-redux';
import Loader from "Components/Elements/Loader";
import DataGrid from '@nokia-csf-uxr/ccfk/DataGrid/DataGrid';
import userStyles from '../admin/Style/UserManagement.module.css';
import Chip, { ChipLabel } from '@nokia-csf-uxr/ccfk/Chip';
import { formatNumberWithCommas } from 'Pages/marketplace/Utils/commons';
import { Skeleton } from '@nokia-csf-uxr/ccfk';

const OrderList = (prop) => {
    const dispatch = useDispatch();
    const merchantSelector = useSelector((state: RootState) => state.merchant);
    const paginationSelector = useSelector((state: RootState) => state.pagination);
    const { currentPage, limitPerpage } = paginationSelector.paginationProps;
    const persona = useSelector((state: RootState) => state.marketplace.persona);
    const selectedSalesOrderIdLists =  store.getState();
    const selectedSalesOrderID = selectedSalesOrderIdLists.merchant.selectedSalesOrderID
    const gridApi = useRef(null);
    const merchantDashboard = store.getState();
    let toDate = merchantDashboard.merchant.dashboard.todate
    let fromDate =  merchantDashboard.merchant.dashboard.fromDate
    let lastToDate = ""
    let lastFromDate = ""

    useEffect(() => {
        const offset = (currentPage - 1) * limitPerpage;
        getOrderListNew(offset);
    }, [limitPerpage, currentPage, merchantSelector.dashboard.analytics, merchantSelector.selectedSalesOrderID])


    const formatDateList = (date) => {
        const year = date.getFullYear(); // Get the year (e.g., 2024)
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month (1-12), zero-padded
        const day = String(date.getDate()).padStart(2, '0'); // Day (1-31), zero-padded
    
        return `${year}-${month}-${day}`;
      };
    
      const getToFromDate = (analyticsType,type) => {
        const today = new Date()
        const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
        const LastYear = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
        if (analyticsType === "weekly") {
          toDate = formatDateList(today)
          fromDate = formatDateList(lastWeek)
          lastToDate = fromDate
          let lastFromDateDate = new Date(lastWeek.getFullYear(), lastWeek.getMonth(), lastWeek.getDate() - 7);
          lastFromDate = formatDateList(lastFromDateDate)
        }
        else if (analyticsType === "month") {
          let date = today.setMonth(today.getMonth() - 1)
          let month = new Date(date)
          let todaydate = new Date
          toDate = formatDateList(todaydate)
          fromDate = formatDateList(month)
        }
        else if (analyticsType === "year") {
          toDate = formatDateList(today)
          fromDate = formatDateList(LastYear)
        }

        return type === "fromDate" ? fromDate : toDate
    }   


    const getOrderListNew = async (offset) => {
        let filterConditions = persona === "nokia" ? `STR_TO_DATE(posting_date, '%m/%d/%Y') >= '${getToFromDate(merchantDashboard.merchant.dashboard.analytics.id, "fromDate")}' AND STR_TO_DATE(posting_date, '%m/%d/%Y') <= '${getToFromDate(merchantDashboard.merchant.dashboard.analytics.id, "toDate")}' AND sales_order_id in ('${selectedSalesOrderID.join("','")}')` : ` STR_TO_DATE(posting_date, '%m/%d/%Y') >= '${getToFromDate(merchantDashboard.merchant.dashboard.analytics.id, "fromDate")}' AND STR_TO_DATE(posting_date, '%m/%d/%Y') <= '${getToFromDate(merchantDashboard.merchant.dashboard.analytics.id, "toDate")}'`
        filterConditions = filterConditions + ' and market is not null'
        try {
            const response = await OOT.getAllMerchantOrders(filterConditions, limitPerpage, offset);
            if (response.data.length !== 0) {
                dispatch(setDashboard({ field: 'orderList', value: response.data }));
            } else {
                dispatch(setDashboard({ field: 'orderList', value: [] }));
            }

        } catch (error) {
            console.error(error);
        } finally {
            dispatch(setLoader({ key: "isProductsLoading", value: false }));
        }
    }

    const columnDefsList = [
        { headerName: 'Order ID', field: 'sales_order_id', minWidth: 300 },
        { headerName: 'Sell-to-party', field: 'name', minWidth: 400 },
        { headerName: 'Order date', field: 'postingDateFormate', minWidth: 300 },
        { headerName: 'Amount(EUR)', field: 'net_value_transactional_currency', minWidth: 300,
            cellRenderer: (params) => {
                return (
                    <div>
                        {formatNumberWithCommas(params?.data.net_value_transactional_currency)}
                    </div>
                );
            }
         }
    ]

    return (
        <>
            {merchantSelector.isProductsLoading ? (<div style={{ padding: '20px' }}><Skeleton height={20} width={1000} /></div>) : (<div style={{ width: '100%', height: '100%', marginTop: '10px' }}>
                <div>
                    Invoicing schedule
                </div>
                <DataGrid
                    isRowDrag={false}
                    wrapperProps={{
                        style: { width: '100%' }
                    }}
                    sideBar
                    suppressContextMenu
                    onGridReady={(params) => {
                        gridApi.current = params.api;
                    }}
                    rowData={merchantSelector.dashboard.orderList}
                    columnDefs={columnDefsList}
                    key={'Order'}
                    defaultColDef={{
                        editable: false,
                        sortable: true,
                        flex: 0,
                        minWidth: 30,
                        filter: true,
                        resizable: false
                    }}
                    overlayNoRowsTemplate="No orders are available"
                    isInlineRowActions={false}
                    domLayout="autoHeight"
                    getRowClass={(params) => {
                        return params.data.isDisabled ? userStyles.disabledRow : '';
                    }}
                />
            </div>)}
            <Pagination disableProp={merchantSelector.dashboard.orderList}  totalItems={merchantSelector.dashboard.orders.ordersData.length}  type="product" />
        </>
    )
}

export default React.memo(OrderList);