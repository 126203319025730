import React, { useState, useEffect, useRef } from "react";
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import Label from '@nokia-csf-uxr/ccfk/Label';
import { setProp } from "Store/Actions";
import userStyles from "../../Style/UserManagement.module.css";
import HierachyTree from "../components/HierachyTree";
import AccountListSmartSelect from "../components/AccountListSmartSelect";
import { Button, Skeleton, TextInput } from "@nokia-csf-uxr/ccfk";
import { setUserInputFields, updateSelectedPersonas, updateRoles, setOotRoles } from "Store/contentState/userManagementSlice";
import SelectBox from "Pages/subscription/Custom/SelectBox";
import CommonFormFields from "./CommonFormFields";
import OOT from "Constants/services/oot";
import { modifyData, getRequestedInfo,getAccountDropdownLabel } from "../utils/constants";
import CommonNameEmailField from "./CommonEmailField";
import SelectBoxWithCustomSearch from "Pages/custom-components/SelectBoxWithCustomSearch";
import CommonFormOOTApprover from "./CommonFormOOTApprover";
import CommonOrganizationType from "./CommonOrganizatinoType";
import Loader from "Components/Elements/Loader";
import debounce from "lodash.debounce";
import AccountReference from "../components/AccountReference";
import SelectSearchBox from "Pages/custom-components/SelectSearchBox";



const UserFormDetails = (prop) => {
  const dispatch = useDispatch();
  const userFullName = useSelector((state: RootState) => state.userManagement.userData.fullName);
  const userOrgType = useSelector((state: RootState) => state.userManagement.userData.orgType);
  const organizationTypes = useSelector((state: RootState) => state.userManagement.metaData.persona);
  const modifiedOrganizationTypes = modifyData(organizationTypes, 'id', 'name');
  const allAccountsForExternalStore = useSelector((state: RootState) => state.userManagement.allAccountsForExternal);
  const supplierAccount = useSelector((state: RootState) => state.userManagement.userData.supplier_account_id);
  const accountThreeStar = useSelector((state: RootState) => state.userManagement.userData.threeStarNumber);
  const isAddingNokiaUser = useSelector((state: RootState) => state.userManagement.isAddingNokiaUser);
  const isEmailEntered = useSelector((state: RootState) => state.userManagement.isEmailEntered);
  const loadDataList = useSelector((state: RootState) => state.userManagement.loadDataList);
  const allAccountList = useSelector((state: RootState) => state.userManagement.allAccountList);
  const { oneStarNumber, threeStarNumber } = useSelector((state: RootState) => state.userManagement.userData);
  const [allAccounts, setAllAccounts] = useState([]);
  const isEditMode = prop.mode === 'edit';
  const selectItemsList = useSelector((state: RootState) => state.userManagement.selectedItems);
  const label = getAccountDropdownLabel(userOrgType[0], modifiedOrganizationTypes);
  const [data, setData] = useState([]);
  const [loadingAccount, setLoadingAccount] = useState(false);
  const [serachLoadingAccount, setSerachLoadingAccount] = useState(false);
  let allAccountsForExternal = allAccountsForExternalStore
  const selectedRoles = useSelector((state: RootState) => state.userManagement.userData.roles);
  const previousValueRef = useRef();
  const limitPerpage = 10
  const currentRecourd = 20
  const mailDomain = useSelector((state: RootState) => state.userManagement.mailDomain);
  const personaList = useSelector((state: RootState) => state.userManagement.metaData.persona);
  let mailDomainType = ""

  const handleUserFullName = (name) => {
    dispatch(setUserInputFields({ field: 'fullName', value: name }));
  };

  useEffect(() => {

    if (userOrgType && userOrgType.length > 0 && userOrgType[0] !== "nokia") {
      if (previousValueRef.current != userOrgType[0] && isEditMode) {
        previousValueRef.current = userOrgType[0];
        getDomainName(mailDomain)
        fetchAllAccount("", 0, false)
      }

    }

  }, [userOrgType]);


  const getDomainName = async (domain) => {
    dispatch(setProp({ slice: 'userManagement', key: 'isMailDomainLoading', value: true }));
    try {
      const filter = `account_domains LIKE '%${domain}%'`
      const response = await OOT.getDomainNameList(filter, 10, 0);
      if (response.status && response.data.length != 0) {
        let org_type = response.data[0].record_type.replace(/"/g, "").toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
       //let org_type = "Direct Reseller"
        let person = personaList.filter((item) => item.name === org_type)
        mailDomainType = person ? person[0].id : ''
      }
      dispatch(setProp({ slice: 'userManagement', key: 'isMailDomainLoading', value: false }));

    } catch (error) {
      console.error('Error fetching data:', error);

    }
  };


  const fetchAllAccount = async (filterValue, offsetValue, loadingCheck) => {

    allAccountsForExternal = []
    setSerachLoadingAccount(true)
    try {

      let requested_info = getRequestedInfo(userOrgType[0]);
      //@ts-ignore
      let filter = generateFilter(requested_info, filterValue);
      const limit = 10;
      const offset = calculateOffset(filterValue, allAccountsForExternal, offsetValue);



      let response = {};
      let responsenew = {};

      //@ts-ignore
      if (requested_info === "supplier" || requested_info === "merchant") {
        response = await OOT.getAccountDetailsMerchant(filter, limit, offset, [], requested_info);
      } else {
        response = await OOT.getAccountsForInternalExternal(filter, limit, offset, requested_info);
      }

      if (response['status']) {
        //console.log(response['data'], 'response.data');
        //@ts-ignore
        if (requested_info !== "supplier" || requested_info !== "merchant") {
          setAllAccounts(response['data']);
        }
        //let modifiedAccounts = modifyData(response['data'], requested_info !== "supplier" ? requested_info !== "merchant" ? 'parent_account_number' : 'account_id' : 'acount_id', 'name');
        let modifiedAccounts = modifyData(
          response['data'],
          requested_info !== "supplier" && requested_info !== "merchant"
            ? 'parent_account_number'
            : 'account_id',
          requested_info !== "supplier" && requested_info !== "merchant"
            ? ['name', 'customer_account_number', 'parent_account_number']  // Replace 'key1', 'key2' with the actual keys you want to combine
            : 'name'
        );
        dispatch(setProp({ slice: 'userManagement', key: 'loadDataList', value: response['data'].length === 0 ? false : true }))

        let datalistData = [...allAccountsForExternal, ...modifiedAccounts]

        let datalist = [...datalistData].sort((a, b) => a.name ? a.value.localeCompare(b.value) : null);


        let allAccountListDataList = [...allAccountList, ...response['data']]
      

        let allAccountListData = [...allAccountListDataList].sort((a, b) => a.name ? a.name.localeCompare(b.name) : null);
        let accountthreestart =userOrgType[0] === "merchant" ? supplierAccount[0] : accountThreeStar[0]

        if (isEditMode && accountthreestart) {
          
          let userOneStartNum = datalist.filter((item) => item.id === oneStarNumber[0])
          if (userOneStartNum && userOneStartNum.length === 0) {
            // setEditUserOneStartNum(true)
            if (requested_info === "supplier" || requested_info === "merchant") {
              responsenew = await OOT.getAccountDetailsMerchant(filter, limit, offset, prop.selectedData.supplier_account_id, requested_info,);
            }
            else {
              responsenew = await OOT.getAccountsForInternalExternal(filter, limit, offset, requested_info, oneStarNumber);
            }

            //let modifiedAccountsNew = modifyData(responsenew['data'], requested_info !== "supplier" ? requested_info !== "merchant" ? 'parent_account_number' : 'account_id' : 'account_id', 'name', true);

            let modifiedAccountsNew = modifyData(
              responsenew['data'],
              requested_info !== "supplier" && requested_info !== "merchant"
                ? 'parent_account_number'
                : 'account_id',
              requested_info !== "supplier" && requested_info !== "merchant"
                ? ['name', 'customer_account_number', 'parent_account_number']  // Replace 'key1', 'key2' with the actual keys you want to combine
                : 'name', true
            );
            //const mergedArray = [...datalist, ...modifiedAccountsNew].reduce((acc, current) => {
            const mergedArray = modifiedAccountsNew.reduce((acc, current) => {
              
              const duplicate = acc.find(item => item.id === current.id && item.value === current.value);
              if (!duplicate) {
                acc.push(current);
              } else if (current.select) {
                // If duplicate exists, prioritize the one with 'select: true'
                duplicate.select = true;
              }
              return acc;
            }, [])

            let datalistNew = mergedArray.sort((a, b) => a.value ? a.value.localeCompare(b.value) : null);
            dispatch(setProp({ slice: 'userManagement', key: 'selectedItems', value: modifiedAccountsNew }))
            dispatch(setProp({ slice: 'userManagement', key: 'allAccountsForExternal', value: datalistNew }))
            let allAccountListDataNew = [...allAccountListData, ...responsenew['data']]
            dispatch(setProp({ slice: 'userManagement', key: 'allAccountList', value: allAccountListDataNew }))
          }
        }
        else {
          //dispatch(setProp({ slice: 'userManagement', key: 'allAccountsForExternal', value: datalist }))
          //dispatch(setProp({ slice: 'userManagement', key: 'allAccountList', value: allAccountListData }))
        }
      } else {
        dispatch(setProp({ slice: 'userManagement', key: 'allAccountsForExternal', value: [] }))
      }
      setSerachLoadingAccount(false)
    } catch (error) {
      console.log(error);
    }
  }


  // Helper to generate the filter condition
  const generateFilter = (requested_info, filterValue) => {
    if (requested_info === "merchant") {
      let filter = "is_merchant = 1";
      if (filterValue) {
        filter += ` AND name LIKE '%${filterValue}%'`;
      }
      return filter;
    }
    if(isEditMode){
      if(mailDomainType){
        return  filterValue ? `name LIKE '%${filterValue}%' AND name NOT LIKE '%nokia %' AND account_domains LIKE '%${mailDomain}%'` : "";
      }
      else{
      return filterValue ? `name LIKE '%${filterValue}%' AND name NOT LIKE '%nokia %' ` : "";
      }
    }
    else{
      return  filterValue ? `name LIKE '%${filterValue}%' AND name NOT LIKE '%nokia %' AND account_domains LIKE '%${mailDomain}%' AND account_id IS NOT NULL` : "";
    }
  };

  // Helper to calculate the offset
  const calculateOffset = (filterValue, allAccountsForExternal, offsetValue) => {
    return filterValue !== '' ? 0 : allAccountsForExternal.length + offsetValue;
  };






  const handleAccountSelection = (account, status) => {
    if (account) {
      let filteredAccount = allAccountsForExternal.filter((item) => item.id === account);
      if (userOrgType[0] === "merchant") {
        dispatch(setUserInputFields({ field: 'supplier_account_id', value: [filteredAccount[0]?.id] }));
        dispatch(setUserInputFields({ field: 'threeStarNumber', value: [] }));
        dispatch(setUserInputFields({ field: 'oneStarNumber', value: [] }));
        dispatch(setUserInputFields({ field: 'business_name', value: [] }));
      } else {
        let accountDetails = allAccountList.filter((item) => item.parent_account_number === account);
        dispatch(setUserInputFields({ field: 'threeStarNumber', value: [accountDetails[0].parent_account_number] }));
        dispatch(setUserInputFields({ field: 'oneStarNumber', value: [accountDetails[0].customer_account_number] }));
        dispatch(setUserInputFields({ field: 'business_name', value: [accountDetails[0].business_name] }));
        dispatch(setOotRoles({ type: 'oneStarNum', value: [accountDetails[0].customer_account_number] }));
      }
      dispatch(setProp({ slice: 'userManagement', key: 'selectedItems', value: [] }))
    }
    else {
      dispatch(setProp({ slice: 'userManagement', key: 'allAccountsForExternal', value: [] }))
      fetchAllAccount("", 0, status)
      // if(!isEditMode){
        dispatch(setProp({ slice: 'userManagement', key: 'selectedItems', value: [] }))
        dispatch(setUserInputFields({ field: 'supplier_account_id', value: [] }));
        dispatch(setUserInputFields({ field: 'threeStarNumber', value: [] }));
        dispatch(setUserInputFields({ field: 'oneStarNumber', value: [] }));
        dispatch(setUserInputFields({ field: 'business_name', value: [] }));
        dispatch(setOotRoles({ type: 'oneStarNum', value: [] }));
      // }
    }

  }



  const handleEnterAccountName = (account, clear) => {
    if(!clear){
    if (prop.mode !== "edit") {
      dispatch(setProp({ slice: 'userManagement', key: 'allAccountsForExternal', value: [] }))
    }


    const fetchDatanew = async () => {
      // setLoadingAccount(true)
      //setSerachLoadingAccount(true)
      let requested_info = getRequestedInfo(userOrgType[0]);
      //setLoadingAccount(false)
      const allData = await fetchAllAccountNew(account, 0);
      if (allData.length === 0) {
        dispatch(setProp({ slice: 'userManagement', key: 'searchAccountList', value: true }))
      }

      setData(allData);
      setSerachLoadingAccount(false)
      //let modifiedAccounts = modifyData(allData, requested_info !== "supplier" ? requested_info !== "merchant" ? 'parent_account_number' : 'account_id' : 'acount_id', 'name');
      let modifiedAccounts = modifyData(
        allData,
        requested_info !== "supplier" && requested_info !== "merchant"
          ? 'parent_account_number'
          : 'account_id',
        requested_info !== "supplier" && requested_info !== "merchant"
          ? ['name', 'customer_account_number', 'parent_account_number']  // Replace 'key1', 'key2' with the actual keys you want to combine
          : 'name'
      );
      dispatch(setProp({ slice: 'userManagement', key: 'loadDataList', value: allData.length === 0 ? false : true }))
      let datalist = [...allAccountsForExternal, ...modifiedAccounts].sort((a, b) => a.value ? a.value.localeCompare(b.value) : null);

      let allAccountListData = [...allAccountList, ...allData]
      if (prop.mode == 'edit') {
        let userOneStartNum = datalist.filter((item) => item.id === prop.selectedData.oneStarNumber[0])
        if (userOneStartNum && userOneStartNum.length === 0 && selectItemsList.length === 0) {
          // setEditUserOneStartNum(true)

          //let modifiedAccountsNew = modifyData(allData, requested_info !== "supplier" ? requested_info !== "merchant" ? 'parent_account_number' : 'account_id' : 'account_id', 'name', true);
          let modifiedAccountsNew = modifyData(
            allData,
            requested_info !== "supplier" && requested_info !== "merchant"
              ? 'parent_account_number'
              : 'account_id',
            requested_info !== "supplier" && requested_info !== "merchant"
              ? ['name', 'customer_account_number', 'parent_account_number']  // Replace 'key1', 'key2' with the actual keys you want to combine
              : 'name', true
          );
          let datalistNew = [...datalist, ...modifiedAccountsNew].sort((a, b) => a.value.localeCompare(b.value));
          dispatch(setProp({ slice: 'userManagement', key: 'selectedItems', value: modifiedAccountsNew }))
          dispatch(setProp({ slice: 'userManagement', key: 'allAccountsForExternal', value: datalistNew }))
          let allAccountListDataNew = [...allAccountListData, ...allData]
          dispatch(setProp({ slice: 'userManagement', key: 'allAccountList', value: allAccountListDataNew }))
        }
      }
      dispatch(setProp({ slice: 'userManagement', key: 'allAccountsForExternal', value: datalist }))
      dispatch(setProp({ slice: 'userManagement', key: 'allAccountList', value: allAccountListData }))
      //setLoadingAccount(false)
    };
    if (account != "") {
      fetchDatanew();
    }
    else {
      handleAccountSelection(account, true)
    }
  }
  else{
    dispatch(setProp({ slice: 'userManagement', key: 'allAccountsForExternal', value: [] }))
        dispatch(setProp({ slice: 'userManagement', key: 'selectedItems', value: [] }))
        dispatch(setUserInputFields({ field: 'supplier_account_id', value: [] }));
        dispatch(setUserInputFields({ field: 'threeStarNumber', value: [] }));
        dispatch(setUserInputFields({ field: 'oneStarNumber', value: [] }));
        dispatch(setUserInputFields({ field: 'business_name', value: [] }));
        dispatch(setOotRoles({ type: 'oneStarNum', value: [] }));
  }
  }

  const fetchAllAccountNew = async (filterValue, page = 0, accumulatedData = []) => {
    const filterConditions = filterValue;
    setSerachLoadingAccount(true)
    allAccountsForExternal = []

    try {
      // const response = await OOT.getAllMerchantOrders(filterConditions, limitPerpage, page);
      let requested_info = getRequestedInfo(userOrgType[0]);
      //@ts-ignore
      let filter = generateFilter(requested_info, filterValue);

      let response = {};
      //@ts-ignore
      if (requested_info === "supplier" || requested_info === "merchant") {
        response = await OOT.getAccountDetailsMerchant(filter, limitPerpage, page, [], requested_info);
      } else {
        response = await OOT.getAccountsForInternalExternal(filter, limitPerpage, page, requested_info);
      }
      const newData = [...accumulatedData, ...response['data']];
      if (response['data'].length >= limitPerpage && newData.length <= currentRecourd) {
        return fetchAllAccountNew(filterConditions, page + response['data'].length, newData);

      } else {
        return newData;

      }

    } catch (error) {
      console.error('Error fetching data:', error);
      return accumulatedData;
    }


  };



  return (
    <>
      <div className={userStyles.flexContainer}>
        <div className={`${userStyles.marginBottom}`}>
          <Label className={userStyles.userLabel}>Full name <span style={{ color: '#C91514' }}>*</span></Label>

          <TextInput
            id="full-name"
            disabled={prop.mode === "edit" && prop.selectedData.name.trim() !== ""}
            value={userFullName}
            variant={"outlined"}
            placeholder="Enter full name"
            onChange={(e) => handleUserFullName(e.target.value)}
          />

        </div>

        <CommonNameEmailField mode={prop.mode} />
        <CommonOrganizationType mode={prop.mode} />

        {/* <div className={userStyles.gridContainerNew}>
        {loadingAccount && <Skeleton width="350px" height="30px" />}
        {!loadingAccount && <AccountListSingleSelect
          data={allAccountsForExternal}
          caseInsentiveMatching={true}
          selectedItem={userOrgType[0] === "merchant" ? supplierAccount[0] : accountThreeStar[0]}
          onUpdateSelection={handleAccountSelection}
          isLoading={serachLoadingAccount}
          onSearchAccount={handleEnterAccountName}
        />}
        
      </div> */}

        {(isAddingNokiaUser || (userOrgType[0] && !isAddingNokiaUser)) && <CommonFormFields mode={prop.mode} />}

        {selectedRoles[0] !=="sales_view_only_sales" && <CommonFormOOTApprover mode={prop.mode} />}
      </div>



      <div className={userStyles.gridContainerNew}>
        {((isEmailEntered && !isAddingNokiaUser && userOrgType[0] && allAccountsForExternal) || (prop.mode === 'edit' && userOrgType[0] !== 'nokia')) && <div className={userStyles.gridItem}>
          <div className={userStyles.selectBoxStyle}>
            <div style={{ width: '100%' }}>
              <Label className={userStyles.userLabel}>{label} <span style={{ color: '#C91514' }}>*</span></Label>

              <SelectSearchBox 
                data={allAccountsForExternal} 
                onSearchAccount={handleEnterAccountName}
                isLoading={serachLoadingAccount} 
                onUpdateSelection={handleAccountSelection} 
                selectedItem={userOrgType[0] === "merchant" ? supplierAccount[0] : accountThreeStar[0]} 
                />
              {/* {loadingAccount &&  <Skeleton width="350px" height="30px" />}
                
                {!loadingAccount && <SelectBoxWithCustomSearch
                // disabled={marketplaceSelector.selectedDeviceID !== '' ? true : false }
                placeholder={`Search`}
                placeholderIcon = {true}
                maxWidth={true}
                caseInsentiveMatching={true}
                selectedItem={userOrgType[0] === "merchant" ? supplierAccount[0] : accountThreeStar[0]}
                data={allAccountsForExternal}
                isOpenValue={false}
                onUpdateSelection={handleAccountSelection}
                isLoading={serachLoadingAccount}
                onSearchAccount={handleEnterAccountName}
              />} */}
            </div>
          </div>
        </div>
        }
        {((isEmailEntered && isAddingNokiaUser) || (prop.mode === 'edit' && userOrgType[0] === 'nokia')) && (
          <><div className={userStyles.gridItem}>
            <HierachyTree mode={prop.mode} />
          </div><div className={userStyles.gridItem}>
              <AccountListSmartSelect mode={prop.mode} />
            </div></>)}
        {/* {!loadingAccount && oneStarNumber.length > 0 && threeStarNumber.length > 0 && userOrgType[0] !== 'nokia' && <div className={userStyles.gridItem}><AccountReference /></div>} */}
      </div>
    </>
  )
}

export default UserFormDetails;