import React from "react";
import CommonEmailField from "./CommonEmailField";
import CommonFormFields from "./CommonFormFields";
import userStyles from "../../Style/UserManagement.module.css"



const WhiteListedClient = (prop) => {
    return (
        <>
            <div className={userStyles.gridContainerNew}>
                <CommonEmailField mode={prop.mode} />
                <CommonFormFields mode={prop.mode} />
            </div>
        </>
    )

}

export default WhiteListedClient;